import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import AdSwitcher from '../../components/Ads/switcher'
import { useDispatch, useSelector } from 'react-redux'
import { getAdDetails } from '../../redux/actions/ads'
import FeedSponsor from '../../components/sponsors/feed-sponsor'
import CardLayout from '../../components/layouts/card'
import { isWagerClose } from '../../helper'
import { MyWagerItem } from '../my-wagers/myWagers'
import { useTheme } from '@emotion/react'
import { listMyWaggers } from '../../redux/actions/myWager'
import AppLayout from '../../components/layouts/app'
import { getTrendingEvents } from '../../redux/actions/eventId'
import ReactSwipe from 'react-swipe'
import EventAd from '../../components/Ads/event'
import { useNavigate } from 'react-router-dom'

const Home = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const theme = useTheme()
    let reactSwipeEl = useRef(null);

    const adData = useSelector((state) => state?.Ads.ads.result)
    const adDataLoading = useSelector((state) => state?.Ads.isLoading)
    const overallLeagues = useSelector((state) => state.Leagues);

    const trendingEvents = useSelector((state) => state?.trendingEvents?.result)
    const trendingEventsLoading = useSelector((state) => state?.trendingEvents.isLoading)

    const wagerResponseData = useSelector((state) => state.myWager)

    useEffect(() => {
        dispatch(getAdDetails(1))
        dispatch(
            listMyWaggers({
                skip: 0,
                limit: 10,
                filter: {
                    mywagers: 'all',
                    mode: 'fantasy'
                }
            })
        )
        dispatch(getTrendingEvents())
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <Stack
            spacing={6}
            sx={{
                pb: '84px'
            }}>

            <CardLayout bubble bgActive>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Typography>Sponsors</Typography>
                    {
                        !adDataLoading &&
                        adData?.slice(0, 2)?.map((item, index) => (
                            <FeedSponsor data={item} grouped key={index} />
                        ))
                    }
                </Stack>
            </CardLayout>


            <Stack spacing={2}>
                <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
                    <Typography>My Wagers</Typography>
                    <Button variant='text' size='small' onClick={() => navigate('/my-wagers')}>Go to My Wagers</Button>
                </Stack>
                {
                    !wagerResponseData?.isLoading &&
                    <CardLayout bubble nopadding>
                        <Stack spacing={0}>
                            {
                                wagerResponseData?.myWager?.result?.slice(0, 2).map((item, index) => {
                                    const wagerStatus = isWagerClose(item?.event_data?.startTime)
                                    return (
                                        <>
                                            <div key={index}>
                                                <MyWagerItem item={{
                                                    ...item,
                                                    wagerStatus: wagerStatus
                                                }} />
                                            </div>

                                            {
                                                index !== wagerResponseData?.myWager?.result?.length - 1 ? <Divider sx={{ bgcolor: theme.palette.dark.otherdark }} key={index} /> : null
                                            }
                                        </>
                                    )
                                }
                                )
                            }
                        </Stack>
                    </CardLayout>
                }
            </Stack>

            <Stack spacing={2}>
                <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
                    <Typography>Trending Games</Typography>
                </Stack>
                {

                    <CardLayout bubble nopadding>
                        <Stack spacing={0}>
                            {
                                trendingEvents?.results?.map((item, index) =>
                                    <>
                                        <EventAd data={item} />
                                        {
                                            index !== trendingEvents?.results?.length - 1 ? <Divider sx={{ bgcolor: theme.palette.dark.otherdark }} key={index} /> : null
                                        }
                                    </>
                                )
                            }
                        </Stack>
                    </CardLayout>
                }
            </Stack>
        </Stack>
    )
}

export default Home
