import axios from './axios'
import authHeader from './auth-header'

const getUserProfile = (userId) => {
  if (userId) {
    return axios.get(`profile/get?profile=${userId}`, {
      headers: authHeader()
    })
  }
  return axios.get('profile/get', {
    headers: authHeader()
  })
}

const updateUserName = (username) => {
  return axios.post(
    'profile/change-username',
    {
      username
    },
    {
      headers: authHeader()
    }
  )
}
const updateUserPwd = (password, newPassword, confirmPassword) => {
  return axios.post(
    'profile/change-password',
    {
      password,
      newPassword,
      confirmPassword
    },
    {
      headers: authHeader()
    }
  )
}

const updateAddress = (streetAddress, address) => {
  return axios.post(
    'profile/change-address',
    {
      streetAddress,
      address
    },
    {
      headers: authHeader()
    }
  )
}

const updateProfilePicture = (userLogo) => {
  return axios.post(
    'profile/change-profile-picture',
    {
      userLogo
    },
    {
      headers: authHeader()
    }
  )
}

const uploadPersonalProfile = (full_name, dateOfBirth, address) => {
  return axios
    .post('/profile/create/profile', {
      full_name: full_name,
      dateOfBirth: dateOfBirth,
      address: address
    }, {
      headers: authHeader()
    })
}

const profileService = {
  getUserProfile,
  updateUserName,
  updateUserPwd,
  updateAddress,
  updateProfilePicture,
  uploadPersonalProfile
}

export default profileService
