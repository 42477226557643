import { LIST_MY_WAGERS, LIST_MY_WAGERS_REQUEST, GET_WAGER_HISTORY, LIST_MY_WAGERS_ERROR, GET_WAGER_HISTORY_ERROR } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import WagersServices from '../../../services/wagers'

export const listMyWaggers = createAsyncThunk(
  '/wagers/my-wagers',
  async ({ skip, limit, filter }, thunkAPI) => {
    thunkAPI.dispatch({
      type: LIST_MY_WAGERS_REQUEST
    })
    try {
      const data = await WagersServices.getMyWagers(skip, limit, filter)
      thunkAPI.dispatch({
        type: LIST_MY_WAGERS,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
      thunkAPI.dispatch({
        type: LIST_MY_WAGERS_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const listUserWaggers = createAsyncThunk(
  '/wagers/user-wagers/id',
  async ({ skip, limit, filter, id }, thunkAPI) => {
    thunkAPI.dispatch({
      type: LIST_MY_WAGERS_REQUEST
    })
    try {
      const data = await WagersServices.getUserWagers(skip, limit, filter, id)
      thunkAPI.dispatch({
        type: LIST_MY_WAGERS,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
      thunkAPI.dispatch({
        type: LIST_MY_WAGERS_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const myWagersHistory = createAsyncThunk(
  '/wagers/wager-history',
  async ({ skip, limit, filter }, thunkAPI) => {
    thunkAPI.dispatch({
      type: LIST_MY_WAGERS_REQUEST
    })
    try {
      const data = await WagersServices.getWagerHistory(skip, limit, filter, {
        sortBy: 'createdAt',
        sort: 'desc'
      })
      thunkAPI.dispatch({
        type: GET_WAGER_HISTORY,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
      thunkAPI.dispatch({
        type: GET_WAGER_HISTORY_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
