import { ADD_CHIPS_ERROR, ADD_CHIPS, ADD_CHIPS_REQUEST, CHIP_BALANCE_REQUEST, CHIP_BALANCE, CHIP_BALANCE_ERROR, WITHDRAW_CHIPS_REQUEST, WITHDRAW_CHIPS, WITHDRAW_CHIPS_ERROR, TRANSCRIPT_REQUEST, TRANSCRIPT, TRANSCRIPT_ERROR } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import chipsServices from '../../../services/chips'

export const addChips = createAsyncThunk(
  '/chip-banks/add',
  async ({ amount }, thunkAPI) => {
    thunkAPI.dispatch({
      type: ADD_CHIPS_REQUEST
    })
    try {
      const data = await chipsServices.addChips(amount)
      thunkAPI.dispatch({
        type: ADD_CHIPS,
        payload: data && data?.data
      })
      return { chips: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      thunkAPI.dispatch({
        type: ADD_CHIPS_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const withdrawChips = createAsyncThunk(
  '/chip-banks/withdraw',
  async ({ amount }, thunkAPI) => {
    thunkAPI.dispatch({
      type: WITHDRAW_CHIPS_REQUEST
    })
    try {
      const data = await chipsServices.withdrawChips(amount)
      thunkAPI.dispatch({
        type: WITHDRAW_CHIPS,
        payload: data && data?.data
      })
      return { chips: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      const redirectUrl = error?.response?.data?.redirectUrl

      thunkAPI.dispatch({
        type: WITHDRAW_CHIPS_ERROR,
        payload: { message, redirectUrl }
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const chipsBalance = createAsyncThunk(
  '/chip-banks/chip-balances',
  async ({ sportsId }, thunkAPI) => {
    thunkAPI.dispatch({
      type: CHIP_BALANCE_REQUEST
    })
    try {
      const data = await chipsServices.chipBalance(sportsId)
      thunkAPI.dispatch({
        type: CHIP_BALANCE,
        payload: data && data?.data
      })
      return { chips: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      thunkAPI.dispatch({
        type: CHIP_BALANCE_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const getTranscripts = createAsyncThunk(
  '/chip-banks/transcripts',
  async ({ skip, limit }, thunkAPI) => {
    thunkAPI.dispatch({
      type: TRANSCRIPT_REQUEST
    })
    try {
      const data = await chipsServices.getTranscript(skip, limit)
      thunkAPI.dispatch({
        type: TRANSCRIPT,
        payload: data && data?.data
      })
      return { chips: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      thunkAPI.dispatch({
        type: TRANSCRIPT_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
