import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { ValidateEmail, isValidName } from '../../helper'
import { useDispatch, useSelector } from 'react-redux'
import { sendFeedback } from '../../redux/actions/feedback'
import AWS from 'aws-sdk'

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_ID
})
const S3_BUCKET = process.env.REACT_APP_BUCKETNAME
const REGION = process.env.REACT_APP_REGION
const myBucket = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: S3_BUCKET },
  region: REGION
})

window.Buffer = window.Buffer || require('buffer').Buffer
function SendFeedback() {
  const feedbackResponse = useSelector((state) => state.sendFeedback)?.feedback?.status
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const dispatch = useDispatch()
  const [feedbackPayload, setFeedbackPayload] = useState({
    name: '',
    email: '',
    message: '',
    attachment: '',
    device: '',
    browserName: '',
    browserVersion: ''
  })
  const handleShow = () => {
    setShow(true)
  }
  const [disabledButton, setDisabledButton] = useState(true)
  const [showForm, setShowForm] = useState(false)
  const [confirmation, setConfirmation] = useState(false)
  const [photo, setPhoto] = useState(null)
  const [loading, setLoading] = useState(false)
  const ref = useRef(null)
  const handleCloseModal = () => {
    handleRemoveAttachment()
    setShowForm(false)
  }
  const handleConfirmation = () => setConfirmation(false)
  const handleModal = () => {
    setShowForm(true)
  }
  useEffect(() => {
    if (feedbackPayload.email.trim().length > 0 && feedbackPayload.name.trim().length > 0 && feedbackPayload.message.trim().length > 19) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }, [feedbackPayload])

  useEffect(() => {
    if (loading) {
      setDisabledButton(true)
    } else if (feedbackPayload.email.trim().length > 0 && feedbackPayload.name.trim().length > 0 && feedbackPayload.message.trim().length > 19) {
      setDisabledButton(false)
    }
  }, [loading])

  const handlePhoto = (e) => {
    setPhoto(e.target.files[0])
    setLoading(true)
  }

  const uploadFile = async (file) => {
    try {
      const fileUrl = await myBucket
        .upload({
          ACL: 'public-read',
          Bucket: process.env.REACT_APP_BUCKETNAME,
          Key: 'profile/' + file.name,
          Body: file,
          ContentEncoding: 'base64'
        })
        .promise()
      setFeedbackPayload({
        ...feedbackPayload,
        attachment: fileUrl.Location
      })
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (photo) {
      uploadFile(photo)
    }
  }, [photo])

  const getDeviceType = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  function getBrowser() {
    const ua = navigator.userAgent
    let tem
    let M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []
      return { name: 'IE', version: tem[1] || '' }
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/)
      if (tem != null) {
        return { name: 'Opera', version: tem[1] }
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1])
    }
    return {
      name: M[0],
      version: M[1]
    }
  }
  useEffect(() => {
    const browser = getBrowser()
    const deviceType = getDeviceType()
    setFeedbackPayload({
      ...feedbackPayload,
      device: deviceType,
      browserName: browser.name,
      browserVersion: browser.version
    })
  }, [])

  const handleForm = (e) => {
    e.preventDefault()
    dispatch(sendFeedback(feedbackPayload))
    // handleCloseModal()
  }
  const showConfirmation = () => {
    setConfirmation(true)
    setTimeout(handleConfirmation, 2000)
    setTimeout(handleCloseModal, 2000)
    setFeedbackPayload({
      ...feedbackPayload,
      attachment: '',
      name: '',
      email: '',
      message: ''
    })
  }
  useEffect(() => {
    if (feedbackResponse) {
      showConfirmation()
    }
  }, [feedbackResponse])

  const handleRemoveAttachment = () => {
    ref.current.value = ''
    setPhoto(null)
    setFeedbackPayload({
      ...feedbackPayload,
      attachment: ''
    })
  }
  return (
    <>
      <div className='fullScreen fullScreen-login login-solid'>
        <div className='pageTop forDrop bgDark'>
          <div className='backLink'>
            <Link to='/account-settings'>
              <div className='backLink-icon noBg'>
              </div>
            </Link>
            <span className='mt-0'>Send Feedback</span>
          </div>
        </div>

        <div className='loginWrap scrollable-notification'>
          <div className='editPro'>
            <div className='row'>
              <div className='col-6'>
                <div className='feedIcon  cursor-pointer' onClick={handleModal}>
                </div>
                <p className='cursor-pointer text-center fw-bold pt-2'>Email</p>
              </div>
              <div className='col-6'>
                <div className='feedIcon  cursor-pointer' onClick={handleShow}>
                </div>
                <p className='cursor-pointer text-center fw-bold pt-2'>Call</p>
              </div>
            </div>
          </div>
        </div>

        <div className='pageBottom text-center blue'>
          <div className='editInpWrap text-center'>
          </div>
        </div>
      </div>
      {
        /*


      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        animation={false}
      >
        <div style={{ padding: '22px' }}>
          <p>Click on the phone number</p> <br />
          <a href='tel:+919810696842'>9810696842</a>
        </div>
      </Modal>

      <Modal
        show={showForm}
        onHide={handleCloseModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        animation={false}
      >
        <form className='p-4' onSubmit={(e) => handleForm(e)}>
          <div className='form-group mb-2'>
            <label htmlFor='email-recipients'>Email recipients</label>
            <input
              className='form-control'
              id='email-recipients'
              type='email'
              placeholder='Recipients'
              multiple
              required='required'
              onChange={(e) =>
                setFeedbackPayload({
                  ...feedbackPayload,
                  email: e.target.value.trim()
                })
              }
            />
            {feedbackPayload.email !== '' && !ValidateEmail(feedbackPayload.email) && (
                <p className='error mt-1'>Email is not valid. </p>
            )}
          </div>
          <div className='form-group mb-2'>
            <label htmlFor='name' onClick={showConfirmation}>Name</label>
            <input
              type='text'
              className='form-control'
              id='name'
              placeholder='Name'
              required='required'
              onChange={(e) =>
                setFeedbackPayload({ ...feedbackPayload, name: e.target.value.trim() })
              }
            />
            {feedbackPayload.name !== '' && !isValidName(feedbackPayload.name) && (
                <p className='error mt-2'> Numbers & Special Characters are not allowed! </p>
            )}
            <div className='valid-feedback'>Looks good!</div>
          </div>

          <div className='form-group mb-2'>
            <label>Message</label>
            <textarea
              className='form-control'
              id='message'
              rows='5'
              maxLength='160' minLength='20'
              placeholder='Minimum 20 words and Maximum 160 words'
              required='required'
              data-validation-required-message='Please enter a message.'
              onChange={(e) =>
                setFeedbackPayload({ ...feedbackPayload, message: e.target.value.trim() })
              }
            ></textarea>
          </div>
          <div className="form-group mb-2">
              <input type="file" onChange={(e) => handlePhoto(e)} accept="image/*,.pdf" ref={ref} />
          </div>
          <div className="my-2">
              {loading
                ? (
              <div>
                <p>Uploading...</p>
              </div>
                  )
                : (

                  <div className='attachment-img'>
                    {feedbackPayload.attachment &&
                    <>
                      <span className='attachmentClose' onClick={handleRemoveAttachment}>
                        <FaRegWindowClose />
                      </span>
                      <img alt="profile" src={feedbackPayload.attachment || ''} />
                    </>
                    }
                  </div>
                  )}
            </div>
          <button type='submit' className={!disabledButton ? 'btn btn-primary btn-xl' : 'btn btn-secondary btn-xl'} disabled={disabledButton} >
            Send
          </button>
        </form>
      </Modal>

      <Modal
        show={confirmation}
        onHide={handleConfirmation}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        animation={false}
      >
        <div style={{
          padding: '20px',
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: 600,
          backgroundColor: 'green',
          borderRadius: '7px'
        }}>
          Feedback submitted
        </div>
      </Modal>
        */
      }
    </>
  )
}

export default SendFeedback
