import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { wagerdetails } from '../../redux/actions/wagerdetails'
import { isWagerClose } from '../../helper'
import db from '../../firebase'
import handleBucket from '../../components/hooks/useBucket'
import {
  doc,
  setDoc,
  onSnapshot,
  collection,
  Timestamp,
  query,
  orderBy
} from 'firebase/firestore'
import Loader from '../../components/loader'
import { deactivateWager } from '../../redux/actions/wagers'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Avatar, Box, Button, Container, Divider, IconButton, Stack, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProfilePill from '../../components/wager/profile-pill'

function WagerDetailsNew(props) {

  const theme = useTheme()
  const { id: propId } = props;
  const { id: paramId } = useParams();
  const id = propId !== undefined ? propId : paramId;
  const user = JSON.parse(localStorage.getItem('user'))
  const navigate = useNavigate()
  const [selectedWager, setWagerDetails] = useState({})
  const [replyTo, setReplyTo] = useState({})
  const [repliesList, setRepliesList] = useState({})
  const [commentVotes, setCommentVotes] = useState({})
  const [commentRepliesVotes, setCommentRepliesVotes] = useState({})
  const dispatch = useDispatch()
  const [media, setMedia] = useState(null)
  const [deleteShow, setDeleteShow] = useState(false)
  const wagerStatus = isWagerClose(selectedWager?.event_data?.startTime)
  const wagerdetailsinfo = useSelector((state) => state?.wagerDetails)
  const location = useLocation()

  const getComments = async () => {
    try {
      const commentQuery = query(collection(db, 'wagers', id, 'comments'), orderBy('date', 'asc'))
      onSnapshot(commentQuery, (commentSnaps) => {
        const list = []
        commentSnaps.forEach((doc) => {
          list.push({ ...doc.data(), id: doc.id })
        })
        // fetching replies
        list.map(doc => {
          const replies = []
          const repliesQuery = query(collection(db, 'wagers', id, 'comments', doc.id, 'replies'), orderBy('date', 'asc'))

          onSnapshot(repliesQuery, (repliesSnap) => {
            repliesSnap.forEach(val => {
              replies.push({ ...val.data(), id: val.id, commentId: doc.id })
              // if (replies.filter(value => value.id === val.id).length === 0) {
              // }
            })

            const repliesObj = { [doc.id]: replies, ...repliesList }
            setRepliesList((prevState) => ({
              ...prevState,
              ...repliesObj
            }))

            // eslint-disable-next-line array-callback-return
            replies.map(doc => {
              onSnapshot(collection(db, 'wagers', id, 'comments', doc.commentId, 'replies', doc.id, 'vote'), (repCommentVoteSnap) => {
                const repliesVote = []
                repCommentVoteSnap.forEach(val => {
                  repliesVote.push({ ...val.data(), id: val.id })
                })
                const repliesVoteObj = { [doc.id]: repliesVote, ...commentRepliesVotes }
                setCommentRepliesVotes((prevState) => ({
                  ...prevState,
                  ...repliesVoteObj
                }))
              })
            })
            return true
          })
          // eslint-disable-next-line array-callback-return
          return true
        })

        // fetching votes of comments
        list.map(doc => {
          onSnapshot(collection(db, 'wagers', id, 'comments', doc.id, 'vote'), (votesSnap) => {
            const votes = []
            votesSnap.forEach(val => {
              votes.push({ ...val.data(), id: val.id })
            })
            const votesObj = { [doc.id]: votes, ...commentVotes }
            setCommentVotes((prevState) => ({
              ...prevState,
              ...votesObj
            }))
          })
          return true
        })
        // setCommentList(list.sort(function (a, b) {
        //   return new Date(a?.date) - new Date(b?.date)
        // }))
      }, error => {
        console.log(error)
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (id) {
      dispatch(wagerdetails({ id }))
      getComments()
    }
  }, [id])

  useEffect(() => {
    if (wagerdetailsinfo?.wager?.result) {
      setDoc(doc(db, 'wagers', id), { ...wagerdetailsinfo?.wager?.result })
      setWagerDetails(wagerdetailsinfo?.wager?.result)
    }
  }, [wagerdetailsinfo])

  useEffect(() => {
    if (media) {
      const dataUrl = handleBucket(media, 'wagers', id)
      const user = JSON.parse(localStorage.getItem('user'))
      dataUrl.then((data) => {
        const singleComment = {
          user,
          wagerId: id,
          text: data,
          date: Timestamp.now(),
          id: Math.random() * 1000
        }
        try {
          if (replyTo?.user) {
            setRepliesList((prevState) => ({
              ...prevState,
              [replyTo.id]: singleComment
            }))
            setDoc(doc(db, 'wagers', id, 'comments', replyTo.id, 'replies', `${Math.floor(Math.random() * 1000000)}`), { ...singleComment })
          } else {
            setDoc(doc(db, 'wagers', id, 'comments', `${Math.floor(Math.random() * 1000000)}`), { ...singleComment })
          }
          setReplyTo({})
        } catch (error) {
          console.log(error)
        }
      })
      setMedia(null)
    }
  }, [media])

  const toOrdinalSuffix = (num) => {
    const int = parseInt(num)
    const digits = [int % 10, int % 100]
    const ordinals = ['st', 'nd', 'rd', 'th']
    const oPattern = [1, 2, 3, 4]
    const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
      ? int + ordinals[digits[0] - 1]
      : int + ordinals[3]
  }
  const handleDeactivation = () => {
    dispatch(deactivateWager({ id: selectedWager?.id }))
    navigate("/my-wagers")
    props.closeFunction()
  }
  const startTimeString = selectedWager?.event_data?.startTime;
  const startTime = new Date(startTimeString);

  // Convert to PST
  const pstOffset = 8 * 60; // 8 hours in minutes
  startTime.setMinutes(startTime.getMinutes() - startTime.getTimezoneOffset() - pstOffset);

  // Extracting date components
  const year = startTime.getFullYear();
  const month = startTime.getMonth() + 1; // January is 0!
  const day = startTime.getDate();

  // Extracting time components
  let hours = startTime.getHours();
  const minutes = startTime.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert 24-hour time to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const resultSwitcher = () => {
    if (user?.id === selectedWager?.createdby || selectedWager?.result === 'refund' || selectedWager?.result === 'refunded') {
      return selectedWager?.result?.toUpperCase()
    }
    else  {
      return selectedWager?.result?.toUpperCase()

    }
  }

  return (
    <>
      {id &&
        <Container sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '36px',
          boxSizing: 'border-box',
          maxWidth: '450px',
          pt: 4,
          pb: location.pathname === '/my-wagers' ? 10 : 2,
          backgroundColor: theme.palette.dark.dark// or any other color you want
        }}>

          {
            wagerdetailsinfo?.isLoading
              ? <Loader isloading={wagerdetailsinfo?.isLoading} />
              :
              <>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '12px',
                }}>
                  <Avatar
                    src={selectedWager?.createdBy_data?.userLogo || null}
                    sx={{
                      height: '84px',
                      width: '84px'
                    }} />
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '6px'
                  }}>
                    <Typography variant='caption' sx={{
                      color: theme.palette.dark.otherlight
                    }}>
                      Wager Status
                    </Typography>
                    <Box sx={{
                      borderRadius: '6px',
                      padding: '6px 24px',
                      bgcolor: theme.palette.dark.otherdark
                    }}>
                      <Typography>
                        {
                          // //{selectedWager?.status?.toUpperCase()}
                          // selectedWager?.status === 'closed' && selectedWager?.result != "pending"
                          //   ?
                          //   selectedWager?.result?.toUpperCase()
                          //   :
                          //   selectedWager?.status?.toUpperCase()
                          (user?.id === selectedWager?.createdby || selectedWager?.result === 'refund' || selectedWager?.result === 'refunded' || selectedWager?.result === "pending")?
                            selectedWager?.result?.toUpperCase()
                            :
                            selectedWager?.result === 'winner' ?
                              "LOSER"
                              :
                              "WINNER"
                        }
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <Typography variant='caption' sx={{
                      color: theme.palette.dark.otherlight
                    }}>
                      Wager closes: {`${month}/${day}/${year} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${ampm}`}
                    </Typography>
                  </Box>
                </Box>

                <Stack spacing={2} sx={{
                  bgcolor: theme.palette.dark.otherdark,
                  pt: 4,
                  pb: 4,
                  pl: 2,
                  pr: 2
                }}>
                  <Stack sx={{
                    textAlign: 'center'
                  }}>
                    <Typography>For you to win:</Typography>
                    {
                      selectedWager?.createdBy === user?.id
                        ?
                        <>
                          {selectedWager?.placement != null
                            ? (
                              <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '}
                                {selectedWager?.placement === selectedWager?.placement_range
                                  ? 'finishes at ' + toOrdinalSuffix(selectedWager?.placement) + ' place'
                                  : selectedWager?.placement_range
                                    ? 'finishes between ' + toOrdinalSuffix(selectedWager?.placement) + ' through ' + toOrdinalSuffix(selectedWager?.placement_range) + ' place'
                                    : toOrdinalSuffix(selectedWager?.placement) + ' place'
                                }
                              </Typography>
                            )
                            :
                            (selectedWager?.wagerType_data?.name === 'podium')
                              ?
                              <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '} places Podium
                              </Typography>
                              :
                              (
                                selectedWager?.wagerType_data?.name === 'holeshot'
                                  ?
                                  <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                    {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} wins the Holeshot
                                  </Typography>
                                  : selectedWager?.wagerType_data?.name === 'spread'
                                    ?
                                    <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                      {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                      {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                      {' '}by more than {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}
                                    </Typography>
                                    :
                                    <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                      {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                      {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                    </Typography>
                              )
                          }
                        </>
                        :
                        <>
                          {selectedWager?.placement != null
                            ? (
                              <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '}
                                {/* {toOrdinalSuffix(selectedWager?.placement)} place */}
                                {selectedWager?.placement === selectedWager?.placement_range
                                  ? "doesn't finish at " + toOrdinalSuffix(selectedWager?.placement) + ' place'
                                  : selectedWager?.placement_range
                                    ? "doesn't finish between " + toOrdinalSuffix(selectedWager?.placement) + ' through ' + toOrdinalSuffix(selectedWager?.placement_range) + ' place'
                                    : toOrdinalSuffix(selectedWager?.placement) + ' place'
                                }
                              </Typography>
                            )
                            : (selectedWager?.wagerType_data?.name === 'podium')
                              ?
                              <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '} doesn't place Podium
                              </Typography>
                              : (
                                selectedWager?.wagerType_data?.name === 'holeshot'
                                  ?
                                  <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                    {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} doesn't win the Holeshot
                                  </Typography>
                                  : selectedWager?.wagerType_data?.name === 'spread'
                                    ?
                                    <>
                                      <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                        {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} loses to OR beats{' '}
                                        {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                        {' '}by less than {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}
                                      </Typography>

                                      {
                                        /*
<Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                        {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name} loses to OR beats{' '}
                                        {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name}
                                        {' '}by less than {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}
                                      </Typography>
                                        */
                                      }

                                    </>
                                    :
                                    <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                      {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name} beats{' '}
                                      {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name}
                                    </Typography>
                              )
                          }
                        </>
                    }
                  </Stack>

                  <Divider />

                  <Stack sx={{
                    textAlign: 'center'
                  }}>
                    <Typography>For opponent to win:</Typography>
                    {
                      selectedWager?.createdBy === user?.id
                        ?
                        <>
                          {selectedWager?.placement != null
                            ? (
                              <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '}
                                {/* {toOrdinalSuffix(selectedWager?.placement)} place */}
                                {selectedWager?.placement === selectedWager?.placement_range
                                  ? "doesn't finish at " + toOrdinalSuffix(selectedWager?.placement) + ' place'
                                  : selectedWager?.placement_range
                                    ? "doesn't finish between " + toOrdinalSuffix(selectedWager?.placement) + ' through ' + toOrdinalSuffix(selectedWager?.placement_range) + ' place'
                                    : toOrdinalSuffix(selectedWager?.placement) + ' place'
                                }
                              </Typography>
                            )
                            : (selectedWager?.wagerType_data?.name === 'podium')
                              ?
                              <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '} doesn't place Podium
                              </Typography>
                              : (
                                selectedWager?.wagerType_data?.name === 'holeshot'
                                  ?
                                  <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                    {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} doesn't win the Holeshot
                                  </Typography>
                                  : selectedWager?.wagerType_data?.name === 'spread'
                                    ?
                                    <>
                                      <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                        {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} loses to OR beats{' '}
                                        {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                        {' '}by less than {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}
                                      </Typography>

                                    </>
                                    :
                                    <>
                                      <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                        {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name} beats{' '}
                                        {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name}
                                      </Typography>
                                    </>
                              )
                          }
                        </>
                        :
                        <>
                          {
                            selectedWager?.placement != null
                              ?
                              (
                                <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                  {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '}
                                  {selectedWager?.placement === selectedWager?.placement_range
                                    ? 'finishes at ' + toOrdinalSuffix(selectedWager?.placement) + ' place'
                                    : selectedWager?.placement_range
                                      ? 'finishes between ' + toOrdinalSuffix(selectedWager?.placement) + ' through ' + toOrdinalSuffix(selectedWager?.placement_range) + ' place'
                                      : toOrdinalSuffix(selectedWager?.placement) + ' place'
                                  }
                                </Typography>
                              )
                              : (selectedWager?.wagerType_data?.name === 'podium')
                                ?
                                <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                  {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '} places Podium
                                </Typography>
                                : (
                                  selectedWager?.wagerType_data?.name === 'holeshot'
                                    ? <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>{selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} wins the Holeshot</Typography>
                                    : selectedWager?.wagerType_data?.name === 'spread'
                                      ? <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                        {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                        {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                        {' '}by more than {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}
                                      </Typography>
                                      :
                                      <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                        {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                        {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                      </Typography>
                                )
                          }
                        </>
                    }

                  </Stack>
                </Stack>

                {
                  selectedWager?.peopleJoined === 0
                  &&
                  user?.id === selectedWager?.createdBy
                  &&
                  selectedWager.result === 'pending'
                  &&
                  <Accordion sx={{
                    background: 'transparent',
                    border: 'none',
                    outline: 'none',
                    bgcolor: theme.palette.dark.otherdark
                  }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                    >
                      <Typography>Delete Wager</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='body1' sx={{
                        color: theme.palette.dark.otherlight
                      }}>
                        Upon selecting "Delete Wager", this wager will be permanently deleted.
                      </Typography>
                    </AccordionDetails>
                    <AccordionActions>
                      <Button
                        onClick={() => {
                          handleDeactivation()
                        }}
                        variant='contained'
                        color='error'
                        sx={{ color: 'white' }}>
                        DELETE
                      </Button>
                    </AccordionActions>
                  </Accordion>
                }

                <Stack
                  spacing={1}>
                  <Typography>Event Details</Typography>

                  <Divider />

                  <Stack
                    spacing={1.25}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        //bgcolor: 'red',
                      }}>
                      <Typography variant='body2' sx={{ fontWeight: 700 }}>Sport</Typography>
                      <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.event_data?.sports_data?.name}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        //bgcolor: 'red',
                      }}>
                      <Typography variant='body2' sx={{ fontWeight: 700 }}>Event Name</Typography>
                      <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.event_data?.name}</Typography>
                    </Box>
                  </Stack>
                </Stack>

                <Stack
                  spacing={1}>
                  <Typography>Wager Details</Typography>

                  <Divider />


                  <Stack
                    spacing={1.25}>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        //bgcolor: 'red',
                      }}>
                      <Typography variant='body2' sx={{ fontWeight: 700 }}>Mode</Typography>
                      <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.mode?.toUpperCase()}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        //bgcolor: 'red',
                      }}>
                      <Typography variant='body2' sx={{ fontWeight: 700 }}>Wager Type</Typography>
                      <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.wagerType_data?.name?.toUpperCase()}</Typography>
                    </Box>
                    {
                      selectedWager?.division_data &&
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          //bgcolor: 'red',
                        }}>
                        <Typography variant='body2' sx={{ fontWeight: 700 }}>Division</Typography>
                        <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.division_data?.name}</Typography>
                      </Box>
                    }
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        //bgcolor: 'red',
                      }}>
                      <Typography variant='body2' sx={{ fontWeight: 700 }}>Chip Amount</Typography>
                      <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.chipsBet} Chips</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        //bgcolor: 'red',
                      }}>
                      <Typography variant='body2' sx={{ fontWeight: 700 }}>Created by</Typography>
                      <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>@{selectedWager?.createdBy_data?.username}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        //bgcolor: 'red',
                      }}>
                      <Typography variant='body2' sx={{ fontWeight: 700 }}>Multiplier</Typography>
                      <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.multiplier_data?.name?.replace(/ /g, '-')}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        //bgcolor: 'red',
                      }}>

                      <Typography variant='body2' sx={{ fontWeight: 700 }}>
                        {
                          !!props?.privateWager ?
                            'Sent to' :
                            'Availability'
                        }
                      </Typography>
                      {
                        !!props?.privateWager ?
                          <ProfilePill
                            max={selectedWager?.maxPeople}
                            items={[...(selectedWager?.wagerusers || [])]}
                            size={24}
                            wagerStatus={(wagerStatus?.h > 0 || wagerStatus?.m > 0)}
                            privateWager />
                          :
                          <ProfilePill
                            max={selectedWager?.maxPeople}
                            items={[...(selectedWager?.wagerusers || [])]}
                            size={24}
                          />
                      }
                    </Box>
                  </Stack>
                </Stack>

                {
                  false &&
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',

                  }}>
                    <Button>Go back</Button>
                    <Button variant='contained'>Accept Wager</Button>
                  </Box>
                }
              </>
          }




        </Container>
      }
    </>
  )
}

export default WagerDetailsNew


/*










    <>
      {id && <div className='fullScreen fullScreen-login login-solid wraggerDetailsWrap pageHeaderFooter' >
        <div className='pageTop forDrop' style={{ background: '#000812', padding: '50px 12px 12px 12px', borderBottom: '1px solid #223F5D' }}>
          <div className='backLink'>
            <div className='backLink-icon alt noBg'><BiLeftArrowAlt onClick={() => navigate(-1)} /></div>
            <span className='mt-0' style={{ position: 'absolute', right: '10px' }}>Wager Details t</span>
          </div>
        </div>

        <div className='loginWrap scrollable-notification'>
 <div className='wraggerDetails'> 
          {wagerdetailsinfo?.isLoading
            ? <Loader isloading={wagerdetailsinfo?.isLoading} />
            : <div className='wraggerDetails'>
              {selectedWager?.peopleJoined === 0 && user?.id === selectedWager?.createdBy &&
                <div className='chipList pt-4'>
                  <div className='chipHeading'>
                    <h2>Actions</h2>
                  </div>
                  <div className='chipList-single'>
                    <div className='col' onClick={() => setDeleteShow(true)}><span className='fw-bold'>Delete wager</span></div>
                    <div className='col text-end'>
                      {!deleteShow
                        ? <div className='text-white' onClick={() => setDeleteShow(true)} ><FaAngleRight /></div>
                        : <div>
                          <span className='delete_wager_text mb-2'>Upon selecting <i>“Delete Wager”,
                            this will permantly delete the wager</i>
                          </span>
                          <div className='d-flex'>
                            <button className='cancel_post' onClick={() => setDeleteShow(false)}> Cancel</button>
                            <button className='delete_post ms-3' onClick={() => handleDeactivation()}> Delete Wager</button>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }
              <div className='chipList pt-4'>
                <div className='chipHeading'>
                  <h2>Event Details</h2>
                </div>
                <div className='chipList-single'>
                  <div className='chipList-left'><span className='fw-bold'>Event Name</span></div>
                  <div className='chipList-right'><span className='txtGrey'>{selectedWager?.event_data?.name}</span></div>
                </div>
              </div>
              <div className='chipList pt-4'>
                <div className='chipHeading'>
                  <h2>Engagement Details</h2>
                </div>
                <div className='chipList-single'>
                <div className='chipList-left'><span className='fw-bold'>For you to win</span></div>
                {selectedWager?.createdBy === user?.id
                  ? <div className='chipList-right'><span className='txtGrey'>
                    {selectedWager?.placement != null
                      ? (
                        <span>
                          {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '}
                     {toOrdinalSuffix(selectedWager?.placement)} place
                          {selectedWager?.placement === selectedWager?.placement_range
                            ? 'finishes at ' + toOrdinalSuffix(selectedWager?.placement) + ' place'
                            : selectedWager?.placement_range
                              ? 'finishes between ' + toOrdinalSuffix(selectedWager?.placement) + ' through ' + toOrdinalSuffix(selectedWager?.placement_range) + ' place'
                              : toOrdinalSuffix(selectedWager?.placement) + ' place'
                          }
                        </span>
                        )
                      : (selectedWager?.wagerType_data?.name === 'podium')
                          ? <span>{selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '} places Podium</span>
                          : (
                              selectedWager?.wagerType_data?.name === 'holeshot'
                                ? <span>{selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} wins the Holeshot</span>
                                : selectedWager?.wagerType_data?.name === 'spread'
                                  ? <span>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                {' '}by more than {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}

                              </span>
                                  : <span>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                              </span>
                            )
                    }
                  </span></div>
                  : <div className='chipList-right'><span className='txtGrey'>
                    {selectedWager?.placement != null
                      ? (
                        <span>
                          {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '}
                      {toOrdinalSuffix(selectedWager?.placement)} place 
                          {selectedWager?.placement === selectedWager?.placement_range
                            ? 'doesn\'t finish in ' + toOrdinalSuffix(selectedWager?.placement) + ' place'
                            : selectedWager?.placement_range
                              ? 'does not finishes between ' + toOrdinalSuffix(selectedWager?.placement) + ' through ' + toOrdinalSuffix(selectedWager?.placement_range) + ' place'
                              : toOrdinalSuffix(selectedWager?.placement) + ' place'
                          }
                        </span>
                        )
                      : (selectedWager?.wagerType_data?.name === 'podium')
                          ? <span>{selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '} {'doesn\'t '}places Podium</span>
                          : (
                              selectedWager?.wagerType_data?.name === 'holeshot'
                                ? <span>{selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} loses the Holeshot</span>
                                : selectedWager?.wagerType_data?.name === 'spread'
                                  ? <span>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                {' '}by less than  {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}

                              </span>
                                  : <span>
                                {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name} beats{' '}
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name}
                              </span>
                            )
                    }
                  </span></div>
                }
              </div>
              <div className='chipList-single'>
                <div className='chipList-left'><span className='fw-bold'>For opponent/s to win</span></div>
                {selectedWager?.createdBy === user?.id
                  ? <div className='chipList-right'><span className='txtGrey'>
                    {selectedWager?.placement != null
                      ? (
                        <span>
                          {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '}
                     {toOrdinalSuffix(selectedWager?.placement)} place 
                          {selectedWager?.placement === selectedWager?.placement_range
                            ? ' doesn\'t finish in ' + toOrdinalSuffix(selectedWager?.placement) + ' place'
                            : selectedWager?.placement_range
                              ? ' does not finishes between ' + toOrdinalSuffix(selectedWager?.placement) + ' through ' + toOrdinalSuffix(selectedWager?.placement_range) + ' place'
                              : toOrdinalSuffix(selectedWager?.placement) + ' place'
                          }
                        </span>
                        )
                      : (selectedWager?.wagerType_data?.name === 'podium')
                          ? <span>{selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '} {'doesn\'t '}places Podium</span>
                          : (
                              selectedWager?.wagerType_data?.name === 'holeshot'
                                ? <span>{selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name}loses the Holeshot</span>
                                : selectedWager?.wagerType_data?.name === 'spread'
                                  ? <span>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name}{' '}beats{' '}
                                {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                {' '}by less than {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}

                              </span>
                                  : <span>
                                {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}{' '} beats{' '}
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name}
                              </span>
                            )
                    }</span></div>
                  : <div className='chipList-right'><span className='txtGrey'>
                    {selectedWager?.placement != null
                      ? (
                        <span>
                          {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '}
                          {toOrdinalSuffix(selectedWager?.placement)} place
                          {selectedWager?.placement === selectedWager?.placement_range
                            ? 'finishes at ' + toOrdinalSuffix(selectedWager?.placement) + ' place'
                            : selectedWager?.placement_range
                              ? 'finishes between ' + toOrdinalSuffix(selectedWager?.placement) + ' through ' + toOrdinalSuffix(selectedWager?.placement_range) + ' place'
                              : toOrdinalSuffix(selectedWager?.placement) + ' place'
                          }
                        </span>
                        )
                      : (selectedWager?.wagerType_data?.name === 'podium')
                          ? <span>{selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '} places Podium</span>
                          : (
                              selectedWager?.wagerType_data?.name === 'holeshot'
                                ? <span>{selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} wins the Holeshot</span>
                                : selectedWager?.wagerType_data?.name === 'spread'
                                  ? <span>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                {' '}by more than {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}

                              </span>
                                  : <span>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                              </span>
                            )
                    }
                  </span></div>
                }
              </div>
              </div>
              <div className='chipList pt-4'>
                <div className='chipHeading'>
                  <h2>Wager Details</h2>
                </div>
                <div className='chipList-single'>
                  <div className='chipList-left'><span className='fw-bold'>Type</span></div>
                  <div className='chipList-right'><span className='txtGrey'>{selectedWager?.wagerType_data?.name === '1v1' ? 'Rider v. Rider wager' : selectedWager?.wagerType_data?.name === 'placement' ? 'Finish Line wager' : selectedWager?.wagerType_data?.name === 'spread' ? 'Spread wager' : selectedWager?.wagerType_data?.name === 'podium' ? 'Podium wager' : 'Holeshot wager' }</span></div>
                </div>
                <div className='chipList-single'>
                  <div className='chipList-left'><span className='fw-bold'>Wager amount</span></div>
                  <div className='chipList-right'><span className='txtGrey'>{selectedWager?.chipsBet?.toLocaleString()} chips</span></div>
                </div>
                <div className='chipList-single'>
                  <div className='chipList-left'><span className='fw-bold'>Created by</span></div>
                  <div className='chipList-right'><span className='txtGrey'>@{selectedWager?.createdBy_data?.username}</span></div>
                </div>
                {selectedWager?.wagerusers?.length > 0 &&
                  <div className='chipList-single py-1' style={{ position: 'relative' }}>
                  <div className='chipListleft' style ={{ position: 'absolute', top: '16px' }}><span className='fw-bold'>Accepted by</span></div>
                  <div className='chipList-right w-100'>
                    <span className='txtGrey'>
                    {selectedWager?.wagerusers?.length > 0 && Number(selectedWager?.peopleJoined) > 0
                      ? selectedWager?.wagerusers?.map((user, idx) =>
                        user?.status === 'accept'
                          ? <div className='chipList-single justify-content-end pe-0' key={idx}>@{user?.username} </div>
                          : <div className='chipList-single justify-content-end'>No one </div>
                      )
                      : <div className='chipList-single justify-content-end'>No one </div>
                    }
                  {selectedWager?.wagerusers?.map((user, idx) => <div className='chipList-single justify-content-end pe-0' key ={idx}>@{user?.username} </div>)} 
                      </span>
                    </div>
              <div className='chipList-right'><span className='txtGrey'>@{selectedWager?.wagerusers?.map((user) => user?.username)} </span></div> 
              </div>
                }
           <div className='chipList-single'>
                  <div className='chipList-left'><span className='fw-bold'>Division</span></div>
                  <div className='chipList-right'><span className='txtGrey'>{selectedWager?.division_data?.name}</span></div>
                </div> 
                {selectedWager?.division_data &&
                <div className='chipList-single'>
                  <div className='chipList-left'><span className='fw-bold'>Division</span></div>
                  <div className='chipList-right'><span className='txtGrey'>{selectedWager?.division_data?.name}</span></div>
                </div>
              }
              <div className='chipList-single'>
                <div className='chipList-left'><span className='fw-bold'>Sport</span></div>
                <div className='chipList-right'><span className='txtGrey'>{selectedWager?.event_data?.sports_data?.name}</span></div>
              </div>
                <div className='chipList-single'>
                  <div className='chipList-left'><span className='fw-bold'>Wager</span></div>
                  <div className='chipList-right'><span className='txtGrey'>
                  {selectedWager?.placement != null
                    ? (
                      <span>
                        {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '}
                  {toOrdinalSuffix(selectedWager?.placement)} place 
                        {selectedWager?.placement === selectedWager?.placement_range
                          ? 'finishes at ' + toOrdinalSuffix(selectedWager?.placement) + ' place'
                          : selectedWager?.placement_range
                            ? 'finishes between ' + toOrdinalSuffix(selectedWager?.placement) + ' through ' + toOrdinalSuffix(selectedWager?.placement_range) + ' place'
                            : toOrdinalSuffix(selectedWager?.placement) + ' place'
                        }
                      </span>
                      )
                    : selectedWager?.wagerType_data?.name === 'podium'
                      ? <>
                        {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name}
                        {' '}places Podium.
                      </>
                      : (
                          selectedWager?.wagerType_data?.name === 'holeshot'
                            ? <span>{selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} wins the Holeshot</span>
                            : selectedWager?.wagerType_data?.name === 'spread'
                              ? <span>
                              {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                              {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                              {' '}by a +{selectedWager?.spreadOptions_data?.option} spread

                            </span>
                              : <span>
                              {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                              {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                            </span>
                        )
                  }
                </span></div>
                </div>
                <div className='chipList-single'>
                  <div className='chipList-left'><span className='fw-bold'>Open to</span></div>
                  <div className='chipList-right'><span className='txtGrey'>{selectedWager?.maxPeople} opponent/s </span></div>
                </div>
                <div className='chipList-single'>
                  <div className='chipList-left'><span className='fw-bold'>Opponents joined</span></div>
                  <div className='chipList-right'><span className='txtGrey'>{selectedWager?.peopleJoined} </span></div>
                </div>
                <div className='chipList-single'>
                  <div className='chipList-left'><span className='fw-bold'>Multiplier</span></div>
                  <div className='chipList-right'><span className='txtGrey'>{selectedWager?.multiplier_data?.name?.replace(/ /g, '-')}</span></div>
                </div>
              </div>
            </div>
          }
      </div> 
      </div>
    </div>
    }
  </>
*/
