import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { requestDeletion } from '../../redux/actions/delete'
import { useDispatch, useSelector } from 'react-redux'

function DeleteAccount () {
  const [reason, setReason] = useState("Didn't make any money")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const responseData = useSelector((state) => state.deletionCode)
  const isCodeSent = responseData?.codeInfo?.status
  const [onMount, setOnMount] = useState(true)

  const handleReason = (e) => {
    if (reason) {
      setOnMount(false)
      dispatch(
        requestDeletion({
          reasonForLeaving: reason
        })
      )
    }
  }

  useEffect(() => {
    if (isCodeSent && !onMount) {
      navigate('/delete-account-step-2')
    }
  }, [isCodeSent])

  const handleBack = () => {
    dispatch({
      type: 'RESET_DELETE_CODE'
    })
    navigate('/edit-profile')
  }
  return (
    <>
      <div className="fullScreen fullScreen-login login-solid">
        <div className="pageTop forDrop bgDark">
          <div className="backLink">
            {/* <Link to="/edit-profile"> */}
              <div className="backLink-icon noBg error cursor-pointer" onClick={handleBack}>
              </div>
            {/* </Link> */}

            <span className="error mt-0">Deactivate Account</span>
          </div>
        </div>

        <div className="loginWrap scrollable-notification">
          <div className="editPro">
            <div className="smLogo text-center">
              <h2 className="error fw-bold pt-3 pb-4">
                We are sad to see you go!
              </h2>
            </div>
            <div className="editInpWrap">
              <label>Why are you leaving?</label>
              <select
                className="editInp"
                onChange={(e) => setReason(e.target.value)}
              >
                <option>{"Didn't make any money"}</option>
                <option>The app is too confusing</option>
                <option>The subscription amount is too much</option>
                <option>{"Wasn't fun enough"}</option>
                <option>{"My friends wouldn't join"}</option>
                <option>I am going to join a competitor</option>
                <option>Other</option>
              </select>
            </div>
            <div className="editInpWrap text-center">
              <button
                className="button button-white mt-3"
                onClick={handleReason}
                disabled={responseData?.isLoading}
              >
                {responseData?.isLoading ? 'Loading' : 'Send Code'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteAccount
