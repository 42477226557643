import axios from './axios'
import authHeader from './auth-header'

const sendFeedback = (
  name,
  email,
  message,
  attachment,
  device,
  browserName,
  browserVersion
) => {
  return axios.post('feedbacks/add',
    {
      name,
      email,
      message,
      attachment,
      device,
      browserName,
      browserVersion
    },
    {
      headers: authHeader()
    }
  )
}

const feedbackService = {
  sendFeedback
}

export default feedbackService
