import React from 'react'
import { useCountdown } from './hooks/useCountdown'
import PropTypes from 'prop-types'
// eslint-disable-next-line react/prop-types
const DateConverter = ({ currTime }) => {
  const [hours, minutes, seconds] = useCountdown(new Date(currTime).getTime() - 300000)
  if (hours < 0 || minutes < 0 || seconds < 0) {
    return <>Wagering has closed</>
  } else {
    return <>Wagering closes in: {hours < 10 ? '0' + hours + 'h' : hours === 0 ? '00' + 'h' : hours + 'h'} : {minutes < 10 ? '0' + minutes + 'm' : minutes === 0 ? '00' + 'm' : minutes + 'm'} : {seconds < 10 ? '0' + seconds + 's' : seconds === 0 ? '00' + 's' : seconds + 's'} </>
  }
}

DateConverter.prototype = {
  currTime: PropTypes.instanceOf(Date)
}
export default DateConverter
