import axios from './axios'
import authHeader from './auth-header'

// Add chips
const addChips = (amount) => {
  return axios.post('chip-banks/add', {
    amount
  }, {
    headers: authHeader()
  })
}

// Get Chip Balance
const chipBalance = (sportsId) => {
  return axios.post('chip-banks/chip-balances', { sportsId }, {
    headers: authHeader()
  })
}

// Withdraw Chips
const withdrawChips = (amount) => {
  return axios.post('chip-banks/withdraw', {
    amount
  }, {
    headers: authHeader()
  })
}

// Get Transcript
const getTranscript = (skip, limit) => {
  return axios.post('chip-banks/transcripts',
    {
      skip,
      limit
    }, {
      headers: authHeader()
    })
}

const chipsServices = {
  addChips,
  chipBalance,
  withdrawChips,
  getTranscript
}
export default chipsServices
