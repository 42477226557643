import { configureStore, combineReducers, compose, applyMiddleware } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunkMiddleware from 'redux-thunk'
import Auth from './reducers/auth'
import Masters from './reducers/masters'
import Wagers from './reducers/wagers'
import Privateers from './reducers/privateer'
import Subscription from './reducers/subscription'
import updatePrivateer from './reducers/updatePrivateer'
import addCard from './reducers/card'
import updateSubscription from './reducers/updateSubscription'
import Manufactuerers from './reducers/manufactuerers'
import Divison from './reducers/divisons'
import Wallet from './reducers/wallet'
import wagerConfig from './reducers/wagerConfig'
import userName from './reducers/userName'
import userEmail from './reducers/userEmail'
import myWager from './reducers/myWager'
import deactivateWager from './reducers/deactivateWager'
import userProfile from './reducers/userProfile'
import updateUsername from './reducers/updateUsername'
import updateUserPwd from './reducers/updatePassword'
import updateAddress from './reducers/updateAddress'
import referralCode from './reducers/referrelCode'
import updateNotification from './reducers/notification'
import Faq from './reducers/faq'
import deletionCode from './reducers/deletionCode'
import deleteAccount from './reducers/deleteAccount'
import updateProfilePicture from './reducers/updateProfilePicture'
import sendFeedback from './reducers/feedback'
import eventId from './reducers/eventId'
import wagerDetails from './reducers/wagerdetails'
import addChips from './reducers/addChips'
import Chips from './reducers/chips'
import BillingDetails from './reducers/billingDetails'
import withdrawChips from './reducers/withdrawChips'
import Transcript from './reducers/transcript'
import allChats from './reducers/allChats'
import usersList from './reducers/usersList'
import allMessages from './reducers/allMessages'
import createChat from './reducers/createChat'
import createMessage from './reducers/createMessage'
import NotificationList from './reducers/notificationList'
import Notifications from './reducers/notificationScreen.js'
import NotificationsStatus from './reducers/notificationStatus'
import addParticipant from './reducers/addParticipant'
import newChatCount from './reducers/newChatCount'
import ChatFilter from './reducers/chatFilter'
import LeaderBoard from './reducers/leaderBoard'
import myPost from './reducers/posts'
import allPost from './reducers/posts/allPosts'
import postDetails from './reducers/posts/postDetails'
import CreatePost from './reducers/posts/createPost'
import Sport from './reducers/sport'
import Counter from './reducers/counter'
import Leagues from './reducers/leagues'
import Modals from './reducers/modal'
import Ads from './reducers/ads'
import postDeletion from './reducers/posts/deletePost.js'
import inviteReducer from './reducers/invite/index.js'
import trendingEvents from './reducers/trendingEvents/index.js'

const persistConfig = {
  key: 'counter',
  storage
}

const reducers = combineReducers({
  Auth,
  Masters,
  Wagers,
  Privateers,
  Subscription,
  updatePrivateer,
  addCard,
  updateSubscription,
  Manufactuerers,
  Divison,
  Wallet,
  wagerConfig,
  userName,
  userEmail,
  myWager,
  deactivateWager,
  userProfile,
  updateUsername,
  updateUserPwd,
  updateAddress,
  referralCode,
  updateNotification,
  Faq,
  deletionCode,
  deleteAccount,
  updateProfilePicture,
  sendFeedback,
  eventId,
  wagerDetails,
  addChips,
  Chips,
  BillingDetails,
  withdrawChips,
  Transcript,
  allChats,
  usersList,
  allMessages,
  createChat,
  createMessage,
  NotificationList,
  Notifications,
  NotificationsStatus,
  addParticipant,
  newChatCount,
  ChatFilter,
  LeaderBoard,
  myPost,
  allPost,
  postDetails,
  CreatePost,
  Sport,
  Counter,
  Leagues,
  Modals,
  Ads,
  postDeletion,
  inviteReducer,
  trendingEvents
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    // localStorage.removeItem('user')
    // window.location = '/'
    // window.location.replace('/')
    return reducers(undefined, action)
  }

  return reducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default configureStore(
  { reducer: persistedReducer },
  composeEnhancers(applyMiddleware(thunkMiddleware))
)
