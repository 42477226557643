import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AuthLayout from '../../components/layouts/auth'
import { LoadingButton } from '@mui/lab'
import logo from '../../images/betzonlogo.svg'
import { useDispatch, useSelector } from 'react-redux'
import { resetInviteCode, submitInviteCode } from '../../redux/actions/invite'
import { useNavigate } from 'react-router-dom'

const InviteCode = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const user = JSON.parse(localStorage.getItem('user'))

    const inviteResponse = useSelector((state) => state.inviteReducer)

    const loginData = useSelector((state) => state.Auth)

    const [input, setInput] = useState({
        inviteCode: ''
    })

    const [error, setError] = useState('')

    const inputHandle = (e) => {
        if (error != '') {
            setError('')
            dispatch(resetInviteCode())
        }
        setInput({ inviteCode: e.target.value })
    }

    const submitCode = () => {
        dispatch(submitInviteCode(input))
    }

    const goBacktoLogin = () => {
        localStorage.clear()
        navigate('/')
    }

    useEffect(() => {
        if (!user) {
            navigate('/')
        }
        if (
            (inviteResponse.status === 'success' && user.isInvited)
            ||
            (
                loginData.Login === 'Success'
                &&
                loginData?.response && loginData?.response?.isInvited
            )
        ) {
            //wLcL26r3HsM5qUhYsxWvM
            navigate('/dashboard')
        }
        else if (inviteResponse.status === 'fail' && inviteResponse.error === 'invite-code-invalid') {
            setError("Invite code is invalid!")
        }
    }, [inviteResponse]);

    const goToWaitlist = () => {
        window.open('https://www.betzon.com', '_blank');
    }

    return (
        <AuthLayout>
            <Stack
                spacing={4}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                    width: '100%',
                    height: '100%'
                }}>

                <img src={logo} style={{
                    width: '60px',
                    marginTop: '-24px'
                }} />
                <Stack
                    spacing={2}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    sx={{
                        width: '100%'
                    }}>
                    <Stack>
                        <Typography variant='h4'>Almost there!</Typography>
                        <Typography variant='body1'>Enter the code that a friend shared with you!</Typography>
                    </Stack>
                    <TextField
                        error={error != ''}
                        onChange={inputHandle}
                        value={input.inviteCode}
                        variant='filled'
                        label="Invite code"
                        sx={{
                            width: '100%'
                        }} />
                    {
                        error != '' &&
                        <Typography color='error'>{error}</Typography>
                    }
                    <LoadingButton
                        onClick={() => submitCode()}
                        loading={inviteResponse.isLoading}
                        disabled={input.inviteCode === '' || error != ''}
                        fullWidth
                        variant='contained'>
                        Submit
                    </LoadingButton>
                    <LoadingButton
                        size='small'
                        onClick={() => goToWaitlist()}
                        fullWidth
                        variant='text'>
                        Join our waitlist
                    </LoadingButton>
                </Stack>
                <Button onClick={() => goBacktoLogin()} variant='text'>Go back to login</Button>
            </Stack>
        </AuthLayout >
    )
}

export default InviteCode
