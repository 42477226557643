const initialState = {
  isLoading: false,
  chatList: []
}
export default function allChats(state = initialState, action) {
  switch (action.type) {
    case 'GET_ALL_CHAT_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'GET_ALL_CHAT': {
      return {
        Status: 'success',
        isLoading: false,
        totalCount: !!action.payload.totalCount ? action.payload.totalCount : state.totalCount,
        chatList: [...action.payload.result]
      }
    }
    case 'GET_ALL_CHAT_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        isLoading: false,
        error: action.payload
      }
    }
    default:
      return state
  }
}
