
const initialState = {
  isLoading: false,
  penis: []
}
export default function NotificationsStatus(state = initialState, action) {
  switch (action.type) {
    case 'NOTIFICATIONS_UPDATE_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'NOTIFICATIONS_UPDATE_STATUS': {
      return {
        ...state,
        isLoading: false,
        notificationStatus: action.payload
      }
    }
    case 'NOTIFICATIONS_UPDATE_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }
    default:
      return state
  }
}
