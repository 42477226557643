import React, { useEffect, useState } from 'react'
import CardLayout from '../layouts/card'
import { Avatar, List, ListItemButton, Divider, Box, Stack, Typography, Button, Container, IconButton, SwipeableDrawer } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LikesComponents from '../wager/likes/likes';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@emotion/react'
import CommentsComponents from '../wager/comments/comments';
import HandleDate from '../DateCalculator'
import { useDispatch, useSelector } from 'react-redux';
import postServices from '../../services/posts'
import LikeServices from '../../services/like'
import { deletePost, listMyPosts } from '../../redux/actions/Posts'
import { useNavigate } from 'react-router-dom';
import TextsmsIcon from '@mui/icons-material/Textsms';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import styled from '@emotion/styled';
import PostDetails from '../../pages/Posts/postDetails';
import { LoadingButton } from '@mui/lab'

const MySwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
    '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, .6)'
    },
}));

const PostItem = ({ data, likeFunction }) => {

    const theme = useTheme()

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const user = JSON.parse(localStorage.getItem('user'))

    const [deleteShow, setDeleteShow] = useState(true)

    const [toggle, setToggle] = useState(false)

    const [liked, setLiked] = useState(false)

    const postDeletion = useSelector((state) => state?.postDeletion)

    const handleDeletePost = (id) => {
        dispatch(deletePost(id))
        if (!postDeletion.isLoading) {
            setDeleteShow(true)
        }
    }

    const handleLikedToggle = () => {
        if (liked) {
            setLiked(false);
        } else {
            setLiked(true);
        }
    };

    const openDrawer = () => {
        setToggle(true)
    }

    const closeDrawer = () => {
        setToggle(false)
    }

    return (
        <>
            <MySwipeableDrawer
                anchor={'bottom'}
                open={toggle}
                onClose={() => closeDrawer()}
                onOpen={() => openDrawer()}
                PaperProps={{
                    style: {
                        height: '80vh',
                        width: '100%',
                        backgroundColor: 'black',
                        boxShadow: 'none',
                        borderTop: 0,
                        borderRadius: '8px 8px 0 0',
                        borderBottom: '0 !important',
                        zIndex: '999999999999',
                        borderTop: `3px solid ${theme.palette.dark.otherdark}`
                    },
                }}
            >
                <Box sx={{
                    bgcolor: 'black',
                    width: '100%',
                    height: '100%'
                }}>
                    {
                        toggle &&
                        <PostDetails id={JSON.stringify(data?.id ?? "undefined")} closeFunction={() => closeDrawer()} />
                    }
                </Box>
            </MySwipeableDrawer>
            <Box sx={{
                //background: 'red',
            }}>
                <Container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    pb: 2,
                    pt: 2
                }}>

                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}>
                            <Avatar
                                src={data?.createdBy_data?.userLogo} />
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-start'
                            }}>
                                <Typography variant='body2' sx={{ color: 'white', mb: -.5 }}>@{data?.createdBy_data.username}</Typography>
                                <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }} ><HandleDate date={data?.createdAt} />{' ago'}</Typography>
                            </Box>
                        </Box>

                        {
                            user.id === data?.createdBy &&

                            (
                                deleteShow ?
                                    <IconButton onClick={() => setDeleteShow(false)}>
                                        <DeleteIcon sx={{ color: theme.palette.dark.otherlight, height: '20px', width: '20px' }} />
                                    </IconButton>
                                    :
                                    <Stack direction="row" spacing={1}>
                                        <Button size="small" variant='text' sx={{ fontSize: '10px' }} onClick={() => setDeleteShow(true)}>Cancel</Button>
                                        <LoadingButton
                                            loading={postDeletion?.isLoading}
                                            size="small"
                                            variant='contained'
                                            color='error'
                                            sx={{ fontSize: '10px', color: 'white' }}
                                            onClick={() => handleDeletePost(data?.id)}>
                                            Delete Post
                                        </LoadingButton>
                                    </Stack>

                            )

                        }

                    </Box>

                    <Box>
                        <Typography variant='body1'>{data.description}</Typography>
                    </Box>

                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '24px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '4px'
                            }}>
                                <IconButton
                                    onClick={() => {
                                        handleLikedToggle()
                                        likeFunction(data?.id, data?.index, data?.isWagerLikeByMe)
                                    }}
                                    sx={{ padding: 0 }}>
                                    {
                                        (data?.likeId.includes(data?.id)) ? < FavoriteIcon fontSize='small' /> : <FavoriteBorderIcon fontSize='small' />
                                    }
                                </IconButton>
                                <Typography variant='body1' sx={{ fontWeight: 700 }}>{(data?.likeId.includes(data?.id) && data?.isPostLikeByMe) ? data?.likeCount : (!data?.likeId.includes(data?.id) && data?.isPostLikeByMe) ? data?.likeCount - 1 : (!data?.likeId.includes(data?.id) && !data?.isPostLikeByMe) ? data?.likeCount : (data?.likeId.includes(data?.id) && !data?.isPostLikeByMe) ? data?.likeCount + 1 : data?.likeCount}</Typography>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '6px'
                            }}>
                                <IconButton onClick={() => openDrawer()} sx={{ padding: 0 }}>
                                    <TextsmsIcon fontSize='small' sx={{ marginTop: '2.5px' }} />
                                </IconButton>
                                <Typography variant='body1' sx={{ fontWeight: 700 }}>{data?.commentsCount}</Typography>
                            </Box>
                        </Box>
                        {
                            // user.id != data?.createdBy &&
                            // <Button
                            //     onClick={() => navigate()}
                            //     variant='outlined'
                            //     size='small'
                            //     sx={{
                            //         paddingTop: 0,
                            //         paddingBottom: 0,
                            //         height: 'fit-content'
                            //     }}>
                            //     Wager
                            // </Button>
                        }
                    </Box>

                </Container>
                <Divider sx={{ width: '100%', bgcolor: theme.palette.dark.otherdark }} />
            </Box>
        </>
    )
}

export default PostItem
