import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import withRouter, { ValidateEmail } from '../../helper'
import { useDispatch, useSelector } from 'react-redux'
import { otpRequest } from '../../redux/actions/auth'
import { Box, Button, Container, IconButton, TextField, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import theme from '../../styles/theme'
import { useTheme } from '@emotion/react'
import { LoadingButton } from '@mui/lab'

function ResetPassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const [email, enterEmail] = useState('')
  const [emailError, enterEmailError] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const requestOTPResponse = useSelector(state => state.Auth)

  useEffect(() => {
    if (requestOTPResponse.Status === 'Fail') {
      setLoading(false)
      enterEmailError(requestOTPResponse?.response)
    }
    if (requestOTPResponse && requestOTPResponse.Status === 'Success') {
      setLoading(false)
      localStorage.setItem('useremail', email)
      navigate('/verifyOtp?screen=resetPassword', email)
    }
  }, [requestOTPResponse])

  const onChangeText = (e) => {
    if (ValidateEmail(e)) {
      enterEmail(e)
      setDisabled(false)
    } else {
      enterEmailError('')
      setDisabled(true)
    }
  }

  const callAPI = () => {
    if (email && email !== '' && !isLoading && !disabled) {
      setLoading(true)
      dispatch(otpRequest({ username: email }))
    }
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter' && email && !disabled) {
        callAPI()
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [email, disabled])

  return (
    <>
      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100vh',
        pb: 2
      }}>
        <Box sx={{
          zIndex: '999',
          display: 'flex',
          alignItems: 'center',
          pt: 2,
          pb: 1
        }}>
          <IconButton onClick={() => navigate('/')}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant='h6'>Forgot Password</Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px'
        }}>
          <Box>
            <Typography variant='h6'>Reset your password</Typography>
            <Typography variant='caption' sx={{
              color: theme.palette.dark.otherlight
            }}>Enter the email associated with your account and an email will be sent with instructions to reset your password.</Typography>
          </Box>
          <TextField
            label='Associated email'
            error={emailError !== ''}
            helperText={emailError}
            onChange={(e) => onChangeText(e.target.value)} />
          <LoadingButton
            isLoading={isLoading}
            disabled={disabled}
            onClick={() => !isLoading && callAPI()}
            variant='contained'>
            Submit
          </LoadingButton>
        </Box>
        <Button variant='text' onClick={() => navigate('/signup')}>Need to create an account?</Button>

      </Container>
    </>
  )
}

export default withRouter(ResetPassword)


/*

      <div className="fullScreen fullScreen-login" style={{ minHeight: '94vh' }}>
        <div className="pageTop">
          <div className="backLink">
            <Link to="/">
              <div className="backLink-icon"><BiLeftArrowAlt /></div>
              <span>Go Back</span>
            </Link>
          </div>
        </div>
        <div className="loginWrap" >
          <div className="infoTxt">
            <h1>Reset Password</h1>
            <p>{"Enter the email associated with your account and we'll send an email with instructions to reset your password"}</p>
          </div>
          <div className="loginInp">
            <div className="loginInp-main">
              <label className="label">Email address</label>
              <input className="inputTxt" type="text" onChange={(e) => onChangeText(e.target.value)} placeholder="Please enter email" />
            </div>
          </div>
          {
            emailError !== '' && <div className="infotxtGroup">
              <p className="error">{emailError}</p>
            </div>
          }
          <div className="loginInp d-grid">
            <button onClick={() => !isLoading && callAPI()} disabled={disabled} style={{
              background: !isLoading && !disabled ? '#133e7a' : 'gray',
              color: !isLoading && !disabled ? '#fff' : '#000'
            }} className={disabled ? 'button button-secondary btn-block' : 'button btn-block text-white'}>{isLoading ? 'Please wait...' : 'Send instructions'}</button>
          </div>
        </div>
        <div className="pageBottom text-center keyOpen">
          <p><Link className="link-white" to="/signup">Need to create an account?</Link></p>
          <p>Brought to you by <span>COVERED Inc</span>.</p>
        </div>
      </div>
*/
