import {
  ERROR_OCCURED,
  DELETION_CODE_REQUEST,
  DELETION_CODE,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_REQUEST

} from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import deleteService from '../../../services/delete'

export const requestDeletion = createAsyncThunk(
  '/profile/send-deletion-code',
  async ({ reasonForLeaving }, thunkAPI) => {
    thunkAPI.dispatch({
      type: DELETION_CODE_REQUEST
    })
    try {
      const data = await deleteService.requestDeletion(reasonForLeaving)
      thunkAPI.dispatch({
        type: DELETION_CODE,
        payload: data && data?.data
      })
      return { code: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const deleteAccount = createAsyncThunk(
  '/profile/delete-account',
  async ({ accountDeletionCode, password }, thunkAPI) => {
    thunkAPI.dispatch({
      type: DELETE_ACCOUNT_REQUEST
    })
    try {
      const data = await deleteService.deleteAccount(accountDeletionCode, password)
      thunkAPI.dispatch({
        type: DELETE_ACCOUNT,
        payload: data && data?.data
      })
      return { code: data && data?.data }
    } catch (error) {
      let message = ''
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      if (error?.response?.data?.errors?.length > 0 && ((error?.response?.data?.errors[0] === 'Password should contain at least 1 uppercase, 1 lowercase & 1 special character. No Spaces allowed') || (error?.response?.data?.errors[0] === 'Password should be minimum 8 characters'))) {
        message = 'Incorrect Password'
      } else if (error?.response?.data?.message) {
        message = error?.response?.data?.message
      } else if (error?.message) {
        message = error?.message
      } else {
        message = error.toString()
      }
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
