import {
  USER_LOGIN_SUCCESS, USER_LOGIN_FAIL, RESET_AUTH, USER_LOGOUT, USER_SIGNUP_REQUEST,
  USER_SIGNUP, USER_SIGNUP_FAIL, REQUEST_OTP, REQUEST_OTP_FAIL, VALIDATE_OTP, VALIDATE_OTP_FAIL, FORGOT_PASSWORD, FORGOT_PASSWORD_FAIL, USERNAME_CHECK, USEREMAIL_CHECK, USERNAME_CHECK_ERROR, USEREMAIL_CHECK_ERROR, USER_LOGOUT_FAIL
} from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import AuthService from '../../../services/auth.service'
// import { useNavigate } from 'react-router-dom'

export const register = createAsyncThunk(
  'auth/add',
  async ({ username, email, password, referralCode }, thunkAPI) => {
    try {
      thunkAPI.dispatch({
        type: USER_SIGNUP_REQUEST,
        payload: {}
      })
      const response = await AuthService.register(username, email, password, referralCode)
      console.log(response)
      if (!response?.data?.status && response?.data?.message === 'invite-code-invalid') {
        thunkAPI.dispatch({
          type: "USER_SIGN_UP_INVITE_ONLY",
          message: 'Your invitation code is invalid.'
        })
      }
      else {
        localStorage.setItem('user', JSON.stringify(response && response?.data?.result))
        thunkAPI.dispatch({
          type: USER_SIGNUP,
          payload: response.data
        })
        return response.data
      }

    } catch (error) {
      let message = ''
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }

      if (error?.response?.data?.errors?.length > 0) {
        message = error?.response?.data?.errors[0]
      } else if (error?.response?.data?.message) {
        message = error?.response?.data?.message
      } else if (error?.message) {
        message = error?.message
      } else {
        message = error.toString()
      }
      thunkAPI.dispatch({
        type: USER_SIGNUP_FAIL,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const login = createAsyncThunk(
  'auth/login',
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(username, password)
      console.log('DATA -', data)
      // if (!data?.data?.status && data?.data?.message === 'user-not-invited') {
      //   thunkAPI.dispatch({
      //     type: "USER_INVITE_ONLY",
      //     message: 'We are in BetaMode, You need to be invited to access Betzon!'
      //   })
      // }
      // else if (data && data?.data?.result) {
      //   localStorage.setItem('user', JSON.stringify(data && data?.data?.result))
      //   thunkAPI.dispatch({
      //     type: USER_LOGIN_SUCCESS,
      //     payload: data && data?.data?.result
      //   })
      // }

      if (data && data?.data?.result) {
        localStorage.setItem('user', JSON.stringify(data && data?.data?.result))
        thunkAPI.dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: data && data?.data?.result
        })
      }
      return { user: data && data?.data?.result }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: USER_LOGIN_FAIL,
        payload: error?.response?.data?.result,
        message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: USER_LOGOUT,
      payload: {}
    })
    localStorage.clear()
    // window.location.href = '/'
  }
}

export const logout2 = createAsyncThunk(
  'auth/logout/:id',
  async ({ id, token }, thunkAPI) => {
    try {
      const data = await AuthService.logout(id, token)
      if (data) {
        localStorage.clear()
        thunkAPI.dispatch({
          type: USER_LOGOUT,
          payload: {}
        })
        // window.location = '/'
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: USER_LOGOUT_FAIL,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const resetAuth = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_AUTH
    })
  }
}

export const otpRequest = createAsyncThunk(
  '/auth/otp-request',
  async ({ username, screen }, thunkAPI) => {
    try {
      const data = await AuthService.requestOtp(username, screen)
      thunkAPI.dispatch({
        type: REQUEST_OTP,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: REQUEST_OTP_FAIL,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const validateOTP = createAsyncThunk(
  '/auth/otp-validate',
  async ({ username, code }, thunkAPI) => {
    try {
      const data = await AuthService.validateOtp(username, code)
      thunkAPI.dispatch({
        type: VALIDATE_OTP,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: VALIDATE_OTP_FAIL,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const forgotPasswordprocess = createAsyncThunk(
  '/auth/forgot-password',
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await AuthService.resetPassword(username, password)
      thunkAPI.dispatch({
        type: FORGOT_PASSWORD,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: FORGOT_PASSWORD_FAIL,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const checkUserName = createAsyncThunk(
  '/auth/username-check',
  async ({ username }, thunkAPI) => {
    try {
      const data = await AuthService.checkUserName(username)
      thunkAPI.dispatch({
        type: USERNAME_CHECK,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      let message = ''
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      if (error?.response?.data?.errors?.length > 0) {
        message = error?.response?.data?.errors[0]
      } else if (error?.response?.data?.message) {
        message = error?.response?.data?.message
      } else if (error?.message) {
        message = error?.message
      } else {
        message = error.toString()
      }

      thunkAPI.dispatch({
        type: USERNAME_CHECK_ERROR,
        payload: { status: false, message }
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const checkUserEmail = createAsyncThunk(
  '/auth/email-check',
  async ({ email }, thunkAPI) => {
    try {
      const data = await AuthService.checkUserEmail(email)
      thunkAPI.dispatch({
        type: USEREMAIL_CHECK,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response.data?.errors?.length && error.response.data.errors[0]) || (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: USEREMAIL_CHECK_ERROR,
        payload: { status: false, message }
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
