import {
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom'
import React from 'react'
import OneSignal from 'react-onesignal';

// export async function runOneSignal() {
//   try {
//     if (!window.OneSignalInitialized) {
//       console.log("Initializing OneSignal");
//       await OneSignal.init({ appId: 'd351be75-0f6d-4d9b-bf99-c0000ef580ff', allowLocalhostAsSecureOrigin: true });
//       window.OneSignalInitialized = true;
//       OneSignal.Slidedown.promptPush();
//     } else {
//       console.log("Attempted to initialize OneSignal again");
//     }

//   } catch (error) {
//     console.log(error)
//   }
// }

export default function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    )
  }

  return ComponentWithRouterProp
}

export const isValidUsernameLength = (str) => {
  if (str.length >= 8) {
    return true
  } else {
    return false
  }
}
export const hasWhiteSpace = (s) => {
  return s.indexOf(' ') >= 0
}

export const hasSpecialCharacter = (s) => {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
  return specialChars.test(s)
}

export const isValidPassword = (str) => {
  // const validator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/
  const validator = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,20}$/
  return validator.test(str)
}

export const ValidateEmail = (email) => {
  if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  } else {
    return (false)
  }
}

export const isOnlyNumber = (str) => {
  return /^[0-9]+$/.test(str)
}

export const isValidName = (str) => {
  return /^[a-zA-Z\s]*$/.test(str)
}

export const checkOnlyNumber = (str) => {
  return /^\d+$/.test(str)
}

export const capitalizeFirstLetter = (str) => {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1)

  return capitalized
}

export const isValidUrl = urlString => {
  const expression =
    /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_  +.~#?&//=]*)?/gi
  const urlPattern = new RegExp(expression)
  // const urlPattern = new RegExp(expression)
  return !!urlPattern.test(urlString)
}

export const urlTypeIdentifier = urlString => {
  const videos = ['mp4', '3gp', 'ogg', 'mov']
  const images = ['jpg', 'gif', 'png', 'svg', 'jpeg', 'jfif']
  if (urlString.includes('https://motobookie.s3.amazonaws.com')) {
    const url = new URL(urlString)
    const breakeUrl = url?.pathname?.split('.')
    const extension = breakeUrl[breakeUrl.length - 1]
    if (videos.includes(extension.toLocaleLowerCase())) {
      return 'v'
    } else if (images.includes(extension.toLocaleLowerCase())) {
      return 'i'
    } else {
      return 'l'
    }
  }
}

export const isWagerClose = (time) => {
  // Subtract 300,000 milliseconds (5 minutes) from the given time to define the closed time
  const closedTime = new Date(time).getTime() - 300000;
  const countDownDate = closedTime - new Date().getTime();
  const days = Math.floor(countDownDate / (1000 * 60 * 60 * 24));
  const hours = days > 0
    ? (days * 24) + Math.floor((countDownDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    : Math.floor((countDownDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDownDate % (1000 * 60 * 60)) / (1000 * 60));

  return {
    h: hours,
    m: minutes
  };
}

