
const initialState = {
  isLoading: false
}
export default function Notifications (state = initialState, action) {
  switch (action.type) {
    case 'NOTIFICATIONS_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'NOTIFICATIONS': {
      return {
        ...state,
        isLoading: false,
        Status: 'Pass',
        notification: action.payload,
        error: null
      }
    }
    case 'NOTIFICATIONS_ERROR': {
      return {
        ...state,
        isLoading: false,
        Status: 'Fail',
        error: action.payload
      }
    }
    default:
      return state
  }
}
