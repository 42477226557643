import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { deleteAccount } from '../../redux/actions/delete'

import { useDispatch, useSelector } from 'react-redux'

function DeleteAccountStep2 () {
  const [authCode, setAuthCode] = useState(null)
  const [pwd, sePwd] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const responseData = useSelector((state) => state.deleteAccount)
  const isUserDeleted = responseData?.deleteInfo?.status
  const [disabled, setDisabled] = useState(true)
  const handleDelete = () => {
    if (authCode && pwd) {
      dispatch(
        deleteAccount({
          accountDeletionCode: authCode,
          password: pwd
        })
      )
    }
  }

  useEffect(() => {
    if (isUserDeleted) {
      navigate('/delete-account-confirmation')
    }
  }, [isUserDeleted])

  useEffect(() => {
    if (authCode && pwd) {
      setDisabled(false)
    } else if (responseData?.isLoading) {
      setDisabled(true)
    } else {
      setDisabled(true)
    }
  }, [authCode, pwd, responseData])

  const handleBack = () => {
    dispatch({
      type: 'RESET_DELETE_ACCOUNT'
    })
    dispatch({
      type: 'RESET_DELETE_CODE'
    })
    navigate('/delete-account')
  }
  return (
    <>
      <div className="fullScreen fullScreen-login login-solid">
        <div className="pageTop forDrop bgDark">
          <div className="backLink">
            {/* <Link to="/delete-account"> */}
              <div className="backLink-icon noBg error cursor-pointer" onClick={handleBack}>
              </div>
            {/* </Link> */}
            <span className="error mt-0">Deactivate Account</span>
          </div>
        </div>
        <div className="loginWrap scrollable-notification">
          <div className="editPro">
            <div className="smLogo text-center">
              <h2 className="error fw-bold pt-3 pb-4">
                We are sad to see you go!
              </h2>
            </div>
            <div className="editInpWrap">
              <label>
                Authentication Code to deactivate. Please check your email.
              </label>
              <input
                className="editInp"
                type="password"
                autoComplete="off"
                placeholder="######"
                onChange={(e) => setAuthCode(e.target.value)}
              />
            </div>

            <div className="editInpWrap">
              <label>
              Password
              </label>
              <input
                className="editInp"
                type="password"
                autoComplete="off"
                placeholder="Password"
                onChange={(e) => sePwd(e.target.value)}
              />
            </div>
            {responseData?.error &&
              <div className="infotxtGroup">
                <p className= 'error'>
                {responseData?.error}
                </p>
              </div>
            }
            <div className="editInpWrap text-center">
              <button
                disabled={disabled}
                className={disabled || responseData?.isLoading ? 'button button-secondary mt-3' : 'button button-red  mt-3'}
                onClick={handleDelete}
              >
                {responseData?.isLoading ? 'Please wait...' : 'Deactivate Account'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteAccountStep2
