import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { validateOTP, otpRequest } from '../../redux/actions/auth'
import { isOnlyNumber } from '../../helper'
import LogoContainer from '../../components/header/logo-container'
import { Box, Container, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@emotion/react'
import MailIcon from '@mui/icons-material/Mail';
import { LoadingButton } from '@mui/lab'

function SignUpOtp() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const otpResponse = useSelector((state) => state.Auth)
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : localStorage.getItem('user')
  const useremail = localStorage.getItem('useremail')
  const [error, setError] = useState({
    status: false,
    message: ''
  })
  const [isResendCode, setResendCode] = useState(false)
  const [validOtp, setValidOtp] = useState('')
  const screen = searchParams.get('screen')
  const [otp, setOTP] = useState({
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: ''
  })
  const { otp1, otp2, otp3, otp4 } = otp
  const LatstOTp = otp1 + otp2 + otp3 + otp4
  // const disabled = () => {
  //   if (!otp1 || !otp2 || !otp3 || !otp4) {
  //     return false
  //   } else return true
  // }

  // useEffect(() => {
  //   if (!user && !useremail) {
  //     navigate('/')
  //   }
  // }, [])

  // const handleChange = (value1, event) => {
  //   setError('')
  //   if (event.target.value.length <= 1) {
  //     if (isOnlyNumber(event.target.value)) {
  //       setOTP({ ...otp, [value1]: event.target.value })
  //     } else {
  //       setOTP({ ...otp, [value1]: '' })
  //     }
  //   }
  // }

  useEffect(() => {
    callAPI()
  }, []);

  useEffect(() => {
    if (otpResponse?.otpValidate === 'Fail') {
      if (!otpResponse?.response?.message) {
        if (otpResponse.response === 'Verification Code you have entered is wrong') {
          setError({
            status: true,
            message: 'Invalid entry.'
          })
        } else {
          setError({
            status: true,
            message: otpResponse.response
          })
        }
      }
    } else if (otpResponse?.otpValidate === 'Success' && localStorage.getItem('user')) {
      user.isVerified = true
      localStorage.setItem('user', JSON.stringify(user))
      navigate('/age-verification')
      //navigate('/card-detail')
    } else if (otpResponse?.otpValidate === 'Success' && useremail) {
      navigate('/create-password')
    }
  }, [otpResponse])

  // const inputfocus = (elmnt) => {
  //   if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
  //     const next = elmnt.target.tabIndex - 2
  //     if (next > -1) {
  //       elmnt.target.form.elements[next].focus()
  //     }
  //   } else {
  //     const next = elmnt.target.tabIndex
  //     if (next < 4) {
  //       elmnt.target.form.elements[next].focus()
  //     }
  //   }
  // }

  const hitApi = (e) => {

    // e.preventDefault()
    // if (!(LatstOTp && LatstOTp.length === 4)) {
    //   setValidOtp('Enter OTP')
    // }
    // if (LatstOTp && LatstOTp.length === 4) {
    //   const { otp1, otp2, otp3, otp4 } = otp
    //   dispatch(
    //     validateOTP({
    //       username: user?.username ? user?.username : useremail,
    //       code: otp1 + otp2 + otp3 + otp4
    //     })
    //   )
    // }
    e.preventDefault()
    if (!(LatstOTp && LatstOTp.length === 4)) {
      setValidOtp('Enter OTP')
    }
    if (LatstOTp && LatstOTp.length === 4) {
      const { otp1, otp2, otp3, otp4 } = otp
      dispatch(
        validateOTP({
          username: user?.username ? user?.username : useremail,
          code: otp1 + otp2 + otp3 + otp4
        })
      )
    }
  }

  const callAPI = () => {
    // Trigger the OTP request
    if (useremail) {
      dispatch(otpRequest({ username: useremail, screen: screen === 'resetPassword' ? 'resetPassword' : 'verifyOtp' }));
    } else {
      dispatch(otpRequest({ username: user?.email, screen: screen === 'resetPassword' ? 'resetPassword' : 'verifyOtp' }));
    }

    // Set resendCode to true immediately
    setResendCode(true);

    // Set resendCode to false after 3 seconds
    setTimeout(() => {
      setResendCode(false);
    }, 3000);
  }


  const theme = useTheme()

  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  const handleInputChange = (index) => (event) => {

    setError({
      status: false,
      message: ''
    })

    setOTP({ ...otp, [event.target.name]: event.target.value })
    // If the user has input a digit, move focus to the next input
    if (event.target.value.length === 1 && index < 3) {
      refs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index) => (event) => {
    // If the user presses backspace on an empty input, move focus to the previous input
    if (event.key === 'Backspace' && !event.target.value && index > 0) {
      refs[index - 1].current.focus();
    }
  };

  const refs = [useRef(''), useRef(''), useRef(''), useRef('')];

  const boxStyles = {
    width: '100%',
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center'
  }

  const inputStyles = {
    width: '100%',
    textAlign: 'center'
  }
  const getValuesFromRefs = () => {
    return refs.map(ref => ref.current.value);
  };
  const values = getValuesFromRefs();

  return (
    <Container maxWidth='xl' sx={{
      ...theme.containers.main,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Box sx={{
        maxWidth: '450px',
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: isMedium ? 'center' : 'space-between',
        alignItems: 'center',
        gap: '24px',
        pt: 2,
        pb: 2,
      }}>


        {
          isMedium ? null : <LogoContainer mini={true} />
        }

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '24px'
        }}>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6px'
          }}>
            <MailIcon sx={{ fontSize: '60px', mb: -1, color: 'white' }} />

            <Typography variant='h4'>Verify your email</Typography>

            <Typography variant='caption' color={theme.palette.dark.otherlight}>Please enter the 4-digit code sent to your email.</Typography>


            {
              isMedium ? <Typography color={theme.palette.dark.otherlight} sx={{ textDecoration: 'underline' }} onClick={() => callAPI()}>Resend Code</Typography> : null
            }

            {
              isResendCode
              &&
              <Typography color="success.main">Sent!</Typography>
            }

          </Box>

          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
            width: '100%'
          }}>

            {
              ['otp1', 'otp2', 'otp3', 'otp4'].map((item, index) => (
                <TextField
                  error={error.status}
                  key={index}
                  hiddenLabel
                  name={item}
                  inputRef={refs[index]}
                  variant="filled"
                  size="large"
                  inputProps={{
                    type: 'text',
                    maxLength: 1,
                    pattern: "\\d" // allows only a single digit
                  }}
                  onChange={handleInputChange(index)}
                  onKeyDown={handleKeyDown(index)}
                  sx={{
                    ...inputStyles,
                    '& input': {
                      textAlign: 'center',
                      fontSize: { xs: '24px', sm: '24px', md: '36px', lg: '40px' }
                    }
                  }}
                />
              ))
            }

          </Box>
          {
            error.status ?
              <Typography color='error'>{error.message}</Typography> :
              null
          }

          {
            isMedium ? null :
              <Typography color={theme.palette.dark.otherlight} sx={{ textDecoration: 'underline', mt: -1.5 }} onClick={async () => {
                await localStorage.clear('user')
                dispatch(({
                  type: 'RESET_AUTH',
                  payload: {}
                }))
                navigate('/')
              }}>Already have an account?</Typography>
          }

        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: '8px'
        }}>
          <LoadingButton
            disabled={otp.otp1 === '' || otp.otp2 === '' || otp.otp3 === '' || otp.otp4 === ''}
            size="large"
            onClick={(e) => hitApi(e)}
            variant='contained'
            sx={{
              width: '100%'
            }}
          >
            Verify
          </LoadingButton>
          {
            isMedium ? <Typography color={theme.palette.dark.otherlight} sx={{ textDecoration: 'underline', mt: 1 }} onClick={async () => {
              await localStorage.clear('user')
              dispatch(({
                type: 'RESET_AUTH',
                payload: {}
              }))
              navigate('/')
            }}>Already have an account?</Typography> : <Typography color={theme.palette.dark.otherlight} sx={{ textDecoration: 'underline' }} onClick={() => callAPI()}>Resend Code</Typography>
          }
        </Box>

      </Box>

    </Container >
  )
}

export default SignUpOtp

/*
<>
      <div className="fullScreen fullScreen-login">
        <div className="loginWrap mobileWrap mt-0 pt-0">
          
          
          <div className='logo_div mb-4'>
            <div className="logo">
              <img src={logo}
              />
            </div>
            <div className='text-center'>
              <span className='span_moto'>Moto</span><span className='span_bookie'>Bookie</span>
            </div>
          </div>

          <div className="infoTxt text-center">
            <p>
              <FaEnvelope className="envLarge" />
            </p>
            <h1>Verify your email</h1>
            <p>
              You have received a 4 digit code at{' '}
              <span className="fw-bold">{user?.email || useremail}</span> <br />
              <Link className="fw-bold link-white link-bdr" onClick={callAPI} to="">
                Resend Verification Code
              </Link>
              <br />
              {
                isResendCode &&
                <span className={'fw-bold success'}>Sent</span>
              }
            </p>
          </div>
          <form onSubmit={(e) => hitApi(e)}>
            <div className="loginInp">
              <div className="row">
                <div className="col-3">
                  <input
                    inputMode="numeric"
                    className="otpInp"
                    name="otp1"
                    autoComplete="off"
                    value={otp.otp1}
                    onChange={(e) => handleChange('otp1', e)}
                    tabIndex="1"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </div>
                <div className="col-3">
                  <input
                    inputMode="numeric"

                    className="otpInp"
                    name="otp2"
                    pattern="[0-9]"
                    autoComplete="off"
                    value={otp.otp2}
                    onChange={(e) => handleChange('otp2', e)}
                    tabIndex="2"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </div>
                <div className="col-3">
                  <input
                    inputMode="numeric"

                    className="otpInp"
                    name="otp3"
                    autoComplete="off"
                    value={otp.otp3}
                    onChange={(e) => handleChange('otp3', e)}
                    tabIndex="3"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </div>
                <div className="col-3">
                  <input
                    inputMode="numeric"

                    className="otpInp"
                    name="otp4"
                    autoComplete="off"
                    value={otp.otp4}
                    onChange={(e) => handleChange('otp4', e)}
                    tabIndex="4"
                    maxLength="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                </div>
              </div>
            </div>

            <div className="loginInp d-grid">
              <button
                // disabled={!(LatstOTp && LatstOTp.length === 4)}
                disabled={!disabled()}
                // onClick={() => hitApi()}
                className="button btn-block"
                type="submit"
                style={{ background: !disabled() ? 'grey' : '#133e7a', color: !disabled() ? '#000' : '#fff' }}
              >
                Verify
              </button>
              {error && (
                <div className="infotxtGroup text-center mt-3">
                  <p className="error">{error}</p>
                </div>
              )}
              {otpResponse?.Auth?.otpValidate === 'Fail' &&
                <div className="infotxtGroup text-center mt-3">
                  <p className="error">{error}</p>
                </div>
              }
              {!(LatstOTp && LatstOTp.length === 4) &&
                <div className="infotxtGroup text-center mt-3">
                  <p className='error'>{validOtp}</p>
                </div>
              }
            </div>
          </form>
          <div className="pageBottom text-center keyOpen">
            <p>
              <div className="link-white cursor-pointer" onClick={async () => {
                await localStorage.clear('user')
                dispatch(({
                  type: 'RESET_AUTH',
                  payload: {}
                }))
                navigate('/')
              }}>
                <u>Already have an account?</u>
              </div>
            </p>
            <p>
              Brought to you by <span>COVERED Inc</span>.
            </p>
          </div>
        </div>
        
      </div>
    </>
*/
