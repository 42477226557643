import axios from './axios'
import authHeader from './auth-header'
const getAllChats = (uid, skip, limit) => {
  return axios.post(`chat/getAllChat/${uid}`,
    {
      skip,
      limit
    },
    {
      headers: authHeader()
    }
  )
}

const searchUsers = (query) => {
  return axios.post('chat/user/search', { query }, {
    headers: authHeader()
  })
}

const getAllMessages = (chatId) => {
  return axios.get(`chat/getAllMessages/${chatId}`,
    {
      headers: authHeader()
    }
  )
}

const getMoreMessages = (chatId, limit, filterBy) => {
  return axios.post(`chat/get-more-messages/${chatId}`,
    {
      limit, filterBy
    }
    ,
    {
      headers: authHeader()
    }
  )
}

const createChat = (type, participants) => {
  return axios.post('chat/createChat',
    {
      type,
      participants
    },
    {
      headers: authHeader()
    }
  )
}

const createMessage = (chatId, message, userId) => {
  return axios.post('chat/createMessage',
    {
      chatId, message, userId
    },
    {
      headers: authHeader()
    }
  )
}

const ChatsServices = {
  getAllChats,
  getAllMessages,
  createChat,
  createMessage,
  searchUsers,
  getMoreMessages
}
export default ChatsServices
