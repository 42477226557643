import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const Invite = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      navigate(`/signup/${id}`)
    }
  }, [id])

  return (
    <div>Invite</div>
  )
}

export default Invite
