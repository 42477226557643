import React, { useEffect, useState } from 'react'
import { Typography, Container, Box, Button, Chip, Alert } from '@mui/material'
import WagerCreateHeader from '../../../components/wager/create/components/header'
import LeagueHeaders from '../../../components/header/leagues-header'
import WagerCreationFooter from '../../../components/wager/create/components/footer'
import { useDispatch, useSelector } from 'react-redux'
import { sportAction } from '../../../redux/actions/sport'
import FeedLayout from '../../../components/layouts/feed'
import { MatchItem } from '../../../components/matches/list-items'
import Loader from '../../../components/loader'
import CreateWager from '../../../components/wager/create'
import { resetWager, setCreationWagerModeToFantasy, setCreationWagerModeToReal } from '../../../redux/actions/wagers'

const modeToSelect = [
    {
        title: 'Real Money',
        mode: 'real'
    },
    {
        title: 'Fantasy',
        mode: 'fantasy'
    }
]

const MessageCreateWager = ({ chatId, roomDetails }) => {
    const wager_data = useSelector((state) => state.Wagers)

    const dispatch = useDispatch()
    const [stage, setStage] = useState(0)
    const [loading, setLoader] = useState(true)
    const [filteredSportEvents, setFilteredSportEvents] = useState([])
    const [selectedEventToWager, setSelectedEventToWager] = useState(null)
    const toggleMain = useSelector((state) => state.Modals.toggle);

    const sport_data = useSelector((state) => state.Sport);
    const eventIdResponse = useSelector((state) => state.eventId)

    useEffect(() => {

        setLoader(true)
        // console.log("CHECKING CREATE WAGER")
        // console.log(eventIdResponse)
        const temp = eventIdResponse?.eventInfo?.result?.filter(item => item?.sportsId === sport_data?.sport?.id);
        setFilteredSportEvents(temp)
        setLoader(false)

    }, [eventIdResponse, sport_data]);

    const setModeHandler = (mode) => {
        switch (mode) {
            case 'real':
                dispatch(setCreationWagerModeToReal())
                break;
            case 'fantasy':
                dispatch(setCreationWagerModeToFantasy())
                break;
            default:
                break;
        }
        setStage(stage + 1)
    }

    useEffect(() => {
        if (!toggleMain) {
            setStage(0)
            dispatch(resetWager())
        }
    }, [toggleMain]);
    console.log(wager_data)
    switch (stage) {
        case 0:
            return (

                <Container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                }}>
                    <Box sx={{
                        // display: 'flex',
                        //justifyContent: 'flex-start',
                        textAlign: 'center'
                    }}>
                        <Typography variant='h6'>Select your wagering mode!</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: '12px'
                    }}>
                        {
                            modeToSelect.map((item, index) => (
                                <Button
                                    key={index}
                                    disabled={item.mode === 'real'}
                                    onClick={() => setModeHandler(item.mode)}
                                    variant='outlined'
                                    sx={{
                                        borderRadius: '4px',
                                        position: 'relative',
                                        width: '100%', // Ensure the width is properly set
                                    }}>
                                    <Typography variant='caption' sx={{
                                        opacity: item.mode === 'real' ? .3 : 1
                                    }}>{item.title}</Typography>
                                </Button>
                            ))
                        }
                    </Box>
                </Container>
            )
            break;
        case 1:
            return (
                <>
                    <CreateWager privateWager chatId={chatId} roomDetails={roomDetails} />
                </>
            )
        default:
            break;
    }
}

export default MessageCreateWager
