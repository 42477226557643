import { useTheme } from '@emotion/react'
import { Alert, Avatar, Box, Button, Chip, Stack, SwipeableDrawer, Typography, alpha } from '@mui/material'
import React, { useState } from 'react'
import WagerCard from '../feed-item'
import WagerTeamItem from '../team-item'
import styled from 'styled-components'
import WagerChatDrawer from './drawer'
import { isWagerClose } from '../../../helper'
import ProfilePill from '../profile-pill'
import LockIcon from '@mui/icons-material/Lock';

const MySwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
    '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, .6)'
    },
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 40,
    height: 4,
    backgroundColor: 'white',
    borderRadius: 3,
    top: 8,
    left: 'calc(50% - 15px)',
    zIndex: '999px'
}));

const checkWagerUserStatus = (wagerUsers, id) => {
    const acceptedUsers = wagerUsers?.filter(item => item.status === 'accept');
    const acceptedUserIds = acceptedUsers.map(user => user.user_data.id);
    const acceptedByUser = acceptedUserIds.includes(id);
    return {
        acceptedUserIds: acceptedUserIds,
        acceptedByMe: acceptedByUser
    }
}


const ChatWager = ({ data }) => {

    const [toggle, setToggle] = useState({
        status: false,
        type: ''
    })

    const user = JSON.parse(localStorage.getItem('user'))

    const wager = data?.wager

    const theme = useTheme()

    const lineStyles = {
        bgcolor: 'white', //theme.palette.dark.otherlight,
        height: '2px',
        width: '100%'
    }

    const noWrapStyles = {
        fontWeight: 700,
        whiteSpace: 'nowrap', // Prevent wrapping
        overflow: 'hidden', // Hide overflow
        textOverflow: 'ellipsis', // Add ellipsis for overflowed text
    };

    const itemSizes = {
        color: theme.palette.dark.light,
        fontWeight: 700
    }

    const ordinalSuffix = (number) => {
        let j = number % 10,
            k = number % 100;
        if (j == 1 && k != 11) {
            return 'st';
        }
        if (j == 2 && k != 12) {
            return 'nd';
        }
        if (j == 3 && k != 13) {
            return 'rd';
        }
        return 'th';
    }


    const WinnerSwitcher = (wagerTypeId) => {
        switch (wagerTypeId) {
            case 1:
                //1v1
                return <><span style={{ color: theme.palette.success.main }}>Winner</span>.</>
                break;

            case 2:
                //1v1
                if (wager?.placement === wager?.placement_range) {
                    return <>Places {wager?.placement}{ordinalSuffix(wager?.placement)}.</>

                }
                else {
                    //<>Places between <span style={{ color: theme.palette.success.main }}>{data.placement}{ordinalSuffix(data.placement)}</span> and <span style={{ color: theme.palette.success.main }}>{data.placement_range}{ordinalSuffix(data.placement_range)}</span ></>
                    return <>Places between <br />{wager?.placement}{ordinalSuffix(wager?.placement)} & {wager?.placement_range}{ordinalSuffix(wager?.placement_range)}.</>
                }
                break;

            case 3:
                //1v1 SPREAD
                return <><span style={{ color: theme.palette.success.main }}>Winner</span> by {wager?.spreadOptions_data?.option} spots.</>
                break;

            case 4:
                //1v1 SPREAD
                return <><span style={{ color: theme.palette.success.main }}>Wins</span> the holeshot.</>
                break;

            case 5:
                //Podium <>Places <span style={{ color: theme.palette.success.main }}>Podium</span></>
                return <>Places Podium</>
                break;

            case 6:
                return <span style={{ color: theme.palette.success.main, fontWeight: 700 }}>Winner</span>
                break;
            default:
                break;
        }
    }

    const LoserSwitcher = (wagerTypeId) => {
        switch (wagerTypeId) {
            case 1:
                //1v1
                return <><span style={{ color: theme.palette.error.main }}>Loser</span>.</>
                break;

            case 2:
                //1v1
                return "Loser."
                break;

            case 3:
                //1v1 SPREAD
                return `Loser.`
                break;

            case 6:
                return <><span style={{ color: theme.palette.error.main, fontWeight: 700 }}>Loser</span></>
                break;
            default:
                break;
        }
    }

    const openDrawer = (type) => {
        setToggle({
            status: true,
            type: type
        })
    }

    const closeDrawer = () => {
        setToggle({
            status: false,
            type: ''
        })
    }

    const allAccepted = data?.wager?.wagerusers.every(user => user.status === 'accept');

    const wagerStatus = isWagerClose(wager?.event_data?.startTime)

    return (
        <>
            <MySwipeableDrawer
                anchor={'bottom'}
                open={toggle.status}
                onOpen={() => openDrawer()}
                onClose={() => closeDrawer()} // This will close the drawer when tapping outside
                PaperProps={{
                    style: {
                        height: '80vh',
                        width: '100%',
                        backgroundColor: 'black',
                        boxShadow: 'none',
                        borderTop: 0,
                        borderRadius: '8px 8px 0 0',
                        borderTop: `solid 1px ${theme.palette.dark.main}`,
                        borderBottom: '0 !important',
                        zIndex: '999999999999',
                        borderTop: `3px solid ${theme.palette.dark.otherdark}`
                    },
                }}
            >
                <Box sx={{ bgcolor: 'black' }}>
                    <WagerChatDrawer
                        type={toggle.type}
                        data={{ ...wager }}
                        closeDrawer={() => closeDrawer()} />
                </Box>
            </MySwipeableDrawer>

            <Stack
                spacing={3}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    width: '100%',
                    boxSizing: 'border-box',
                    bgcolor: allAccepted || checkWagerUserStatus(wager?.wagerusers, user?.id).acceptedByMe || wager?.result === 'refund' ? theme.palette.dark.otherdark : alpha(theme.palette.primary.main, .10),
                    border: allAccepted || checkWagerUserStatus(wager?.wagerusers, user?.id).acceptedByMe || wager?.result === 'refund' ? `2px solid ${theme.palette.dark.otherdark}` : `2px solid ${theme.palette.primary.main}`,
                    borderRadius: wager?.createdBy === user?.id ? '8px 8px 0px 8px' : '8px 8px 8px 0px',
                    p: 2,
                    opacity: (wagerStatus?.h <= 0 && wagerStatus?.m <= 0) ? .4 : 1
                }}>

                <Stack alignItems={'center'} width={'100%'} spacing={0} sx={{ color: theme.palette.dark.otherlight }}>
                    {
                        wager?.result === 'refund' ?
                            <Typography variant="body1" sx={noWrapStyles}>
                                {
                                    wager?.createdBy === user?.id
                                        ?
                                        `You have deleted this wager.`
                                        :
                                        `@${wager?.createdBy_data?.username} has deleted this wager.`
                                }
                            </Typography>
                            :
                            <>

                                <Typography variant="body1" sx={noWrapStyles}>
                                    {
                                        wager?.status === 'close' && wager?.createdBy === user?.id && !allAccepted && 'Your wager request'
                                    }
                                    {
                                        wager?.createdBy === user?.id && allAccepted && 'Everyone accepted this wager!'
                                    }
                                    {
                                        wager?.status === 'open' && wager?.createdBy === user?.id && !allAccepted && 'Your wager request'
                                    }
                                    {
                                        wager.wagerusers?.length === 1 && wager?.status === 'open' && wager?.createdBy != user?.id && !allAccepted && `@${wager?.createdBy_data?.username} sent you a wager!`
                                    }
                                    {
                                        wager.wagerusers?.length > 1 && wager?.status === 'open' && wager?.createdBy != user?.id && !allAccepted && !checkWagerUserStatus(wager?.wagerusers, user?.id).acceptedByMe && `@${wager?.createdBy_data?.username} sent the group a wager!`
                                    }
                                    {
                                        wager?.status === 'close' && wager?.createdBy != user?.id && `Wager is closed!`
                                    }
                                    {
                                        wager?.status === 'open' && wager?.createdBy != user?.id && checkWagerUserStatus(wager?.wagerusers, user?.id).acceptedByMe && `You accepted this wager!`
                                    }
                                </Typography>
                                <Typography
                                    variant='caption'
                                //color={theme.palette.dark.otherlight}
                                >
                                    {
                                        wager.wagerusers?.length === 1 && (wagerStatus?.h > 0 || wagerStatus?.m > 0) && wager?.status === 'open' && wager?.createdBy === user?.id && !allAccepted && `Waiting for @${wager?.wagerusers[0]?.user_data?.username} to respond!`
                                    }
                                    {
                                        wager.wagerusers?.length > 1 && (wagerStatus?.h > 0 || wagerStatus?.m > 0) && wager?.status === 'open' && wager?.createdBy === user?.id && !allAccepted && `Waiting for the rest of the group to respond!`
                                    }
                                    {
                                        (wagerStatus?.h > 0 || wagerStatus?.m > 0) && wager?.status === 'open' && wager?.createdBy != user?.id && !allAccepted && `What do you have to say?`
                                    }
                                    {
                                        (wagerStatus?.h <= 0 && wagerStatus?.m <= 0) &&
                                        `WAGER IS CLOSED`
                                    }
                                </Typography>
                            </>
                    }
                    {
                        wager?.result != 'refund' &&
                        <Box sx={{ mt: 1 }}>
                            <ProfilePill
                                max={wager?.maxPeople}
                                wagerId={wager?.id}
                                items={wager?.wagerusers}
                                size={32}
                                privateWager
                                wagerStatus={(wagerStatus?.h > 0 || wagerStatus?.m > 0)} />
                        </Box>
                    }
                </Stack>
                {
                    wager?.result === 'refund' &&
                    < Alert sx={{ width: '100%' }} severity={"info"} iconMapping={{
                        error: <LockIcon fontSize="inherit" />,
                    }}>
                        {
                            user.id === wager?.createdBy
                                ?
                                `You were refunded.`
                                :
                                `@${wager?.createdBy_data?.username} was refunded.`
                        }
                    </Alert>
                }
                {
                    wager?.result != 'pending' && wager?.result != 'refund' &&
                    < Alert sx={{ width: '100%' }} severity={
                        wager?.result === 'winner'
                            ?
                            user?.id === wager?.createdBy ?
                                "success"
                                :
                                wager?.wagerusers.some(userObj => userObj?.userId === user?.id)
                                    ?
                                    "error"
                                    :
                                    "success"
                            :
                            user?.id === wager?.createdBy ?
                                `error`
                                :
                                wager?.wagerusers.some(userObj => userObj?.userId === user?.id)
                                    ?
                                    `success`
                                    :
                                    `error`
                    } iconMapping={{
                        error: <LockIcon fontSize="inherit" />,
                    }}>
                        {
                            wager?.result === 'winner'
                                ?
                                user?.id === wager?.createdBy ?
                                    `You won this wager.`
                                    :
                                    wager?.wagerusers.some(userObj => userObj?.userId === user?.id)
                                        ?
                                        `You lost this wager.`
                                        :
                                        `@${wager?.createdBy_data?.username} won this wager.`
                                :
                                user?.id === wager?.createdBy ?
                                    `You lost this wager.`
                                    :
                                    wager?.wagerusers.some(userObj => userObj?.userId === user?.id)
                                        ?
                                        `You won this wager.`
                                        :
                                        `@${wager?.createdBy_data?.username} lost this wager.`
                        }
                    </Alert>
                }
                <Stack sx={{ width: '100%' }} spacing={.5}>

                    <Box sx={{
                        width: '100%',
                        borderRadius: '12px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: data?.wagerType_data?.id === 4 || data?.wagerType_data?.id === 5 || data?.wagerType_data?.id === 2 ? 'center' : 'center',
                            gap: '8px'
                        }}>

                            <WagerTeamItem data={{
                                racer: wager?.event_data?.sports_data?.type === "match" ? wager?.teamsFor_data : wager?.racerFor_data,
                                prediction: WinnerSwitcher(wager?.wagerType_data?.id)
                            }} />
                            {
                                wager?.wagerType_data?.id === 4 || wager?.wagerType_data?.id === 5 || wager?.wagerType_data?.id === 2 ?
                                    null :
                                    <WagerTeamItem data={{
                                        racer: wager?.event_data?.sports_data?.type === "match" ? wager?.teamsAgainst_data : wager?.racerAgainst_data,
                                        prediction: LoserSwitcher(wager?.wagerType_data?.id)
                                    }} />
                            }
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                            textAlign: 'right',
                            hegiht: '100%',
                            //bgcolor: 'red'
                            gap: '24px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-end'
                            }}>
                                <Box sx={{ mb: .5 }}>
                                    <Chip size='small' label={wager?.event_data?.sports_data?.name} sx={{ width: 'fit-content' }} />

                                </Box>
                                {
                                    !!data?.division_data &&
                                    <Typography variant='body2' sx={itemSizes}>{wager?.division_data?.name}</Typography>
                                }
                                <Typography variant='body2' sx={itemSizes}>{wager?.multiplier_data?.name} Multiplier</Typography>
                                <Typography variant='body2' sx={{ ...itemSizes, color: 'white' }}>{wager?.chipsBet} chips to join</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Stack>
                {

                    wager?.result != 'refund'
                    &&
                    <Stack sx={{ width: '100%' }} justifyContent={'flex-end'} alignItems={'center'} direction='row'>
                        {
                            (allAccepted
                                ||
                                checkWagerUserStatus(wager?.wagerusers, user?.id).acceptedByMe)
                            &&
                            <Button size="small" variant='contained' onClick={() => openDrawer('owner')}>View Details</Button>
                        }
                        {
                            wager?.status === 'open'
                            &&
                            !allAccepted
                            &&
                            wager?.createdBy === user?.id
                            &&
                            <>
                                <Button size="small" variant='contained' onClick={() => openDrawer('owner')}>View Details</Button>
                            </>
                        }
                        {
                            wager?.status === 'open'
                            &&
                            (wagerStatus?.h > 0 || wagerStatus?.m > 0)
                            &&
                            !allAccepted
                            &&
                            wager?.createdBy != user?.id
                            &&
                            !checkWagerUserStatus(wager?.wagerusers, user?.id).acceptedByMe
                            &&
                            <>
                                <Button size="small" variant='contained' onClick={() => openDrawer('viewer')}>Accept</Button>
                            </>
                        }
                    </Stack>

                }
            </Stack>
        </>
    )
}

export default ChatWager
