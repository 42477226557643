import React, { useEffect, useState, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { getUserProfile } from '../../redux/actions/profile'
import { useDispatch, useSelector } from 'react-redux'
import '../../css/UserProfile.css'
import { createChat } from '../../redux/actions/chats'
import { listMyWaggers, listUserWaggers } from '../../redux/actions/myWager'
import Loader from '../../components/loader'
import { listMyPosts, listUserPosts } from '../../redux/actions/Posts'
import { useSocket } from '../../components/hooks/useSocket'
import AppLayout from '../../components/layouts/app'
import DashboardHeader from '../../components/header/dashboard-header'
import { Avatar, List, ListItemButton, Button, Box, Stack, Typography, Chip, Tabs, Tab, Container, Alert, ListItem } from '@mui/material'
import PropTypes from 'prop-types';
import theme from '../../styles/theme'
import WagerCard from '../../components/wager/feed-item'
import PostItem from '../../components/Post/post-item'
import { isWagerClose } from '../../helper'
import LikeServices from '../../services/like'
import LeagueHeaders from '../../components/header/leagues-header'
import { LoadingButton } from '@mui/lab'
import { chipsBalance } from '../../redux/actions/chips'

function FantasyChipPanelTab(props) {
  const { children, fantasyValue, fantasyIndex, value, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={(fantasyValue !== fantasyIndex) && value === 0}
      id={`simple-tabpanel-${fantasyIndex}`}
      aria-labelledby={`simple-tab-${fantasyIndex}`}
      {...other}
    >
      {fantasyValue === fantasyIndex && value === 2 && (
        <Container>
          {children}
        </Container>
      )}
    </div>
  );
}



function FantasyChipPanelTabChips(props) {
  const { children, fantasyValue, fantasyIndex, value, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={(fantasyValue !== fantasyIndex) && value === 0}
      id={`simple-tabpanel-${fantasyIndex}`}
      aria-labelledby={`simple-tab-${fantasyIndex}`}
      {...other}
    >
      {fantasyValue === fantasyIndex && value === 3 && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

FantasyChipPanelTab.propTypes = {
  children: PropTypes.node,
  fantasyIndex: PropTypes.number.isRequired,
  fantasyValue: PropTypes.number.isRequired,
};


function UserProfile() {
  const dispatch = useDispatch()
  const navigate = useNavigate()


  //zack cahnges
  const [value, setValue] = React.useState(0);
  const SportData = useSelector((state) => state?.Sport?.sport)
  const responseData = useSelector((state) => state.Chips)
  const chipsData = responseData?.chips?.result
  //indians
  const ref = useRef(null)
  const ref2 = useRef(null)
  const socket = useSocket()
  const overallLeagues = useSelector((state) => state.Leagues);
  const [loader, setLoader] = useState(false)
  const profileResponse = useSelector((state) => state.userProfile)
  const chatResponse = useSelector((state) => state.createChat)
  const profileData = profileResponse?.userInfo?.result
  const unReadChatCount = useSelector((state) => state.newChatCount)
  const [tab, setTab] = useState('stats')
  const [wagerLimit, setWagerLimit] = useState(10)
  const [postLimit, setPostLimit] = useState(10)
  const [show, setShow] = useState(false)
  const Sport = useSelector((state) => state.Sport.sport)
  const CounterVal = useSelector((state) => state?.Counter?.counter)
  const [counter, setCounter] = useState(0)
  const [counter2, setCounter2] = useState(0)
  const [likeId, setLikeId] = useState([])
  const wagerResponseData = useSelector((state) => state.myWager)
  const postResponseData = useSelector((state) => state.myPost)
  // const [counterCount, setCounterCount] = useState(null)
  const [postLikeId, setPostLikeId] = useState([])
  const user = JSON.parse(localStorage.getItem('user'))
  const leagues = useSelector((state) => state.Leagues.league);
  const [fantasyValue, setFantasyValue] = React.useState(0);
  const { id } = useParams()
  const [userId, setUserId] = useState()
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false); // New state for the button visibility
  const [showHeader, setShowHeader] = useState(true);
  const lastScrollY = useRef(0);

  const scrollingContainerRef = useRef(null); // Ref for the scrolling div
  const scrollTimeout = useRef(null); // Ref to hold the timeout ID

  const controlHeaderVisibility = () => {
    if (scrollingContainerRef.current) {
      let currentScrollY = scrollingContainerRef.current.scrollTop;

      // Show or hide the header based on scroll direction
      if (currentScrollY < lastScrollY.current) {
        setShowHeader(true);
      } else if (currentScrollY > lastScrollY.current) {
        setShowHeader(false);
      }

      // Determine whether to show the "Scroll to Top" button
      setShowScrollToTopButton(currentScrollY > 100); // Example threshold

      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      scrollTimeout.current = setTimeout(() => {
        setShowHeader(true);
      }, 500);

      lastScrollY.current = currentScrollY;
    }
  };


  useEffect(() => {
    const scrollingContainer = scrollingContainerRef.current;
    if (scrollingContainer) {
      scrollingContainer.addEventListener('scroll', controlHeaderVisibility);

      return () => {
        scrollingContainer.removeEventListener('scroll', controlHeaderVisibility);
        if (scrollTimeout.current) {
          clearTimeout(scrollTimeout.current);
        }
      };
    }
  }, []);




  // Function to scroll to the top
  const scrollToTop = () => {
    if (scrollingContainerRef.current) {
      scrollingContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };





  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    socket.on('newCounter', (arg) => {
      if (arg?.wagerCreatedBy === user?.id) {
        if (arg?.CounterCount > 0) {
          let temp = []
          // setCounterCount(arg?.CounterCount)
          if (CounterVal?.length === 0) {
            temp = [arg?.wagerId]
          } else {
            const temp2 = CounterVal?.filter((val) => val === arg.wagerId)
            if (temp2.length === 0) {
              temp = [...CounterVal, arg.wagerId]
            } else {
              temp = CounterVal
            }
            // temp = CounterVal?.filter((val) => val !== arg.wagerId)
            // temp = [...CounterVal, CounterVal?.filter((val) => val !== arg.wagerId)]
          }
          dispatch({
            type: 'SETCOUNTER',
            payload: temp
          })
        }
      }
    })
  }, [socket])

  // const [win, setWin] = useState(0)
  // const [lost, setLost] = useState(0)


  useEffect(() => {
    if (value === 0) {
      if (id) {
        if (id !== userId) {
          dispatch(
            listUserWaggers({
              skip: 0,
              limit: wagerLimit,
              filter: { mywagers: 'active' },
              id
            })
          )
        }
      } else {
        dispatch(
          listMyWaggers({
            skip: 0,
            limit: wagerLimit,
            filter: { mywagers: 'active' }
          })
        )
      }
    }
    if (value === 1) {
      if (id) {
        if (id !== userId) {
          dispatch(
            listUserPosts({
              skip: 0,
              limit: postLimit,
              id
            })
          )
        }
      } else {
        dispatch(
          listMyPosts({
            skip: 0,
            limit: postLimit
          })
        )
      }
    }
  }, [value, wagerLimit, postLimit, id])

  const newChat = () => {
    if (!id || id.toString() === userId.toString()) {
      navigate('/message?profile=true')
    } else {
      const participant = [userId.toString(), id]
      dispatch(createChat({
        type: 'P',
        participants: participant
      }))
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && ref2.current && !ref2.current.contains(event.target)) {
        // onClickOutside && onClickOutside();
        setShow(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false
    if (user) {
      setUserId(user?.id)
    }
    // })()
  }, [])

  // calculate Win : Lost Ratio
  // useEffect(() => {
  // if (profileData) {
  // const avg = ((profileData?.wagersWins + profileData?.wagerLoss) / 10)
  // const win1 = ((profileData?.wagersWins) / avg)
  // const lost1 = (profileData?.wagerLoss / avg)
  // setWin(win1)
  // setLost(lost1)
  // }
  // }, [profileData])
  //

  useEffect(() => {
    dispatch(chipsBalance({
      sportsId: SportData?.id
    }))
  }, [SportData?.id])

  const fetchMoreData = () => {
    setLoader(true)
    if (value === 0) {
      setCounter(counter + 1)
      setTimeout(() => {
        if (wagerLimit < wagerResponseData?.myWager?.count) {
          setWagerLimit(wagerLimit + 10)
          setLoader(false)
        }
      }, 500)
    }
    if (value === 1) {
      setCounter2(counter2 + 1)
      setTimeout(() => {
        if (postLimit < postResponseData?.myPosts?.count) {
          setPostLimit(postLimit + 10)
          setLoader(false)
        }
      }, 500)
    }
  }

  useEffect(() => {
    // need to remove skip limit
    dispatch(
      getUserProfile({
        skip: '0',
        limit: '10',
        userId: id
      })
    )
  }, [id])

  /*
  useEffect(() => {
     if (chatResponse?.chatInfo?.id) {
       navigate(`/chat-screen?chatId=${chatResponse?.chatInfo?.id}`)
     }
   }, [chatResponse])
  */

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //console.log(profileData)

  const handleWagerLike = (id, idx, isWagerLikeByMe) => {
    if (likeId.includes(id)) {
      const array = likeId.filter(item => item !== id)
      setLikeId(array)
      // }
    } else {
      setLikeId([...likeId, id])
    }
    LikeServices.wagerLike(id).then((result) => {
      // console.log(result)
    }).catch((err) => {
      console.log(err, 'error')
    })
  }

  useEffect(() => {
    if (wagerResponseData) {
      const array = []
      for (let i = 0; i < wagerResponseData?.myWager?.result?.length; i++) {
        if (wagerResponseData?.myWager?.result[i]?.isWagerLikeByMe) {
          array.push(wagerResponseData?.myWager?.result[i]?.id)
        }
      }
      setLikeId(array)
    }
    if (postResponseData?.posts?.result) {
      const array2 = []
      for (let i = 0; i < postResponseData?.posts?.result.length; i++) {
        if (postResponseData?.posts?.result[i].isPostLikeByMe) {
          array2.push(postResponseData?.posts?.result[i].id)
        }
      }
      setPostLikeId(array2)
    }
  }, [wagerResponseData, postResponseData?.posts?.result])

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <>
        {value === index && (
          <Box sx={props.sx}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {children}
          </Box>
        )}
      </>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handlePostLike = (id) => {
    if (postLikeId.includes(id)) {
      const array = postLikeId.filter(item => item !== id)
      setPostLikeId(array)
      // }
    } else {
      setPostLikeId([...postLikeId, id])
    }

    LikeServices.postLike(id).then((result) => {
    }).catch((err) => {
      console.log(err, 'error')
    })
  }

  const handleSportFilter = (id) => {
    setFantasyValue(id);
  }
  const fantasy = [
    [
      //MOTOCROSS
      {
        title: 'Fantasy Chips Available',
        data: profileData?.fantasy?.fantasyStats[0]?.chipsAvailable?.toLocaleString()
      },
      {
        title: 'Fantasy Wagers Won',
        data: profileData?.fantasy?.fantasyStats[0]?.wagersWin?.toLocaleString()
      },
      {
        title: 'Fantasy Wagers Lost',
        data: profileData?.fantasy?.fantasyStats[0]?.wagersLost?.toLocaleString()
      }
    ],
    [
      //FORMULA ONE
      {
        title: 'Fantasy Chips Available',
        data: profileData?.fantasy?.fantasyStats[1]?.chipsAvailable?.toLocaleString()
      },
      {
        title: 'Fantasy Wagers Won',
        data: profileData?.fantasy?.fantasyStats[1]?.wagersWin?.toLocaleString()
      },
      {
        title: 'Fantasy Wagers Lost',
        data: profileData?.fantasy?.fantasyStats[1]?.wagersLost?.toLocaleString()
      }
    ],
    [
      //MOTOGP
      {
        title: 'Fantasy Chips Available',
        data: profileData?.fantasy?.fantasyStats[2]?.chipsAvailable?.toLocaleString()
      },
      {
        title: 'Fantasy Wagers Won',
        data: profileData?.fantasy?.fantasyStats[2]?.wagersWin?.toLocaleString()
      },
      {
        title: 'Fantasy Wagers Lost',
        data: profileData?.fantasy?.fantasyStats[2]?.wagersLost?.toLocaleString()
      }
    ],
    [
      //NASCAR
      {
        title: 'Fantasy Chips Available',
        data: profileData?.fantasy?.fantasyStats[3]?.chipsAvailable?.toLocaleString()
      },
      {
        title: 'Fantasy Wagers Won',
        data: profileData?.fantasy?.fantasyStats[3]?.wagersWin?.toLocaleString()
      },
      {
        title: 'Fantasy Wagers Lost',
        data: profileData?.fantasy?.fantasyStats[3]?.wagersLost?.toLocaleString()
      }
    ],
    [
      //MLB
      {
        title: 'Fantasy Chips Available',
        data: profileData?.fantasy?.fantasyStats[4]?.chipsAvailable?.toLocaleString()
      },
      {
        title: 'Fantasy Wagers Won',
        data: profileData?.fantasy?.fantasyStats[4]?.wagersWin?.toLocaleString()
      },
      {
        title: 'Fantasy Wagers Lost',
        data: profileData?.fantasy?.fantasyStats[4]?.wagersLost?.toLocaleString()
      }
    ]

  ]

  const marginTopContainers = '170px'
  
  return (
    <AppLayout>
      <Box
        ref={scrollingContainerRef}
        sx={{
          height: 'inherit',
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          pb: value === 2 ? '0' : '110px',
        }}>


        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 99,
          bgcolor: 'black',
          transform: (showHeader) ? 'translateY(0)' : 'translateY(-100%)',
          transition: 'transform 0.3s ease-in-out'
        }}>
          <DashboardHeader />
          <Box sx={{
            top: 0,
            position: 'sticky',
            bgcolor: theme.palette.dark.dark,
            zIndex: 99,
          }}>
            <Container sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '12px',
              pt: 2,
            }}>
              <Avatar
                src={profileData?.userLogo || null}
                sx={{
                  width: '60px',
                  height: '60px'
                }} />
              <Stack spacing={0}>
                <Typography variant='body1' sx={{ fontWeight: 700, mb: -1.25 }}>@{profileData?.username}</Typography>
                <Link to="/invite-friends">
                  <Typography sx={{ textDecoration: 'underline', ml: .5, color: theme.palette.dark.otherlight }} variant='body2'>Invite a friend today!</Typography>
                </Link>
              </Stack>
            </Container>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Tabs
                variant="scrollable"
                allowScrollButtonsMobile
                scrollButtons="auto"
                sx={{
                  width: '100%',
                  '.MuiTabs-scrollButtons': {
                    width: '24px',
                    color: 'white'
                  },
                  '.MuiTabs-scroller': {
                    scrollBehavior: 'smooth'
                  }
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example">
                <Tab label="Wagers" {...a11yProps(0)} sx={{ width: '40%', fontWeight: 700 }} />
                <Tab label="Posts" {...a11yProps(1)} sx={{ width: '40%', fontWeight: 700 }} />
                <Tab label="Stats" {...a11yProps(2)} sx={{ width: '40%', fontWeight: 700 }} />
                {
                  profileResponse?.userInfo?.result.id === user?.id
                  &&
                  <Tab label="Chip Bank" {...a11yProps(3)} sx={{ width: '40%', fontWeight: 700 }} />
                }
              </Tabs>
            </Box>
          </Box>
          {showScrollToTopButton && (
            <Container sx={{ pt: 2, pb: 1 }}>
              <Button
                //endIcon={<ArrowCircleUpIcon />}
                size='small'
                onClick={scrollToTop}
                fullWidth
                variant='outlined'>
                Scroll to top
              </Button>
            </Container>
          )}
        </Box>

        <List
          hidden={value !== 0}
          sx={{ mt: marginTopContainers, pb: 14 }}
        >
          {
            wagerResponseData?.isLoading && counter === 0

              ? <Loader isloading={true} />
              :
              wagerResponseData?.myWager?.result.length > 0
                ?
                <>
                  {
                    wagerResponseData?.myWager?.result?.map((item, index) => {
                      const wagerStatus = isWagerClose(item?.event_data?.startTime)
                      return (
                        <div key={index}>
                          <WagerCard
                            data={{
                              ...item,
                              wagerStatus: wagerStatus,
                              user: user,
                              index: index,
                              likeId: likeId
                            }}
                            likeFunction={() => handleWagerLike(item?.id, index, item?.isWagerLikeByMe)}
                          />
                        </div>
                      )
                    }
                    )
                  }
                  {
                    wagerLimit < wagerResponseData?.myWager?.count &&
                    <Container >
                      <LoadingButton
                        loading={wagerResponseData?.isLoading || loader}
                        onClick={() => fetchMoreData()}
                        variant='outlined'
                        fullWidth >
                        Load More
                      </LoadingButton>
                    </Container>
                  }

                </>
                :
                (
                  <Container sx={{
                    mt: 2
                  }}>
                    <Alert severity='success'>Create your first wager!</Alert>
                  </Container>
                )
          }
        </List>



        <List
          hidden={value !== 1}
          sx={{ mt: marginTopContainers, pb: 14 }}
        >
          {value === 1 && (
            < >
              {
                postResponseData?.isLoading && counter2 === 0

                  ? <Loader isloading={true} />
                  :
                  postResponseData?.myPosts?.result.length > 0
                    ?
                    <>
                      {
                        postResponseData?.myPosts?.result?.map((post, index) =>
                          <PostItem
                            key={index}
                            likeFunction={() => handlePostLike(post?.id)}
                            data={{
                              ...post,
                              likeId: postLikeId
                            }} />)
                      }
                      {
                        postLimit < postResponseData?.myPosts?.count &&
                        <Container >
                          <LoadingButton
                            loading={postResponseData?.isLoading || loader}
                            onClick={() => fetchMoreData()}
                            variant='outlined'
                            fullWidth >
                            Load More
                          </LoadingButton>
                        </Container>
                      }
                    </>
                    :
                    (
                      <Container sx={{
                        mt: 2
                      }}>
                        <Alert severity='success'>Create your first post!</Alert>
                      </Container>
                    )
              }

            </>
          )}
        </List>


        <CustomTabPanel
          value={value}
          index={2}
          sx={{ mt: marginTopContainers, pb: 14 }}>

          <Box sx={{ pt: 2, pb: 2 }}>
            <LeagueHeaders
              nohome
              fantasyChips
              selectedSportChips={fantasyValue}
              handleSportFilter={handleSportFilter}
            />
          </Box>
          {
            leagues.slice(1).map((item, index) => (
              <FantasyChipPanelTab
                key={index}
                value={value}
                fantasyValue={overallLeagues?.indexSelected - 1}
                fantasyIndex={index}>
                {
                  fantasy[overallLeagues?.indexSelected - 1]?.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        pt: 1,
                        pb: 1
                      }}>
                      <Typography variant='body2' sx={{ fontWeight: 700 }}>{item?.title}</Typography>
                      <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{item?.data}</Typography>
                    </Box>
                  ))
                }
              </FantasyChipPanelTab>
            ))
          }
        </CustomTabPanel>


        {
          profileResponse?.userInfo?.result.id === user?.id
          &&
          <CustomTabPanel
            value={value}
            index={3}
            sx={{ mt: marginTopContainers, pb: 14 }}>

            <Box sx={{ pt: 2, pb: 2 }}>
              <LeagueHeaders
                nohome
                fantasyChips
                selectedSportChips={fantasyValue}
                handleSportFilter={handleSportFilter}
              />
            </Box>
            {
              leagues.slice(1).map((item, index) => (
                <FantasyChipPanelTabChips
                  key={index}
                  value={value}
                  fantasyValue={overallLeagues?.indexSelected - 1}
                  fantasyIndex={index}>

                  <ListItem
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%'
                    }}>
                    <Typography variant='body2' sx={{ fontWeight: 700 }}>Fantasy Chips Available</Typography>
                    <Typography variant='body2' sx={{ fontWeight: 700, color: theme.palette.dark.otherlight }}>
                      {chipsData?.fantasyBank[overallLeagues?.indexSelected - 1]?.chipsLostInWagers?.toLocaleString()}
                    </Typography>
                  </ListItem>

                  <ListItem
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%'
                    }}>
                    <Typography variant='body2' sx={{ fontWeight: 700 }}>Fantasy Chips Won in wagers</Typography>
                    <Typography variant='body2' sx={{ fontWeight: 700, color: theme.palette.dark.otherlight }}>
                      {chipsData?.fantasyBank[overallLeagues?.indexSelected - 1]?.chipsGainedInWagers?.toLocaleString()}
                    </Typography>
                  </ListItem>

                  <ListItem
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%'
                    }}>
                    <Typography variant='body2' sx={{ fontWeight: 700 }}>Fantasy Chips Lost in wagers</Typography>
                    <Typography variant='body2' sx={{ fontWeight: 700, color: theme.palette.dark.otherlight }}>
                      {chipsData?.fantasyBank[overallLeagues?.indexSelected - 1]?.chipsLostInWagers?.toLocaleString()}
                    </Typography>
                  </ListItem>

                </FantasyChipPanelTabChips>
              ))
            }
          </CustomTabPanel>
        }
      </Box>
    </AppLayout >
  )
}

export default UserProfile

