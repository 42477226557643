import React from 'react'
import { Link } from 'react-router-dom'

function NotFoundPage () {
  return (<div className="logo mb-4">
        <div className="loginInp-main">
            <br />
            <div style={{ marginTop: '20px' }}>
                <Link to={'/'}> Back to login </Link>
            </div>
        </div>
    </div>)
}

export default NotFoundPage
