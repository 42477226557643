const initialState = {
  isLoading: false
}
export default function Faq (state = initialState, action) {
  switch (action.type) {
    case 'GET_FAQ_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'GET_FAQ': {
      return {
        ...state,
        isLoading: false,
        faq: action.payload
      }
    }
    case 'ERROR_OCCURED': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }
    default:
      return state
  }
}
