const initialState = {
  isLoading: false,
  chatCount: 0
}
export default function newChatCount(state = initialState, action) {
  switch (action.type) {
    case 'NEWCHAT_COUNT_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'NEWCHAT_COUNT': {
      return {
        ...state,
        isLoading: false,
        chatCount: action.payload
      }
    }
    case 'NEWCHAT_COUNT_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }
    default:
      return state
  }
}
