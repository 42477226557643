import { useEffect } from 'react'
import { io } from 'socket.io-client'
const socket = io(process.env.REACT_APP_SOCKET_URL)
const useSocket = (targetDate) => {
  useEffect(() => {
    // return () => socket.disconnect()
  }, [])

  return socket
}

export { useSocket }
