
const initialState = {
  isLoading: false
}
export default function updateAddress (state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_ADDRESS_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'UPDATE_ADDRESS': {
      return {
        ...state,
        isLoading: false,
        userAdress: action.payload
      }
    }
    case 'UPDATE_ADDRESS_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }
    case 'RESET_ADDRESS': {
      return {
        initialState
      }
    }
    default:
      return state
  }
}
