import axios from './axios'
import authHeader from './auth-header'

const getRefferelCode = () => {
  return axios.post('profile/referral-code',
    {}
    , {
      headers: authHeader()
    })
}

const referralService = {
  getRefferelCode
}

export default referralService
