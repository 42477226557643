const initialState = {
  isLoading: false
}
export default function userProfile (state = initialState, action) {
  switch (action.type) {
    case 'GET_PROFILE_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'GET_PROFILE': {
      return {
        ...state,
        isLoading: false,
        userInfo: action.payload
      }
    }
    case 'GET_PROFILE_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        isLoading: false,
        error: action.payload
      }
    }
    default:
      return state
  }
}
