import { Box, Stack, Typography, Container, Badge, IconButton } from '@mui/material'
import React from 'react'
import SettingsIcon from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import logo from "../../images/betzonlogo.svg"
import { useDispatch, useSelector } from 'react-redux';
import { UpdateStatus } from '../../redux/actions/notificationScreen';

const DashboardHeader = ({ title }) => {
    const unReadChatCount = useSelector((state) => state.newChatCount)
    const response = useSelector((state) => state?.NotificationList)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleNotification = () => {
        dispatch(UpdateStatus({}))
        navigate('/notifications')
    }
    return (
        <Container sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: 'white',
            pt: 1,
            pb: 1
        }}>

            <Stack spacing={2} direction='row'>

                <IconButton onClick={() => navigate('/account-settings')}  >
                    <Badge badgeContent={0} color="primary">
                        <SettingsIcon fontSize='small' />
                    </Badge>
                </IconButton>

                {
                    <IconButton sx={{
                        opacity: 0
                    }}>
                        <Badge badgeContent={response?.notification?.readCount} color="primary">
                            <NotificationsIcon fontSize='small' />
                        </Badge>
                    </IconButton>
                }

            </Stack>

            {
                /*
                <img src={logo} style={{
                width: '100px'
            }} />
                */
            }

            <img src={logo} style={{
                width: '36px'
            }} />

            <Stack spacing={2} direction='row'>

                {
                    <IconButton onClick={() => navigate('/social?dms')} >
                        <Badge badgeContent={unReadChatCount?.chatCount} color="primary">
                            <EmailIcon fontSize='small' />
                        </Badge>
                    </IconButton>
                }

                <IconButton onClick={() => {
                    dispatch(UpdateStatus({}))
                    navigate('/notifications')
                }}>
                    <Badge badgeContent={response?.notification?.readCount} color="primary">
                        <NotificationsIcon fontSize='small' />
                    </Badge>
                </IconButton>
            </Stack>

        </Container>
    )
}

export default DashboardHeader
