import { ERROR_OCCURED, GET_FAQ, GET_FAQ_REQUEST } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import faqService from '../../../services/faq'

export const getFaq = createAsyncThunk(
  '/faqs/list',
  async ({ id }, thunkAPI) => {
    thunkAPI.dispatch({
      type: GET_FAQ_REQUEST
    })
    try {
      const data = await faqService.getFaq()
      thunkAPI.dispatch({
        type: GET_FAQ,
        payload: data && data?.data
      })
      return { code: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
