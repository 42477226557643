import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/loader'
import { ListNotifications, updateIsCliked, clearNotification } from '../../redux/actions/notificationScreen'
import { Box, Container, IconButton, Typography, Button, Alert, List } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NotificationItem from '../../components/notification/notification-page-item'
import { LoadingButton } from '@mui/lab'

function NotificationScreen() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const response = useSelector((state) => state?.NotificationList)
  const responseData = response?.notification?.result
  const [notificationLimit, setNotificationLimit] = useState(20)
  const [counter, setCounter] = useState(0)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    dispatch(ListNotifications({
      limit: notificationLimit,
      skip: 0
    }))
  }, [notificationLimit])

  const fetchMoreData = () => {
    setLoader(true)
    setCounter(counter + 1)
    setTimeout(() => {
      if (response?.notification?.count > notificationLimit) {
        setNotificationLimit(notificationLimit + 10)
        setLoader(false)
      }
    }, 1500)
  }
  const handleClick = (value) => {
    if (value?.metadata?.isClickable) {
      if (!value?.isClicked) {
        dispatch(updateIsCliked(value?.id))
      }
      if (value?.type === 'wager') {
        if (value?.metadata?.action === 'invite' || value?.metadata?.action === 'accept') {
          if (value?.metadata?.action === 'invite' && value?.metadata?.wager?.chatId) {
            navigate(`/chat-screen?chatId=${value?.metadata?.wager?.chatId}`)
          } else {
            navigate(`/my-Wager-details-new/${value?.metadata?.wager?.id}`)
          }
        } else if (value?.metadata?.action === 'wager-counter') {
          // dispatch({ type: 'RESET_ACCEPT_WAGER' })
          // navigate(`/confirm-wager/${value?.metadata?.wager?.id}`)
          navigate(`/my-wager-details-new/${value?.metadata?.wager?.id}`)
        } else if (value?.metadata?.action === 'wager-counter-accept') {
          dispatch({ type: 'RESET_ACCEPT_WAGER' })
          navigate(`/confirm-wager/${value?.metadata?.wager?.id}`)
          // navigate(`/my-wager-details-new/${value?.metadata?.wager?.id}`)
        } else if (value?.metadata?.action === 'wager-counter-reject') {
          // dispatch({ type: 'RESET_ACCEPT_WAGER' })
          navigate('/dashboard')
          // navigate(`/my-wager-details-new/${value?.metadata?.wager?.id}`)
        } else {
          navigate(`/Wager-details-new/${value?.metadata?.wager?.id}`)
        }
      } else if (value?.type === 'result') {
        navigate(`/my-Wager-details-new/${value?.metadata?.wager?.id}`)
      } else if (value?.type === 'comment') {
        navigate(`/Wager-details-new/${value?.metadata?.wager?.id}`)
      }
    }
  }
  const toOrdinalSuffix = (num) => {
    const int = parseInt(num)
    const digits = [int % 10, int % 100]
    const ordinals = ['st', 'nd', 'rd', 'th']
    const oPattern = [1, 2, 3, 4]
    const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
      ? int + ordinals[digits[0] - 1]
      : int + ordinals[3]
  }

  const handleClearNotification = () => {
    dispatch(clearNotification({})).then(
      setTimeout(() => {
        dispatch(ListNotifications({
          limit: notificationLimit,
          skip: 0
        }))
      }, 1500)
    )
  }
  return (
    <>

      <Box sx={{
        position: 'fixed',
        top: 0,
        display: 'flex',
        width: '100%',
        zIndex: '99',
        bgcolor: 'black',
        justifyContent: 'space-between',
        pt: 2,
        pb: 1
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon fontSize='medium' />
          </IconButton>
          <Typography variant='h6' fontWeight={700}>Notifications</Typography>
        </Box>
        <Button
          sx={{
            pt: 0,
            pb: 0
          }}
          onClick={handleClearNotification} disabled={response?.error && !responseData}
          size='small'
          variant='text'>
          Clear All
        </Button>
      </Box>

      <Box sx={{
        mt: 6,
        pb: 4
      }}>
        {
          (response?.error && !responseData)
            ?
            <Container sx={{ mt: 10 }}>
              <Alert severity='warning'>{response?.error}</Alert>
            </Container>
            :
            <>

              <Container>
                <List>
                  {responseData?.length > 0 && responseData?.map((value, index) => (
                    <div key={index}>
                      {!value?.isDeleted &&
                        <NotificationItem type={value?.type} data={value} />
                      }
                    </div>
                  ))}
                </List>
              </Container>

              {
                notificationLimit < response?.notification?.count
                &&
                <Container>
                  <LoadingButton
                    loading={response?.notification?.isLoading || loader}
                    onClick={() => fetchMoreData()}
                    fullWidth
                    variant='outlined'>
                    Load more
                  </LoadingButton>
                </Container>
              }
            </>
        }
      </Box>
    </>
  )
}

export default NotificationScreen