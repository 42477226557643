import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Compressor from 'compressorjs'
import {
  getUserProfile,
  updateProfilePicture
} from '../../redux/actions/profile'
import { useSelector, useDispatch } from 'react-redux'
import { BillingDetails } from '../../redux/actions/billingDetails'
import authService from '../../services/auth.service'
import { Container, Box, IconButton, Typography, Avatar, Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from '../../components/loader'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'

window.Buffer = window.Buffer || require('buffer').Buffer
function EditProfile() {
  const navigate = useNavigate()
  const theme = useTheme()
  const profileResponse = useSelector((state) => state.userProfile)
  const profileData = profileResponse?.userInfo?.result
  const profilePicture = useSelector((state) => state.updateProfilePicture)
  const isProfileUpdated = profilePicture?.profileLogo?.status
  const billingResponse = useSelector((state) => state.BillingDetails)
  const cardDetails = billingResponse?.BillingDetails?.result
  const dispatch = useDispatch()
  const [photo, setPhoto] = useState(null)
  const [disabled, setDisabled] = useState(false)
  // const [image, setImage] = useState(null)
  const user = JSON.parse(localStorage.getItem('user'))
  useEffect(() => {
    // need to remove skip limit
    dispatch(
      getUserProfile({
        skip: '0',
        limit: '10'
      })
    )
  }, [])

  useEffect(() => {
    if (user?.id) {
      dispatch(BillingDetails({
        id: user.id,
        isWithdraw: false
      }
      ))
    }
  }, [])

  const handlePhoto = (e) => {
    setDisabled(true)
    // if (!e.target.files[0].type.includes('video') && !e.target.files[0].type.includes('application/pdf')) {
    //   setPhoto(e.target.files[0])
    //   setImage(URL.createObjectURL(e.target.files[0]))
    // } else {
    //   alert('Please select a valid image.')
    // }

    if (e.target.files[0].type.includes('image')) {
      // eslint-disable-next-line no-new
      new Compressor(e.target.files[0], {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          setPhoto(compressedResult)
        }
      })
      // setPhoto(e.target.files[0])
      // setImage(URL.createObjectURL(e.target.files[0]))
    } else {
      alert('Please select a valid image.')
    }
  }

  const uploadFile = async (file) => {
    if (file) {
      try {
        authService.imageUpload(file)
          .then(result => {
            dispatch(
              updateProfilePicture({
                userLogo: result?.data?.imageUrl
              })
            )
          }).catch(error => {
            console.log(error, 'err')
          })
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (photo) {
      const formData = new FormData()
      formData.append('file', photo, photo.name)
      uploadFile(formData)
    }
  }, [photo])

  useEffect(() => {
    if (isProfileUpdated) {
      dispatch(
        getUserProfile({
          skip: '0',
          limit: '10'
        })
      )
    }
  }, [isProfileUpdated])

  useEffect(() => {
    if (profileData?.userLogo) {
      const userInfo = JSON.parse(localStorage.getItem('user'))
      userInfo.userLogo = profileData?.userLogo
      localStorage.setItem('user', JSON.stringify(userInfo))
    }
  }, [profileData])

  useEffect(() => {
    if (profilePicture.isLoading || profileResponse.isLoading) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [profilePicture, profileResponse])
  return (
    <>
      <Container sx={{
        //position: 'fixed',
        //top: 0,
        display: 'flex',
        width: '100%',
        zIndex: '99999',
        bgcolor: 'black',
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: 4,
        pb: 2
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <IconButton onClick={() => navigate('/user-profile')}>
            <ArrowBackIcon fontSize='medium' />
          </IconButton>
          <Typography variant='h6'>Account Setttings</Typography>
        </Box>
      </Container>
      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        pt: 6,
        gap: 6
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px'
        }}>
          <Typography>Profile Image</Typography>
          {profilePicture.isLoading || profileResponse.isLoading
            ? (
              <Loader isloading={true} />
            )
            : (
              <Avatar
                sx={{
                  height: '84px',
                  width: '84px'
                }}
                alt="profile"
                src={profileData?.userLogo || null}
              />
            )}

          <Button
            disabled={disabled}
            size="small"
            variant='contained'
            className="upload-photo">
            <input type="file" accept="image/*" onChange={(e) => handlePhoto(e)} title="" disabled={disabled} />
            Upload Photo
          </Button>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Box sx={{
            pt: 1,
            pb: 1
          }}>
            <Typography sx={{ color: theme.palette.dark.otherlight }} variant='caption'>Username</Typography>
            <Typography sx={{ mt: -.5 }}>@{profileData?.username}</Typography>
          </Box>
          <Box sx={{
            pt: 1,
            pb: 1
          }}>
            <Typography sx={{ color: theme.palette.dark.otherlight }} variant='caption'>Email Address</Typography>
            <Typography sx={{ mt: -.5 }}>{profileData?.email}</Typography>
          </Box>
          <Box
            onClick={() => navigate("/edit-password")}
            sx={{
              pt: 1,
              pb: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Typography>Change Password</Typography>
            <IconButton>
              <ChevronRightIcon fontSize='medium' />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default EditProfile

/*

      <div className="fullScreen fullScreen-login login-solid justify-content-start screenWithoutFooter">
        <div className="pageTop forDrop" style={{ background: '#000812', padding: '50px 12px 12px 12px' }}>
          <div className="backLink">
            <Link to="/user-profile">
              <div className="backLink-icon noBg">
                <BiLeftArrowAlt />
              </div>
            </Link>
            <span className="mt-0" style={{ position: 'absolute', right: '10px' }}>Account Settings</span>
          </div>
        </div>
        <div className="loginWrap scrollable-notification">
          {(billingResponse.isLoading)
            ? (
              <div className="text-center pt-3 errorDiv">
                <div className="lds-roller">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )
            : (
              <div className="UserProfile-cont pb-4">
                <div className="userName">
                  <span>Profile Image</span>
                </div>
                <div className="user-Img mt-1">
                 
                  <>
                    {profilePicture.isLoading || profileResponse.isLoading
                      ? (
                        <div className="text-center">
                          <div className="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      )
                      : (
                        <img
                          alt="profile"
                          src={profileData?.userLogo || avatar}
                        />
                      )}
                  </>

                </div>
                <div className="upload-photo">
                  <input type="file" accept="image/*" onChange={(e) => handlePhoto(e)} title="" disabled={disabled} />
                  <span style={{ color: (disabled) ? 'grey' : '#fff' }}>Upload Photo</span>
                </div>

                <div className="pt-3">
                  <div className="acsLink">
                    <div className="acsLink-left">
                      <p>
                        <span className="grey-text">User Name</span>
                        <span className="grey-text2">{profileData?.username}</span>
                      </p>
                    </div>
                  </div>
                  <div className="acsLink">
                    <div className="acsLink-left">
                      <p>
                        <span className="grey-text">Email Address</span>
                        <span className="grey-text2">{profileData?.email} </span>
                      </p>
                    </div>
                  </div>
                  <Link className="acsLink" to="/edit-address">
                    <div className="acsLink-left">
                      <p>
                        <span className="grey-text">Address</span>{' '}
                        {profileData?.streetAddress || profileData?.address
                          ? <>{profileData?.streetAddress + ', ' + profileData?.address}</>
                          : ''
                        }
                      </p>
                    </div>
                    <div className="acsLink-right">
                      <ImArrowRight2 />
                    </div>
                  </Link>
                  <Link className="acsLink" to="/edit-password">
                    <div className="acsLink-left">
                      <p>
                        <span className="grey-text">Password</span> *******{' '}
                      </p>
                    </div>
                    <div className="acsLink-right">
                      <ImArrowRight2 />
                    </div>
                  </Link>
                  <div className="acsLink">
                    <div className="acsLink-left">
                      <p>
                        <span className="grey-text">Card Information</span>{' '}
                        <span className="grey-text2"> ****{cardDetails?.card?.last4}{' '}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
*/