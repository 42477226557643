import { ERROR_OCCURED, SEND_FEEDBACK, SEND_FEEDBACK_REQUEST } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import feedbackService from '../../../services/feedback'

export const sendFeedback = createAsyncThunk(
  '/feedbacks/add',
  async ({ name, email, message, attachment, device, browserName, browserVersion }, thunkAPI) => {
    thunkAPI.dispatch({
      type: SEND_FEEDBACK_REQUEST
    })
    try {
      const data = await feedbackService.sendFeedback(name, email, message, attachment, device, browserName, browserVersion)
      thunkAPI.dispatch({
        type: SEND_FEEDBACK,
        payload: data && data?.data
      })
      return { password: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
