
const initialState = {}
export default function Divisons (state = initialState, action) {
  switch (action.type) {
    case 'LIST_DIVISONS': {
      return {
        ...state,
        ListStatus: 'Success',
        divisons: action.payload
      }
    }

    case 'LIST_DIVISONS_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }

    default:
      return state
  }
}
