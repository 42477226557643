
const initialState = {
  isLoading: false
}
// Use the initialState as a default value
export default function Wagers(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case 'CREATE_WAGER': {
      const sameMode = state.mode
      return {
        mode: sameMode,
        isLoading: false,
        Status: 'Success',
        wager: action.payload,
        createWagerError: '',
        error: ''
      }
    }
    case 'CREATE_WAGER_ERROR': {
      return {
        Status: 'Fail',
        isLoading: false,
        createWagerError: action.payload
      }
    }

    case 'LIST_WAGERS_REQUEST': {
      return {
        ...state,
        isLoading: true,
        error: ''
      }
    }

    case 'LIST_WAGERS': {
      return {
        ...state,
        isLoading: false,
        ListStatus: 'Success',
        wager: action.payload,
        error: '',
        createWagerError: ''
      }
    }

    case 'RESET_WAGER': {
      return initialState
    }

    case 'ERROR_OCCURED': {
      return {
        Status: 'Fail',
        isLoading: false,
        error: action.payload
      }
    }

    case 'SET_WAGER_MODE_REAL': {
      return {
        ...state,
        mode: 'real'
      }
    }

    case 'SET_WAGER_MODE_FANTASY': {
      return {
        ...state,
        mode: 'fantasy'
      }
    }

    case 'SET_WAGER_MODE_POST': {
      return {
        ...state,
        mode: 'post'
      }
    }

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}
