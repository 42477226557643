const initialState = {
  isLoading: false
}
export default function createChat (state = initialState, action) {
  switch (action.type) {
    case 'CREATE_CHAT_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'CREATE_CHAT': {
      let temp = {
        ...state
      }
      delete temp.error
      return {
        ...temp,
        Status: 'Success',
        isLoading: false,
        chatInfo: action.payload
      }
    }
    case 'CREATE_CHAT_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        isLoading: false,
        error: action.payload
      }
    }
    case 'RESET_CHAT': {
      return {
        ...state,
        isLoading: false,
        chatInfo: {}
      }
    }
    default:
      return state
  }
}
