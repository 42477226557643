import { ADD_PARTICIPANT_REQUEST, ADD_PARTICIPANT, ADD_PARTICIPANT_ERROR, RESET_PARTICIPANT } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import ParticipantServices from '../../../services/participant'

export const addParticipants = createAsyncThunk(
  '/chat/addparticipant',
  async ({ chatId, participants }, thunkAPI) => {
    thunkAPI.dispatch({
      type: ADD_PARTICIPANT_REQUEST
    })
    try {
      const data = await ParticipantServices.addParticipants(chatId, participants)
      thunkAPI.dispatch({
        type: ADD_PARTICIPANT,
        payload: data && data?.data
      })
      return { chats: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      thunkAPI.dispatch({
        type: ADD_PARTICIPANT_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }

)
export const resetParticipant = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_PARTICIPANT
    })
  }
}
