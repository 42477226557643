import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button, Container, Stack, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'

function EditSuccess() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const theme = useTheme()

  const handleContinue = () => {
    dispatch({
      type: 'RESET_PASSWORD'
    })
    navigate('/edit-profile')
  }
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        handleContinue()
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  return (
    <Container sx={{
      height: '100vh',
      width: '100vw'
    }}>
      <Stack
        spacing={1}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          width: '100%',
          height: '100%',
          textAlign: 'center'
        }}>
        <Typography variant='h4'>Change Successful!</Typography>
        <Button
          size='small'
          variant='outlined'
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Stack>
    </Container>
  )
}

export default EditSuccess

/*
<div className="fullScreen fullScreen-login login-solid">
             <div className="loginWrap scrollable-notification">
                <div className="editPro text-center">
                    <div className="successCircle">
                    </div>
                    <div className="successTxt pt-4">
                        <h2>Change Successful!</h2>
                        <p>It may take a few minutes for change to take effect.</p>
                    </div>
                    <div className="text-center pt-4">
                          <button className="button button-success" onClick = {handleContinue}>Continue</button>
                    </div>
                </div>
             </div>

          </div>
*/