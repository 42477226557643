import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRefferelCode } from '../../redux/actions/referrelCode'
import { Box, Button, Container, IconButton, TextField, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@emotion/react'
import { LoadingButton } from '@mui/lab'
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '6px',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function InviteFriend() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const responseData = useSelector((state) => state.referralCode)
  const referralCode = responseData?.code?.result?.referralCode
  const textAreaRef = useRef(null)

  const [url, seturl] = useState(null)
  function copyReferallCode(e) {
    textAreaRef.current.select()
    document.execCommand('copy')
    e.target.focus()
    // alert('Link Copied: ' + url)
    setShow(true)
  }
  useEffect(() => {
    dispatch(getRefferelCode({
      id: '4'
    }))
  }, [])
  useEffect(() => {
    // seturl(`https://pwa.motobookie.com/invite/${referralCode}`)
    seturl(`${window.location.origin}/invite/${referralCode}`)
  }, [referralCode])
  return (
    <>
      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100vh',
        pb: 2
      }}>
        <Box sx={{
          zIndex: '999',
          display: 'flex',
          alignItems: 'center',
          pt: 2,
          pb: 1
        }}>
          <IconButton onClick={() => navigate('/user-profile')}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px'
        }}>
          <Box sx={{
            textAlign: 'center'
          }}>
            <Typography variant='h6'>Invite a friend!</Typography>
            <Typography variant='caption' sx={{
              color: theme.palette.dark.otherlight
            }}>Tap on the button below and share code with a friend!</Typography>
          </Box>
          <Box sx={{
            bgcolor: theme.palette.dark.otherdark,
            p: 1
          }}>
            <input
              ref={textAreaRef}
              type='text'
              value={url}
              readOnly
              id='myInput'
            />
          </Box>
          <LoadingButton
            onClick={copyReferallCode}

            variant='contained'>
            Tap here to copy link!
          </LoadingButton>
        </Box>
        <div></div>
      </Container>

      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Link copied!
          </Typography>
          <Typography variant="caption" id="modal-modal-description">
            {url}
          </Typography>
          <Button
            size='small'
            sx={{
              mt: 1
            }}
            onClick={handleClose}
            variant='outlined'>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export default InviteFriend
