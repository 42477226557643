import axios from 'axios'

axios.interceptors.request.use(
  config => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && user?.tokens && user?.tokens?.accessToken) {
      config.headers = { Authorization: 'Bearer ' + user.tokens.accessToken, platform: 'web', 'x-api-key': 'mb52ea2d-4567-4956-9d19-35a7e75a2c17' }
    }
    config.baseURL = process.env.REACT_APP_API_URL
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response
}, function (error) {
  // Do something with response error
  if (error.response.status === 401) {
    localStorage.clear()
    window.location = '/'
  }
  return Promise.reject(error)
})
export default axios
