import axios from './axios'
import authHeader from './auth-header'
const getWallet = () => {
  return axios.post(
    'profile/wallet-balance',
    {},
    {
      headers: authHeader()
    }
  )
}

const WalletServices = {
  getWallet
}
export default WalletServices
