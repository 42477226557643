import { useTheme } from '@emotion/react'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import { blue } from '@mui/material/colors';

function isColorLight(hex) {
    if (!hex) return false; // Assume dark color if hex is not provided

    // Convert hex to RGB
    let r, g, b;
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
        r = parseInt(hex.slice(1, 3), 16);
        g = parseInt(hex.slice(3, 5), 16);
        b = parseInt(hex.slice(5, 7), 16);
    }

    // Convert RGB to linear space
    r /= 255; g /= 255; b /= 255;
    r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
    g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
    b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

    // Calculate luminance
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // A general threshold for determining if the color is light can be around 0.5
    // This can be adjusted based on preferences or specific needs
    return luminance > 0.5;
}


const MatchTeamItem = ({ predictionOnly, data }) => {

    const theme = useTheme()

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '8px',
            color: 'white'
        }}>

            {
                data?.type === 'event' ?
                    <Avatar sx={{
                        bgcolor: theme.palette.primary.main,
                        borderRadius: '100px',
                        color: 'white'
                    }}
                        variant="square">
                        <SportsScoreIcon />
                    </Avatar>
                    :
                    <Avatar sx={{ width: '32px', height: '32px', bgcolor: data?.url_logo, color: isColorLight(data?.url_logo) ? 'black' : 'white' }}>
                        <SportsBaseballIcon fontSize='small' />
                    </Avatar>
            }
            <Stack spacing={-.8}>
                <Typography
                    variant='body2'
                    sx={{
                        fontWeight: 700,
                    }}
                >
                    {
                        !!data?.name ? data?.name : "Los Angeles Lakers"
                    }
                </Typography>

                {
                    !!location
                    &&
                    <Typography
                        variant='caption' 
                        sx={{
                            fontWeight: 700,
                            color: theme.palette.dark.otherlight
                        }}
                    >
                        {
                            data?.location
                        }
                    </Typography>
                }
            </Stack>

        </Box>
    )
}

export default MatchTeamItem
