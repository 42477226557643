import axios from './axios'
import authHeader from './auth-header'

const register = (username, email, password, referralCode) => {
  if (referralCode) {
    return axios.post('auth/add', {
      username,
      email,
      password,
      referralCode
    }, {
      headers: authHeader()
    })
  } else {
    return axios.post('auth/add', {
      username,
      email,
      password
    }, {
      headers: authHeader()
    })
  }
}

const login = (username, password) => {
  return axios
    .post('auth/login', {
      username,
      password
    }, {
      headers: authHeader()
    })
}

const requestOtp = (username, screen) => {
  return axios
    .post('/auth/otp-request', {
      username,
      screen
    }, {
      headers: authHeader()
    })
}

const validateOtp = (username, code) => {
  return axios
    .post('/auth/otp-validate', {
      username, code
    }, {
      headers: authHeader()
    })
}

const resetPassword = (username, password) => {
  return axios
    .post('/auth/forgot-password', {
      username, password
    }, {
      headers: authHeader()
    })
}

const checkUserName = (username) => {
  return axios
    .post('auth/username-check', {
      username
    }, {
      headers: authHeader()
    })
}

const checkUserEmail = (email) => {
  return axios
    .post('auth/email-check', {
      email
    }, {
      headers: authHeader()
    })
}

const logout = (id, deviceToken) => {
  return axios
    .post(`auth/logout/${id}`, { deviceToken }, {
      headers: authHeader()
    })
}

const imageUpload = (file) => {
  return axios
    .post('auth/upload-image', file, {
      headers: authHeader()
    })
}

const submitInviteCode = (inviteCode) => {
  return axios
    .post('auth/invite-code', inviteCode, {
      headers: authHeader()
    })
}


const authService = {
  register,
  login,
  requestOtp,
  validateOtp,
  resetPassword,
  checkUserName,
  checkUserEmail,
  logout,
  imageUpload,
  submitInviteCode
}
export default authService
