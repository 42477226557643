import { SET_CALENDAR_DATE, SET_CURRENT_LEAGUE } from "../../types";

const initialState = {
    league: [
        {
            name: 'Trending',
            status: true,
            isLoading: false
        }
    ],
    dateSelected: {
        index: 0
    },
    indexSelected: 0,
    creatorWagerIndexSelected: 0,
    sport: {}
};

function leagueBarReducer(state = initialState, action) {
    switch (action.type) {

        case SET_CURRENT_LEAGUE:
            return {
                ...state,
                league: state.league.map((item, i) => ({
                    ...item,
                    status: i === action.payload.index
                })),
                indexSelected: action.payload.index,
                creatorWagerIndexSelected: action.payload.index,
                dateSelected: {
                    index: 0
                }
            };

        case 'SET_CREATE_WAGER_CURRENT_LEAGUE':
            return {
                ...state,
                league: state.league.map((item, i) => ({
                    ...item,
                    createWagerStatus: i === action.payload.index
                })),
                creatorWagerIndexSelected: action.payload.index,
                dateSelected: {
                    index: 0
                }
            };

        case SET_CALENDAR_DATE:
            return {
                ...state,
                dateSelected: action.payload.date,
                //indexSelected: action.payload.index
            };

        case "SET_WAGER_FEED":
            return {
                ...state,
                message: action?.payload?.message, // Spread the existing state
                league: state.league.map((item) => {
                    // Check if the current item's id matches the action's leagueId
                    if (item.id === action.leagueId && !!action.leagueId) {
                        return {
                            ...item,
                            feed: [...action.payload.result],
                            count: action.payload.count,
                            isLoading: false
                        };
                    }
                    else if (!!action.eventId && state.league[state.indexSelected].events.find(event => event.id === Number(action.eventId))) {
                        return {
                            ...item,
                            feed: [...action.payload.result],
                            count: action.payload.count,
                            isLoading: false
                        };
                    }
                    return item;
                }),
            };

        case "SET_WAGER_TO_ACCEPT":
            const updatedWager = action.payload.result;
            return {
                ...state,
                league: state.league.map((league, index) =>
                    index === state.indexSelected ? {
                        ...league,
                        feed: league.feed.map(wager =>
                            wager.id === updatedWager.id ? updatedWager : wager
                        ),
                    } : league
                ),
            };

        case "INCREMENT_COMMENT_COUNT":
            return {
                ...state,
                league: state.league.map((league, index) =>
                    index === state.indexSelected ? {
                        ...league,
                        feed: league.feed.map(wager =>
                            wager.id === action.payload.id ?
                                {
                                    ...wager,
                                    CommentCount: wager.CommentCount + 1
                                }
                                :
                                wager
                        ),
                    } : league
                ),
            };

        case "SET_WAGER_LOADING_FEED":
            return {
                ...state, // Spread the existing state
                league: state.league.map((item, index) => {
                    // Check if the current index is the one we want to mutate
                    if (index === state.indexSelected && state.indexSelected != 0) {
                        // Return the mutated item
                        return {
                            ...item,
                            isLoading: true
                        };
                    }
                    // For all other items, return them as they are
                    return item;
                }),
            };


        case "SET_LEAGUES":
            return {
                ...state, // Spread the existing state
                league: [
                    {
                        ...state.league[0],
                        name: 'Home',
                        isLoading: false
                    },
                    ...action.payload.result.map((item, index) => {
                        switch (item.type) {
                            case "match":
                                return {
                                    ...item,
                                    feed: !!state.league[index + 1]?.feed ? [...state.league[index + 1]?.feed] : [],
                                    count: 0,
                                    isLoading: false
                                }
                            case "event":
                                return {
                                    ...item,
                                    feed: !!state.league[index + 1]?.feed ? [...state.league[index + 1]?.feed] : [],
                                    count: !!state.league[index + 1]?.count ? state.league[index + 1]?.count : 0,
                                    isLoading: false
                                }

                            default:
                                break;
                        }
                    })
                ]
            };

        case "SET_DATES":
            return {
                ...state, // Spread the existing state
                league: state.league.map((item, index) => {
                    // Check if the current index is the one we want to mutate
                    if (index === state.indexSelected && state.indexSelected != 0) {
                        // Return the mutated item
                        return {
                            ...item,
                            dates: action.payload,
                            isLoading: false
                        };
                    }
                    // For all other items, return them as they are
                    return {
                        ...item
                    };
                }),
            };


        case "UPDATE_FEED_W_CREATED_WAGER":
            const updatedWagerCreated = action.payload.wager;
            return {
                ...state,
                league: state.league.map((league, index) =>
                    (
                        index === state.indexSelected
                        &&
                        (
                            !!action.payload.matchId ? updatedWagerCreated?.eventId === Number(action.payload.matchId)
                                :
                                updatedWagerCreated?.event_data?.sports_data?.id === league.id
                        )
                    )
                        ?
                        {
                            ...league,
                            feed: [updatedWagerCreated, ...league.feed] // Prepend updatedWagerCreated
                        }
                        :
                        league
                ),
            };

        case "SET_AD_EVENT_WAGER_TO_ACCEPTED":
            return {
                ...state,
                league: state.league.map((league, index) =>
                    index === state.indexSelected ? {
                        ...league,
                        feed: league.feed.map((feedItem, index) => {
                            if (feedItem.engagementType && feedItem.wagers && feedItem?.wagers?.length > 0) {
                                return {
                                    ...feedItem,
                                    wagers: feedItem.wagers.map(wager =>
                                        wager.id === action.payload.id
                                            ? action.payload : wager
                                    )
                                };
                            }
                            return {
                                ...feedItem
                            }
                        }
                        ) // Prepend updatedWagerCreated
                    } : {
                        ...league
                    }
                ),
            };

        default:
            return state;
    }
}

export default leagueBarReducer;


/*
case "SET_LEAGUES":
            return {
                ...state, // Spread the existing state
                league: [
                    {
                        ...state.league[0],
                        name: 'Home',
                        isLoading: false
                    },
                    ...action.payload.result.map((item, index) => {
                        switch (item.type) {
                            case "match":
                                return {
                                    ...item,
                                    feed: [...state.league[index]?.feed],
                                    count: 0,
                                    isLoading: false
                                }
                            case "event":
                                return {
                                    ...item,
                                    feed: [...state.league[index]?.feed],
                                    count: 0,
                                    isLoading: false
                                }

                            default:
                                break;
                        }
                    })
                ]
            };
*/
