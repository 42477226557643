import { Box, Button, Container, Stack, Typography, IconButton, SwipeableDrawer, Avatar, List, ListItemAvatar, ListItemButton, Alert } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { realtimeDb } from '../../firebase'
import { ref, onValue, set, push } from "firebase/database";
import { useDispatch, useSelector } from 'react-redux';
import ChatItem from './chat-item';
import { useTheme } from '@emotion/react';
import CelebrationIcon from '@mui/icons-material/Celebration';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/system';
import Loader from '../loader';
import EmailIcon from '@mui/icons-material/Email';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { createChat, resetChat } from '../../redux/actions/chats';
import { useNavigate } from 'react-router-dom';
import CreateWager from '../wager/create';
import { resetWager, setCreationWagerModeToFantasy } from '../../redux/actions/wagers';
import Drawer from '../drawer';
import { openModal } from '../../redux/actions/modal';
import Receipt from '../reciept';

const MySwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
    '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, .6)'
    },
}));

const LockerRoom = ({ eventId }) => {
    const toggleMain = useSelector((state) => state.Modals.toggle);
    const dispatch = useDispatch()
    const theme = useTheme()
    const [chatList, setChatList] = useState([])
    const [message, setMessage] = useState('')
    const [loader, setLoader] = useState(true)
    const [showScrollToBottom, setShowScrollToBottom] = useState(false);
    const [userToShow, setUserToShow] = useState(null);
    const [newMessage, setNewMessage] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'))
    const bottomRef = useRef(null);
    const [toggle, setToggle] = useState(false)
    const [chatActionType, setChatActionType] = useState('')
    const [stage, setStage] = useState(0)
    const chatResponse = useSelector((state) => state.createChat)
    const navigate = useNavigate()
    const [roomDetails, setRoomDetails] = useState([])
    const [wagerSuccess, setWagerSuccess] = useState('')
    const wager_data = useSelector((state) => state.Wagers)

    useEffect(() => {
        if (wager_data?.Status === 'Success') {
            setWagerSuccess('Wager sent!')
            setTimeout(() => {
                dispatch(resetWager())
                setWagerSuccess('')
            }, 5000);
        }
    }, [wager_data]);

    const handleDrawerFunction = (chatuser, type) => {
        switch (type) {
            case 'message':
                dispatch(createChat({
                    type: 'P',
                    participants: [user?.id, chatuser]
                }))
                break;
            case 'wager':
                dispatch(createChat({
                    type: 'P',
                    participants: [user?.id, chatuser]
                }))
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (chatResponse?.chatInfo?.id && stage === 0 && chatActionType === 'message') {
            navigate(`/chat-screen?chatId=${chatResponse?.chatInfo?.id}`)
            dispatch(resetChat())
        }
        else if (chatResponse?.chatInfo?.id && stage === 0 && chatActionType === 'wager') {
            setRoomDetails(chatResponse?.chatInfo?.chatparticipants?.filter((val) => val.userId !== user?.id));
            dispatch(setCreationWagerModeToFantasy())
            setStage(1)
            dispatch(openModal())
        }
    }, [chatResponse])


    useEffect(() => {
        if (!toggleMain) {
            setStage(0)
        }
    }, [toggleMain]);

    useEffect(() => {
        try {
            const starCountRef = ref(realtimeDb, 'lockerroom/' + eventId);
            // Fetch and set chat messages
            const unsubscribe = onValue(starCountRef, (snapshot) => {
                const data = snapshot.val();
                const dataArray = data ? Object.values(data) : [];
                if (chatList?.length != dataArray?.length) {
                    setNewMessage(true)
                }
                setChatList(dataArray);
                setLoader(false)
                if (!(bottomRef.current.scrollHeight - bottomRef.current.scrollTop > bottomRef.current.clientHeight + 100)) {
                    setTimeout(() => {
                        if (bottomRef.current) {
                            bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
                            // After scrolling to the bottom, ensure the button doesn't show immediately
                            setShowScrollToBottom(false);
                        }
                    }, 500);
                }
            });

            return () => unsubscribe();
        } catch (error) {
            console.log(error)
        }
    }, [eventId]);

    const handleScroll = () => {
        if (bottomRef.current) {
            const isNotAtBottom = bottomRef.current.scrollHeight - bottomRef.current.scrollTop > bottomRef.current.clientHeight + 100; // Adding a threshold
            setShowScrollToBottom(isNotAtBottom);
            if (!isNotAtBottom) {
                setNewMessage(false)
            }
        }
    };


    const handleEnter = async (e) => {
        if (e.key === 'Enter') {
            handleMessage()
        }
    }

    const sendMessage = () => {
        try {
            const lockerRoomRef = ref(realtimeDb, 'lockerroom/' + eventId);
            // Create a new entry with a unique key under `lockerroom/{eventId}`
            const newEntryRef = push(lockerRoomRef);
            // Set the data for the new entry
            set(newEntryRef, {
                user: {
                    userId: user?.id,
                    username: user?.username,
                    userLogo: user?.userLogo
                },
                message: message
            });
            if (bottomRef.current) {
                bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleMessage = () => {
        const user = JSON.parse(localStorage.getItem('user'))
        if (message.trim().length > 0) {
            if (message.trim().length < 255) {
                sendMessage()
                setMessage('')
            } else {
                alert('Message character length should be less than 255.')
            }
            //   dispatch(createMessage(payload))
        }
    }

    const openDrawer = (data) => {
        setUserToShow(data)
        setToggle(true)
    }

    const closeDrawer = () => {
        setUserToShow(null)
        setToggle(false)
    }

    const iconStyles = { mr: 1 }

    const drawerActionItems = [
        {
            icon: <EmailIcon sx={iconStyles} />,
            text: 'Send a message',
            type: 'message'
        },
        {
            icon: <HandshakeIcon sx={iconStyles} />,
            text: 'Send a wager',
            type: 'wager'
        }
    ]
    useEffect(() => {
        if (!toggle) {
            setStage(0)
            setRoomDetails([])
            setChatActionType('')
            dispatch(resetWager())
            setWagerSuccess('')
        }
    }, [toggle]);
    return (
        <>

            {
                stage === 1 &&
                <Drawer>
                    <CreateWager privateWager chatId={chatResponse?.chatInfo?.id} roomDetails={roomDetails} />
                </Drawer>
            }

            {
                stage === 0 &&
                <MySwipeableDrawer
                    open={toggle}
                    anchor={'bottom'}
                    onOpen={() => openDrawer()}
                    onClose={() => {
                        closeDrawer()
                    }}
                    PaperProps={{
                        style: {
                            bgcolor: 'black',
                            height: 'fit-content',
                            width: '100%',
                            backgroundColor: 'black',
                            boxShadow: 'none',
                            borderTop: 0,
                            borderRadius: '8px 8px 0 0',
                            borderBottom: '0 !important',
                            zIndex: '999999999999',
                            borderTop: `3px solid ${theme.palette.dark.otherdark}`
                        },
                    }}
                >
                    <Container
                        sx={{
                            height: 'inherit',
                            pt: 1.5,
                            pb: 4,
                            bgcolor: 'black',
                        }}>
                        <Stack spacing={1.5}>
                            <Stack
                                alignItems={'center'}
                                direction={"row"}
                                spacing={1}
                            >
                                <Avatar
                                    src={userToShow?.userLogo} />
                                <Typography>@{userToShow?.username}</Typography>
                            </Stack>
                            {
                                wagerSuccess != '' &&
                                <Alert severity='success'>Wager has been sent!</Alert>
                            }
                            <List sx={{ p: 0 }}>
                                {
                                    drawerActionItems.map((item, index) => (
                                        <ListItemButton
                                            sx={{ pl: 1 }}
                                            key={index}
                                            onClick={() => {
                                                setChatActionType(item.type)
                                                handleDrawerFunction(userToShow?.userId, item.type)
                                            }}
                                        >
                                            {item?.icon}
                                            <Typography variant='body1'>
                                                {item?.text}
                                            </Typography>
                                        </ListItemButton>
                                    ))
                                }
                            </List>
                        </Stack>
                    </Container>
                </MySwipeableDrawer>
            }
            <Box
                onScroll={handleScroll}
                ref={bottomRef} // Add this line
                sx={{
                    height: '100%',
                    overflow: 'auto'
                }}>
                {
                    loader ?
                        <Stack justifyContent={"center"} alignItems={"center"}
                            sx={{
                                height: '100%',
                                flexGrow: 1,
                                position: 'relative',
                                pb: 12
                            }}>
                            <Loader isloading={true} />
                        </Stack>
                        :
                        chatList.length === 0
                            ?
                            <Stack
                                spacing={1}
                                justifyContent={'center'}
                                alignItems={"center"}
                                sx={{ height: '100%', pb: '96px' }}>
                                <CelebrationIcon fontSize='large' sx={{ color: "white" }} />
                                <Typography>Be the first to talk smack!</Typography>
                            </Stack>
                            :
                            <Container sx={{
                                position: 'relative',
                                flexGrow: 1,
                                height: '100%'
                            }}>

                                {showScrollToBottom && (
                                    <Stack
                                        alignItems={'center'}
                                        sx={{
                                            width: '100%',
                                            position: 'sticky',
                                            top: '12px',
                                            zIndex: 9,
                                        }}
                                    >
                                        <Button
                                            variant='contained'
                                            onClick={() => {
                                                // When the button is clicked, scroll to the bottom
                                                if (bottomRef.current) {
                                                    bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
                                                }
                                            }}>
                                            {
                                                newMessage ?
                                                    'New messages!'
                                                    :
                                                    'Scroll to bottom'
                                            }

                                        </Button>
                                    </Stack>
                                )}



                                <Stack spacing={2}
                                    justifyContent={'flex-end'}
                                    sx={{
                                        minHeight: '100%',
                                        height: 'fit-content',
                                        pb: '96px',
                                    }}>
                                    {
                                        chatList?.map((chatItem, index) => (
                                            <ChatItem
                                                key={index}
                                                isCreator={user.id === chatItem.user.userId}
                                                data={chatItem}
                                                functionToOpen={(data) => openDrawer(data)} />
                                        ))
                                    }
                                </Stack>
                            </Container>
                }

                <Container
                    sx={{
                        borderTop: `2px solid ${theme.palette.dark.light}`,
                        bgcolor: 'black',
                        width: '100%',
                        pt: 1.5,
                        pb: 3.25,
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <Box sx={{
                        maxWidth: '400px',
                        borderRadius: '100px',
                        border: `2px solid ${theme.palette.dark.light}`,
                        pt: .25,
                        pb: .25,
                        pl: 2,
                        pr: .5,
                        display: 'flex',
                        width: '100%',
                        bgcolor: 'black',
                    }}>

                        <input
                            style={{
                                color: 'white',
                                fontWeight: 700,
                                width: '100%',
                                background: 'transparent',
                                border: 'none',
                                outline: 'none',
                                fontSize: '16px'
                            }}
                            type='text'
                            placeholder="What's on your mind?"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)} onKeyDown={handleEnter} />

                        <IconButton
                            onClick={handleMessage}
                            sx={{
                                opacity: message.length === 0 ? .3 : 1
                            }}
                            disabled={message.length === 0}
                        >
                            <SendIcon fontSize="small" sx={{ color: theme.palette.dark.light }} />
                        </IconButton>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default LockerRoom

/*


            
*/
