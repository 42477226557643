
const initialState = {}
// Use the initialState as a default value
export default function appReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case 'USER_LOGIN_SUCCESS': {
      return {
        ...state,
        Login: 'Success',
        response: action.payload
      }
    }

    case 'USER_SIGNUP_REQUEST': {
      return {
        ...state,
        isLoading: 1
      }
    }

    case 'USER_LOGIN_FAIL': {
      return {
        ...state,
        Login: 'Fail',
        response: "fail",
        message: action.message
      }
    }

    case 'USER_INVITE_ONLY': {
      return {
        ...state,
        Login: 'INVITE_ONLY',
        response: "invite-only",
        message: action.message
      }
    }



    case 'USER_SIGN_UP_INVITE_ONLY': {
      return {
        ...state,
        Status: 'INVITE_ONLY',
        response: action.message,
        message: action.message
      }
    }

    case 'USER_LOGOUT': {
      return {
        ...state,
        Login: 'Logout',
        response: {}
      }
    }

    case 'USER_LOGOUT_FAIL': {
      return {
        ...state,
        Logout: 'Fail',
        response: action.payload
      }
    }

    case 'USER_SIGNUP': {
      return {
        ...state,
        isLoading: 0,
        Status: 'Success',
        response: action.payload
      }
    }

    case 'USER_SIGNUP_FAIL': {
      return {
        ...state,
        isLoading: 0,
        Status: 'Fail',
        response: action.payload
      }
    }

    case 'REQUEST_OTP_FAIL': {
      return {
        ...state,
        Status: 'Fail',
        otpStatus: 'Fail',
        response: action.payload
      }
    }

    case 'REQUEST_OTP': {
      return {
        ...state,
        Status: 'Success',
        response: action.payload
      }
    }

    case 'VALIDATE_OTP': {
      return {
        ...state,
        otpValidate: 'Success',
        response: action.payload
      }
    }

    case 'VALIDATE_OTP_FAIL': {
      return {
        ...state,
        otpValidate: 'Fail',
        response: action.payload
      }
    }

    case 'UPLOAD_PROFILE': {
      return {
        ...state,
        personalProfile: 'Success',
        response: action.payload
      }
    }

    case 'UPLOAD_PROFILE_FAIL': {
      return {
        ...state,
        personalProfile: 'Fail',
        response: action.payload
      }
    }

    case 'FORGOT_PASSWORD': {
      return {
        ...state,
        passwordStatus: 'Succcess',
        response: action.payload
      }
    }

    case 'FORGOT_PASSWORD_FAIL': {
      return {
        ...state,
        passwordStatus: 'Fail',
        response: action.payload
      }
    }

    case 'RESET_AUTH': {
      return initialState
    }

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}
