import { useTheme } from '@emotion/react'
import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const ChatItem = ({ data, functionToOpen, isCreator }) => {
    const theme = useTheme()
    return (
        <Stack
            direction={'row'}
            alignItems={'flex-start'}
            spacing={1}>
            <Avatar src={data?.user?.userLogo} />
            <Stack spacing={-.5} sx={{ flexGrow: 1 }}>
                <Typography
                    color={theme.palette.dark.otherlight}
                    variant='body2'
                    sx={{ fontWeight: 200 }}>
                    <span style={{ fontWeight: 700 }}>
                        {data?.user?.username}
                    </span>
                </Typography>
                <Typography sx={{ fontWeight: 200 }}>
                    {data?.message}
                </Typography>
            </Stack>
            <IconButton
                sx={{
                    opacity: isCreator ? 0 : 1,
                }}
                disabled={isCreator}
                onClick={() => functionToOpen(data?.user)}>
                <MoreHorizIcon />
            </IconButton>
        </Stack>
    )
}

export default ChatItem
