const initialState = {
  isLoading: false
}

export default function ChatFilter(state = initialState, action) {
  switch (action.type) {

    case 'FILTER_CHAT_LIST': {
      return {
        ...state,
        filterBy: action.payload
      }
    }

    case 'SET_MORE_MESSAGES_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }

    case 'SET_MORE_MESSAGES': {
      return {
        ...state,
        messages: action.payload,
        isLoading: false,
        status: 'success'
      }
    }

    case 'SET_MESSAGES_ERROR': {
      return {
        ...state,
        error: action.payload,
        status: 'fail'
      }
    }


    case 'RESET_MESSAGES': {
      return {
        ...initialState
      }
    }

    case 'RESET_MESSAGES_ERROR': {
      return {
        messages: [...state?.messages],
        filterBy: 'messages',
        isLoading: false,
      }
    }

    default:
      return state
  }
}
