import { useTheme } from '@emotion/react'
import { Box, Button, Container, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LogoContainer from '../../components/header/logo-container'

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import GoogleMaps from './location-field';
import { useDispatch, useSelector } from 'react-redux'
import { uploadProfile } from '../../redux/actions/profile'
import { VerticalAlignBottom } from '@mui/icons-material'
import { logout } from '../../redux/actions/auth'

const AgeVerificationPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()
    const personalProfileResponse = useSelector((state) => state.Auth)
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : localStorage.getItem('user')
    const useremail = localStorage.getItem('useremail')
    useEffect(() => {
        if (!user && !useremail) {
            navigate('/')
        }
    }, [])

    const [inputs, setInputs] = useState({
        full_name: '',
        dateOfBirth: '',
        address: null
    })

    const [error, setError] = useState({
        status: false,
        message: ''
    })

    const handleDateChange = (event) => {
        setError({
            status: false,
            location: 0,
            message: ''
        })
        const dateValue = event
        const twentyOneYearsAgo = dayjs().subtract(21, 'year');
        let temp = { ...inputs }
        temp.dateOfBirth = dateValue.format()

        // console.log(twentyOneYearsAgo.format() >= dateValue.format())

        // console.log(twentyOneYearsAgo.format())

        // console.log(dateValue.format())

        if (twentyOneYearsAgo.format() <= dateValue.format()) {
            setError(
                {
                    status: true,
                    location: 1,
                    message: 'You must 21 or older to participate on this platform.'
                })
        }
        setInputs({ ...temp })
        //console.log(twentyOneYearsAgo.format() >= dateValue.format()) // returns true if input IS OLDER else false
    };


    const handleInputs = (event) => {
        setError({
            status: false,
            location: 0,
            message: ''
        })
        let temp = { ...inputs }
        temp[event.target.name] = event.target.value
        setInputs({ ...temp })
    }

    const handleLocation = (newInputValue) => {
        setError({
            status: false,
            location: 0,
            message: ''
        })
        let temp = { ...inputs }
        if (newInputValue !== null) {
            temp.address = newInputValue.place_id
        }
        else {
            temp.address = newInputValue
        }
        setInputs({ ...temp })
    }

    const handleSubmit = () => {
        dispatch(uploadProfile({
            full_name: inputs.full_name,
            dateOfBirth: inputs.dateOfBirth,
            address: inputs.address
        }))
    }

    useEffect(() => {
        if (personalProfileResponse?.personalProfile === 'Fail') {
            if (personalProfileResponse?.response?.message === "User Not Found") {
                setError({
                    status: true,
                    message: "We couldn't find the user that you are trying to modify. Please sign up!"
                })
                setTimeout(() => {
                    navigate('/signup')
                    // Place your code here that you want to execute after 3 seconds
                }, 3000);
            }
            else {
                setError({
                    status: true,
                    message: personalProfileResponse.response
                })
            }
        } else if (personalProfileResponse?.personalProfile === 'Success' && localStorage.getItem('user')) {
            user.stage = 3
            localStorage.setItem('user', JSON.stringify(user))
            navigate('/dashboard')
        } else if (personalProfileResponse?.personalProfile === 'Success' && useremail) {
            navigate('/create-password')
        }
    }, [personalProfileResponse])

    return (
        <Container maxWidth='xl' sx={{
            ...theme.containers.main,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Box sx={{
                maxWidth: '450px',
                width: '100%',
                pt: 2,
                pb: 2,
                height: 'inherit',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: isMedium ? 'center' : 'space-between',
                alignItems: 'center',
                gap: '24px'
            }}>

                <LogoContainer mini={true} />

                <Stack spacing={2} sx={{ width: '100%' }}>

                    <Typography
                        variant='h5'
                        sx={{
                            fontWeight: 700
                        }}>
                        Tell us about yourself!
                    </Typography>

                    <Stack spacing={6}>
                        <Stack spacing={2}>
                            <Typography variant='body1' sx={{ fontWeight: 700 }}>Enter your full name.</Typography>
                            <TextField
                                error={error.status}
                                name='full_name'
                                onChange={handleInputs}
                                label='Full Name'
                                variant='filled' />
                        </Stack>
                        <Stack spacing={2}>
                            <Typography variant='body1' sx={{ fontWeight: 700 }}>What&apos;s your date of birth?</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            error: error.status
                                        }
                                    }}
                                    label="Date of Birth"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='filled'
                                        />
                                    )}
                                    onChange={handleDateChange}
                                />
                            </LocalizationProvider>

                        </Stack>
                        <Stack spacing={2}>
                            <Typography variant='body1' sx={{ fontWeight: 700 }}>Where do you live?</Typography>
                            <GoogleMaps
                                handleLocation={handleLocation}
                                error={error.status} />
                        </Stack>
                    </Stack>

                    {
                        error.status ?
                            <Typography color='error'>{error.message}</Typography> : ''
                    }

                </Stack>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width:'100%'
                }}>

                    <Button
                        variant='outlined'
                        onClick={() => {
                            dispatch(logout())
                            navigate('/')
                        }}
                    >Log Out</Button>

                    <LoadingButton
                        onClick={() => handleSubmit()}
                        disabled={inputs.full_name === '' || inputs.dateOfBirth === '' || inputs.address === null || inputs.dateOfBirth === "Invalid Date" || error.status}
                        variant='contained'
                        size='large'>
                        Continue
                    </LoadingButton>
                </Box>

            </Box>
        </Container >
    )
}

export default AgeVerificationPage
