import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPasswordprocess, resetAuth } from '../../redux/actions/auth'
import withRouter, {
  hasWhiteSpace,
  isValidPassword
} from '../../helper'
import { Box, Button, Container, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import logo from "../../images/betzonlogo.svg"

function CreatePassword() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const PasswordResponse = useSelector((state) => state.Auth)
  const user = localStorage.getItem('useremail')
  const [showPwd, setShowPwd] = useState(false)
  const [showCpwd, setShowCpwd] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [input, setInput] = useState({
    password: '',
    cpassword: ''
  })

  useEffect(() => {
    if (!user) {
      navigate('/')
    }
  })

  const [error, setError] = useState('')
  useEffect(() => {
    if (PasswordResponse.passwordStatus === 'Fail') {
      setLoading(false)
      setError(PasswordResponse.response)
    }
    if (PasswordResponse.passwordStatus === 'Succcess') {
      setLoading(false)
      dispatch(resetAuth())
      navigate('/password-reset-success')
    }
  }, [PasswordResponse])

  const onInputChange = (e) => {
    setError('')
    const { name, value } = e.target
    setInput((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const comparePasswords = () => {
    if (input?.password === input?.cpassword) {
      return false
    } else {
      return true
    }
  }
  const hitApi = () => {
    setLoading(true)
    dispatch(
      forgotPasswordprocess({
        username: user,
        password: input.password
      })
    )
  }
  const showPassword = () => {
    setShowPwd(!showPwd)
  }

  const showCpassword = () => {
    setShowCpwd(!showCpwd)
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter' && (input?.password.length >= 8 && isValidPassword(input?.password) && (input?.password === input?.cpassword))) {
        hitApi()
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [input])

  const handleLogin = () => {
    dispatch(resetAuth())
    navigate('/')
  }

  const typeStyle = {
    width: '100%'
  }
  return (
    <Container sx={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '16px'
    }}>
      <img src={logo} style={{
        height: '120px'
      }} />
      <Typography variant='h6'>Create a new password</Typography>


      <TextField
        sx={typeStyle}
        type="password"
        name="password"
        onChange={(e) => onInputChange(e)}
        error={error}
        label="New Password"
      />
      <TextField
        sx={typeStyle}
        type="password"
        name="cpassword"
        onChange={(e) => onInputChange(e)}
        error={error}
        label="Confirm New Password"
      />
      {
        input?.cpassword?.length > 0 && comparePasswords() &&
        <Typography variant='caption' color="error">Passwords don't match</Typography>
      }
      {
        error &&
        <Typography variant='caption' color="error">{error}</Typography>
      }
      <LoadingButton
        sx={{
          width: '100%'
        }}
        variant='contained'
        onClick={() => hitApi()}
        disabled={
          !(input?.password.length >= 8 &&
            isValidPassword(input?.password) &&
            !comparePasswords())
        }
        loading={isLoading}
      >
        Reset Password
      </LoadingButton>

      {input?.password?.length > 0 &&
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}>
          <Typography
            variant='caption'
            color={isValidPassword(input?.password) && input?.password?.length > 7 && !hasWhiteSpace(input?.password) ? 'success' : 'error'}>
            A password should be...
          </Typography >
          <Typography
            variant='caption'
            color={isValidPassword(input?.password) && input?.password?.length > 7 && !hasWhiteSpace(input?.password) ? 'success' : 'error'}>
            A minimum of 8 characters long.
          </Typography >
          <Typography
            variant='caption'
            color={isValidPassword(input?.password) && input?.password?.length > 7 && !hasWhiteSpace(input?.password) ? 'success' : 'error'}>
            Contains at least 1 uppercase.
          </Typography >
          <Typography
            variant='caption'
            color={isValidPassword(input?.password) && input?.password?.length > 7 && !hasWhiteSpace(input?.password) ? 'success' : 'error'}>
            Contains at least 1 lowercase.
          </Typography >
          <Typography
            variant='caption'
            color={isValidPassword(input?.password) && input?.password?.length > 7 && !hasWhiteSpace(input?.password) ? 'success' : 'error'}>
            Contains at least 1 numeric digit.
          </Typography >
          <Typography
            variant='caption'
            color={isValidPassword(input?.password) && input?.password?.length > 7 && !hasWhiteSpace(input?.password) ? 'success' : 'error'}>
            Contains at least 1 special character.
          </Typography >
          <Typography
            variant='caption'
            color={isValidPassword(input?.password) && input?.password?.length > 7 && !hasWhiteSpace(input?.password) ? 'success' : 'error'}>
            No spaces are allowed.
          </Typography >
        </Box>
      }
    </Container>
  )
}

export default withRouter(CreatePassword)


/*

    <React.Fragment>
      <div className="fullScreen fullScreen-login">
        <div className="loginWrap pt-0">
            <div className='logo_div mb-4'>
            <div className="logo">
              <img src={logo}
              />
            </div>
            <div className='text-center'>
              <span className='span_moto'>Moto</span><span className='span_bookie'>Bookie</span>
            </div>
          </div>
   
          <div className="infoTxt">
            <h1>Create a new password</h1>
            <p>
              Your new password must be different from previous used passwords.
            </p>
          </div>
          <div className="loginInp">
            <div className="loginInp-main">
              <label className="label">Password</label>
              <input
                className="inputTxt"
                name="password"
                type={showPwd ? 'text' : 'password'}
                placeholder="New Password"
                onChange={(e) => onInputChange(e)}
              />
              <span className="inpIcon" onClick={showPassword}>
                {showPwd ? <GoEye /> : <GoEyeClosed />}
              </span>
            </div>
            {input?.password?.length > 0 &&
              <div className="infotxtGroup">
                <p className={isValidPassword(input?.password) && input?.password?.length > 7 && !hasWhiteSpace(input?.password) ? 'success' : 'error'}>
                  A password should be a minimum of 8 characters long, containing at least 1 uppercase, 1 lowercase, 1 numeric digit, and 1 special character.No spaces are allowed.
                </p >
              </div >
            }
<div className="loginInp">
  <div className="loginInp-main">
    <label className="label">Confirm Password</label>
    <input
      className="inputTxt"
      name="cpassword"
      type={showCpwd ? 'text' : 'password'}
      placeholder="Confirm Password"
      onChange={(e) => onInputChange(e)}
    />
    <span className="inpIcon" onClick={showCpassword}>
      {showCpwd ? <GoEye /> : <GoEyeClosed />}
    </span>
    {input?.cpassword?.length > 0 && comparePasswords() &&
      <div className="infotxtGroup">
        <p className={comparePasswords() ? 'error' : 'success'}>
          {" Passwords don't match"}
        </p>
      </div>
    }
    <div className="infotxtGroup">
      {error && <p className="error">{error}</p>}
    </div>
  </div>
</div>
          </div >
          <div className="loginInp d-grid">
            <button
              onClick={() => hitApi()}
              disabled={
                !(input?.password.length >= 8 &&
                isValidPassword(input?.password) &&
                !comparePasswords())
              }
              className="button"
              style={{
                background:
                input?.password.length >= 8 &&
                isValidPassword(input?.password) &&
                !comparePasswords() && !isLoading
                  ? 'var(--color-light-blue)'
                  : 'gray',
                color:
                input?.password.length >= 8 &&
                isValidPassword(input?.password) &&
                !comparePasswords() && !isLoading
                  ? '#fff'
                  : '#000'
              }}
            >
              {isLoading ? 'Please wait...' : 'Reset Password'}
            </button>
          </div>
        <div className="infotxtGroup">
            {error && <p className="error">{error}</p>}
          </div> 
        </div >
  <div className="pageBottom text-center keyOpen">
    <p className="link-white cursor-pointer" onClick={handleLogin}><u>Already have an account? </u></p>
    <p>Brought to you by <span>COVERED Inc</span>.</p>
  </div>
      </div >
    </React.Fragment >
*/
