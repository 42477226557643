import React from 'react'
import logo from '../../../src/images/betzonlogo.svg'
import { Box, Typography } from '@mui/material'

const LogoContainer = ({ mini }) => {
  return (
    <>
      {
        mini ?
          < img src={logo} style={{
            width: '48px',
            height: '48px'
          }} />
          :
          < img src={logo} style={{
            width: '144px',
            height: '144px',
            mb: '-120px'
          }} />
      }
    </>
  )
}

export default LogoContainer
