import { BILLING_DETAILS_REQUEST, BILLING_DETAILS, BILLING_DETAILS_ERROR } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import BillingDetailsService from '../../../services/billing-details'

export const BillingDetails = createAsyncThunk(
  '/profile/retrieve-card/:id',
  async ({ id, isWithdraw }, thunkAPI) => {
    thunkAPI.dispatch({
      type: BILLING_DETAILS_REQUEST
    })
    try {
      const data = await BillingDetailsService.BillingDetails(id, isWithdraw)
      thunkAPI.dispatch({
        type: BILLING_DETAILS,
        payload: data && data?.data
      })
      return { chips: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: BILLING_DETAILS_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
