const initialState = {
  isLoading: false
}
export default function addParticipant (state = initialState, action) {
  switch (action.type) {
    case 'ADD_PARTICIPANT_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'ADD_PARTICIPANT': {
      return {
        ...state,
        isLoading: false,
        participantInfo: action.payload
      }
    }
    case 'ADD_PARTICIPANT_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        isLoading: false,
        error: action.payload
      }
    }
    case 'RESET_PARTICIPANT': {
      return {
        ...state,
        isLoading: false,
        participantInfo: {}
      }
    }
    // RESET_PARTICIPANT
    default:
      return state
  }
}
