import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  wagerdetails,
  acceptWagger,
  resetAcceptWagerError
} from '../../redux/actions/wagerdetails'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Loader from '../../components/loader'
import { isWagerClose } from '../../helper'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Avatar, Box, Button, Container, Divider, IconButton, Stack, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@emotion/react'
import ProfilePill from '../../components/wager/profile-pill'
import AnimationWagerAccepted from '../../components/animations/wager-accepted'
import Receipt from '../../components/reciept'
import { LoadingButton } from '@mui/lab'


function ConfirmWrager({ hideReviewWager, id, functionToClose, functionIncrementCounter, privateWager }) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('user'))
  //const { id } = useParams()
  const wagerDetailsViewed = useSelector((state) => state?.wagerDetails?.wager)
  const wagerDetailsViewed2 = useSelector((state) => state?.wagerDetails)
  const [selectedWager, setWagerDetails] = useState({})
  const wagerStatus = isWagerClose(selectedWager?.event_data?.startTime)
  const acceptedInfo = useSelector((state) => state?.wagerDetails?.data)
  const [loader, setLoader] = useState(false)
  const [stage, setStage] = useState(1)

  useEffect(() => {
    setLoader(true)
    if (wagerDetailsViewed?.result) {
      setWagerDetails(wagerDetailsViewed?.result)
      setLoader(false)
    }
  }, [wagerDetailsViewed])

  const handleSubmit = () => {
    dispatch(acceptWagger({ id, status: 'accept', user }))
  }

  useEffect(() => {
    if (id) {
      dispatch(wagerdetails({ id }))
    }
  }, [id])

  useEffect(() => {
    if (acceptedInfo?.id === id) {
      //navigate('/my-wagers')
      functionToClose()
      //setStage(1)
    }
  }, [acceptedInfo])



  const toOrdinalSuffix = (num) => {
    const int = parseInt(num)
    const digits = [int % 10, int % 100]
    const ordinals = ['st', 'nd', 'rd', 'th']
    const oPattern = [1, 2, 3, 4]
    const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
      ? int + ordinals[digits[0] - 1]
      : int + ordinals[3]
  }


  const startTimeString = selectedWager?.event_data?.startTime;
  const startTime = new Date(startTimeString);

  // Convert to PST
  const pstOffset = 8 * 60; // 8 hours in minutes
  startTime.setMinutes(startTime.getMinutes() - startTime.getTimezoneOffset() - pstOffset);

  // Extracting date components
  const year = startTime.getFullYear();
  const month = startTime.getMonth() + 1; // January is 0!
  const day = startTime.getDate();

  // Extracting time components
  let hours = startTime.getHours();
  const minutes = startTime.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert 24-hour time to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  /*
  
  */

  // console.log(selectedWager?.chipsBet)
  // console.log(selectedWager?.chipsBet > user?.walletBalance)
  // console.log("Wager close -", wagerStatus)
  // console.log("Wager SELECTED -", selectedWager)
  return (
    <>
      {
        acceptedInfo?.id != id ?
          <>
            <Container sx={{
              display: 'flex',
              bgcolor: 'black',
              flexDirection: 'column',
              gap: '36px',
              pb: 12,
              pt: 2,
              position: 'relative',
              maxWidth: '450px',
            }}>

              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '12px'
              }}>
                <Avatar
                  src={selectedWager?.createdBy_data?.userLogo || null}
                  sx={{
                    height: '84px',
                    width: '84px'
                  }} />

                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '6px'
                }}>
                  <Typography>Wager Status</Typography>
                  <Box sx={{
                    borderRadius: '6px',
                    padding: '6px 24px',
                    bgcolor: theme.palette.dark.otherdark
                  }}>
                    <Typography>Open to accept</Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography variant='caption'>Wager closes: {`${month}/${day}/${year} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${ampm}`}</Typography>
                </Box>
              </Box>

              {
                selectedWager?.chipsBet > user?.walletBalance
                &&
                <Alert severity='error'>You don't have enough chips!</Alert>
              }

              <Stack spacing={2} sx={{
                bgcolor: theme.palette.dark.otherdark,
                pt: 4,
                pb: 4,
                pl: 2,
                pr: 2
              }}>
                <Stack sx={{
                  textAlign: 'center'
                }}>
                  <Typography>For you to win:</Typography>
                  {
                    selectedWager?.createdBy === user?.id
                      ?
                      <>
                        {selectedWager?.placement != null
                          ? (
                            <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                              {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '}
                              {selectedWager?.placement === selectedWager?.placement_range
                                ? 'finishes at ' + toOrdinalSuffix(selectedWager?.placement) + ' place'
                                : selectedWager?.placement_range
                                  ? 'finishes between ' + toOrdinalSuffix(selectedWager?.placement) + ' through ' + toOrdinalSuffix(selectedWager?.placement_range) + ' place'
                                  : toOrdinalSuffix(selectedWager?.placement) + ' place'
                              }
                            </Typography>
                          )
                          :
                          (selectedWager?.wagerType_data?.name === 'podium')
                            ?
                            <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                              {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '} places Podium
                            </Typography>
                            :
                            (
                              selectedWager?.wagerType_data?.name === 'holeshot'
                                ?
                                <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                  {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} wins the Holeshot
                                </Typography>
                                : selectedWager?.wagerType_data?.name === 'spread'
                                  ?
                                  <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                    {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                    {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                    {' '}by more than {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}
                                  </Typography>
                                  :
                                  <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                    {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                    {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                  </Typography>
                            )
                        }
                      </>
                      :
                      <>
                        {selectedWager?.placement != null
                          ? (
                            <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                              {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '}
                              {/* {toOrdinalSuffix(selectedWager?.placement)} place */}
                              {selectedWager?.placement === selectedWager?.placement_range
                                ? "doesn't finish at " + toOrdinalSuffix(selectedWager?.placement) + ' place'
                                : selectedWager?.placement_range
                                  ? "doesn't finish between " + toOrdinalSuffix(selectedWager?.placement) + ' through ' + toOrdinalSuffix(selectedWager?.placement_range) + ' place'
                                  : toOrdinalSuffix(selectedWager?.placement) + ' place'
                              }
                            </Typography>
                          )
                          : (selectedWager?.wagerType_data?.name === 'podium')
                            ?
                            <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                              {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '} doesn't place Podium
                            </Typography>
                            : (
                              selectedWager?.wagerType_data?.name === 'holeshot'
                                ?
                                <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                  {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} doesn't win the Holeshot
                                </Typography>
                                : selectedWager?.wagerType_data?.name === 'spread'
                                  ?
                                  <>
                                    <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                      {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} loses to OR beats{' '}
                                      {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                      {' '}by less than {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}
                                    </Typography>

                                    {
                                      /*
<Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                      {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name} loses to OR beats{' '}
                                      {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name}
                                      {' '}by less than {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}
                                    </Typography>
                                      */
                                    }

                                  </>
                                  :
                                  <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                    {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name} beats{' '}
                                    {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name}
                                  </Typography>
                            )
                        }
                      </>
                  }
                </Stack>

                <Divider />

                <Stack sx={{
                  textAlign: 'center'
                }}>
                  <Typography>For opponent to win:</Typography>
                  {
                    selectedWager?.createdBy === user?.id
                      ?
                      <>
                        {selectedWager?.placement != null
                          ? (
                            <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                              {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '}
                              {/* {toOrdinalSuffix(selectedWager?.placement)} place */}
                              {selectedWager?.placement === selectedWager?.placement_range
                                ? "doesn't finish at " + toOrdinalSuffix(selectedWager?.placement) + ' place'
                                : selectedWager?.placement_range
                                  ? "doesn't finish between " + toOrdinalSuffix(selectedWager?.placement) + ' through ' + toOrdinalSuffix(selectedWager?.placement_range) + ' place'
                                  : toOrdinalSuffix(selectedWager?.placement) + ' place'
                              }
                            </Typography>
                          )
                          : (selectedWager?.wagerType_data?.name === 'podium')
                            ?
                            <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                              {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '} doesn't place Podium
                            </Typography>
                            : (
                              selectedWager?.wagerType_data?.name === 'holeshot'
                                ?
                                <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                  {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} doesn't win the Holeshot
                                </Typography>
                                : selectedWager?.wagerType_data?.name === 'spread'
                                  ?
                                  <>
                                    <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                      {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} loses to OR beats{' '}
                                      {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                      {' '}by less than {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}
                                    </Typography>

                                  </>
                                  :
                                  <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                    {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                    {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                  </Typography>
                            )
                        }
                      </>
                      :
                      <>
                        {
                          selectedWager?.placement != null
                            ?
                            (
                              <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '}
                                {selectedWager?.placement === selectedWager?.placement_range
                                  ? 'finishes at ' + toOrdinalSuffix(selectedWager?.placement) + ' place'
                                  : selectedWager?.placement_range
                                    ? 'finishes between ' + toOrdinalSuffix(selectedWager?.placement) + ' through ' + toOrdinalSuffix(selectedWager?.placement_range) + ' place'
                                    : toOrdinalSuffix(selectedWager?.placement) + ' place'
                                }
                              </Typography>
                            )
                            : (selectedWager?.wagerType_data?.name === 'podium')
                              ?
                              <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} {' '} places Podium
                              </Typography>
                              : (
                                selectedWager?.wagerType_data?.name === 'holeshot'
                                  ? <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>{selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} wins the Holeshot</Typography>
                                  : selectedWager?.wagerType_data?.name === 'spread'
                                    ? <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                      {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                      {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                      {' '}by more than {selectedWager?.spreadOptions_data?.id} {selectedWager?.spreadOptions_data?.id === 1 ? 'spot' : 'spots'}
                                    </Typography>
                                    :
                                    <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                      {selectedWager?.racerFor_data?.name || selectedWager?.teamsFor_data?.name} beats{' '}
                                      {selectedWager?.racerAgainst_data?.name || selectedWager?.teamsAgainst_data?.name}
                                    </Typography>
                              )
                        }
                      </>
                  }

                </Stack>
              </Stack>

              <Stack
                spacing={1}>
                <Typography>Event Details</Typography>

                <Divider />

                <Stack
                  spacing={1.25}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      //bgcolor: 'red',
                    }}>
                    <Typography variant='body2' sx={{ fontWeight: 700 }}>Sport</Typography>
                    <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.event_data?.sports_data?.name}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      //bgcolor: 'red',
                    }}>
                    <Typography variant='body2' sx={{ fontWeight: 700 }}>Event Name</Typography>
                    <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.event_data?.name}</Typography>
                  </Box>
                </Stack>
              </Stack>

              <Stack
                spacing={1}>
                <Typography>Wager Details</Typography>

                <Divider />


                <Stack
                  spacing={1.25}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      //bgcolor: 'red',
                    }}>
                    <Typography variant='body2' sx={{ fontWeight: 700 }}>Mode</Typography>
                    <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.mode?.toUpperCase()}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      //bgcolor: 'red',
                    }}>
                    <Typography variant='body2' sx={{ fontWeight: 700 }}>Wager Type</Typography>
                    <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.wagerType_data?.name?.toUpperCase()}</Typography>
                  </Box>
                  {
                    selectedWager?.division_data &&
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        //bgcolor: 'red',
                      }}>
                      <Typography variant='body2' sx={{ fontWeight: 700 }}>Division</Typography>
                      <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.division_data?.name}</Typography>
                    </Box>
                  }
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      //bgcolor: 'red',
                    }}>
                    <Typography variant='body2' sx={{ fontWeight: 700 }}>Chip Amount</Typography>
                    <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.chipsBet} Chips</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      //bgcolor: 'red',
                    }}>
                    <Typography variant='body2' sx={{ fontWeight: 700 }}>Created by</Typography>
                    <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>@{selectedWager?.createdBy_data?.username}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      //bgcolor: 'red',
                    }}>
                    <Typography variant='body2' sx={{ fontWeight: 700 }}>Multiplier</Typography>
                    <Typography variant='body2' sx={{ fontWeight: 500, color: theme.palette.dark.otherlight }}>{selectedWager?.multiplier_data?.name?.replace(/ /g, '-')}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      //bgcolor: 'red',
                    }}>
                    <Typography variant='body2' sx={{ fontWeight: 700 }}>
                      {
                        !!privateWager ?
                          'Sent to' :
                          'Availability'
                      }
                    </Typography>
                    {
                      !!privateWager ?
                        <ProfilePill
                          max={selectedWager?.maxPeople}
                          items={[...(selectedWager?.wagerusers || [])]}
                          size={24}
                          wagerStatus={(wagerStatus?.h > 0 || wagerStatus?.m > 0)}
                          privateWager />
                        :
                        <ProfilePill
                          max={selectedWager?.maxPeople}
                          items={[...(selectedWager?.wagerusers || [])]}
                          size={24}
                        />
                    }
                  </Box>
                </Stack>
              </Stack>

            </Container>
            {
              //(wagerStatus.h >= 0 || wagerStatus.m >= 0) &&
              <Container sx={{
                zIndex: '999999',
                bgcolor: theme.palette.dark.dark,
                position: 'fixed',
                bottom: 0,
                left: 0,
                pt: 2,
                borderTop: `1px solid #0F1823`,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '12px',
                pb: 3,
                borderTop: '3px solid black'
              }}>

                {
                  acceptedInfo?.error &&
                  <Alert severity='error'>{acceptedInfo?.error ? acceptedInfo?.message : ''}</Alert>
                }

                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  maxWidth: '450px'
                }}>
                  <Button onClick={() => {
                    functionToClose()
                    dispatch(resetAcceptWagerError())
                  }}>
                    Go back
                  </Button>
                  <LoadingButton
                    loading={wagerDetailsViewed2?.isLoading}
                    disabled={acceptedInfo?.error || selectedWager?.chipsBet > user?.walletBalance || (wagerStatus.h <= 0 && wagerStatus.m <= 0)}
                    variant='contained'
                    //onClick={handleSubmit}
                    onClick={() => {
                      handleSubmit()
                      setStage(2)
                    }}
                  >
                    {
                      (wagerStatus.h <= 0 && wagerStatus.m <= 0)
                        ?
                        "Wagering has closed!"
                        :
                        "Accept Wager"
                    }

                  </LoadingButton>
                </Box>
              </Container >
            }
          </>
          :
          <>
            <Container sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '36px',
              pt: 2,
              height: '65vh',
              boxSizing: 'border-box',
              bgcolor: 'black'
            }}>
              <Stack
                justifyContent={"center"}
                alignItems={'center'}
                sx={{
                  height: '100%',
                  pt: 1
                }}>
                <Box sx={{
                  width: '100%',
                  maxWidth: '450px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <Receipt data={selectedWager} />
                </Box>
              </Stack>
            </Container>
            <Container sx={{
              zIndex: '999999',
              bgcolor: theme.palette.dark.dark,
              position: 'fixed',
              bottom: 0,
              left: 0,
              pt: 2,
              borderTop: `1px solid #0F1823`,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '12px',
              pb: 3
            }}>
              <Stack
                spacing={1.5}
                sx={{
                  width: '100%',
                  maxWidth: '450px',
                }}>
                <Button
                  onClick={() => {
                    functionIncrementCounter()
                    functionToClose()
                    navigate('/my-wagers')
                  }}
                  variant='outlined'
                >
                  Go to My Wagers
                </Button>
                <Button
                  variant='contained'
                  onClick={() => {
                    functionIncrementCounter()
                    functionToClose()
                  }
                  }
                >
                  Continue Scrolling
                </Button>
              </Stack>
            </Container>
          </>
      }
    </>
  )
}

export default ConfirmWrager

ConfirmWrager.propTypes = {
  hideReviewWager: PropTypes.func
}