import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getUsers } from '../../redux/actions/users'
import { createChat, resetChat } from '../../redux/actions/chats'
import { Avatar, Backdrop, Box, Button, Container, IconButton, ListItemButton, Stack, TextField, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@emotion/react'
import Radio from '@mui/material/Radio';
import Loader from '../../components/loader'
import { LoadingButton } from '@mui/lab'

function SearchFriend() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [keyword, setKeyword] = useState('')
  const [userList, setUserList] = useState(null)
  const user = JSON.parse(localStorage.getItem('user'))
  const [participant, setParticipant] = useState([user?.id])
  const [show, setShow] = useState(false)
  const wagerConfigResponse = useSelector((state) => state.wagerConfig)
  const maxPeople = wagerConfigResponse?.wagerConfigInfo?.result?.maxPeople
  const userResponse = useSelector((state) => state.usersList)
  const chatResponse = useSelector((state) => state.createChat)

  const handleSelectedUser = (value) => {
    const duplicate = participant
    if (participant.length >= maxPeople && !participant.includes(value.id)) {
      return setShow(true)
    }
    if (duplicate.includes(value.id)) {
      const filtered = duplicate.filter((val) => val !== value.id)
      setParticipant(filtered)
    } else {
      duplicate.push(value.id)
      setParticipant(JSON.parse(JSON.stringify(duplicate)))
    }
  }

  const handleChat = () => {
    const totalParticipantLength = participant.length
    if (totalParticipantLength <= 10 && participant.length > 1) {
      dispatch(createChat({
        type: 'P',
        participants: participant
      }))
    } else if (participant.length === 1) {
      alert('Please select a user')
    } else {
      if (totalParticipantLength === 1) {
        return (alert('Please select user'))
      } else {
        return (
          alert('you can not add more than 5 participant')
        )
      }
    }
  }

  useEffect(() => {
    if (chatResponse?.chatInfo?.id) {
      console.log('RUIUNNNING TRUE')
      navigate(`/chat-screen?chatId=${chatResponse?.chatInfo?.id}`)
      dispatch(resetChat())
    }
  }, [chatResponse])

  useEffect(() => {
    if (!keyword.length) {
      //setUserList(userResponse?.usersList?.result)
      dispatch(getUsers({
        query: ''
      }))
    }
  }, [keyword])

  const handleClose = () => {
    setShow(!show)
  }
  console.log('chatResponse -', chatResponse)
  console.log('chatResponse -', chatResponse?.chatInfo?.id)
  const hitUserSearchApi = () => {
    dispatch(getUsers({
      query: keyword
    }))
  }

  const controlProps = (item) => ({
    checked: participant.includes(item.id),
    // onChange: handleChange,
    value: item.id,
    name: 'size-radio-button-demo',
    inputProps: { 'aria-label': item.id },
  });

  return (
    <Box sx={{ bgcolor: '', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Container sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: 4,
        pb: 3
      }}>

        <IconButton
          onClick={() => navigate('/social?dms')}
          sx={{
            m: 0,
            p: 0
          }}>
          <ArrowBackIcon fontSize='medium' />
        </IconButton>

        <Button
          size='small'
          color='primary'
          disabled={!(participant.length > 1)}
          onClick={handleChat}
          variant='contained'>
          Chat
        </Button>
      </Container>

      <Container>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px'
        }}>
          <Typography variant='h6'>To: </Typography>
          <TextField
            sx={{
              width: '100%'
            }}
            onChange={(e) => setKeyword(e.target.value)}
            label='Search' />
          <LoadingButton
            disabled={userResponse?.isLoading || keyword === ''}
            loading={userResponse?.isLoading}
            variant='contained'
            onClick={() => hitUserSearchApi()}>
            Search
          </LoadingButton>
        </Box>
        <Box sx={{
          mt: 4,
          mb: 2
        }}>
          <Typography>
            Suggested
          </Typography>
        </Box>
      </Container>
      <Box sx={{
        pt: 1,
        flexGrow: 2,
        borderRadius: '12px 12px 0 0',
        bgcolor: theme.palette.dark.otherdark
      }}>
        {
          userResponse?.isLoading

            ?
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              pb: 4
            }}>
              <Loader isloading={true} />
            </Box>
            :
            userResponse.usersList?.map((value, index) => {
              return (
                value.id !== user.id && !value.isDeleted && value.isActive &&
                <ListItemButton
                  key={value.id}
                  onClick={() => handleSelectedUser(value)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '12px',
                    pt: 1,
                    pb: 1
                  }}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                  }}>
                    <Avatar
                      src={value.userLogo ? value.userLogo : null}
                      sx={{
                        height: '36px',
                        width: '36px'
                      }} />
                    <Typography>@{value.username}</Typography>
                  </Box>

                  <Radio
                    checked={participant.includes(value.id)}
                    value="a"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                </ListItemButton>
              )
            })
        }
      </Box>
    </Box>
  )
}

export default SearchFriend