export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL'
export const USER_SIGNUP = 'USER_SIGNUP'
export const USER_SIGNUP_REQUEST = 'USER_SIGNUP_REQUEST'
export const USER_SIGNUP_FAIL = 'USER_SIGNUP_FAIL'
export const RESET_AUTH = 'RESET_AUTH'
export const REQUEST_OTP = 'REQUEST_OTP'
export const REQUEST_OTP_FAIL = 'REQUEST_OTP_FAIL'
export const VALIDATE_OTP = 'VALIDATE_OTP'
export const VALIDATE_OTP_FAIL = 'VALIDATE_OTP_FAIL'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'
export const UPLOAD_PROFILE = "UPLOAD_PROFILE"
export const UPLOAD_PROFILE_FAIL = "UPLOAD_PROFILE_FAIL"

export const GET_DIVISIONS = 'GET_DIVISIONS'
export const GET_MULTIPLIERS = 'GET_MULTIPLIERS'
export const GET_PRIVATEERS = 'GET_PRIVATEERS'

export const CREATE_WAGER = 'CREATE_WAGER'
export const CREATE_WAGER_REQUEST = 'CREATE_WAGER_REQUEST'
export const CREATE_WAGER_ERROR = 'CREATE_WAGER_ERROR'

export const RESET_WAGER = 'RESET_WAGER'
export const DEACTIVATE_WAGER = 'DEACTIVATE_WAGER'
export const REQUEST_DEACTIVATE_WAGER = 'REQUEST_DEACTIVATE_WAGER'
export const RESET_DEACTIVATE_WAGER = 'RESET_DEACTIVATE_WAGER'

export const LIST_WAGERS = 'LIST_WAGERS'
export const LIST_WAGERS_REQUEST = 'LIST_WAGERS_REQUEST'

export const LIST_DIVISONS = 'LIST_DIVISONS'
export const LIST_DIVISONS_ERROR = 'LIST_DIVISONS_ERROR'

export const LIST_MANUFACTUERERS = 'LIST_MANUFACTUERERS'
export const LIST_MANUFACTUERERS_ERROR = 'LIST_MANUFACTUERERS_ERROR'

export const ERROR_OCCURED = 'ERROR_OCCURED'

export const LIST_PRIVATEERS = 'LIST_PRIVATEERS'
export const SET_PRIVATEERS = 'SET_PRIVATEERS'
export const SET_PRIVATEERS_ERROR = 'SET_PRIVATEERS_ERROR'
export const LIST_PRIVATEERS_ERROR = 'LIST_PRIVATEERS_ERROR'

export const LIST_SUBSCRIPTION = 'LIST_SUBSCRIPTION'
export const LIST_SUBSCRIPTION_ERROR = 'LIST_SUBSCRIPTION_ERROR'
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'
export const SET_SUBSCRIPTION_ERROR = 'SET_SUBSCRIPTION_ERROR'
export const SET_SUBSCRIPTION_REQUEST = 'SET_SUBSCRIPTION_REQUEST'
export const RESET_SUBSCRIPTION = 'RESET_SUBSCRIPTION'

export const ADD_CARD_REQUEST = 'ADD_CARD_REQUEST'
export const ADD_CARD = 'ADD_CARD'
export const RESET_CARD = 'RESET_CARD'

export const USERNAME_CHECK = 'USERNAME_CHECK'
export const USERNAME_CHECK_ERROR = 'USERNAME_CHECK_ERROR'
export const USERNAME_CHECK_REQUEST = 'USERNAME_CHECK_REQUEST'

export const USEREMAIL_CHECK = 'USEREMAIL_CHECK'
export const USEREMAIL_CHECK_ERROR = 'USEREMAIL_CHECK_ERROR'
export const USEREMAIL_CHECK_REQUEST = 'USEREMAIL_CHECK_REQUEST'

export const GET_WALLET_BALANCE = 'GET_WALLET_BALANCE'
export const GET_WALLET_BALANCE_REQUEST = 'GET_WALLET_BALANCE_REQUEST'
export const GET_WALLET_BALANCE_ERROR = 'GET_WALLET_BALANCE_ERROR'

export const GET_WAGER_CONFIG_REQUEST = 'GET_WAGER_CONFIG_REQUEST'
export const GET_WAGER_CONFIG = 'GET_WAGER_CONFIG'
export const GET_WAGER_CONFIG_ERROR = 'GET_WAGER_CONFIG_ERROR'

export const LIST_MY_WAGERS = 'LIST_MY_WAGERS'
export const LIST_MY_WAGERS_ERROR = 'LIST_MY_WAGERS_ERROR'
export const LIST_MY_WAGERS_REQUEST = 'LIST_MY_WAGERS_REQUEST'

export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR'
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'

export const UPDATE_USERNAME = 'UPDATE_USERNAME'
export const UPDATE_USERNAME_ERROR = 'UPDATE_USERNAME_ERROR'
export const UPDATE_USERNAME_REQUEST = 'UPDATE_USERNAME_REQUEST'

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const RESET_PASSWORD = 'RESET_PASSWORD'

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
export const UPDATE_ADDRESS_ERROR = 'UPDATE_ADDRESS_ERROR'
export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST'
export const RESET_ADDRESS = 'RESET_ADDRESS'

export const GET_REFERRAL_CODE = 'GET_REFERRAL_CODE'
export const GET_REFERRAL_CODE_REQUEST = 'GET_REFERRAL_CODE_REQUEST'

export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const SET_NOTIFICATION_REQUEST = 'SET_NOTIFICATION_REQUEST'

export const GET_FAQ = 'GET_FAQ'
export const GET_FAQ_REQUEST = 'GET_FAQ_REQUEST'

export const DELETION_CODE_REQUEST = 'DELETION_CODE_REQUEST'
export const DELETION_CODE = 'DELETION_CODE'
export const RESET_DELETE_CODE = 'RESET_DELETE_CODE'

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const RESET_DELETE_ACCOUNT = 'RESET_DELETE_ACCOUNT'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'

export const SEND_FEEDBACK_REQUEST = 'SEND_FEEDBACK_REQUEST'
export const SEND_FEEDBACK = 'SEND_FEEDBACK'

export const GET_EVENT_ID = 'GET_EVENT_ID'
export const GET_EVENT_ID_REQUEST = 'GET_EVENT_ID_REQUEST'
export const RESET_EVENT_ID = 'RESET_EVENT_ID'

export const GET_WAGER_HISTORY = 'GET_WAGER_HISTORY'
export const GET_WAGER_HISTORY_ERROR = 'GET_WAGER_HISTORY_ERROR'

export const WAGER_DETAILS_REQUEST = 'WAGER_DETAILS_REQUEST'
export const WAGER_DETAILS = 'WAGER_DETAILS'
export const WAGER_DETAILS_ERROR = 'WAGER_DETAILS_ERROR'

export const ACCEPT_WAGEER = 'ACCEPT_WAGGER'
export const ACCEPT_WAGEER_ERROR = 'ACCEPT_WAGGER_ERROR'

export const ADD_CHIPS_REQUEST = 'ADD_CHIPS_REQUEST'
export const ADD_CHIPS = 'ADD_CHIPS'
export const ADD_CHIPS_ERROR = 'ADD_CHIPS_ERROR'
export const RESET_CHIPS = 'RESET_CHIPS'

export const CHIP_BALANCE_REQUEST = 'CHIP_BALANCE_REQUEST'
export const CHIP_BALANCE = 'CHIP_BALANCE'
export const CHIP_BALANCE_ERROR = 'CHIP_BALANCE_ERROR'

export const BILLING_DETAILS_REQUEST = 'BILLING_DETAILS_REQUEST'
export const BILLING_DETAILS = 'BILLING_DETAILS'
export const BILLING_DETAILS_ERROR = 'BILLING_DETAILS_ERROR'

export const WITHDRAW_CHIPS_REQUEST = 'WITHDRAW_CHIPS_REQUEST'
export const WITHDRAW_CHIPS = 'WITHDRAW_CHIPS'
export const WITHDRAW_CHIPS_ERROR = 'WITHDRAW_CHIPS_ERROR'
export const RESET_WITHDRAW = 'RESET_WITHDRAW'

export const TRANSCRIPT_REQUEST = 'TRANSCRIPT_REQUEST'
export const TRANSCRIPT = 'TRANSCRIPT'
export const TRANSCRIPT_ERROR = 'TRANSCRIPT_ERROR'

export const GET_ALL_CHAT_REQUEST = 'GET_ALL_CHAT_REQUEST'
export const GET_ALL_CHAT = 'GET_ALL_CHAT'
export const GET_ALL_CHAT_ERROR = 'GET_ALL_CHAT_ERROR'

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR'

export const GET_ALL_MESSAGES_REQUEST = 'GET_ALL_MESSAGES_REQUEST'
export const GET_ALL_MESSAGES = 'GET_ALL_MESSAGES'
export const GET_ALL_MESSAGES_ERROR = 'GET_ALL_MESSAGES_ERROR'

export const CREATE_CHAT_REQUEST = 'CREATE_CHAT_REQUEST'
export const CREATE_CHAT = 'CREATE_CHAT'
export const CREATE_CHAT_ERROR = 'CREATE_CHAT_ERROR'
export const RESET_CHAT = 'RESET_CHAT'

export const CREATE_MESSAGE_REQUEST = 'CREATE_MESSAGE_REQUEST'
export const CREATE_MESSAGE = 'CREATE_MESSAGE'
export const CREATE_MESSAGE_ERROR = 'CREATE_MESSAGE_ERROR'

export const NOTIFICATIONS_REQUEST = 'NOTIFICATION_REQUEST'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR'

export const NOTIFICATIONS_LIST_REQUEST = 'NOTIFICATIONS_LIST_REQUEST'
export const NOTIFICATIONS_LIST = 'NOTIFICATIONS_LIST'
export const NOTIFICATIONS_LIST_ERROR = 'NOTIFICATIONS_LIST_ERROR'

export const NOTIFICATIONS_UPDATE_REQUEST = 'NOTIFICATIONS_UPDATE_REQUEST'
export const NOTIFICATIONS_UPDATE_STATUS = 'NOTIFICATIONS_UPDATE_STATUS'
export const NOTIFICATIONS_UPDATE_ERROR = 'NOTIFICATIONS_UPDATE_ERROR'

export const LEADERBOARD_REQUEST = 'LEADERBOARD_REQUEST'
export const LEADERBOARD = 'LEADERBOARD'
export const LEADERBOARD_ERROR = 'LEADERBOARD_ERROR'

export const ADD_PARTICIPANT_REQUEST = 'ADD_PARTICIPANT_REQUEST'
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT'
export const ADD_PARTICIPANT_ERROR = 'ADD_PARTICIPANT_ERROR'
export const RESET_PARTICIPANT = 'RESET_PARTICIPANT'

export const NEWCHAT_COUNT_REQUEST = 'NEWCHAT_COUNT_REQUEST'
export const NEWCHAT_COUNT = 'NEWCHAT_COUNT'
export const NEWCHAT_COUNT_ERROR = 'NEWCHAT_COUNT_ERROR'

export const FILTER_CHAT_LIST = 'FILTER_CHAT_LIST'

export const LIST_PRIVATEERS_BY_EVENT = 'LIST_PRIVATEERS_BY_EVENT'
export const LIST_PRIVATEERS_ERROR_BY_EVENT = 'LIST_PRIVATEERS_ERROR_BY_EVENT'

export const GET_ADS = 'GET_ADS'
export const GET_ADS_REQUEST = 'GET_ADS_REQUEST'

export const SEND_URL = 'SEND_URL'
export const SEND_ADD_REQUEST = 'SEND_ADD_REQUEST'

export const GET_POST_REQUEST = 'GET_POST_REQUEST'
export const GET_POST = 'GET_POST'
export const GET_POST_ERROR = 'GET_POST_ERROR'

export const SET_SPORT_REQUEST = 'SET_SPORT_REQUEST'
export const SET_SPORT = 'SET_SPORT'
export const SET_SPORT_ERROR = 'SET_SPORT_ERROR'

export const SET_CURRENT_LEAGUE = 'SET_CURRENT_LEAGUE'
export const SET_CALENDAR_DATE = 'SET_CALENDAR_DATE'