import { useTheme } from '@emotion/react'
import { Avatar, Box, Container, Typography } from '@mui/material'
import React from 'react'
import AdsClickIcon from '@mui/icons-material/AdsClick';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CreateIcon from '@mui/icons-material/Create';
import CategoryIcon from '@mui/icons-material/Category';

const ProgressItem = ({ icon, stage, ownStage, title }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: stage >= ownStage ? 1 : .3,
            gap: '4px'
        }}>
            <Avatar sx={{ bgcolor: '' }}>
                {icon}
            </Avatar>
            <Typography variant='caption'>{title}</Typography>
        </Box>
    )
}

const SpacerCircles = ({ stage, ownStage, small }) => {
    return (
        <Box sx={{
            mt: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            opacity: stage > ownStage ? 1 : .3,

        }}>
            {
                [1, 2, 3].map((item, index) => (
                    <Box 
                    key={index}
                    sx={{
                        borderRadius: '100px',
                        background: 'white',
                        height: '6px',
                        width: '6px'
                    }}></Box>
                ))
            }
        </Box>
    )
}

const WagerCreationFooter = ({ stage, eventType, privateWager }) => {
    const theme = useTheme()
    return (
        <Box sx={{
            width: '100%',
            //bgcolor:'red',
            pt: 3,
            borderTop: `1px solid ${theme.palette.dark.otherlight}`
        }}>
            {
                eventType === 'event' ?
                    <Container sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '12px',
                        alignItems: 'flex-start'
                    }}>
                        <ProgressItem icon={<AdsClickIcon fontSize={"small"} />} stage={stage} ownStage={0} title="Event" />
                        <SpacerCircles stage={stage} ownStage={0} small />
                        <ProgressItem icon={<CategoryIcon fontSize={"small"} />} stage={stage} ownStage={1} title="Type" />
                        <SpacerCircles stage={stage} ownStage={1} small />
                        <ProgressItem icon={<HandshakeIcon fontSize={"small"} />} stage={stage} ownStage={2} title="Wager" />
                        {
                            !!!privateWager &&
                            <>
                                <SpacerCircles stage={stage} ownStage={2} small />
                                <ProgressItem icon={<CreateIcon fontSize={"small"} />} stage={stage} ownStage={3} title="Caption" />
                            </>
                        }
                    </Container>
                    :
                    <Container sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '12px',
                        alignItems: 'flex-start'
                    }}>
                        <ProgressItem icon={<AdsClickIcon fontSize={"small"} />} stage={stage} ownStage={0} title="Event" />
                        <SpacerCircles stage={stage} ownStage={0} />
                        <ProgressItem icon={<HandshakeIcon fontSize={"small"} />} stage={stage} ownStage={1} title="Wager" />
                        {
                            !!!privateWager &&
                            <>
                                <SpacerCircles stage={stage} ownStage={1} />
                                <ProgressItem icon={<CreateIcon fontSize={"small"} />} stage={stage} ownStage={3} title="Caption" />
                            </>
                        }
                    </Container>
            }

        </Box>
    )
}

export default WagerCreationFooter
