
const initialState = {
  isLoading: false
}
export default function CreatePost (state = initialState, action) {

  switch (action.type) {

    case 'CREATE_POST_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    
    case 'CREATE_POST': {
      return {
        ...state,
        isLoading: false,
        myPosts: action.payload
      }
    }

    case 'CREATE_POST_ERROR': {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    
    case 'RESET_CREATE_POST_ERROR': {
      return {
        isLoading: false
      }
    }

    default:
      return state
  }
}
