import axios from './axios'
import authHeader from './auth-header'

const setNotification = (notificationSetting) => {
  return axios.post('profile/change-notification-setting', {
    notificationSetting
  }, {
    headers: authHeader()
  })
}

const NotificationServices = {
  setNotification
}
export default NotificationServices
