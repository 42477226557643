import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSocket } from '../../components/hooks/useSocket'
// import handleBucket from '../../components/hooks/useBucket'
import authService from '../../services/auth.service'
import { resetParticipant } from '../../redux/actions/participant'
import Compressor from 'compressorjs'
import { isValidUrl, urlTypeIdentifier } from '../../helper'
import { wagerdetails, acceptWagger, resetAcceptWagerError } from '../../redux/actions/wagerdetails'
import { getWalletBalance } from '../../redux/actions/wallet'
import Drawer from '../../components/drawer'
import { Typography, Container, Box, Divider, Stack, Button, Alert, IconButton, Avatar, SwipeableDrawer } from '@mui/material'
import { closeModal, openModal } from '../../redux/actions/modal'
import MessageCreateWager from './create'
import { useTheme } from '@emotion/react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SendIcon from '@mui/icons-material/Send';
import Loader from '../../components/loader'
import { styled } from '@mui/system';
import ChatWager from '../../components/wager/chat/chat'
import { getMoreMessages, resetMessages } from '../../redux/actions/chatFilter'
import { LoadingButton } from '@mui/lab'
import TuneIcon from '@mui/icons-material/Tune';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ForumIcon from '@mui/icons-material/Forum';

const ActiveIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  height: '42px',
  width: '42px',
  backgroundColor: active ? theme.palette.action.selected : 'transparent', // Example of an active state style
}));

function ChatScreen() {
  const navigate = useNavigate()
  const theme = useTheme()
  const user = JSON.parse(localStorage.getItem('user'))
  const socket = useSocket()
  const messagesEndRef = useRef(null);
  const wagerDetailsViewed = useSelector((state) => state?.wagerDetails?.wager)
  const chat = useSelector((state) => state?.ChatFilter)
  const [message, setMessage] = useState('')
  const [totalMessages, setTotalMessages] = useState(0)
  const [media, setMedia] = useState(null)
  const dispatch = useDispatch()
  const [messageLimit, setMessageLimit] = useState(25)
  const Sport = useSelector((state) => state.Sport?.sport)
  const acceptedWager = useSelector((state) => state?.wagerDetails?.data)
  const overAllWagerDetails = useSelector((state) => state?.wagerDetails)
  const [successMsg, setSuccess] = useState('')
  const [errMsg, setError] = useState('')
  const [filterBy, setFilterby] = useState({
    filter: 'all'
  })
  const [anchorEl, setAnchorEl] = useState(false);

  const handleAnchorClick = () => {
    setAnchorEl(!anchorEl);
  };

  const handleAnchorClose = () => {
    setAnchorEl(false);
  };

  const handleChangeFilter = (event, nextView) => {
    if (nextView !== null) {
      setFilterby({
        filter: nextView
      });
      dispatch(getMoreMessages(chatId, messageLimit, { filter: nextView }))
      //socket.emit('getAllMsg', { chatId, filterBy: { filter: nextView } })
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({});
  };

  useEffect(() => {
    dispatch({ type: 'RESET_ACCEPT_WAGER' })
  }, [])

  useEffect(() => {
    if (acceptedWager?.status) {
      setSuccess(acceptedWager?.message)
      setTimeout(() => {
        dispatch({ type: 'RESET_ACCEPT_WAGER' })
        setShow3(false)
      }, 5000)
      setError('')
    } else {
      setSuccess('')
      setError(acceptedWager?.message)
    }
  }, [acceptedWager])
  const [messagesData, setMessages] = useState([])
  const [groupLength, setGroupLength] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isScrolledLoader, setIsScrolledLoader] = useState(true)
  const [show3, setShow3] = useState(false)

  useLayoutEffect(() => {
    // Function to scroll to the bottom
    if (messageLimit === 25) {
      const scrollToBottom = () => {
        const container = document.getElementById('container2');
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      };

      scrollToBottom();
      // Set the loader to false after scrolling
      setIsScrolledLoader(false);
    }
  }, [messagesData]); // This should be triggered whenever messagesData changes



  const [roomType, setType] = useState(null)
  const [roomDetails, setRoomDetails] = useState([])
  const handleShow3 = async (id) => {
    dispatch(wagerdetails({ id }))
    setShow3(true)
  }

  const handleSubmit = (id) => {
    dispatch(acceptWagger({ id, status: 'accept' }))
  }
  const [searchParams] = useSearchParams()
  const chatId = searchParams.get('chatId')

  useEffect(() => {
    // Function to initialize or reinitialize event listeners and emits
    const initSocketEvents = () => {
      socket.emit('getAllMsg', { chatId, socketLimit: messageLimit, filterBy: filterBy });
      socket.emit('chatroom', { chatId });
      socket.emit('markunread', { chatId, uid: user?.id });
    };

    // Attach the main event listener
    socket.on('getAllMsg', (data) => {
      if (data?.status === false) {
        navigate('/search-friend');
        return;
      }
      data.chatId === chatId && setRoomDetails(data?.chatResponse?.chatparticipants?.filter((val) => val.userId !== user?.id));
      data.chatId === chatId && setMessages(data?.message);
      data.chatId === chatId && setType(data?.chatResponse);
      data.chatId === chatId && setTotalMessages(data?.totalMessages);
      setFilterby({
        filter: 'all'
      })
      setIsLoading(false);
    });

    // Listen for socket disconnection and reconnection
    socket.on('disconnect', () => {
      console.log('Socket disconnected. Attempting to reconnect...');
    });

    socket.on('connect', () => {
      // Reinitialize events and emits after reconnection
      initSocketEvents();
    });

    // Initialize events and emits for the first connection
    initSocketEvents();

    // Cleanup function to remove event listeners
    return () => {
      socket.off('getAllMsg');
      socket.off('disconnect');
      socket.off('connect');
    };
  }, [socket]); // Dependency array


  const handleMessage = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const payload = {
      chatId,
      message,
      userId: user?.id
    }
    if (message.trim().length > 0) {
      if (message.trim().length < 255) {
        socket.emit('chat_msg', payload)
        setMessage('')
      } else {
        alert('Message character length should be less than 255.')
      }
      //   dispatch(createMessage(payload))
    }
  }

  const handleEnter = async (e) => {
    if (e.key === 'Enter') {
      handleMessage()
    }
  }

  const handleCompressedUpload = (e) => {
    const input = e.target.files[0]
    const fileSize = e.target.files[0].size / 1024 / 1024 // in MiB
    if (input.type.includes('image')) {
      // eslint-disable-next-line no-new
      new Compressor(input, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          setMedia(compressedResult)
        }
      })
    } else if (input.type.includes('video')) {
      // alert('video file is not allowed')
      if (fileSize <= 5) {
        setMedia(input)
      } else {
        alert('File size is greater than 5Mb')
      }
    } else {
      alert('File format other than image/video is not allowed')
    }
  }

  const handleClosedEvent = (eventTime) => {
    const eventStartDate = new Date(eventTime).getTime() - 900000
    const currentDate = new Date().getTime()
    if (currentDate > eventStartDate) {
      return -1
    } else {
      return 1
    }
  }

  const container = document.getElementById('container2')

  useEffect(() => {
    messagesData.sort((a, b) => {
      return a.id - b.id
    })
    const container = document.getElementById('container2')
    if (container && messageLimit === 25) {
      container.scrollTop = container.scrollHeight
    }
  }, [messagesData])

  useEffect(() => {
    if (roomDetails?.length > 2) {
      setGroupLength(roomDetails?.length - 2)
    }
  }, [roomDetails])

  useEffect(() => {
    if (media) {
      // const dataUrl = handleBucket(media, 'chat', chatId)
      const formData = new FormData()
      formData.append('file', media, media.name)
      const dataUrl = authService.imageUpload(formData)
      const user = JSON.parse(localStorage.getItem('user'))
      dataUrl.then((data) => {
        const payload = {
          chatId,
          message: data?.data?.imageUrl,
          userId: user?.id
        }
        socket.emit('chat_msg', payload)
      })
      setMedia(null)
    }
  }, [media])

  useEffect(() => {
    if (acceptedWager?.status) {
      socket.emit('getAllMsg', { chatId, filterBy: filterBy })
      socket.emit('chat_msg', { chatId: chatId })
      dispatch(resetAcceptWagerError())
    }
  }, [acceptedWager])

  useEffect(() => {
    dispatch(
      getWalletBalance({
        skip: 5,
        limit: 6
      })
    )
  }, [])
  // console.log(messagesData, 'messagesData')
  // console.log(roomDetails, 'roomDetails')

  const toggleWagerDetails = () => {
    dispatch(openModal())
  }

  useEffect(() => {
    if (chat?.status === 'success') {
      setMessages(chat?.messages?.result)
      setTotalMessages(chat?.messages?.totalCount)
      dispatch(resetMessages())
    }
  }, [chat]);

  const loadMoreMessages = () => {
    dispatch(getMoreMessages(chatId, messageLimit + 25, filterBy))
    setMessageLimit(messageLimit + 25)
  }

  return (
    <>
      {!isLoading
        ?
        (
          <>
            {
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '4px',
                bgcolor: 'black',
                position: 'fixed',
                width: '100%',
                top: 0,
                pt: 4,
                pb: 2,
                pr: 2,
                pl: 2,
                zIndex: '999'
              }}>
                <Stack
                  direction='row'
                >
                  <IconButton
                    sx={{ pr: 1, m: 0, }}
                    onClick={() => {
                      dispatch(resetParticipant())
                      //navigate('/social?dms')
                      navigate(-1)
                    }}>
                    <ArrowBackIcon fontSize='medium' />
                  </IconButton>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                  }}>
                    {roomDetails?.length > 2
                      ? <Typography style={{ fontSize: '13px' }}>{roomDetails?.[0].user_data.username.slice(0, 2)} </Typography>
                      :

                      <Avatar
                        src={
                          roomDetails?.[0]?.user_data?.userLogo ? roomDetails?.[0]?.user_data?.userLogo : null
                        }
                        sx={{
                          width: '36px',
                          height: '36px'
                        }} />
                    }

                    {
                      // eslint-disable-next-line array-callback-return
                      roomDetails?.map((val, index) => {
                        if (index === 0) {
                          return (
                            <Typography key={index}>
                              @{val?.user_data?.username}
                            </Typography>
                          )
                        } else if (index === 1) {
                          return (
                            <Typography key={index}>
                              @{val?.user_data?.username?.length > 16 ? `${val?.user_data?.username?.slice(0, 16)}...` : val?.user_data?.username} <span style={{ fontSize: '15px' }} onMouseOver={() => setShowHiddenPart(true)} onMouseOut={() => setShowHiddenPart(false)}>{groupLength > 0 && index > 0 ? `+${groupLength}` : ''}</span>
                            </Typography>
                          )
                        } else {
                          return null
                        }
                      })
                    }
                  </Box>
                </Stack>
              </Box>
            }
            <div
              id='container2'
              className='scrollable-chat'
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100vh)',
                paddingTop: '100px',
                paddingBottom: '100px',
                boxSizing: 'border-box'
              }}>
              <Box
                sx={{
                  //bgcolor: 'red'
                }}>
                {
                  !messagesData?.length > 0 && !isLoading
                    ?
                    <Box
                      className="fullScreen"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      <Typography variant='h6'>Start a conversation today!</Typography>
                    </Box>
                    :
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '20px'
                    }}>
                      {
                        totalMessages > messagesData?.length &&
                        < LoadingButton
                          loading={chat?.isLoading}
                          variant='outlined'
                          onClick={() => loadMoreMessages()}>
                          Load More
                        </LoadingButton>
                      }
                      {
                        messagesData?.map((value, index) => {
                          if (value.userId === user.id) {
                            if (value?.wagerId > 0) {
                              //Wager  of logged in user
                              return (
                                <ChatWager data={{ ...value, recipientUser: roomDetails?.[0].user_data.username }} />
                              )
                            } else {
                              //msg  of logged in user
                              return (
                                <>
                                  <Box
                                    key={index}
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'flex-end'
                                    }}>
                                    <Box
                                      sx={{
                                        pt: 1,
                                        pb: 1,
                                        pr: 2,
                                        pl: 2,
                                        bgcolor: theme.palette.primary.dark,
                                        borderRadius: '8px 8px 0 8px',
                                        maxWidth: '70%'
                                      }}>
                                      <Typography variant='body2'
                                        sx={{
                                          wordBreak: 'break-word',
                                          lineHeight: 1.75
                                        }}>
                                        {isValidUrl(value.message) ? urlTypeIdentifier(value.message) === 'v' ? <video width="100%" height="100%" controls src={value.message}></video> : urlTypeIdentifier(value.message) === 'i' ? <img src={value.message} alt='data' /> : <a href={value.message} > {value.message} </a> : value.message}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </>
                              )
                            }
                          } else {
                            if (value?.wagerId > 0) {
                              //Wager of other user to me
                              return (
                                <Stack alignItems={'flex-start'} sx={{}}>
                                  <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                    {value?.user_data?.username}
                                  </Typography>
                                  <ChatWager data={{ ...value, recipientUser: roomDetails?.[0]?.user_data?.username }} />
                                </Stack>
                              )
                            } else {
                              return (
                                //msg of toehr user to me
                                <Box
                                  key={index}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    width: '100%'
                                  }}>
                                  {/* Username Typography */}
                                  <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>
                                    {value?.user_data?.username}
                                  </Typography>

                                  {/* Message Box */}
                                  <Box
                                    onClick={() => navigate(`/user-profile/${value?.user_data?.id}`)}
                                    sx={{
                                      pt: 1,
                                      pb: 1,
                                      pr: 2,
                                      pl: 2,
                                      bgcolor: theme.palette.dark.otherdark, // Adjust color as needed
                                      borderRadius: '8px 8px 8px 0',
                                      width: 'fit-content', // This will make the box width dependent on the content
                                      maxWidth: '70%', // This ensures that it does not exceed 70% of the parent container's width
                                    }}>
                                    <Typography variant='body2' sx={{
                                      wordBreak: 'break-word',
                                      lineHeight: 1.75
                                    }}>
                                      {/* Conditional rendering for URLs or message */}
                                      {isValidUrl(value.message) ? urlTypeIdentifier(value.message) === 'v' ? <video width="100%" height="100%" controls src={value.message}></video> : urlTypeIdentifier(value.message) === 'i' ? <img src={value.message} alt='data' /> : <a href={value.message}>{value.message}</a> : value.message}
                                    </Typography>
                                  </Box>
                                </Box>


                              )
                            }
                          }
                        }
                        )
                      }
                    </Box >
                }
              </Box>

              {
                /*
   <div className='pageBottom chatInp'>
                  {
                    //!roomType?.wagerId && <div onClick={handleShow} className='addChat'><AiOutlinePlus /></div>
                    !roomType?.wagerId && <div onClick={() => dispatch(openModal())} className='addChat'><AiOutlinePlus /></div>
                  }
                  <div className='chattxtBox' >
                  <input type='text' placeholder='Type Something...' value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={handleEnter} />
                  </div>
                  <div className='addChat' > {message.length !== 0
                    ? <AiOutlineArrowRight onClick={handleMessage} />
                    : <><label htmlFor='file-input'>
                      <AiOutlineArrowUp />
                    </label>
                      <input id="file-input" type="file" onChange={(e) => handleCompressedUpload(e)} /> </>}</div>
                </div>
                */
              }
            </div>



            {
              <Stack
                spacing={1}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  bgcolor: 'black',
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  pb: 3,
                  pt: 1.5,
                  borderTop: `2px solid ${theme.palette.dark.light}`
                }}>
                <Container sx={{
                  maxWidth: '425px',
                  display: 'flex',
                  gap: '6px',
                }}>

                  <div>
                    {
                      anchorEl &&
                      <ClickAwayListener onClickAway={handleAnchorClose}>
                        <ToggleButtonGroup
                          orientation="vertical"
                          value={filterBy.filter}
                          exclusive
                          onChange={handleChangeFilter}
                          sx={{
                            position: 'absolute',
                            bottom: '70px',
                            borderRadius: '6px',
                            bgcolor: theme.palette.dark.dark,
                            border: `2px solid ${theme.palette.primary.main}`
                          }}>
                          <ToggleButton value="all" aria-label="quilt">
                            All
                          </ToggleButton>
                          <ToggleButton value="wagers-only" aria-label="wagers">
                            <HandshakeIcon sx={{ mr: 1 }} fontSize='small' />
                            Wagers only
                          </ToggleButton>
                          <ToggleButton value="messages-only" aria-label="messages">
                            <ForumIcon sx={{ mr: 1 }} fontSize='small' />
                            Messages only
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </ClickAwayListener>

                    }
                    <ActiveIconButton
                      color={filterBy.filter === 'all' ? 'white' : 'primary'}
                      active={anchorEl}
                      onClick={handleAnchorClick}
                    >
                      <TuneIcon />
                    </ActiveIconButton>
                  </div >
                  <IconButton sx={{ height: '42px', width: '42px' }} onClick={() => dispatch(openModal())}>
                    <HandshakeIcon />
                  </IconButton>
                  <Box sx={{
                    borderRadius: '100px',
                    border: `2px solid ${theme.palette.dark.light}`,
                    pt: .25,
                    pb: .25,
                    pl: 2,
                    pr: .5,
                    display: 'flex',
                    width: '100%'
                  }}>

                    <input
                      style={{
                        color: 'white',
                        fontWeight: 700,
                        width: '100%',
                        background: 'transparent',
                        border: 'none',
                        outline: 'none'
                      }}
                      type='text'
                      placeholder="What's on your mind?"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)} onKeyDown={handleEnter} />

                    <IconButton
                      onClick={handleMessage}
                      sx={{
                        opacity: message.length === 0 ? .3 : 1
                      }}
                      disabled={message.length === 0}
                    >
                      <SendIcon fontSize="small" sx={{ color: theme.palette.dark.light }} />
                    </IconButton>
                  </Box>
                </Container>
              </Stack>
            }
          </>

        )
        : (

          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            bgcolor: 'black',
            width: '100%',
            height: '100vh'
          }}>
            <Loader isloading={true} />
          </Box>
        )
      }


      <Drawer>
        <MessageCreateWager chatId={chatId} roomDetails={roomDetails} />
      </Drawer>
    </>
  )
}

export default ChatScreen