import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'dark',
        error: {
            main: '#FF6767'
        },
        success: {
            main: '#BCF9B2'
        },
        primary: {
            main: '#FF7700',
        },
        secondary: {
            main: '#000000',
        },
        neutral: {
            main: '#FFFFFF',
        },
        background: {
            default: '#000000', // Set background to black
            paper: '#141414', // Adjust this if you also want paper components to have a different background
        },
        dark: {
            dark: '#000000',
            otherdark: '#141414',
            main: '#000000',
            light: '#B7B7B7',
            otherlight: '#808080'
        }
    },
    containers: {
        main: {
            width: "100%",
            height: '100vh'
        }
    },
    tab: {
        width: '40%',
        fontWeight: 700,
        fontSize: '12px',
        paddingLeft: 1,
        paddingRight: 1,
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: '36px', // Set your desired height here
    },
    tabs: {
        minHeight: '10px', // Set your desired height here
        height: 'fit-content',
        width: '100%',
        '.MuiTabs-scrollButtons': {
            width: '24px',
            color: 'white'
        },
        '.MuiTabs-scroller': {
            scrollBehavior: 'smooth'
        }
    },
    typography: {
        // Override text colors to be light for better contrast and set fontWeight to 700 for all variants
        allVariants: {
            color: 'rgba(255, 255, 255, 0.95)', // Standard light text color for dark mode
            fontWeight: 700, // Apply fontWeight 700 for all text variants
        },
    },
    components: {
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    height: '2px',
                    backgroundColor: 'gray',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'white',
                    fontWeight: 700, // Ensure this remains 700 for button text
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    fontWeight: 700, // Ensure alerts also have fontWeight 700
                },
            },
        },
        // Customizing MuiBottomNavigationAction
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    // Apply fontWeight 700 and transform label text to uppercase
                    fontWeight: 700,
                    textTransform: 'uppercase',
                },
                label: {
                    // Ensure label text transformation and weight applies when the action is selected as well
                    '&.Mui-selected': {
                        fontWeight: 700,
                        textTransform: 'uppercase',
                    },
                },
            },
        },
    }
});

export default theme;
