import React, { useEffect, useState, useRef } from 'react';
import { Avatar, BottomNavigation, BottomNavigationAction, Box, Button, Typography, Divider, Container } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTheme } from '@emotion/react';
import HomeIcon from '@mui/icons-material/Home';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AddIcon from '@mui/icons-material/Add';
import SavingsIcon from '@mui/icons-material/Savings';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../redux/actions/modal';
import Drawer from '../drawer';
import CreateWager from '../wager/create';
import { setCreationModeToPost, setCreationWagerModeToFantasy, setCreationWagerModeToReal } from '../../redux/actions/wagers';
import { getLeagues } from '../../redux/actions/leagues';
import PeopleIcon from '@mui/icons-material/People';
import CreatePost from '../Post/create-post';
import LeagueHeaders from '../header/leagues-header';

const MobileBottomNavigation = ({ pathname }) => {

    const location = useLocation()

    let { matchId } = useParams(); // Extracting matchId from the URL

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const boxRef = useRef();

    const [value, setValue] = useState(pathname.includes("/match") ? "/dashboard" : pathname);

    const leagues = useSelector((state) => state.Leagues.league);
    const eventIdResponse = useSelector((state) => state.eventId)


    const wager_mode = useSelector((state) => state.Wagers)

    const theme = useTheme()

    const user = JSON.parse(localStorage.getItem('user'))

    const overallLeagues = useSelector((state) => state.Leagues);

    const changeScreensHandler = (event, newValue) => {
        if ('/create' === newValue) {
            setValue(newValue);
        }
        else {
            setValue(newValue);
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Optional: for smooth scrolling
            });
            setTimeout(() => {

                navigate(newValue)
                //router.push(newValue)
            }, 200); // adjust the delay as needed

        }
    }

    const blackList = ['/account-settings']

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (boxRef.current && !boxRef.current.contains(event.target)) {
                setValue(pathname)
                // Place your logic here that should run when clicked outside the Box
            }
        };
        // Attach the event listener
        document.addEventListener("mousedown", handleClickOutside);
        // Clean up
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleModal = (mode) => {
        switch (mode) {
            case 'real':
                dispatch(setCreationWagerModeToReal())
                break;
            case 'fantasy':
                dispatch(setCreationWagerModeToFantasy())
                break;
            case 'post':
                dispatch(setCreationModeToPost())
                break;
            default:
                break;
        }
        dispatch(openModal())
    }

    return (
        <>
            <Drawer contentKey="createWager">
                {
                    wager_mode?.mode === 'post'
                    &&
                    <CreatePost />
                }
                {
                    (
                        wager_mode?.mode === 'real'
                        ||
                        wager_mode?.mode === 'fantasy'
                    )
                    &&
                    <CreateWager />
                }

            </Drawer>
            {
                (
                    value === '/create'
                )
                &&
                <Box
                    ref={boxRef}
                    sx={{
                        position: 'absolute',
                        zIndex: '9999999999',
                        width: 'fit-content',
                        bottom: 64,
                        left: '50%',
                        transform: 'translateX(-50%)', // This centers the box horizontally
                        zIndex: '99',
                        padding: '12px',
                        borderRadius: '12px',
                        bgcolor: theme.palette.dark.dark,
                        border: `2px solid ${theme.palette.primary.dark}`
                    }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px'
                    }}>
                        <Typography sx={{ fontSize: '10px', color: theme.palette.dark.otherlight }}>CREATE A POST</Typography>
                        <Button onClick={() => toggleModal('post')} size='small' variant="text" sx={{ color: 'white', fontSize: '12px' }}>POST</Button>
                    </Box>

                    <Divider sx={{ width: '100%', mt: 1, mb: 2 }} />

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px'
                    }}>
                        <Typography sx={{ fontSize: '10px', color: theme.palette.dark.otherlight }}>CREATE A WAGER</Typography>
                        <Button onClick={() => toggleModal('real')} size='small' variant="text" sx={{ color: 'white', fontSize: '12px' }} disabled={true}>Real Money</Button>
                        <Button size='small' onClick={() => {
                            toggleModal('fantasy')
                        }} variant="text" sx={{ color: 'white', fontSize: '12px' }}>Fantasy</Button>
                    </Box>

                </Box>
            }
            {
                pathname === '/account-settings' ?
                    null :
                    <Box sx={{
                        display: {
                            xs: 'inline-block',
                            sm: 'inline-block',
                        },
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        boxSizing: 'border-box',
                        width: '100%',
                        zIndex: '9',
                        backgroundColor: theme.palette.dark.dark,
                    }}>

                        {
                            (pathname === '/dashboard' && false) &&
                            <Box sx={{
                                borderTop: `1px solid ${theme.palette.secondary.light}`,
                                pb: 1,
                                pt: 1
                            }}>
                                <LeagueHeaders
                                    leagues={leagues}
                                    eventInfo={eventIdResponse}
                                />
                            </Box>
                        }
                        <BottomNavigation
                            sx={{
                                borderTop: (pathname === '/dashboard' && false) ? 0 : `1px solid ${theme.palette.secondary.light}`,
                                position: 'relative',
                                width: '100%',
                                height: 'fit-content', // was 72px
                                backgroundColor: theme.palette.dark.dark,
                                pb: 0,
                                pt: 0,
                                '& .MuiBottomNavigationAction-root': { // This targets the root of the BottomNavigationAction
                                    flexDirection: 'column', // Stack items vertically
                                    justifyContent: 'center',
                                    pt: 1,
                                    pb: 3,
                                    height: 'fit-content' // Adjust padding to align items as needed
                                },
                                '& .MuiBottomNavigationAction-label': { // This targets the label within the BottomNavigationAction
                                    fontSize: '0.70rem', // Adjust the font size as needed
                                    whiteSpace: 'nowrap', // Prevents label from wrapping
                                },
                                '& .MuiSvgIcon-root': { // If you have to adjust the icon size
                                    fontSize: '1.25rem', // Adjust the font size of the icon as needed
                                },
                            }}
                            value={value}
                            showLabels
                            onChange={changeScreensHandler}
                        >
                            <BottomNavigationAction value='/dashboard' label="Home" icon={<HomeIcon />} />
                            <BottomNavigationAction value='/my-wagers' label="Wagers" icon={<HandshakeIcon />} />
                            <BottomNavigationAction value='/create' label="Create" icon={<AddIcon />} />
                            <BottomNavigationAction value='/social' label="Social" icon={<PeopleIcon />} />
                            <BottomNavigationAction value='/user-profile' label="Profile" icon={<Avatar src={user?.userLogo} sx={{ width: '22px', height: '22px' }} />} />
                        </BottomNavigation>
                    </Box>
            }
        </>
    );
}

export default MobileBottomNavigation;