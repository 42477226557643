import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AppLayout from '../../components/layouts/app'
import { useDispatch, useSelector } from 'react-redux'
import { chipsBalance } from '../../redux/actions/chips'
// import { useSocket } from './hooks/useSocket'
import { useSocket } from '../../components/hooks/useSocket'
import { Alert, Tab, Tabs } from '@mui/material'

// import GoogleAds from '../../components/Ads'

import { Box, Container, Divider, List, ListItem, ListItemButton, Stack, Typography } from '@mui/material'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import TitleHeader from '../../components/header/title-header'
//import chips from '../../assets/chips.svg'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PropTypes from 'prop-types';
import LeagueHeaders from '../../components/header/leagues-header'


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{
        }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function FantasyChipPanelTab(props) {
  const { children, fantasyValue, fantasyIndex, value, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={(fantasyValue !== fantasyIndex) && value === 0}
      id={`simple-tabpanel-${fantasyIndex}`}
      aria-labelledby={`simple-tab-${fantasyIndex}`}
      {...other}
    >
      {fantasyValue === fantasyIndex && value === 1 && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

FantasyChipPanelTab.propTypes = {
  children: PropTypes.node,
  fantasyIndex: PropTypes.number.isRequired,
  fantasyValue: PropTypes.number.isRequired,
};

const ChipBankItem = ({ data }) => {
  const theme = useTheme()
  return (
    <ListItem
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
      }}>
      <Typography variant='body2' sx={{ fontWeight: 700 }}>{data.title}</Typography>
      <Typography variant='body2' sx={{ fontWeight: 700, color: theme.palette.dark.otherlight }}>{data.data}</Typography>
    </ListItem>
  )
}


const ChipBankLinkItem = ({ data }) => {

  //const router = useRouter()
  const navigate = useNavigate()
  return (
    <ListItemButton
      // onClick={() => navigate(`/chip-bank/${data.path}`)}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        p: 0
      }}>
      <Typography variant='caption' sx={{ fontWeight: 700 }}>{data.title}</Typography>
      <KeyboardArrowRightIcon fontSize='small' />
    </ListItemButton>
  )
}

function ChipBank() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const socket = useSocket()
  const ref = useRef(null)
  const ref2 = useRef(null)
  const [show, setShow] = useState(false)
  const Sport = useSelector((state) => state.Sport.sport)
  const responseData = useSelector((state) => state.Chips)
  const chipsData = responseData?.chips?.result
  const unReadChatCount = useSelector((state) => state.newChatCount)
  // const [counterCount, setCounterCount] = useState(null)
  const SportData = useSelector((state) => state?.Sport?.sport)
  const CounterVal = useSelector((state) => state?.Counter?.counter)
  const [value, setValue] = React.useState(1);
  const overallLeagues = useSelector((state) => state.Leagues);
  const [fantasyValue, setFantasyValue] = React.useState(0);
  const leagues = useSelector((state) => state.Leagues.league);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // useEffect(() => {
  //   // const user = JSON.parse(localStorage.getItem('user'))
  //   // const interval = setInterval(() => {
  //   // socket.emit('getAllCounterCount', { uid: user?.id })
  //   socket.on('newCounter', (arg) => {
  //     if (arg?.CounterCount > 0) {
  //       setCounterCount(arg?.CounterCount)
  //     }
  //   })
  //   // }, 5000)
  //   // return () => clearInterval(interval) // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  // }, [socket])'
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    socket.on('newCounter', (arg) => {
      if (arg?.wagerCreatedBy === user?.id) {
        if (arg?.CounterCount > 0) {
          let temp = []
          // setCounterCount(arg?.CounterCount)
          if (CounterVal?.length === 0) {
            temp = [arg?.wagerId]
          } else {
            const temp2 = CounterVal?.filter((val) => val === arg.wagerId)
            if (temp2.length === 0) {
              temp = [...CounterVal, arg.wagerId]
            } else {
              temp = CounterVal
            }
            // temp = CounterVal?.filter((val) => val !== arg.wagerId)
            // temp = [...CounterVal, CounterVal?.filter((val) => val !== arg.wagerId)]
          }
          dispatch({
            type: 'SETCOUNTER',
            payload: temp
          })
        }
      }
    })
  }, [socket])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && ref2.current && !ref2.current.contains(event.target)) {
        // onClickOutside && onClickOutside();
        setShow(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    dispatch(chipsBalance({
      sportsId: SportData?.id
    }))
  }, [SportData?.id])

  const dataLinks = [
    /*
    {
      title: 'Transfer History',
      path: '/transfer-history'
    },
    {
      title: 'Get more chips',
      path: '/buy'
    },
    {
      title: 'Cash out my chips',
      path: '/'
    },
    {
      title: 'Get more wager credits',
      path: '/'
    }
    */
  ]

  const handleSportFilter = (id) => {
    console.log('changing -', id)

    setFantasyValue(id - 1);
  }
  console.log('fantasyValue -', overallLeagues)
  console.log("chipsData?.fantasyBank", chipsData)
  return (
    <AppLayout>

      <Container>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pt: 2,
          pb: 1,
          width: '100%',
        }}>
          <Typography variant='h5' sx={{ fontWeight: 700 }}>Chip Bank</Typography>
        </Box>
      </Container>

      <Box sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        top: '48px',
        background: theme.palette.dark.dark,
        zIndex: 99
      }}>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          allowScrollButtonsMobile
          scrollButtons="auto"
          sx={{
            width: '100%',
            '.MuiTabs-scrollButtons': {
              width: '24px',
              color: 'white'
            },
            '.MuiTabs-scroller': {
              scrollBehavior: 'smooth'
            }
          }}
          aria-label="basic tabs example">
          <Tab label="Real Money Chips" sx={{ width: '50%', fontWeight: 700 }} />
          <Tab label="Fantasy Chips" sx={{ width: '50%', fontWeight: 700 }} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Container sx={{ mt: 2 }}>
          <Alert severity='info'>Coming Soon!</Alert>
        </Container>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box sx={{
          pt: 2,
          pb: 2
        }}>
          <LeagueHeaders
            nohome
            fantasyChips
            handleSportFilter={handleSportFilter}
          />
        </Box>
      </CustomTabPanel>

      {
        leagues.slice(1).map((item, index) => (
          <FantasyChipPanelTab
            key={index}
            value={value}
            fantasyValue={overallLeagues?.indexSelected - 1}
            fantasyIndex={index}>

            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}>
              <Typography variant='body2' sx={{ fontWeight: 700 }}>Fantasy Chips Available</Typography>
              <Typography variant='body2' sx={{ fontWeight: 700, color: theme.palette.dark.otherlight }}>
                {chipsData?.fantasyBank[overallLeagues?.indexSelected - 1]?.chipsLostInWagers?.toLocaleString()}
              </Typography>
            </ListItem>

            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}>
              <Typography variant='body2' sx={{ fontWeight: 700 }}>Fantasy Chips Won in wagers</Typography>
              <Typography variant='body2' sx={{ fontWeight: 700, color: theme.palette.dark.otherlight }}>
                {chipsData?.fantasyBank[overallLeagues?.indexSelected - 1]?.chipsGainedInWagers?.toLocaleString()}
              </Typography>
            </ListItem>

            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}>
              <Typography variant='body2' sx={{ fontWeight: 700 }}>Fantasy Chips Lost in wagers</Typography>
              <Typography variant='body2' sx={{ fontWeight: 700, color: theme.palette.dark.otherlight }}>
                {chipsData?.fantasyBank[overallLeagues?.indexSelected - 1]?.chipsLostInWagers?.toLocaleString()}
              </Typography>
            </ListItem>

          </FantasyChipPanelTab>
        ))
      }

    </AppLayout>
  )
}

export default ChipBank
