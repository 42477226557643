import { Typography } from '@mui/material'
import React, { useState } from 'react'

const ReadMore = (children) => {
  const text = children?.children
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }

  return (
    <>
      {text.length > 60
        ? (
          <Typography className='text'>
            {isReadMore ? text?.slice(0, 60) : text}
            <span onClick={toggleReadMore} className="read-or-hide text-secondary">
              {isReadMore ? ' ...tap for more' : ' show less'}
            </span>
          </Typography>
        )
      : (
      <Typography className='text'>{text}</Typography>
      )
}

    </>
  )
}
export default ReadMore
