import { Tab, Tabs, Avatar, Box, Chip, Stack, Typography, Alert, IconButton, Button, SwipeableDrawer } from '@mui/material'
import CardLayout from '../layouts/card'
import { useTheme } from '@emotion/react'
import WagerTeamItem from './team-item'
import ProfilePill from './profile-pill'
import LockIcon from '@mui/icons-material/Lock';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { useNavigate } from 'react-router-dom'
import HandshakeIcon from '@mui/icons-material/Handshake';
import { useDispatch } from 'react-redux'
import { styled } from '@mui/system';
import ConfirmWrager from '../../pages/wagerboard/confirmWrager'
import WagerDetailsNew from '../../pages/wagerboard/wagerDetailsNew'
import PropTypes from 'prop-types';
import MyWragerDetails from '../../pages/my-wagers/myWragerDetails'
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector } from 'react-redux'
import { resetAcceptWagerError } from '../../redux/actions/wagerdetails'
import { setAdEventWagerToAccepted } from '../../redux/actions/leagues'
import React, { useEffect, useState } from 'react'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{
          bgcolor: "black",
        }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MySwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, .6)'
  },
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 40,
  height: 4,
  backgroundColor: theme.palette.dark.otherlight,
  borderRadius: 3,
  top: 8,
  left: 'calc(50% - 15px)',
  zIndex: '999px'
}));

const WagerCard = ({ data, likeFunction, adComponent }) => {

  const theme = useTheme()

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [liked, setLiked] = useState(false)

  const [value, setValue] = React.useState(1);

  const [likes, setLikes] = useState(10)

  const [toggle, setToggle] = useState(false)

  const [counter, setCounter] = useState(0)

  const [copyOfFilteredUsers, setCopyOfFilteredUsers] = useState(data?.wagerusers?.filter((users) => users?.userId === data?.user?.id).length < 1);

  const acceptedInfo = useSelector((state) => state?.wagerDetails?.data)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openDrawer = () => {
    setToggle(true)
  }

  const closeDrawer = () => {
    if (acceptedInfo?.id === JSON.stringify(data?.id ?? "undefined")) {
      dispatch(resetAcceptWagerError())
    }
    setToggle(false)
  }

  const handleCounter = () => {
    if (counter === 0) {
      setCounter(1)
    }
  }

  const handleLikedToggle = () => {
    if (liked) {
      setLikes(likes - 1);
      setLiked(false);
    } else {
      setLikes(likes + 1);
      setLiked(true);
    }
  };

  const ordinalSuffix = (number) => {
    let j = number % 10,
      k = number % 100;
    if (j == 1 && k != 11) {
      return 'st';
    }
    if (j == 2 && k != 12) {
      return 'nd';
    }
    if (j == 3 && k != 13) {
      return 'rd';
    }
    return 'th';
  }

  const WinnerSwitcher = (wagerTypeId) => {
    switch (wagerTypeId) {
      case 1:
        //1v1
        return <><span style={{ color: theme.palette.success.main }}>Winner</span>.</>
        break;

      case 2:
        //1v1
        if (data?.placement === data?.placement_range) {
          return <>Places {data?.placement}{ordinalSuffix(data?.placement)}.</>

        }
        else {
          //<>Places between <span style={{ color: theme.palette.success.main }}>{data.placement}{ordinalSuffix(data.placement)}</span> and <span style={{ color: theme.palette.success.main }}>{data.placement_range}{ordinalSuffix(data.placement_range)}</span ></>
          return <>Places between <br />{data?.placement}{ordinalSuffix(data?.placement)} & {data?.placement_range}{ordinalSuffix(data?.placement_range)}.</>
        }
        break;

      case 3:
        //1v1 SPREAD
        return <><span style={{ color: theme.palette.success.main }}>Winner</span> by {data?.spreadOptions_data?.option} spots.</>
        break;

      case 4:
        //1v1 SPREAD
        return <><span style={{ color: theme.palette.success.main }}>Wins</span> the holeshot.</>
        break;

      case 5:
        //Podium <>Places <span style={{ color: theme.palette.success.main }}>Podium</span></>
        return <>Places Podium</>
        break;

      case 6:
        return <span style={{ color: theme.palette.success.main, fontWeight: 700 }}>Winner</span>
        break;
      default:
        break;
    }
  }

  const LoserSwitcher = (wagerTypeId) => {
    switch (wagerTypeId) {
      case 1:
        //1v1
        return <><span style={{ color: theme.palette.error.main }}>Loser</span>.</>
        break;

      case 2:
        //1v1
        return "Loser."
        break;

      case 3:
        //1v1 SPREAD
        return `Loser.`
        break;

      case 6:
        return <><span style={{ color: theme.palette.error.main, fontWeight: 700 }}>Loser</span></>
        break;
      default:
        break;
    }
  }

  const itemSizes = {
    //color: theme.palette.dark.light,
    fontWeight: 700
  }

  //console.log('WAGER WAGER -', data)
  useEffect(() => {
    if (acceptedInfo?.id === JSON.stringify(data?.id ?? "undefined")) {
      dispatch(setAdEventWagerToAccepted(acceptedInfo))
      //dispatch(resetAcceptWagerError())  --> add this to when it closes!
    }
  }, [acceptedInfo]);

  return (
    <>


      {
        (
          data?.user?.id !== data?.createdBy && data?.wagerusers?.filter((users, index) => users?.userId === data?.user?.id && users?.status === 'accept').length
          &&
          adComponent
        )
          ?
          <>
            <Stack
              spacing={1}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                width: '100%',
                height: '100%'
              }}>

              <Typography variant='h6'>WAGER ACCEPTED</Typography>
              <Button
                onClick={() => navigate('/my-wagers')}
                variant='outlined'
              >
                Go to my wagers
              </Button>
            </Stack>
          </>
          :
          <>
            <MySwipeableDrawer
              anchor={'bottom'}
              open={toggle}
              onClose={() => {
                if (acceptedInfo?.id === JSON.stringify(data?.id ?? "undefined")) {
                  handleCounter()
                }
                dispatch(resetAcceptWagerError())
                closeDrawer()
              }}
              onOpen={() => openDrawer()}
              PaperProps={{
                style: {
                  height: '80vh',
                  width: '100%',
                  backgroundColor: 'black',
                  boxShadow: 'none',
                  borderTop: 0,
                  borderRadius: '8px 8px 0 0',
                  borderBottom: '0 !important',
                  zIndex: '999999999999',
                  borderTop: `3px solid ${theme.palette.dark.otherdark}`
                },
              }}
            >
              <Stack
                alignItems={"center"}
                sx={{
                  //padding: '32px 24px 24px 24px',
                  minHeight: '100%',
                  width: '100vw',
                  backgroundColor: 'black',
                  position: 'relative'
                }}>
                <Box sx={{
                  width: '100%',

                  backgroundColor: 'black',
                  maxWidth: '450px'
                }}>
                  <Tabs
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    sx={{
                      width: '100%',
                      position: 'sticky',
                      background: theme.palette.dark.dark,
                      top: 0,
                      zIndex: '99',
                      '.MuiTabs-scrollButtons': {
                        width: '24px',
                        color: 'white'
                      },
                      '.MuiTabs-scroller': {
                        scrollBehavior: 'smooth'
                      }
                    }}
                    aria-label="basic tabs example">
                    <Tab label="Comments" sx={{ width: '45%', fontWeight: 700 }} />
                    <Tab label="Wager Details" sx={{ width: '45%', fontWeight: 700 }} />
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        width: '10%'
                      }}>
                      <IconButton onClick={() => {
                        if (acceptedInfo?.id === JSON.stringify(data?.id ?? "undefined")) {
                          handleCounter()
                        }
                        closeDrawer()
                        dispatch(resetAcceptWagerError())
                      }}>
                        <ClearIcon />
                      </IconButton>
                    </Stack>

                  </Tabs>

                  <CustomTabPanel value={value} index={0}>

                    <Box sx={{
                      bgcolor: 'black',
                      width: '100%',
                      height: 'fit-content',
                      position: 'absolute',
                      left: 0,
                      pt: 8,
                      top: 0
                    }}>
                      {
                        toggle &&
                        <MyWragerDetails id={JSON.stringify(data?.id ?? "undefined")} />
                      }
                    </Box>
                  </CustomTabPanel>


                  <CustomTabPanel value={value} index={1}>
                    <Box sx={{
                      bgcolor: theme.palette.dark.otherdark,
                      width: '100%',
                      height: 'fit-content',
                      position: 'absolute',
                      left: 0,
                      pt: 6,
                      top: 0
                    }}>
                      {
                        toggle && (data?.wagerStatus?.h > 0 || data?.wagerStatus?.m > 0) &&
                        <>
                          {
                            data?.user?.id === data?.createdBy
                              ?
                              <>
                                <WagerDetailsNew id={JSON.stringify(data?.id ?? "undefined")} closeFunction={() => closeDrawer()} />
                              </>
                              : (data?.user?.id !== data?.createdBy) && (data?.wagerusers?.length < 1) && (data?.maxPeople !== data?.peopleJoined)
                                ?
                                <>
                                  <ConfirmWrager id={JSON.stringify(data?.id ?? "undefined")} functionToClose={() => closeDrawer()} functionIncrementCounter={() => handleCounter()} />
                                </>
                                :
                                (data?.maxPeople !== data?.peopleJoined) && data?.wagerusers?.filter((users, index) => data?.users?.userId === data?.user?.id && data?.users?.status === 'accept').length && counter === 1
                                  ?
                                  <>
                                    <WagerDetailsNew id={JSON.stringify(data?.id ?? "undefined")} functionToClose={() => closeDrawer()} />
                                  </>
                                  :
                                  data?.maxPeople === data?.peopleJoined && data?.user?.id === data?.createdBy && counter === 1
                                    ?
                                    <>
                                      <WagerDetailsNew id={JSON.stringify(data?.id ?? "undefined")} closeFunction={() => closeDrawer()} />
                                    </>
                                    :
                                    data?.wagerusers?.filter((users, index) => users?.userId === data?.user?.id && users?.status === 'accept').length && (data?.maxPeople === data?.peopleJoined) && counter === 1
                                      ?
                                      <>
                                        <WagerDetailsNew id={JSON.stringify(data?.id ?? "undefined")} closeFunction={() => closeDrawer()} />
                                      </>
                                      :
                                      data?.wagerusers?.filter((users, index) => users?.userId !== data?.user?.id).length && (data?.maxPeople === data?.peopleJoined) && counter === 1
                                        ?
                                        <>
                                          <WagerDetailsNew id={JSON.stringify(data?.id ?? "undefined")} closeFunction={() => closeDrawer()} />
                                        </>
                                        :
                                        data?.wagerusers?.filter((users, index) => users?.userId === data?.user?.id).length < 1 && (data?.maxPeople !== data?.peopleJoined) || (counter === 0 && copyOfFilteredUsers)
                                          ?
                                          <>
                                            <ConfirmWrager id={JSON.stringify(data?.id ?? "undefined")} functionToClose={() => closeDrawer()} functionIncrementCounter={() => handleCounter()} />
                                          </>
                                          :
                                          <>
                                            <WagerDetailsNew id={JSON.stringify(data?.id ?? "undefined")} closeFunction={() => closeDrawer()} />
                                          </>
                          }
                        </>
                      }
                      {
                        toggle && (data?.wagerStatus?.h <= 0 && data?.wagerStatus?.m <= 0) &&
                        <>
                          <WagerDetailsNew id={JSON.stringify(data?.id ?? "undefined")} functionToClose={() => closeDrawer()} />
                        </>
                      }
                    </Box>

                  </CustomTabPanel>

                </Box>

              </Stack>
            </MySwipeableDrawer>

            <CardLayout>

              <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                gap: '12px'
              }}>
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: '20px' }}>

                  <Box sx={{
                    display: 'flex',
                    gap: '12px'
                  }}>

                    <Avatar
                      onClick={() => navigate(`/user-profile/${data?.createdBy_data.id}`)}
                      src={data?.createdBy_data?.userLogo || null}
                    />

                    <Box
                      sx={{
                        height: '40px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start'
                      }}>
                      <Typography variant='body2' sx={{ color: 'white', mt: -.7 }}>@{data?.createdBy_data?.username}</Typography>
                      <Stack direction='row' spacing={1}>
                        {
                          data?.event_data?.sports_data?.type != 'match'
                          &&
                          <Chip
                            label={data?.event_data?.name?.toUpperCase()}
                            sx={{
                              padding: '1px 0',
                              height: 'fit-content',
                              width: 'fit-content',
                              fontSize: '12px',
                              fontWeight: 700
                            }} />
                        }
                        <Chip
                          label={data?.wagerType_data?.name?.toUpperCase()}
                          sx={{
                            padding: '1px 0',
                            height: 'fit-content',
                            width: 'fit-content',
                            fontSize: '12px',
                            fontWeight: 700
                          }} />

                        <Chip
                          label={data?.mode === "fantasy" ? "FANTASY" : "REAL MONEY"}
                          sx={{
                            padding: '1px 0',
                            height: 'fit-content',
                            width: 'fit-content',
                            fontSize: '12px',
                            fontWeight: 700
                          }} />
                      </Stack>
                    </Box>
                  </Box>

                  {
                    data?.status === 'closed' && data?.result === 'pending' ?
                      < Alert severity="error" iconMapping={{
                        error: <LockIcon fontSize="inherit" />,
                      }}>
                        This wager is closed and pending results.
                      </Alert> : null
                  }



                  {
                    data?.result != 'pending' && data?.result === 'refunded' &&
                    < Alert severity={"info"} iconMapping={{
                      error: <LockIcon fontSize="inherit" />,
                    }}>
                      Wager was refunded!
                    </Alert>
                  }

                  {
                    data?.user?.id !== data?.createdBy && data?.wagerusers?.filter((users, index) => users?.userId === data?.user?.id && users?.status === 'accept').length ?
                      < Alert severity="success" iconMapping={{
                        success: <HandshakeIcon fontSize="inherit" />,
                      }}>
                        Wager Accepted
                      </Alert> : null
                  }

                  {
                    data?.result != 'pending' && data?.result != 'refund' &&
                    < Alert severity={
                      data?.result === 'winner'
                        ?
                        data?.user?.id === data?.createdBy ?
                          "success"
                          :
                          data?.wagerusers?.some(userObj => userObj?.userId === data?.user?.id)
                            ?
                            "error"
                            :
                            "success"
                        :
                        data?.user?.id === data?.createdBy ?
                          `error`
                          :
                          data?.wagerusers?.some(userObj => userObj?.userId === data?.user?.id)
                            ?
                            `success`
                            :
                            `error`
                    } iconMapping={{
                      error: <LockIcon fontSize="inherit" />,
                    }}>
                      {
                        data?.result === 'winner'
                          ?
                          data?.user?.id === data?.createdBy ?
                            `You won this wager.`
                            :
                            data?.wagerusers?.some(userObj => userObj?.userId === data?.user?.id)
                              ?
                              `You lost this wager.`
                              :
                              `@${data?.createdBy_data?.username} won this wager.`
                          :
                          data?.user?.id === data?.createdBy ?
                            `You lost this wager.`
                            :
                            data?.wagerusers?.some(userObj => userObj?.userId === data?.user?.id)
                              ?
                              `You won this wager.`
                              :
                              `@${data?.createdBy_data?.username} lost this wager.`
                      }
                    </Alert>
                  }
                  {
                    data?.result === 'refund' &&
                    < Alert severity={"info"} iconMapping={{
                      error: <LockIcon fontSize="inherit" />,
                    }}>
                      {
                        data?.user.id === data?.createdBy
                          ?
                          `You were refunded.`
                          :
                          `@${data?.createdBy_data?.username} was refunded.`
                      }
                    </Alert>
                  }
                  {
                    //BODY
                    data?.caption ?
                      <Typography variant='body1' sx={{ color: 'white' }}>{data?.caption}</Typography> : null
                  }

                  <Box sx={{
                    width: '100%',
                    bgcolor: theme.palette.dark.otherdark,
                    borderRadius: '12px',
                    p: 1.5,
                    boxSizing: 'border-box',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}>

                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: data?.wagerType_data?.id === 4 || data?.wagerType_data?.id === 5 || data?.wagerType_data?.id === 2 ? 'center' : 'center',
                      gap: '8px'
                    }}>
                      <WagerTeamItem data={{
                        racer: data?.event_data?.sports_data?.type === "match" ? data.teamsFor_data : data.racerFor_data,
                        prediction: WinnerSwitcher(data?.wagerType_data?.id)
                      }} />
                      {
                        data?.wagerType_data?.id === 4 || data?.wagerType_data?.id === 5 || data?.wagerType_data?.id === 2 ?
                          null :
                          <WagerTeamItem data={{
                            racer: data?.event_data?.sports_data?.type === "match" ? data.teamsAgainst_data : data.racerAgainst_data,
                            prediction: LoserSwitcher(data?.wagerType_data?.id)
                          }} />
                      }
                    </Box>

                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                      textAlign: 'right',
                      hegiht: '100%',
                      //bgcolor: 'red'
                      gap: '24px'
                    }}>
                      <ProfilePill max={data?.maxPeople} wagerId={data?.id} items={data?.wagerusers} size={24} wagerStatus={(data?.wagerStatus?.h > 0 || data?.wagerStatus?.m > 0)} />
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-end'
                      }}>
                        {
                          !!data?.division_data &&
                          <Typography variant='body2' sx={itemSizes}>{data?.division_data?.name}</Typography>
                        }
                        {
                          //                <Typography variant='caption' sx={itemSizes}>{data?.event_data?.name}</Typography>
                        }
                        <Typography variant='body2' sx={itemSizes}>{data?.multiplier_data?.name} Multiplier</Typography>
                        <Typography variant='body2' sx={{ ...itemSizes, color: 'white' }}>{data?.chipsBet} chips to join</Typography>
                      </Box>
                    </Box>
                  </Box>


                  {
                    !!!adComponent
                      ?
                      <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>

                        <Box sx={{
                          display: 'flex',
                          gap: '20px'
                        }}>
                          <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px'
                          }}>
                            <IconButton sx={{ padding: 0 }} onClick={() => {
                              handleLikedToggle()
                              likeFunction(data?.id, data?.index, data?.isWagerLikeByMe)
                            }}>
                              {
                                (data?.likeId?.includes(data?.id)) ? < FavoriteIcon fontSize='small' /> : <FavoriteBorderIcon fontSize='small' />
                              }
                            </IconButton>

                            <Typography variant='body1' sx={{ fontWeight: 700 }}>{(data?.likeId.includes(data?.id) && data?.isWagerLikeByMe) ? data?.WagerLikeCount : (!data?.likeId.includes(data?.id) && data?.isWagerLikeByMe) ? data?.WagerLikeCount - 1 : (!data?.likeId.includes(data?.id) && !data?.isWagerLikeByMe) ? data?.WagerLikeCount : (data?.likeId.includes(data?.id) && !data?.isWagerLikeByMe) ? data?.WagerLikeCount + 1 : data?.WagerLikeCount}</Typography>
                          </Box>

                          <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px'
                          }}>
                            <IconButton
                              sx={{ padding: 0 }}
                              onClick={() => {
                                openDrawer()
                                setValue(0)
                              }}
                            //onClick={() => navigate(`/comments/${data?.id}`)}
                            >
                              < TextsmsIcon fontSize='small' sx={{ marginTop: '2.5px' }} />
                            </IconButton>
                            <Typography variant='body1' sx={{ fontWeight: 700 }}>{data?.CommentCount}</Typography>
                          </Box>
                        </Box>

                        <Stack spacing={1} direction='row'>
                          {
                            (data?.wagerStatus.h >= 0 && data?.wagerStatus.m >= 0) &&
                            <>
                              {
                                data?.user?.id === data?.createdBy
                                  ?
                                  <>
                                    <Button
                                      onClick={() => {
                                        openDrawer()
                                        setValue(1)
                                      }}
                                      //onClick={() => navigate(`/wager-details-new/${data?.id}`)}
                                      size='small'
                                      color='primary'
                                      variant="outlined">
                                      <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                        View Details
                                      </Typography>
                                    </Button>
                                  </>
                                  : (data?.user?.id !== data?.createdBy) && (data?.wagerusers?.length < 1) && (data?.maxPeople !== data?.peopleJoined)
                                    ?
                                    <>
                                      <Button
                                        onClick={() => {
                                          openDrawer()
                                          setValue(1)
                                        }}
                                        size='small'
                                        color='primary'
                                        variant="contained">
                                        <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                          Accept Wager
                                        </Typography>
                                      </Button>
                                    </>
                                    :
                                    (data?.maxPeople !== data?.peopleJoined) && data?.wagerusers?.filter((users, index) => data?.users?.userId === data?.user?.id && data?.users?.status === 'accept').length
                                      ?
                                      <>
                                        <Button

                                          onClick={() => {
                                            openDrawer()
                                            setValue(1)
                                          }}
                                          size='small'
                                          color='primary'
                                          variant="outlined">
                                          <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                            View Details
                                          </Typography>
                                        </Button>
                                      </>
                                      :
                                      data?.maxPeople === data?.peopleJoined && data?.user?.id === data?.createdBy
                                        ?
                                        <>
                                          {
                                            /*
            <Button
                                            onClick={() => navigate(`/wager-details-new/${data?.id}`)}
                                            size='small'
                                            color='primary'
                                            variant="contained">
                                            <Typography variant='caption' sx={{ fontWeight: 700 }}>
                                              Wager is closed
                                            </Typography>
                                          </Button>
                                            */
                                          }
                                          <Button

                                            onClick={() => {
                                              openDrawer()
                                              setValue(1)
                                            }}

                                            size='small'
                                            color='primary'
                                            variant="outlined">
                                            <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                              View Details
                                            </Typography>
                                          </Button>
                                        </>
                                        :
                                        data?.wagerusers?.filter((users, index) => users?.userId === data?.user?.id && users?.status === 'accept').length && (data?.maxPeople === data?.peopleJoined)
                                          ?
                                          <>
                                            <Button

                                              onClick={() => {
                                                openDrawer()
                                                setValue(1)
                                              }}
                                              size='small'
                                              color='primary'
                                              variant="outlined">
                                              <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                                View Details
                                              </Typography>
                                            </Button>
                                          </>
                                          :
                                          data?.wagerusers?.filter((users, index) => users?.userId !== data?.user?.id).length && (data?.maxPeople === data?.peopleJoined)
                                            ?
                                            <>
                                              {
                                                /*
                                              <Button
                                                onClick={() => navigate(`/wager-details-new/${data?.id}`)}
                                                size='small'
                                                color='primary'
                                                variant="contained">
                                                <Typography variant='caption' sx={{ fontWeight: 700 }}>
                                                  Wager is closed
                                                </Typography>
                                              </Button>
                                                */
                                              }
                                              <Button

                                                size='small'
                                                onClick={() => {
                                                  openDrawer()
                                                  setValue(1)
                                                }}
                                                color='primary'
                                                variant="outlined">
                                                <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                                  View Details
                                                </Typography>
                                              </Button>
                                            </>
                                            :
                                            data?.wagerusers?.filter((users, index) => users?.userId === data?.user?.id).length < 1 && (data?.maxPeople !== data?.peopleJoined)
                                              ? <Button

                                                onClick={() => {
                                                  openDrawer()
                                                  setValue(1)
                                                }}
                                                size='small'
                                                color='primary'
                                                variant="contained">
                                                <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                                  Accept Wager
                                                </Typography>
                                              </Button>
                                              :
                                              <>
                                                {
                                                  /*
              
                                                  <Button
                                                    onClick={() => navigate(`/wager-details-new/${data?.id}`)}
                                                    size='small'
                                                    color='primary'
                                                    disable
                                                    variant="contained">
                                                    <Typography variant='caption' sx={{ fontWeight: 700 }}>
                                                      Wager is closed
                                                    </Typography>
                                                  </Button>
                                                  */
                                                }
                                                <Button

                                                  size='small'
                                                  onClick={() => {
                                                    openDrawer()
                                                    setValue(1)
                                                  }}
                                                  color='primary'
                                                  variant="outlined">
                                                  <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                                    View Details
                                                  </Typography>
                                                </Button>
                                              </>

                              }
                            </>
                          }
                          {
                            (data?.wagerStatus?.h < 0 || data?.wagerStatus?.m < 0) &&
                            <>
                              <Button

                                onClick={() => {
                                  openDrawer()
                                  setValue(1)
                                }}
                                size='small'
                                color='primary'
                                variant="outlined">
                                <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                  View Details
                                </Typography>
                              </Button>
                            </>
                          }
                        </Stack>

                      </Box>
                      :

                      <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                      }}>

                        <Stack spacing={1} direction='row' sx={{ width: '100%' }}>
                          {
                            (data?.wagerStatus.h >= 0 && data?.wagerStatus.m >= 0) &&
                            <>
                              {
                                data?.user?.id === data?.createdBy
                                  ?
                                  <>
                                    <Button
                                      fullWidth
                                      onClick={() => {
                                        openDrawer()
                                        setValue(1)
                                      }}
                                      //onClick={() => navigate(`/wager-details-new/${data?.id}`)}
                                      size='small'
                                      color='primary'
                                      variant="outlined">
                                      <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                        View Details
                                      </Typography>
                                    </Button>
                                  </>
                                  : ((data?.user?.id !== data?.createdBy) && (data?.wagerusers?.length < 1) && (data?.maxPeople !== data?.peopleJoined))
                                    ?
                                    <>
                                      <Button
                                        fullWidth
                                        onClick={() => {
                                          openDrawer()
                                          setValue(1)
                                        }}
                                        size='small'
                                        color='primary'
                                        variant="contained">
                                        <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                          Accept Wager
                                        </Typography>
                                      </Button>
                                    </>
                                    :
                                    (data?.maxPeople !== data?.peopleJoined) && data?.wagerusers?.filter((users, index) => data?.users?.userId === data?.user?.id && data?.users?.status === 'accept').length
                                      ?
                                      <>
                                        <Button
                                          fullWidth
                                          onClick={() => {
                                            openDrawer()
                                            setValue(1)
                                          }}
                                          size='small'
                                          color='primary'
                                          variant="outlined">
                                          <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                            View Details
                                          </Typography>
                                        </Button>
                                      </>
                                      :
                                      data?.maxPeople === data?.peopleJoined && data?.user?.id === data?.createdBy
                                        ?
                                        <>
                                          {
                                            /*
            <Button
                                            onClick={() => navigate(`/wager-details-new/${data?.id}`)}
                                            size='small'
                                            color='primary'
                                            variant="contained">
                                            <Typography variant='caption' sx={{ fontWeight: 700 }}>
                                              Wager is closed
                                            </Typography>
                                          </Button>
                                            */
                                          }
                                          <Button
                                            fullWidth
                                            onClick={() => {
                                              openDrawer()
                                              setValue(1)
                                            }}

                                            size='small'
                                            color='primary'
                                            variant="outlined">
                                            <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                              View Details
                                            </Typography>
                                          </Button>
                                        </>
                                        :
                                        data?.wagerusers?.filter((users, index) => users?.userId === data?.user?.id && users?.status === 'accept').length && (data?.maxPeople === data?.peopleJoined)
                                          ?
                                          <>
                                            <Button
                                              fullWidth
                                              onClick={() => {
                                                openDrawer()
                                                setValue(1)
                                              }}
                                              size='small'
                                              color='primary'
                                              variant="outlined">
                                              <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                                View Details
                                              </Typography>
                                            </Button>
                                          </>
                                          :
                                          data?.wagerusers?.filter((users, index) => users?.userId !== data?.user?.id).length && (data?.maxPeople === data?.peopleJoined)
                                            ?
                                            <>
                                              {
                                                /*
                                              <Button
                                                onClick={() => navigate(`/wager-details-new/${data?.id}`)}
                                                size='small'
                                                color='primary'
                                                variant="contained">
                                                <Typography variant='caption' sx={{ fontWeight: 700 }}>
                                                  Wager is closed
                                                </Typography>
                                              </Button>
                                                */
                                              }
                                              <Button
                                                fullWidth
                                                size='small'
                                                onClick={() => {
                                                  openDrawer()
                                                  setValue(1)
                                                }}
                                                color='primary'
                                                variant="outlined">
                                                <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                                  View Details
                                                </Typography>
                                              </Button>
                                            </>
                                            :
                                            data?.wagerusers?.filter((users, index) => users?.userId === data?.user?.id).length < 1 && (data?.maxPeople !== data?.peopleJoined)
                                              ? <Button
                                                fullWidth
                                                onClick={() => {
                                                  openDrawer()
                                                  setValue(1)
                                                }}
                                                size='small'
                                                color='primary'
                                                variant="contained">
                                                <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                                  Accept Wager
                                                </Typography>
                                              </Button>
                                              :
                                              <>
                                                {
                                                  /*
              
                                                  <Button
                                                    onClick={() => navigate(`/wager-details-new/${data?.id}`)}
                                                    size='small'
                                                    color='primary'
                                                    disable
                                                    variant="contained">
                                                    <Typography variant='caption' sx={{ fontWeight: 700 }}>
                                                      Wager is closed
                                                    </Typography>
                                                  </Button>
                                                  */
                                                }
                                                <Button
                                                  fullWidth
                                                  size='small'
                                                  onClick={() => {
                                                    openDrawer()
                                                    setValue(1)
                                                  }}
                                                  color='primary'
                                                  variant="outlined">
                                                  <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                                    View Details
                                                  </Typography>
                                                </Button>
                                              </>

                              }
                            </>
                          }
                          {
                            (data?.wagerStatus?.h < 0 || data?.wagerStatus?.m < 0) &&
                            <>
                              <Button
                                fullWidth
                                onClick={() => {
                                  openDrawer()
                                  setValue(1)
                                }}
                                size='small'
                                color='primary'
                                variant="outlined">
                                <Typography variant='body2' sx={{ fontWeight: 700 }}>
                                  View Details
                                </Typography>
                              </Button>
                            </>
                          }
                        </Stack>

                      </Box>
                  }

                </Box>
              </Box>

            </CardLayout >
          </>
      }

    </>
  )
}

export default WagerCard