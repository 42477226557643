import { useTheme } from '@emotion/react'
import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../../src/images/betzonlogo.svg'


const HowItWorks = () => {

    const navigate = useNavigate()

    const theme = useTheme()
    
    const listStyle = {
        listStyle: 'disc',
        paddingLeft: "30px",
        color: theme.palette.dark.otherlight
    }
    const nextScreenHandler = () => {
        navigate('/dashboard')
    }

    return (
        <Container
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                pb: 2,
                pt: 2,
                color: theme.palette.dark.otherlight
            }}
        >
            <img src={logo} style={{
                width: '48px'
            }} />
            <Box sx={{
                bgcolor: theme.palette.dark.otherdark,
                p: 2,
                borderRadius: '8px',
                border: `2px solid ${theme.palette.primary.main}`,
                display: 'flex',

                flexDirection: 'column',
                justifyContent: 'flex-start',
                gap: '16px',
                alignItems: 'center',

            }}>
                <Box sx={{
                    width: '100%'
                }}>
                    <Typography variant='h6'>How it works!</Typography>
                </Box>

                <Box sx={{
                    width: '100%'
                }}>
                    <Typography variant='body1'>Wager against real people in the  <br />following sports:</Typography>
                    <ul style={listStyle}>
                        <li><Typography variant='caption'>NASCAR</Typography></li>
                        <li><Typography variant='caption'>Motocross/Supercross</Typography></li>
                        <li><Typography variant='caption'>MotoGP (Coming Soon)</Typography></li>
                        <li><Typography variant='caption'>Formula One (Coming Soon)</Typography></li>
                        <li><Typography variant='caption'>MLB (Coming Soon)</Typography></li>
                    </ul>
                </Box>

                <Box>
                    <Typography variant='body1'>The Rules:</Typography>
                    <ul style={listStyle}>
                        <li><Typography variant='caption'>Everyone starts with <strong>2,000 chips</strong></Typography></li>
                        <li><Typography variant='caption'>Wager for chips against other real people!</Typography></li>
                        <li><Typography variant='caption'>At the end of each challenge, the user with the most wagers won, <strong>wins the cash prize</strong>!</Typography></li>
                        <li><Typography variant='caption'>Feel free to comment on peoples wagers! Or even message them!</Typography></li>
                    </ul>
                </Box>
                <Button
                    onClick={() => {
                        window.open("https://www.motobookie.com/help-center/motobookie-challenge", '_blank', 'noopener,noreferrer');
                    }}
                    variant='outlined' sx={{ width: '100%' }}>
                    Learn more about the challenge!
                </Button>
            </Box>
            <Button
                onClick={() => nextScreenHandler()}
                sx={{ width: '100%' }}
                variant='contained'>
                I understand!
            </Button>
        </Container>
    )
}

export default HowItWorks

/*

        <React.Fragment>
            <div className='fullScreen bgDark' style={{
                color: 'white',
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                position: 'relative',
                alignItems: 'center'
            }}>

                <div></div>
                <div style={{
                    border: '2px solid white',
                    background: '#0F1823',
                    padding: '16px',
                    borderRadius: '12px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '36px'
                }}>

                    <div>
                        <h1 style={{
                            fontWeight: 700,
                            fontSize: '24px'
                        }}>How it works</h1>
                    </div>

                    <ul style={listStyle}>
                        <p style={{
                            fontWeight: 700,
                            fontSize: '16px'
                        }}>Wager against real people in the <br />following sports:</p>
                        <li>Formula One</li>
                        <li>MotoGP</li>
                        <li>NASCAR</li>
                        <li>Motocross</li>
                        <li>Supercross</li>
                    </ul>

                    <ul style={listStyle}>
                        <p style={{
                            fontWeight: 700,
                            fontSize: '16px'
                        }}>The Rules:</p>
                        <li>Everyone starts with <strong>1,000 chips</strong></li>
                        <li>Wager for chips against other real people!</li>
                        <li>At the end of the season, the user with the most chips wins <strong>$1,000</strong>!</li>
                        <li>Feel free to comment on peoples wagers! Or even message them!</li>
                    </ul>

                </div>


                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    gap: '6px'
                }}>
                    <button
                        onClick={() => nextScreenHandler()}
                        style={{
                            background: '#0083E7',
                            color: "white",
                            bottom: '16px',
                            border: 'none',
                            width: '100%',
                            fontSize: '18px',
                            fontWeight: 700,
                            borderRadius: '6px',
                            paddingTop: '8px',
                            paddingBottom: '8px'
                        }}>
                        I understand!
                    </button>
                    <p>Questions? <a className='link-white' style={{ fontWeight: 700 }} href="mailto:support@motobookie.com?subject=Need%20Help">Contact Support!</a></p>
                </div>

            </div>
        </React.Fragment>
*/
