import { GET_ALL_CHAT_REQUEST, GET_ALL_CHAT, GET_ALL_CHAT_ERROR, GET_ALL_MESSAGES_REQUEST, GET_ALL_MESSAGES, GET_ALL_MESSAGES_ERROR, CREATE_CHAT_REQUEST, CREATE_CHAT, CREATE_CHAT_ERROR, CREATE_MESSAGE_REQUEST, CREATE_MESSAGE, CREATE_MESSAGE_ERROR, RESET_CHAT } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import ChatsServices from '../../../services/chat'

export const getAllChats = createAsyncThunk(
  '/chat/getAllChat',
  async ({ uid, skip, limit }, thunkAPI) => {
    thunkAPI.dispatch({
      type: GET_ALL_CHAT_REQUEST
    })
    try {
      const data = await ChatsServices.getAllChats(uid, skip, limit)
      thunkAPI.dispatch({
        type: GET_ALL_CHAT,
        payload: data && data?.data
      })
      return { chats: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: GET_ALL_CHAT_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }

)

export const getAllMessages = createAsyncThunk(
  '/chat/getAllMessages',
  async ({ chatId }, thunkAPI) => {
    thunkAPI.dispatch({
      type: GET_ALL_MESSAGES_REQUEST
    })
    try {
      const data = await ChatsServices.getAllMessages(chatId)
      thunkAPI.dispatch({
        type: GET_ALL_MESSAGES,
        payload: data && data?.data
      })
      return { chats: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: GET_ALL_MESSAGES_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }

)

export const createChat = createAsyncThunk(
  '/chat/createChat',
  async ({ type, participants }, thunkAPI) => {
    thunkAPI.dispatch({
      type: CREATE_CHAT_REQUEST
    })
    try {
      const data = await ChatsServices.createChat(type, participants)
      thunkAPI.dispatch({
        type: CREATE_CHAT,
        payload: data && data?.data?.result
      })
      return { chats: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: CREATE_CHAT_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }

)

export const createMessage = createAsyncThunk(
  '/chat/createMessage',
  async ({ chatId, message, userId }, thunkAPI) => {
    thunkAPI.dispatch({
      type: CREATE_MESSAGE_REQUEST
    })
    try {
      const data = await ChatsServices.createMessage(chatId, message, userId)
      thunkAPI.dispatch({
        type: CREATE_MESSAGE,
        payload: data && data?.data
      })
      return { chats: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: CREATE_MESSAGE_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }

)

export const resetChat = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_CHAT
    })
  }
}

export const setChat = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_CHAT,
      payload: data
    })
  }
}
