import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import barcode from '../../images/barcode.svg'
import React, { useRef, useEffect, useState } from 'react';
import logo from "../../images/betzonlogo.svg"
import { useTheme } from '@emotion/react'
import { alpha } from '@mui/material/styles';
import Lottie from 'react-lottie-player';
import handshake from '../animations/lottieJSON/handshake.json';
import gsap from 'gsap';

const Receipt = ({ data }) => {

    const theme = useTheme()

    const lottieRef = useRef(null); // Create a ref for the Lottie component

    const [firstTimeViewed, setFirstTimeViewed] = useState(true)

    useEffect(() => {
        if (firstTimeViewed) {
            lottieRef.current.addEventListener('complete', () => {
                gsap.to("#handshakeLogo", {
                    duration: .25,
                    opacity: 0,
                    y: -100,
                });
                gsap.to("#wagerAcceptedTitle", {
                    duration: 3,
                    opacity: 1,
                    y: 0,
                    ease: "elastic.out(.5, 0.1)"
                });
            });
            return () => {
                // Cleanup listener when the component unmounts to avoid memory leaks
                if (lottieRef.current) {
                    lottieRef.current.removeEventListener('complete');
                }
            };
        }
    }, []);
    
    return (
        <>

            {
                firstTimeViewed &&
                <Stack
                    id="handshakeLogo"
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                        width: '235px',
                        height: '235px',
                        border: `4px solid ${theme.palette.primary.main}`,
                        bgcolor: alpha(theme.palette.primary.dark, 0.2),
                        borderRadius: '200px',
                        position: 'absolute',
                    }}>
                    <Stack
                        alignItems={"center"}
                        sx={{
                            mb: -1
                        }}>
                        <Lottie
                            ref={lottieRef}
                            speed={0.5}
                            animationData={handshake}
                            play
                            loop={false} // Set loop to false to ensure it only plays once
                            style={{ marginBottom: -12, height: 200 }}
                            background="transparent"
                        />
                    </Stack>
                </Stack>
            }

            <Stack
                id="wagerAcceptedTitle"
                spacing={4}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    opacity: firstTimeViewed ? 0 : 1,
                    transform: firstTimeViewed ? 'translateY(200px)' : 0, // Move the Stack up by 100px
                    border: `4px solid ${theme.palette.primary.main}`,
                    bgcolor: alpha(theme.palette.primary.dark, 0.2),
                    borderRadius: '16px',
                    p: 2,
                    width: "100%",
                }}>

                <Stack alignItems={"center"} spacing={1} sx={{ width: '100%' }}>
                    <Typography variant="h4" sx={{ fontSize: { xs: '9vw', sm: '7vw', md: '50px', lg: '50px' } }}>Wager Joined!</Typography>
                    <Typography variant="caption" color={theme.palette.dark.light}>Results are released every monday!</Typography>
                </Stack>


                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    width: '100%'
                }}>
                    <Typography sx={{ fontWeight: 700 }}>BREAKDOWN</Typography>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1.3fr 2fr .75fr',
                        gridTemplateRows: '1.3fr 0fr 1fr',
                    }}>
                        {
                            ['ITEMS', 'QUANTITY', 'To Win'].map((items, index) => (
                                <Typography sx={{ fontSize: '10px' }} key={index}>{items}</Typography>
                            ))
                        }
                        {
                            [
                                'Cost to Join', `${data?.chipsBet} chips`, `${data?.chipsBet * data?.multiplier_data?.multiplier} chips`,
                                'Multipier', '-', `${data?.multiplier_data?.multiplier}x`
                            ].map((items, index) => (
                                <Typography variant='caption' sx={{ fontWeight: 700 }} key={index}>{items}</Typography>
                            ))
                        }
                    </Box>

                    <Divider />
                </Box>

                <Stack spacing={1.5} sx={{ width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography>EARNING POTENTIAL</Typography>
                        <Typography>{data?.chipsBet * data?.multiplier_data?.multiplier} Chips</Typography>
                    </Box>
                </Stack>

            </Stack>
        </>
    )
}

export default Receipt