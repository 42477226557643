import { useTheme } from '@emotion/react'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'

const WagerTeamItem = ({ predictionOnly, data }) => {

    const theme = useTheme()
    
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '12px',
            width: '100%',
            // maxWidth:'80%',
            //bgcolor:'red'
        }}>

            {
                //            <Avatar sx={{ width: '46px', height: '46px' }} />
            }
            <Stack spacing={0}>

                <Typography
                    variant='body1'
                    sx={{
                        fontWeight: 700
                    }}
                >
                    {
                        data?.racer?.name
                    }
                </Typography>

                <Typography sx={{ lineHeight: '16px', color: theme.palette.dark.light }} variant='caption'>
                    Prediction: {data?.prediction}
                </Typography>
            </Stack>

        </Box>
    )
}

export default WagerTeamItem
