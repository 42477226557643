import { GET_POST_REQUEST, GET_POST, GET_POST_ERROR } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import postServices from '../../../services/posts'

export const listMyPosts = createAsyncThunk(
  '/posts/my',
  async ({ skip, limit }, thunkAPI) => {
    thunkAPI.dispatch({
      type: GET_POST_REQUEST
    })
    try {
      const data = await postServices.getMyPosts(skip, limit)
      thunkAPI.dispatch({
        type: GET_POST,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: GET_POST_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const listAllPosts = createAsyncThunk(
  '/posts/getAll',
  async ({ skip, limit }, thunkAPI) => {
    thunkAPI.dispatch({
      type: 'GET_ALL_POST_REQUEST'
    })
    try {
      const data = await postServices.getAllPosts(skip, limit)
      thunkAPI.dispatch({
        type: 'GET_ALL_POST',
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: 'GET_ALL_POST_ERROR',
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const listPostDetails = createAsyncThunk(
  '/posts/get/id',
  async ({ id }, thunkAPI) => {
    thunkAPI.dispatch({
      type: 'GET_POST_DETAILS_REQUEST'
    })
    try {
      const data = await postServices.getPostById(id)
      thunkAPI.dispatch({
        type: 'GET_POST_DETAILS_POST',
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: 'GET_POST_DETAILS_ERROR',
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const listUserPosts = createAsyncThunk(
  '/posts/user/id',
  async ({ skip, limit, id }, thunkAPI) => {
    thunkAPI.dispatch({
      type: GET_POST_REQUEST
    })
    try {
      const data = await postServices.getUserPosts(skip, limit, id)
      thunkAPI.dispatch({
        type: GET_POST,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: GET_POST_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const createPosts = createAsyncThunk(
  '/posts/create',
  async ({ description }, thunkAPI) => {
    thunkAPI.dispatch({
      type: 'CREATE_POST_REQUEST'
    })
    try {
      console.log("DESCRIPTION -", description)
      const data = await postServices.createPost(description)
      thunkAPI.dispatch({
        type: 'CREATE_POST',
        payload: data && data?.data
      })

      thunkAPI.dispatch({
        type: 'UPDATE_POST_FEED_W_CREATED_POST',
        payload: data && data?.data?.result
      })

      return { user: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: 'CREATE_POST_ERROR',
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const resetPostError = () => {
  return (dispatch) => {
    console.log("GETTING CALLED NIQQA")
    dispatch({
      type: "RESET_CREATE_POST_ERROR"
    })
  }
}

export const updatePostFeed = (post) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_POST_FEED_W_CREATED_POST",
      payload: post
    })
  }
}

export const deletePost = (id) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: "REQUEST_DELETE_POST"
      })

      const response = await postServices.deletePost(id)
      console.log('POST DELETION RESPONSE -', response)
      dispatch({
        type: 'RESET_POST_DELETION_ERROR'
      })

      dispatch({
        type: "DELETE_POST",
        payload: response && response?.data?.result
      })

    }
    catch (error) {

      console.log(error)

      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: 'DELETE_POST_ERROR',
        payload: message
      })

    }

  }
}

export const resetPostCreationError = () => {
  return async (dispatch) => {
    dispatch({
      type: 'RESET_POST_DELETION_ERROR'
    })
  }
}