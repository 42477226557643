
const initialState = {
  isLoading: false
}
export default function trendingEvents(state = initialState, action) {
  switch (action.type) {

    case 'GET_TRENDING_EVENTS_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }

    case 'SET_TRENDING_EVENTS': {
      return {
        ...state,
        isLoading: false,
        result: action.payload,
        status: 'success'

      }
    }

    case 'SET_TRENDING_EVENTS_ERROR': {
      return {
        ...state,
        isLoading: false,
        status: 'fail',
        error: action.payload
      }
    }

    case 'RESET_TRENDING_EVENTS': {
      let temp = {
        ...state
      }
      delete temp.error
      return {
        ...temp,
        isLoading: false,
        error: null
      }
    }

    default:
      return state
  }
}
