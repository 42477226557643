import moment from 'moment'

const HandleDate = ({ date }) => {
  let ago = moment(date).startOf().fromNow(true)
  if (ago === 'an hour') {
    ago = '1h'
  } else if (ago.includes('hour')) {
    ago = ago.split(' ')[0] + 'h'
  } else if (ago === 'a minute') {
    ago = '1min'
  } else if (ago.includes('minutes')) {
    ago = ago.split(' ')[0] + 'min'
  } else if (ago === 'a day') {
    ago = '1d'
  } else if (ago.includes('days')) {
    ago = ago.split(' ')[0] + 'd'
  } else if (ago.includes('seconds')) {
    ago = 'few secs'
  }
  return ago
}

export default HandleDate
