import { Alert, Avatar, Box, Button, Chip, Container, IconButton, List, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import AppLayout from '../../components/layouts/app'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { listWaggers } from '../../redux/actions/wagers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DateConverter from '../../components/DateConverter';
import Loader from '../../components/loader';
import { isWagerClose } from '../../helper';
import WagerCard from '../../components/wager/feed-item';
import LikeServices from '../../services/like';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import MatchTeamItem from '../../components/matches/team-item';
import { getLeagues } from '../../redux/actions/leagues';
import LockerRoom from '../../components/lockerroom';
import { LoadingButton } from '@mui/lab'
import AdSwitcher from '../../components/Ads/switcher';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <>
            {value === index && (
                <Box sx={props.sx}
                    role="tabpanel"
                    hidden={value !== index}
                    id={`simple-tabpanel-${index}`}
                    aria-labelledby={`simple-tab-${index}`}
                    {...other}>
                    {children}
                </Box>
            )}
        </>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const MatchPage = () => {

    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch = useDispatch()
    let { matchId } = useParams(); // Extracting matchId from the URL
    const user = JSON.parse(localStorage.getItem('user'))
    const leagues = useSelector((state) => state.Leagues.league);
    const overallLeagues = useSelector((state) => state.Leagues);
    const [loader, setLoader] = React.useState(false)
    const [wagerLimit, setWagerLimit] = React.useState(10)
    const [counter, SetCounter] = React.useState(0)
    const [likeId, setLikeId] = React.useState([])
    const [event, setEvent] = React.useState({})
    const [filterBy, setFilterBy] = React.useState({
        mode: 'fantasy',
        eventId: Number(matchId),
    })
    const [value, setValue] = React.useState(1);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [showScrollToTopButton, setShowScrollToTopButton] = useState(false); // New state for the button visibility
    const [showHeader, setShowHeader] = useState(true);

    const lastScrollY = useRef(0);
    const scrollingContainerRef = useRef(null); // Ref for the scrolling div
    const scrollTimeout = useRef(null); // Ref to hold the timeout ID

    useEffect(() => {
        // eslint-disable-next-line eqeqeq
        if (!user && loginData.Login != 'Success') {
            navigate('/')
        }
        dispatch(
            listWaggers({
                skip: 0,
                limit: wagerLimit,
                filter: {
                    ...filterBy
                }
            })
        )
    }, [wagerLimit])

    useEffect(() => {
        dispatch(
            listWaggers({
                skip: 0,
                limit: wagerLimit,
                filter: {
                    ...filterBy
                }
            })
        )
    }, [])

    useEffect(() => {
        if (leagues[overallLeagues.indexSelected]) {
            const array = []
            for (let i = 0; i < leagues[overallLeagues.indexSelected]?.feed?.length; i++) {
                if (leagues[overallLeagues.indexSelected]?.feed[i]?.isWagerLikeByMe) {
                    array.push(leagues[overallLeagues.indexSelected]?.feed[i]?.id)
                }
            }
            setLikeId(array)
        }
    }, [leagues[overallLeagues.indexSelected].feed])

    const handleWagerLike = (id, idx, isWagerLikeByMe) => {
        if (likeId.includes(id)) {
            const array = likeId.filter(item => item !== id)
            setLikeId(array)
        } else {
            setLikeId([...likeId, id])
        }
        LikeServices.wagerLike(id).then((result) => { }).catch((err) => {
            console.log(err, 'error')
        })
    }

    const checkIfTimestampIsInFuture = (timestamp) => {
        const givenDate = new Date(timestamp);
        const currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes() + 5);
        return givenDate > currentDate;
    }

    useEffect(() => {
        if (overallLeagues.league.length < 2) {
            navigate('/dashboard')
        }
        else if (!!overallLeagues?.indexSelected && !!overallLeagues?.league && !!overallLeagues?.league[overallLeagues.indexSelected]) {
            let tempEvent = overallLeagues?.league[overallLeagues.indexSelected]
            const item = tempEvent.events.find(event => event?.id === Number(matchId));
            if (item === undefined || !checkIfTimestampIsInFuture(item?.startTime)) {
                navigate('/dashboard')
            }
            else {
                setEvent(item)
                dispatch(
                    listWaggers({
                        skip: 0,
                        limit: wagerLimit,
                        filter: {
                            ...filterBy
                        }
                    })
                )

            }
        }
        //get wagers by event #!

    }, []);

    const handleLoader = () => {
        if (leagues[overallLeagues?.indexSelected]?.isLoading) {
            setLoader(true)
        }
        else {
            if (!leagues[overallLeagues?.indexSelected]?.isLoading) {
                setTimeout(() => {
                    setLoader(false)
                }, 5000)
            } else {
                setLoader(false)
            }
        }
    }

    const fetchMoreData = () => {
        setLoader(true)
        SetCounter(counter + 1)
        setTimeout(() => {
            setWagerLimit(wagerLimit + 5)
            setLoader(false)
        }, 1000)
    }

    useEffect(() => {
        SetCounter(0)
        window.scrollTo(0, 0)
        handleLoader()
    }, [overallLeagues.indexSelected]);

    const controlHeaderVisibility = () => {
        if (scrollingContainerRef.current) {
            let currentScrollY = scrollingContainerRef.current.scrollTop;

            // Show or hide the header based on scroll direction
            if (currentScrollY < lastScrollY.current) {
                setShowHeader(true);
            } else if (currentScrollY > lastScrollY.current) {
                setShowHeader(false);
            }

            // Determine whether to show the "Scroll to Top" button
            setShowScrollToTopButton(currentScrollY > 100); // Example threshold

            if (scrollTimeout.current) {
                clearTimeout(scrollTimeout.current);
            }

            scrollTimeout.current = setTimeout(() => {
                setShowHeader(true);
            }, 500);

            lastScrollY.current = currentScrollY;
        }
    };

    useEffect(() => {
        const scrollingContainer = scrollingContainerRef.current;
        if (scrollingContainer) {
            scrollingContainer.addEventListener('scroll', controlHeaderVisibility);
            return () => {
                scrollingContainer.removeEventListener('scroll', controlHeaderVisibility);
                if (scrollTimeout.current) {
                    clearTimeout(scrollTimeout.current);
                }
            };
        }
    }, []);

    const scrollToTop = () => {
        if (scrollingContainerRef.current) {
            scrollingContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };
    return (
        <AppLayout hideNavigation={
            (overallLeagues?.indexSelected === 0
                ?
                false
                :
                event?.startTime)
                ? value === 2
                : false
        }>
            <Box
                ref={scrollingContainerRef}
                sx={{
                    height: 'inherit',
                    overflow: 'scroll',
                    pb: value === 2 ? '0' : '110px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 99,
                    bgcolor: 'black',
                    transform: showHeader ? 'translateY(0)' : 'translateY(-100%)',
                    transition: 'transform 0.3s ease-in-out'
                }}>


                    <Container sx={{
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        pt: 3,
                        pb: 2
                    }}>
                        <IconButton onClick={() => navigate('/dashboard')} >
                            <ArrowBackIcon fontSize='medium' />
                        </IconButton>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '1px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '6px',
                                mb: "-6px"
                            }}>
                                <Typography variant='h6'>{event?.away_teams_data?.abbr_name}</Typography>
                                <Typography variant='body'>@</Typography>
                                <Typography variant='h6'>{event?.home_teams_data?.abbr_name}</Typography>
                            </Box>
                            <Typography color={theme.palette.dark.otherlight} variant='caption'><DateConverter currTime={event?.startTime} /></Typography>
                        </Box>
                        <Chip label="MLB" size="small" sx={{ fontSize: '10px' }} />
                    </Container>

                    <Container sx={{ pt: 1, pb: 1 }}>
                        <Box sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                            <MatchTeamItem data={{
                                name: event?.home_teams_data?.name,
                                type: event?.sports_data?.type,
                                url_logo: event?.home_teams_data?.url_logo,
                                location: "Home Team"
                            }} />
                            <MatchTeamItem data={{
                                name: event?.away_teams_data?.name,
                                type: event?.sports_data?.type,
                                url_logo: event?.away_teams_data?.url_logo,
                                location: "Away Team"
                            }} />
                        </Box>


                        {showScrollToTopButton && (
                            <Container sx={{ pt: 2, pb: 1 }}>
                                <Button
                                    //endIcon={<ArrowCircleUpIcon />}
                                    size='small'
                                    onClick={scrollToTop}
                                    fullWidth
                                    variant='outlined'>
                                    Scroll to top
                                </Button>
                            </Container>
                        )}

                    </Container>
                </Box>




                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    background: theme.palette.dark.dark,
                    marginTop: '192px'
                }}>
                    <Tabs
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        allowScrollButtonsMobile
                        scrollButtons="auto"
                        sx={theme.tabs}
                        aria-label="basic tabs example">
                        <Tab label="Real Money" sx={theme.tab} />
                        <Tab label="Fantasy" sx={theme.tab} />
                        <Tab label="Locker Room" sx={theme.tab} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                    <List>
                        <Container sx={{ mt: 2 }}>
                            <Alert severity="warning">Real Money Wagering coming soon!</Alert>
                        </Container>
                    </List>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1} sx={{ pb: 14 }}>
                    <List >
                        {
                            leagues[event?.sports_data?.id]?.isLoading && counter === 0
                                ?
                                <Container>
                                    <Loader isloading={true} />
                                </Container>
                                :
                                leagues[event?.sports_data?.id]?.feed?.length === 0
                                    ?
                                    <Container sx={{ pt: 2 }}>
                                        <Alert severity='info'>Be the first to create a wager!</Alert>
                                    </Container>
                                    :
                                    <List>
                                        {
                                            leagues[event?.sports_data?.id]?.feed?.map((item, index) => {
                                                const wagerStatus = isWagerClose(item?.event_data?.startTime)
                                                return (
                                                    <div key={index}>
                                                        {
                                                            !!item?.engagementType
                                                                ?
                                                                <AdSwitcher
                                                                    wagerData={{
                                                                        user: user
                                                                    }}
                                                                    data={item} />
                                                                :
                                                                <WagerCard
                                                                    data={{
                                                                        ...item,
                                                                        wagerStatus: wagerStatus,
                                                                        user: user,
                                                                        index: index,
                                                                        likeId: likeId
                                                                    }}
                                                                    likeFunction={() => handleWagerLike(item?.id, index, item?.isWagerLikeByMe)} />

                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            wagerLimit < leagues[event?.sports_data?.id]?.count
                                            &&
                                            <Container>
                                                <LoadingButton
                                                    loading={leagues[event?.sports_data?.id]?.isLoading || loader}
                                                    onClick={() => fetchMoreData()}
                                                    fullWidth
                                                    variant='outlined'>
                                                    Load more
                                                </LoadingButton>
                                            </Container>
                                        }
                                    </List>
                        }
                    </List>
                </CustomTabPanel>


                {
                    leagues[overallLeagues?.indexSelected].events[0]?.startTime &&
                    <CustomTabPanel
                        value={value}
                        index={2}
                        sx={{
                            flexGrow: 1,
                            overflow: 'hidden'
                        }}>
                        <LockerRoom eventId={event?.id} />
                    </CustomTabPanel>
                }
            </Box>
        </AppLayout >
    )
}

export default MatchPage


/*
return (
        <AppLayout>

            <Container sx={{
                color: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pt: 3,
                pb: 2
            }}>
                <IconButton onClick={() => navigate('/dashboard')} >
                    <ArrowBackIcon fontSize='medium' />
                </IconButton>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1px'
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '6px',
                        mb: "-6px"
                    }}>
                        <Typography variant='h6'>{event?.away_teams_data?.abbr_name}</Typography>
                        <Typography variant='body'>@</Typography>
                        <Typography variant='h6'>{event?.home_teams_data?.abbr_name}</Typography>
                    </Box>
                    <Typography color={theme.palette.dark.otherlight} variant='caption'><DateConverter currTime={event?.startTime} /></Typography>
                </Box>
                <Chip label="MLB" size="small" sx={{ fontSize: '10px' }} />
            </Container>
            <Container sx={{ pt: 1, pb: 1 }}>
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px'
                }}>
                    <MatchTeamItem data={{
                        name: event?.home_teams_data?.name,
                        type: event?.sports_data?.type,
                        url_logo: event?.home_teams_data?.url_logo,
                        location: "Home Team"
                    }} />
                    <MatchTeamItem data={{
                        name: event?.away_teams_data?.name,
                        type: event?.sports_data?.type,
                        url_logo: event?.away_teams_data?.url_logo,
                        location: "Away Team"
                    }} />
                </Box>
            </Container>
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                position: 'sticky',
                top: '48px',
                background: theme.palette.dark.dark,
                zIndex: 99
            }}>
                <Tabs
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    allowScrollButtonsMobile
                    scrollButtons="auto"
                    sx={{
                        width: '100%',
                        '.MuiTabs-scrollButtons': {
                            width: '24px',
                            color: 'white'
                        },
                        '.MuiTabs-scroller': {
                            scrollBehavior: 'smooth'
                        }
                    }}
                    aria-label="basic tabs example">
                    <Tab label="Real Money" sx={{ width: '40%', fontWeight: 700 }} />
                    <Tab label="Fantasy" sx={{ width: '40%', fontWeight: 700 }} />
                    <Tab label="Locker Room" sx={{ width: '40%', fontWeight: 700 }} />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <List className="loginWrap scrollable center" id='scrollableDiv'>
                    <Container sx={{ mt: 2 }}>
                        <Alert severity="warning">Real Money Wagering coming soon!</Alert>
                    </Container>
                </List>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <List className="loginWrap scrollable center" id='scrollableDiv'>
                    {
                        leagues[overallLeagues?.indexSelected]?.isLoading && counter === 0
                            ?
                            <Container>
                                <Loader isLoading={true} />
                            </Container>
                            :
                            leagues[overallLeagues?.indexSelected]?.feed?.length < 0
                                ?
                                <Container sx={{ pt: 2 }}>
                                    <Alert severity='info'>Be the first to wager!</Alert>
                                </Container>
                                :
                                <InfiniteScroll
                                    dataLength={wagerLimit}
                                    next={fetchMoreData}
                                    hasMore={wagerLimit < leagues[overallLeagues.indexSelected]?.count}
                                    loader={<div style={{ overflow: 'hidden' }}><Loader isloading={true} /></div>}
                                    scrollableTarget="scrollableDiv"
                                    style={{ overflowX: 'hidden' }}>
                                    {leagues[overallLeagues?.indexSelected]?.feed?.map((item, index) => {
                                        const wagerStatus = isWagerClose(item?.event_data?.startTime)
                                        return (
                                            <div key={index}>
                                                <WagerCard
                                                    data={{
                                                        ...item,
                                                        wagerStatus: wagerStatus,
                                                        user: user,
                                                        index: index,
                                                        likeId: likeId
                                                    }}
                                                    likeFunction={() => handleWagerLike(item?.id, index, item?.isWagerLikeByMe)} />
                                            </div>
                                        )
                                    })}
                                </InfiniteScroll >
                    }
                </List>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
                <List className="loginWrap scrollable center" id='scrollableDiv'>
                    <Container sx={{ mt: 2 }}>
                        <Alert severity="warning">Locker room coming soon!</Alert>
                    </Container>
                </List>
            </CustomTabPanel>

        </AppLayout>
    )
*/