import { useTheme } from '@emotion/react'
import { Box, Container, IconButton, Typography } from '@mui/material'
import React from 'react'
import WagerDetailsNew from '../../../pages/wagerboard/wagerDetailsNew'
import ConfirmWrager from '../../../pages/wagerboard/confirmWrager'
import ClearIcon from '@mui/icons-material/Clear';

const WagerChatDrawer = ({ type, data, closeDrawer }) => {

    const theme = useTheme()

    const bodyStyles = {
        bgcolor: theme.palette.dark.dark,
        height: '100%'
    }
    
    const handleCounter = () => {
        //console.log('SET UP')
    }
    // console.log('DATA INSIDE -', data)

    switch (type) {
        case "owner":
            return (
                <Box sx={bodyStyles}>
                    <Container sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        pt: 1
                    }}>
                        <IconButton onClick={() => closeDrawer()}>
                            <ClearIcon />
                        </IconButton>
                    </Container>
                    <WagerDetailsNew
                        privateWager
                        id={JSON.stringify(data?.id ?? "undefined")}
                        closeFunction={() => closeDrawer()} />
                </Box>
            )
            break;
        case "viewer":
            return (
                <Box sx={bodyStyles}>
                    <Container sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        pt: 1
                    }}>
                        <IconButton onClick={() => closeDrawer()}>
                            <ClearIcon />
                        </IconButton>
                    </Container>
                    <ConfirmWrager
                        privateWager
                        id={JSON.stringify(data?.id ?? "undefined")}
                        functionToClose={() => closeDrawer()}
                        functionIncrementCounter={() => handleCounter()} />
                </Box>
            )
            break;
        default:
            break;
    }

}

export default WagerChatDrawer
