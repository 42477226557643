
const initialState = {
  isLoading: false
}
export default function updateSubscription (state = initialState, action) {
  switch (action.type) {
    case 'SET_SUBSCRIPTION_REQUEST': {
      return {
        ...state,
        isLoading: true,
        cardInfo: action.payload
      }
    }
    case 'SET_SUBSCRIPTION': {
      return {
        ...state,
        isLoading: false,
        cardInfo: action.payload
      }
    }
    case 'SET_SUBSCRIPTION_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }
    case 'RESET_SUBSCRIPTION': {
      return {
        initialState
      }
    }
    default:
      return state
  }
}
