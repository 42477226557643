import { GET_ALL_USERS_REQUEST, GET_ALL_USERS, GET_ALL_USERS_ERROR } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import UsersServices from '../../../services/users'
import ChatsServices from '../../../services/chat'

export const getUsers = createAsyncThunk(
  '/users/list',
  async ({ query }, thunkAPI) => {
    thunkAPI.dispatch({
      type: GET_ALL_USERS_REQUEST
    })
    try {
      const data = await ChatsServices.searchUsers(query)
      thunkAPI.dispatch({
        type: GET_ALL_USERS,
        payload: data && data?.data
      })
      return { chats: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: GET_ALL_USERS_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }

)
