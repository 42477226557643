import { useTheme } from '@emotion/react';
import { Alert, Box, Chip, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import React from 'react'

const WagerTypeForms = ({
    roomDetails,
    walletBalance,
    privateWager,
    wagerTypeId,
    divisionFunction,
    divisionValue,
    divisionOptions,
    privateerOptions,
    riderOneFunction,
    riderOneValue,
    peopleList,
    wagerInput,
    multipierList,
    multiplierFunction,
    chipsFunction,
    peopleListFunction,
    privateerPosition,
    privateerPositionFunction,
    spreadFunction,
    spreadOptions,
    selectedEventToWager,
    getPrivateerFunctionNoDivison
}) => {


    const toOrdinalSuffix = (num) => {
        let a
        let b
        const int = parseInt(num)
        const digits = [int % 10, int % 100]
        const ordinals = ['st', 'nd', 'rd', 'th']
        const oPattern = [1, 2, 3, 4]
        const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
        if (typeof (num) === 'string') {
            if (num.includes('through')) {
                a = num.split(' ')[0]
                b = num.split(' ')[2]
                const inta = parseInt(a)
                const intb = parseInt(b)
                const digitsa = [inta % 10, inta % 100]
                return oPattern.includes(digitsa[0]) && !tPattern.includes(digitsa[1])
                    ? inta + ordinals[digits[0] - 1] + ' through ' + intb + ordinals[digits[0] - 1]
                    : inta + ordinals[3] + ' through ' + intb + ordinals[3]
            }
        } else {
            return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
                ? int + ordinals[digits[0] - 1]
                : int + ordinals[3]
        }
    }
    
    const theme = useTheme()
    
    const currencies = [
        {
            value: 1,
            label: '1 user',
        },
        {
            value: 2,
            label: '2 users',
        },
        {
            value: 3,
            label: '3 users',
        },
        {
            value: 4,
            label: '4 users',
        },
        {
            value: 5,
            label: '5 users',
        },
    ];
    switch (wagerTypeId) {
        case 1:
            return (
                <>

                    {
                        selectedEventToWager?.sports_data.id === 1 &&
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>Division</Typography>

                            <TextField
                                name="divisionId"
                                id="outlined-select-currency"
                                select
                                value={wagerInput?.divisionId === '' ? -1 : wagerInput?.divisionId}
                                //defaultValue={-1}
                                onChange={(e) => divisionFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >
                                <MenuItem value={-1} disabled>
                                    Select a Division!
                                </MenuItem>
                                {divisionOptions?.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    }
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>I believe that:</Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                name='racerFor'
                                disabled={selectedEventToWager?.sports_data.id === 1 ? wagerInput?.divisionId === '' || wagerInput?.divisionId === -1 : false}
                                value={wagerInput?.racerFor === '' ? 0 : wagerInput?.racerFor}
                                //defaultValue={0}
                                onChange={(e) => riderOneFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >

                                <MenuItem value={0} disabled>

                                    {
                                        selectedEventToWager.sportsId === 1
                                            ?
                                            "Select a Rider!"
                                            :

                                            "Select a Racer!"
                                    }
                                </MenuItem>
                                {(selectedEventToWager.sportsId != 1 || wagerInput?.divisionId) && privateerOptions?.map((option) => {
                                    if (wagerInput?.racerAgainst != option.racerId) {
                                        return (
                                            <MenuItem key={option?.racerId} value={option?.racerId}>
                                                {option?.privateer_data?.name}
                                            </MenuItem>
                                        )
                                    }

                                }
                                )}
                            </TextField>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>Will beat:</Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                name='racerAgainst'
                                disabled={wagerInput?.racerFor === '' || wagerInput?.racerFor === 0}
                                value={wagerInput?.racerAgainst === '' ? 0 : wagerInput?.racerAgainst}
                                //defaultValue={0}
                                onChange={(e) => riderOneFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >
                                <MenuItem value={0} disabled>

                                    {
                                        selectedEventToWager.sportsId === 1
                                            ?
                                            "Select a Rider!"
                                            :

                                            "Select a Racer!"
                                    }
                                </MenuItem>
                                {(selectedEventToWager.sportsId != 1 || wagerInput?.divisionId) && privateerOptions?.map((option) => {
                                    if (riderOneValue != option.racerId) {
                                        return (
                                            <MenuItem key={option?.racerId} value={option?.racerId}>
                                                {option?.privateer_data?.name}
                                            </MenuItem>
                                        )
                                    }

                                }
                                )}
                            </TextField>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            {
                                !!privateWager ?
                                    <>
                                        <Typography variant='caption'>{roomDetails?.length} {roomDetails?.length === 1 ? 'user' : 'users'} can accept</Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            defaultValue={roomDetails[0].userId}
                                            sx={{
                                                //color:`${theme.palette.dark.otherlight}`,
                                                width: '100%',
                                                '.MuiSelect-select': {
                                                    padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                    fontSize: '0.75rem', // Font size for the input text
                                                },
                                                '& .MuiInputLabel-root': { // If you're using a label
                                                    fontSize: '0.75rem', // Font size for the label
                                                }
                                            }}
                                        >
                                            {roomDetails.map((user) => (
                                                <MenuItem key={user.userId} value={user.userId} disabled>
                                                    @{user.user_data.username}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </>
                                    :
                                    <>
                                        <Typography variant='body2'># of users that can join</Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            name="maxPeople"
                                            value={wagerInput?.maxPeople === '' ? 1 : wagerInput?.maxPeople}
                                            //defaultValue={1}
                                            onChange={(e) => peopleListFunction(e)}
                                            sx={{
                                                //color:`${theme.palette.dark.otherlight}`,
                                                width: '100%',
                                                '.MuiSelect-select': {
                                                    padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                    fontSize: '0.75rem', // Font size for the input text
                                                },
                                                '& .MuiInputLabel-root': { // If you're using a label
                                                    fontSize: '0.75rem', // Font size for the label
                                                }
                                            }}
                                        >
                                            {peopleList?.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option == 1 ? option + " user" : option + " users"}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </>
                            }
                        </Box>


                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>Multiplier</Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                name="multiplierId"
                                value={wagerInput?.multiplierId === '' ? multipierList[0].multiplier : wagerInput?.multiplierId}
                                //defaultValue={multipierList[0].multiplier}
                                onChange={(e) => multiplierFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >
                                {multipierList?.map((option, index) => (
                                    <MenuItem key={index} value={option?.id}>
                                        {option?.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>

                            <Typography variant='body2'>Select your wager amount (Chips Available: {walletBalance})</Typography>

                            <TextField
                                name="chipsBet"
                                value={wagerInput?.chipsBet}
                                onChange={(e) => chipsFunction(e)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <span style={{ fontSize: '0.75rem', color: theme.palette.dark.otherlight }}>chips</span>
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        input: {
                                            height: '22.5px',
                                            fontSize: '0.75rem', // Font size for the input text
                                            padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        }
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                    {
                        ((wagerInput?.chipsBet * multipierList[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople) > walletBalance) &&
                        <Alert severity='error'>This wager will cost you: {wagerInput?.chipsBet * multipierList[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople} chips. <br />You don't have enough!</Alert>
                    }

                    {
                        ((wagerInput?.chipsBet * multipierList[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople) > 1000) && walletBalance > 1000 &&
                        <Alert severity='error'>Wagers more than 1,000 chips are not allowed!</Alert>
                    }

                    {
                        (wagerInput?.chipsBet < 10) &&
                        <Alert severity='error'>Wagers less than 10 chips are not allowed!</Alert>
                    }
                </>
            )
            break;
        case 2:
            return (
                <>

                    {
                        selectedEventToWager?.sports_data.id === 1 &&
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>Division</Typography>

                            <TextField
                                name="divisionId"
                                id="outlined-select-currency"
                                select
                                value={wagerInput?.divisionId === '' ? -1 : wagerInput?.divisionId}
                                //defaultValue={-1}
                                onChange={(e) => divisionFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >
                                <MenuItem value={-1} disabled>
                                    Select a Division!
                                </MenuItem>
                                {divisionOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    }
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>I believe that:</Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                name='racerFor'
                                disabled={selectedEventToWager?.sports_data.id === 1 ? wagerInput?.divisionId === '' || wagerInput?.divisionId === -1 : false}
                                value={wagerInput?.racerFor === '' ? 0 : wagerInput?.racerFor}
                                //defaultValue={0}
                                onChange={(e) => riderOneFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >

                                <MenuItem value={0} disabled>
                                    {
                                        selectedEventToWager.sportsId === 1
                                            ?
                                            "Select a Rider!"
                                            :

                                            "Select a Racer!"
                                    }
                                </MenuItem>
                                {(selectedEventToWager.sportsId != 1 || wagerInput?.divisionId) && privateerOptions?.map((option) => {
                                    if (wagerInput?.racerAgainst != option.racerId) {
                                        return (
                                            <MenuItem key={option?.racerId} value={option?.racerId}>
                                                {option?.privateer_data?.name}
                                            </MenuItem>
                                        )
                                    }

                                }
                                )}
                            </TextField>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>Will place:</Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                name="placement"
                                disabled={wagerInput?.racerFor === '' || wagerInput?.racerFor === 0}
                                value={
                                    wagerInput?.placement === ''
                                        ?
                                        0
                                        :
                                        wagerInput?.placement && wagerInput.placement != wagerInput.placementRange
                                            ?
                                            `${wagerInput?.placement} through ${wagerInput?.placementRange}`
                                            :
                                            wagerInput?.placement
                                }
                                onChange={(e) => privateerPositionFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >
                                <MenuItem value={0} disabled>
                                    Select a placement!
                                </MenuItem>
                                {privateerPosition?.map((option, index) => {
                                    return (
                                        <MenuItem key={index} value={option}>
                                            {toOrdinalSuffix(option)}
                                        </MenuItem>
                                    )
                                }
                                )}
                            </TextField>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            {
                                privateWager ?
                                    <>
                                        <Typography variant='caption'>{roomDetails?.length} {roomDetails?.length === 1 ? 'user' : 'users'} can accept</Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            defaultValue={roomDetails[0].userId}
                                            sx={{
                                                //color:`${theme.palette.dark.otherlight}`,
                                                width: '100%',
                                                '.MuiSelect-select': {
                                                    padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                    fontSize: '0.75rem', // Font size for the input text
                                                },
                                                '& .MuiInputLabel-root': { // If you're using a label
                                                    fontSize: '0.75rem', // Font size for the label
                                                }
                                            }}
                                        >
                                            {roomDetails.map((user) => (
                                                <MenuItem key={user.userId} value={user.userId} disabled>
                                                    @{user.user_data.username}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </>
                                    :
                                    <>

                                        <Typography variant='body2'># of users that can join</Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            name="maxPeople"
                                            value={wagerInput?.maxPeople === '' ? 1 : wagerInput?.maxPeople}
                                            //defaultValue={1}
                                            onChange={(e) => peopleListFunction(e)}
                                            sx={{
                                                //color:`${theme.palette.dark.otherlight}`,
                                                width: '100%',
                                                '.MuiSelect-select': {
                                                    padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                    fontSize: '0.75rem', // Font size for the input text
                                                },
                                                '& .MuiInputLabel-root': { // If you're using a label
                                                    fontSize: '0.75rem', // Font size for the label
                                                }
                                            }}
                                        >
                                            {peopleList?.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option == 1 ? option + " user" : option + " users"}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </>
                            }
                        </Box>


                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>Multiplier</Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                name="multiplierId"
                                value={wagerInput?.multiplierId === '' ? multipierList[0].multiplier : wagerInput?.multiplierId}
                                //defaultValue={multipierList[0].multiplier}
                                onChange={(e) => multiplierFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >
                                {multipierList?.map((option, index) => (
                                    <MenuItem key={index} value={option?.id}>
                                        {option?.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>

                            <Typography variant='body2'>Select your wager amount (Chips Available: {walletBalance})</Typography>

                            <TextField
                                name="chipsBet"
                                value={wagerInput?.chipsBet}
                                onChange={(e) => chipsFunction(e)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <span style={{ fontSize: '0.75rem', color: theme.palette.dark.otherlight }}>chips</span>
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        input: {
                                            height: '22.5px',
                                            fontSize: '0.75rem', // Font size for the input text
                                            padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        }
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                    {
                        ((wagerInput?.chipsBet * multipierList[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople) > walletBalance) &&
                        <Alert severity='error'>This wager will cost you: {wagerInput?.chipsBet * multipierList[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople} chips. <br />You don't have enough!</Alert>
                    }

                    {
                        ((wagerInput?.chipsBet * multipierList[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople) > 1000) && walletBalance > 1000 &&
                        <Alert severity='error'>Wagers more than 1,000 chips are not allowed!</Alert>
                    }

                    {
                        (wagerInput?.chipsBet < 10) &&
                        <Alert severity='error'>Wagers less than 10 chips are not allowed!</Alert>
                    }
                </>
            )
            break;
        case 3:
            return (
                <>

                    {
                        selectedEventToWager?.sports_data.id === 1 &&
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>Division</Typography>

                            <TextField
                                name="divisionId"
                                id="outlined-select-currency"
                                select
                                value={wagerInput?.divisionId === '' ? -1 : wagerInput?.divisionId}
                                //defaultValue={-1}
                                onChange={(e) => divisionFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >
                                <MenuItem value={-1} disabled>
                                    Select a Division!
                                </MenuItem>
                                {divisionOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    }
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>I believe that:</Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                name='racerFor'
                                disabled={selectedEventToWager?.sports_data.id === 1 ? wagerInput?.divisionId === '' || wagerInput?.divisionId === -1 : false}
                                value={wagerInput?.racerFor === '' ? 0 : wagerInput?.racerFor}
                                //defaultValue={0}
                                onChange={(e) => riderOneFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >

                                <MenuItem value={0} disabled>

                                    {
                                        selectedEventToWager.sportsId === 1
                                            ?
                                            "Select a Rider!"
                                            :

                                            "Select a Racer!"
                                    }
                                </MenuItem>
                                {(selectedEventToWager.sportsId != 1 || wagerInput?.divisionId) && privateerOptions?.map((option) => {
                                    if (wagerInput?.racerAgainst != option.racerId) {
                                        return (
                                            <MenuItem key={option?.racerId} value={option?.racerId}>
                                                {option?.privateer_data?.name}
                                            </MenuItem>
                                        )
                                    }

                                }
                                )}
                            </TextField>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>Will beat:</Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                name='racerAgainst'
                                disabled={wagerInput?.racerFor === '' || wagerInput?.racerFor === 0}
                                value={wagerInput?.racerAgainst === '' ? 0 : wagerInput?.racerAgainst}
                                //defaultValue={0}
                                onChange={(e) => riderOneFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >
                                <MenuItem value={0} disabled>

                                    {
                                        selectedEventToWager.sportsId === 1
                                            ?
                                            "Select a Rider!"
                                            :

                                            "Select a Racer!"
                                    }
                                </MenuItem>
                                {(selectedEventToWager.sportsId != 1 || wagerInput?.divisionId) && privateerOptions?.map((option) => {
                                    if (riderOneValue != option.racerId) {
                                        return (
                                            <MenuItem key={option?.racerId} value={option?.racerId}>
                                                {option?.privateer_data?.name}
                                            </MenuItem>
                                        )
                                    }

                                }
                                )}
                            </TextField>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '4px'
                    }}>
                        <Typography variant='body2'>by what spread?</Typography>
                        <TextField
                            id="outlined-select-currency"
                            select
                            name="spredOptionId"
                            defaultValue={0}
                            disabled={wagerInput.racerAgainst === '' || wagerInput.racerAgainst === 0}
                            onChange={(e) => spreadFunction(e)}
                            sx={{
                                //color:`${theme.palette.dark.otherlight}`,
                                width: '100%',
                                '.MuiSelect-select': {
                                    padding: '5px 5px 5px 10px', // Adjust this value as needed
                                    fontSize: '0.75rem', // Font size for the input text
                                },
                                '& .MuiInputLabel-root': { // If you're using a label
                                    fontSize: '0.75rem', // Font size for the label
                                }
                            }}
                        >

                            <MenuItem value={0} disabled>
                                Select your spread!
                            </MenuItem>
                            {spreadOptions?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>

                            {
                                privateWager ?
                                    <>
                                        <Typography variant='caption'>{roomDetails?.length} {roomDetails?.length === 1 ? 'user' : 'users'} can accept</Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            defaultValue={roomDetails[0].userId}
                                            sx={{
                                                //color:`${theme.palette.dark.otherlight}`,
                                                width: '100%',
                                                '.MuiSelect-select': {
                                                    padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                    fontSize: '0.75rem', // Font size for the input text
                                                },
                                                '& .MuiInputLabel-root': { // If you're using a label
                                                    fontSize: '0.75rem', // Font size for the label
                                                }
                                            }}
                                        >
                                            {roomDetails.map((user) => (
                                                <MenuItem key={user.userId} value={user.userId} disabled>
                                                    @{user.user_data.username}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </>
                                    :
                                    <>
                                        <Typography variant='body2'># of users that can join</Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            name="maxPeople"
                                            value={wagerInput?.maxPeople === '' ? 1 : wagerInput?.maxPeople}
                                            //defaultValue={1}
                                            onChange={(e) => peopleListFunction(e)}
                                            sx={{
                                                //color:`${theme.palette.dark.otherlight}`,
                                                width: '100%',
                                                '.MuiSelect-select': {
                                                    padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                    fontSize: '0.75rem', // Font size for the input text
                                                },
                                                '& .MuiInputLabel-root': { // If you're using a label
                                                    fontSize: '0.75rem', // Font size for the label
                                                }
                                            }}
                                        >
                                            {peopleList?.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option == 1 ? option + " user" : option + " users"}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </>
                            }
                        </Box>


                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>Multiplier</Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                name="multiplierId"
                                value={wagerInput?.multiplierId === '' ? multipierList[0].multiplier : wagerInput?.multiplierId}
                                //defaultValue={multipierList[0].multiplier}
                                onChange={(e) => multiplierFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >
                                {multipierList?.map((option, index) => (
                                    <MenuItem key={index} value={option?.id}>
                                        {option?.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>

                            <Typography variant='body2'>Select your wager amount (Chips Available: {walletBalance})</Typography>

                            <TextField
                                name="chipsBet"
                                value={wagerInput?.chipsBet}
                                onChange={(e) => chipsFunction(e)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <span style={{ fontSize: '0.75rem', color: theme.palette.dark.otherlight }}>chips</span>
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        input: {
                                            height: '22.5px',
                                            fontSize: '0.75rem', // Font size for the input text
                                            padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        }
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                    {
                        ((wagerInput?.chipsBet * multipierList[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople) > walletBalance) &&
                        <Alert severity='error'>This wager will cost you: {wagerInput?.chipsBet * multipierList[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople} chips. <br />You don't have enough!</Alert>
                    }

                    {
                        ((wagerInput?.chipsBet * multipierList[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople) > 1000) && walletBalance > 1000 &&
                        <Alert severity='error'>Wagers more than 1,000 chips are not allowed!</Alert>
                    }

                    {
                        (wagerInput?.chipsBet < 10) &&
                        <Alert severity='error'>Wagers less than 10 chips are not allowed!</Alert>
                    }
                </>
            )
            break;
        case 4:
            return (
                <>

                    {
                        selectedEventToWager?.sports_data.id === 1 &&
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>Division</Typography>

                            <TextField
                                name="divisionId"
                                id="outlined-select-currency"
                                select
                                value={wagerInput?.divisionId === '' ? -1 : wagerInput?.divisionId}
                                //defaultValue={-1}
                                onChange={(e) => divisionFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >
                                <MenuItem value={-1} disabled>
                                    Select a Division!
                                </MenuItem>
                                {divisionOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    }
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '4px'
                    }}>
                        <Typography variant='body2'>I believe that ___ will win the holeshot:</Typography>
                        <TextField
                            id="outlined-select-currency"
                            select
                            name='racerFor'
                            disabled={selectedEventToWager?.sports_data.id === 1 ? wagerInput?.divisionId === '' || wagerInput?.divisionId === -1 : false}
                            value={wagerInput?.racerFor === '' ? 0 : wagerInput?.racerFor}
                            //defaultValue={0}
                            onChange={(e) => riderOneFunction(e)}
                            sx={{
                                //color:`${theme.palette.dark.otherlight}`,
                                width: '100%',
                                '.MuiSelect-select': {
                                    padding: '5px 5px 5px 10px', // Adjust this value as needed
                                    fontSize: '0.75rem', // Font size for the input text
                                },
                                '& .MuiInputLabel-root': { // If you're using a label
                                    fontSize: '0.75rem', // Font size for the label
                                }
                            }}
                        >

                            <MenuItem value={0} disabled>

                                {
                                    selectedEventToWager.sportsId === 1
                                        ?
                                        "Select a Rider!"
                                        :

                                        "Select a Racer!"
                                }
                            </MenuItem>
                            {wagerInput?.divisionId && privateerOptions?.map((option) => {
                                if (wagerInput?.racerAgainst != option.racerId) {
                                    return (
                                        <MenuItem key={option?.racerId} value={option?.racerId}>
                                            {option?.privateer_data?.name}
                                        </MenuItem>
                                    )
                                }

                            }
                            )}
                        </TextField>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>

                            {
                                privateWager ?
                                    <>
                                        <Typography variant='caption'>{roomDetails?.length} {roomDetails?.length === 1 ? 'user' : 'users'} can accept</Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            defaultValue={roomDetails[0].userId}
                                            sx={{
                                                //color:`${theme.palette.dark.otherlight}`,
                                                width: '100%',
                                                '.MuiSelect-select': {
                                                    padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                    fontSize: '0.75rem', // Font size for the input text
                                                },
                                                '& .MuiInputLabel-root': { // If you're using a label
                                                    fontSize: '0.75rem', // Font size for the label
                                                }
                                            }}
                                        >
                                            {roomDetails.map((user) => (
                                                <MenuItem key={user.userId} value={user.userId} disabled>
                                                    @{user.user_data.username}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </>
                                    :
                                    <>
                                        <Typography variant='body2'># of users that can join</Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            name="maxPeople"
                                            value={wagerInput?.maxPeople === '' ? 1 : wagerInput?.maxPeople}
                                            //defaultValue={1}
                                            onChange={(e) => peopleListFunction(e)}
                                            sx={{
                                                //color:`${theme.palette.dark.otherlight}`,
                                                width: '100%',
                                                '.MuiSelect-select': {
                                                    padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                    fontSize: '0.75rem', // Font size for the input text
                                                },
                                                '& .MuiInputLabel-root': { // If you're using a label
                                                    fontSize: '0.75rem', // Font size for the label
                                                }
                                            }}
                                        >
                                            {peopleList?.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option == 1 ? option + " user" : option + " users"}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </>
                            }
                        </Box>


                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>Multiplier</Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                name="multiplierId"
                                value={wagerInput?.multiplierId === '' ? multipierList[0].multiplier : wagerInput?.multiplierId}
                                //defaultValue={multipierList[0].multiplier}
                                onChange={(e) => multiplierFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >
                                {multipierList?.map((option, index) => (
                                    <MenuItem key={index} value={option?.id}>
                                        {option?.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '4px'
                    }}>
                        <Typography variant='body2'>Select your wager amount</Typography>

                        <TextField
                            name="chipsBet"
                            value={wagerInput?.chipsBet}
                            onChange={(e) => chipsFunction(e)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <span style={{ fontSize: '0.75rem', color: theme.palette.dark.otherlight }}>chips</span>
                                    </InputAdornment>
                                ),
                                sx: {
                                    input: {
                                        height: '22.5px',
                                        fontSize: '0.75rem', // Font size for the input text
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                    }
                                }
                            }}
                        />
                    </Box>
                </>
            )
            break;
        case 5:
            return (
                <>

                    {
                        selectedEventToWager?.sports_data.id === 1 &&
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>Division</Typography>

                            <TextField
                                name="divisionId"
                                id="outlined-select-currency"
                                select
                                value={wagerInput?.divisionId === '' ? -1 : wagerInput?.divisionId}
                                //defaultValue={-1}
                                onChange={(e) => divisionFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >
                                <MenuItem value={-1} disabled>
                                    Select a Division!
                                </MenuItem>
                                {divisionOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    }
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '4px'
                    }}>
                        <Typography variant='body2'>I believe that ___ will place in the podium:</Typography>
                        <TextField
                            id="outlined-select-currency"
                            select
                            name='racerFor'
                            disabled={selectedEventToWager?.sports_data.id === 1 ? wagerInput?.divisionId === '' || wagerInput?.divisionId === -1 : false}
                            value={wagerInput?.racerFor === '' ? 0 : wagerInput?.racerFor}
                            //defaultValue={0}
                            onChange={(e) => riderOneFunction(e)}
                            sx={{
                                //color:`${theme.palette.dark.otherlight}`,
                                width: '100%',
                                '.MuiSelect-select': {
                                    padding: '5px 5px 5px 10px', // Adjust this value as needed
                                    fontSize: '0.75rem', // Font size for the input text
                                },
                                '& .MuiInputLabel-root': { // If you're using a label
                                    fontSize: '0.75rem', // Font size for the label
                                }
                            }}
                        >

                            <MenuItem value={0} disabled>

                                {
                                    selectedEventToWager.sportsId === 1
                                        ?
                                        "Select a Rider!"
                                        :

                                        "Select a Racer!"
                                }
                            </MenuItem>
                            {(selectedEventToWager.sportsId != 1 || wagerInput?.divisionId) && privateerOptions?.map((option) => {
                                if (wagerInput?.racerAgainst != option.racerId) {
                                    return (
                                        <MenuItem key={option?.racerId} value={option?.racerId}>
                                            {option?.privateer_data?.name}
                                        </MenuItem>
                                    )
                                }

                            }
                            )}
                        </TextField>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>


                            {
                                privateWager ?
                                    <>
                                        <Typography variant='caption'>{roomDetails?.length} {roomDetails?.length === 1 ? 'user' : 'users'} can accept</Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            defaultValue={roomDetails[0].userId}
                                            sx={{
                                                //color:`${theme.palette.dark.otherlight}`,
                                                width: '100%',
                                                '.MuiSelect-select': {
                                                    padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                    fontSize: '0.75rem', // Font size for the input text
                                                },
                                                '& .MuiInputLabel-root': { // If you're using a label
                                                    fontSize: '0.75rem', // Font size for the label
                                                }
                                            }}
                                        >
                                            {roomDetails.map((user) => (
                                                <MenuItem key={user.userId} value={user.userId} disabled>
                                                    @{user.user_data.username}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </>
                                    :
                                    <>
                                        <Typography variant='body2'># of users that can join</Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            name="maxPeople"
                                            value={wagerInput?.maxPeople === '' ? 1 : wagerInput?.maxPeople}
                                            //defaultValue={1}
                                            onChange={(e) => peopleListFunction(e)}
                                            sx={{
                                                //color:`${theme.palette.dark.otherlight}`,
                                                width: '100%',
                                                '.MuiSelect-select': {
                                                    padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                    fontSize: '0.75rem', // Font size for the input text
                                                },
                                                '& .MuiInputLabel-root': { // If you're using a label
                                                    fontSize: '0.75rem', // Font size for the label
                                                }
                                            }}
                                        >
                                            {peopleList?.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option == 1 ? option + " user" : option + " users"}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </>
                            }
                        </Box>


                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>
                            <Typography variant='body2'>Multiplier</Typography>
                            <TextField
                                id="outlined-select-currency"
                                select
                                name="multiplierId"
                                value={wagerInput?.multiplierId === '' || wagerInput?.multiplierId === null ? multipierList[0].multiplierId : wagerInput?.multiplierId}
                                //defaultValue={multipierList[0].multiplier}
                                onChange={(e) => multiplierFunction(e)}
                                sx={{
                                    //color:`${theme.palette.dark.otherlight}`,
                                    width: '100%',
                                    '.MuiSelect-select': {
                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        fontSize: '0.75rem', // Font size for the input text
                                    },
                                    '& .MuiInputLabel-root': { // If you're using a label
                                        fontSize: '0.75rem', // Font size for the label
                                    }
                                }}
                            >
                                {multipierList?.map((option, index) => (
                                    <MenuItem key={index} value={option?.id}>
                                        {option?.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Box>


                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '4px'
                        }}>

                            <Typography variant='body2'>Select your wager amount (Chips Available: {walletBalance})</Typography>

                            <TextField
                                name="chipsBet"
                                value={wagerInput?.chipsBet}
                                onChange={(e) => chipsFunction(e)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <span style={{ fontSize: '0.75rem', color: theme.palette.dark.otherlight }}>chips</span>
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        input: {
                                            height: '22.5px',
                                            fontSize: '0.75rem', // Font size for the input text
                                            padding: '5px 5px 5px 10px', // Adjust this value as needed
                                        }
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                    {
                        ((wagerInput?.chipsBet * multipierList[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople) > walletBalance) &&
                        <Alert severity='error'>This wager will cost you: {wagerInput?.chipsBet * multipierList[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople} chips. <br />You don't have enough!</Alert>
                    }

                    {
                        ((wagerInput?.chipsBet * multipierList[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople) > 1000) && walletBalance > 1000 &&
                        <Alert severity='error'>Wagers more than 1,000 chips are not allowed!</Alert>
                    }

                    {
                        (wagerInput?.chipsBet < 10) &&
                        <Alert severity='error'>Wagers less than 10 chips are not allowed!</Alert>
                    }
                </>
            )
            break;

        default:
            break;
    }
}

export default WagerTypeForms
