import { WAGER_DETAILS_REQUEST, WAGER_DETAILS, LIST_MY_WAGERS_REQUEST, ACCEPT_WAGEER, ACCEPT_WAGEER_ERROR } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import WagersServices from '../../../services/wagerdetails'

export const wagerdetails = createAsyncThunk(
  '/wagers/getbyid',
  async ({ id }, thunkAPI) => {
    try {
      thunkAPI.dispatch({
        type: WAGER_DETAILS_REQUEST
      })
      // thunkAPI.dispatch({
      //   type: ACCEPT_WAGEER,
      //   payload: {}
      // })
      const data = await WagersServices.getWagerDetails({ id })
      thunkAPI.dispatch({
        type: "WAGER_DETAILS",
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: ACCEPT_WAGEER_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const acceptWagger = createAsyncThunk(
  '/wagers/acceptWagger',
  async ({ id, status, user }, thunkAPI) => {
    thunkAPI.dispatch({
      type: "WAGER_DETAILS_REQUEST"
    })
    try {

      const data = await WagersServices.acceptWagger({ id, status })
      
      switch (data?.data?.result?.type) {
        case 'public':
          thunkAPI.dispatch({
            type: "SET_WAGER_TO_ACCEPT",
            payload: data && { ...data?.data }
          })
          thunkAPI.dispatch({
            type: ACCEPT_WAGEER,
            payload: data && { ...data?.data, id }
          })
          break;
        case 'private':
          thunkAPI.dispatch({
            type: ACCEPT_WAGEER,
            payload: data && { ...data?.data, id }
          })
          break;
        default:
          break;
      }

      return { user: data && data?.data }

    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: ACCEPT_WAGEER,
        payload: {
          error: 1,
          message
        }
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const resetAcceptWagerError = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_ACCEPT_WAGER"
    })
  }
}
