
const initialState = {
  isLoading: false
}
export default function allPost(state = initialState, action) {
  switch (action.type) {

    case 'GET_ALL_POST_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }

    case 'GET_ALL_POST': {
      return {
        ...state,
        isLoading: false,
        posts: action.payload
      }
    }

    case 'UPDATE_POST_FEED_W_CREATED_POST': {
      return {
        ...state,
        isLoading: false,
        posts: {
          ...state?.posts,
          result: [
            {
              ...action.payload
            },
            ...state?.posts.result
          ]
        }
      }
    }

    case 'DELETE_POST': {
      return {
        ...state,
        isLoading: false,
        posts: {
          ...state.posts,
          result: state.posts.result.filter(post => post.id !== action.payload.id)
        }
      }
    }
    

    case 'GET_ALL_POST_ERROR': {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }

    default:
      return state
  }
}
