import axios from 'axios'
import authHeader from './auth-header'

const addToken = ({ endpoint, expirationTime, keys }) => {
  console.log('BENIS', { endpoint, expirationTime, keys })
  return axios.post('notification/add-token', { endpoint, expirationTime, keys }, {
    headers: authHeader()
  })
}
const updateStatus = () => {
  return axios.post('notification/update-status', {}, {
    headers: authHeader()
  })
}

const listNotifications = (limit, skip) => {
  return axios.post('notification/list', { limit, skip }, {
    headers: authHeader()
  })
}
const updateIsCliked = (id) => {
  return axios.post(`notification/notification-status/${id}`, {
    id
  }, {
    headers: authHeader()
  })
}

const clearNotification = (id) => {
  return axios.delete('notification/clearnotification', {
    headers: authHeader()
  })
}

const NotificationScreenServices = {
  addToken,
  updateStatus,
  listNotifications,
  updateIsCliked,
  clearNotification
}
export default NotificationScreenServices
