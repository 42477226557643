import React, { useEffect, useRef, useState } from 'react'
import AppLayout from '../../components/layouts/app'
import { Box, Button, Container, Fab, List, Tab, Tabs, Typography } from '@mui/material'
import DashboardHeader from '../../components/header/dashboard-header';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { listAllPosts } from '../../redux/actions/Posts';
import PostItem from '../../components/Post/post-item';
import LikeServices from '../../services/like';
import { LoadingButton } from '@mui/lab';
import Loader from '../../components/loader';
import MessageScreen from '../messaging/messageScreen';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useSocket } from '../../components/hooks/useSocket';
import { setChat } from '../../redux/actions/chats';
import AddIcon from '@mui/icons-material/Add';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <>
            {value === index && (
                <Box sx={props.sx}
                    role="tabpanel"
                    hidden={value !== index}
                    id={`simple-tabpanel-${index}`}
                    aria-labelledby={`simple-tab-${index}`}
                    {...other}>
                    {children}
                </Box>
            )}
        </>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const SocialPage = () => {
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('user'))
    const socket = useSocket()
    const dispatch = useDispatch()
    let [searchParams, setSearchParams] = useSearchParams();
    let location = useLocation()

    const [postLimit, setPostLimit] = React.useState(10);
    const [value, setValue] = React.useState(searchParams.has('dms') ? 1 : 0);

    const postResponseData = useSelector((state) => state.allPost)

    const [postLikeId, setPostLikeId] = useState([])

    const [loader, setLoader] = useState(false)

    const [postCounter, setPostCounter] = useState(0)

    const [showHeader, setShowHeader] = useState(true);
    const lastScrollY = useRef(0);
    const [showScrollToTopButton, setShowScrollToTopButton] = useState(false); // New state for the button visibility
    const scrollingContainerRef = useRef(null); // Ref for the scrolling div
    const scrollTimeout = useRef(null); // Ref to hold the timeout ID

    useEffect(() => {
        setValue(searchParams.has('dms') ? 1 : 0)
    }, [location]);

    const controlHeaderVisibility = () => {
        if (scrollingContainerRef.current) {
            let currentScrollY = scrollingContainerRef.current.scrollTop;

            // Show or hide the header based on scroll direction
            if (currentScrollY < lastScrollY.current) {
                setShowHeader(true);
            } else if (currentScrollY > lastScrollY.current) {
                setShowHeader(false);
            }

            // Determine whether to show the "Scroll to Top" button
            setShowScrollToTopButton(currentScrollY > 100); // Example threshold

            if (scrollTimeout.current) {
                clearTimeout(scrollTimeout.current);
            }

            scrollTimeout.current = setTimeout(() => {
                setShowHeader(true);
            }, 500);

            lastScrollY.current = currentScrollY;
        }
    };

    useEffect(() => {
        const scrollingContainer = scrollingContainerRef.current;
        if (scrollingContainer) {
            scrollingContainer.addEventListener('scroll', controlHeaderVisibility);

            return () => {
                scrollingContainer.removeEventListener('scroll', controlHeaderVisibility);
                if (scrollTimeout.current) {
                    clearTimeout(scrollTimeout.current);
                }
            };
        }
    }, []);

    // Function to scroll to the top
    const scrollToTop = () => {
        if (scrollingContainerRef.current) {
            scrollingContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        dispatch(listAllPosts({
            limit: postLimit
        }))
        socket.emit('messagelist', { uid: user?.id })
        socket.emit('something', { uid: user?.id })
        socket.on('something', data => {
            dispatch(setChat({ result: data.slice(0, 10), totalCount: data?.length || 0 }))
            //setIsLoading(false)

        })
    }, []);

    useEffect(() => {
        if (postResponseData?.posts?.result) {
            const array2 = []
            for (let i = 0; i < postResponseData?.posts?.result.length; i++) {
                if (postResponseData?.posts?.result[i].isPostLikeByMe) {
                    array2.push(postResponseData?.posts?.result[i].id)
                }
            }
            setPostLikeId(array2)
        }
    }, [postResponseData?.posts?.result])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handlePostLike = (id) => {
        if (postLikeId.includes(id)) {
            const array = postLikeId.filter(item => item !== id)
            setPostLikeId(array)
            // }
        } else {
            setPostLikeId([...postLikeId, id])
        }

        LikeServices.postLike(id).then((result) => {
        }).catch((err) => {
            console.log(err, 'error')
        })
    }

    const fetchMoreData = () => {
        setLoader(true)
        setPostCounter(postCounter + 1)
        setTimeout(() => {
            setPostLimit(postLimit + 10)
            setLoader(false)
        }, 1000)
    }


    useEffect(() => {
        dispatch(listAllPosts({
            limit: postLimit
        }))
    }, [postLimit])

    const marginTopContainers = '96px'

    return (
        <AppLayout>
            <Box
                ref={scrollingContainerRef}
                sx={{
                    height: 'inherit',
                    pb: 15,
                    overflow: 'scroll',
                    position: 'relative'
                }}>

                {
                    postResponseData.isLoading && postCounter === 0 ?
                        <Loader isloading={true} />
                        :
                        <>
                            <Box
                                sx={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    zIndex: 99,
                                    bgcolor: 'black',
                                    transform: (showHeader) ? 'translateY(0)' : 'translateY(-100%)',
                                    transition: 'transform 0.3s ease-in-out'
                                }}>
                                <DashboardHeader />
                                <Tabs
                                    onChange={handleChange}
                                    sx={{
                                        width: '100%'
                                    }}
                                    value={value}
                                >
                                    <Tab label="Posts" sx={{ width: '50%', fontWeight: 700 }} />
                                    <Tab label="DM's" sx={{ width: '50%', fontWeight: 700 }} />
                                </Tabs>
                                {showScrollToTopButton && (
                                    <Container sx={{ pt: 2, pb: 1 }}>
                                        <Button
                                            //endIcon={<ArrowCircleUpIcon />}
                                            size='small'
                                            onClick={scrollToTop}
                                            fullWidth
                                            variant='outlined'>
                                            Scroll to top
                                        </Button>
                                    </Container>
                                )}
                            </Box>


                            <Box sx={{ pt: 12, pb: 14, boxSizing: 'border-box' }}>
                                <CustomTabPanel value={value} index={0} sx={{ height: 'fit-content' }}>
                                    <List>
                                        {
                                            postResponseData?.posts?.result?.map((post, index) => (
                                                <PostItem
                                                    key={index}
                                                    likeFunction={() => handlePostLike(post?.id)}
                                                    data={{
                                                        ...post,
                                                        likeId: postLikeId
                                                    }} />
                                            ))
                                        }
                                    </List>
                                    {
                                        postLimit < postResponseData?.posts?.count
                                        &&
                                        <Container>
                                            <LoadingButton
                                                loading={postResponseData?.isLoading || loader}
                                                onClick={() => fetchMoreData()}
                                                fullWidth
                                                variant='outlined'>
                                                Load more
                                            </LoadingButton>
                                        </Container>
                                    }
                                </CustomTabPanel>

                                <CustomTabPanel value={value} index={1} sx={{ position: 'relative' }}>
                                    <MessageScreen />
                                </CustomTabPanel>
                            </Box>
                        </>
                }
            </Box>
        </AppLayout>
    )
}

export default SocialPage
