import React, { useState, useEffect, createRef, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import CelebrationIcon from '@mui/icons-material/Celebration';
import { useSelector, useDispatch } from 'react-redux'
import { commentOnWagerNotifcation, deactivateWager, incrementCommentCount, resetDeactivateWager } from '../../redux/actions/wagers'
import { wagerdetails } from '../../redux/actions/wagerdetails'
import { isWagerClose, urlTypeIdentifier, isValidUrl } from '../../helper'
import db from '../../firebase'
import handleBucket from '../../components/hooks/useBucket'
import {
  doc,
  setDoc,
  onSnapshot,
  collection,
  deleteDoc,
  Timestamp,
  query,
  orderBy
} from 'firebase/firestore'
import Loader from '../../components/loader'
// import Compressor from 'compressorjs'
import ReadMore from '../../components/textShow'
import { Container, Button, Stack, Box, Typography, IconButton, Avatar, Chip, Divider } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite';
import WagerTeamItem from '../../components/wager/team-item'
import ProfilePill from '../../components/wager/profile-pill'
import { useTheme } from '@emotion/react'
import SendIcon from '@mui/icons-material/Send';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { AddBoxRounded } from '@mui/icons-material';

function MyWragerDetails(props) {

  const { id: propId } = props;
  const { id: paramId } = useParams();
  const id = propId !== undefined ? propId : paramId;
  const bottomRef = useRef(null)
  const theme = useTheme()
  const navigate = useNavigate()
  const userId = id
  const [show, setShow] = useState(false)
  const [comment, setComment] = useState(null)
  const [commentList, setCommentList] = useState([])
  const [selectedWager, setWagerDetails] = useState({})
  const [replyTo, setReplyTo] = useState({})
  const [repliesList, setRepliesList] = useState({})
  const [commentVotes, setCommentVotes] = useState({})
  const [commentRepliesVotes, setCommentRepliesVotes] = useState({})
  // const deactivateStatus = useSelector((state) => state?.deactivateWager)
  const inputRef = createRef()
  const dispatch = useDispatch()
  const [media, setMedia] = useState(null)

  const handleClose = () => {
    setShow(false)
  }
  const handleDeactivation = () => {
    dispatch(deactivateWager({ id: userId }))
  }
  const handleResetDeactivation = () => {
    navigate('/my-wagers')
    dispatch(resetDeactivateWager())
  }

  // const handleShow = () => {
  //   if (selectedWager?.peopleJoined === 0 && selectedWager?.status !== 'closed') {
  //     dispatch(resetDeactivateWager())
  //     setShow(true)
  //   }
  // }

  const deactivateWagerResponse = useSelector((state) => state.deactivateWager)

  const wagerdetailsinfo = useSelector((state) => state?.wagerDetails)



  const ordinalSuffix = (number) => {
    let j = number % 10,
      k = number % 100;
    if (j == 1 && k != 11) {
      return 'st';
    }
    if (j == 2 && k != 12) {
      return 'nd';
    }
    if (j == 3 && k != 13) {
      return 'rd';
    }
    return 'th';
  }

  const WinnerSwitcher = (wagerTypeId, data) => {
    switch (wagerTypeId) {
      case 1:
        //1v1
        return <><span style={{ color: theme.palette.success.main }}>Winner</span>.</>
        break;

      case 2:
        //1v1
        if (data?.placement === data?.placement_range) {
          return <>Places {data?.placement}{ordinalSuffix(data?.placement)}.</>

        }
        else {
          //<>Places between <span style={{ color: theme.palette.success.main }}>{data.placement}{ordinalSuffix(data.placement)}</span> and <span style={{ color: theme.palette.success.main }}>{data.placement_range}{ordinalSuffix(data.placement_range)}</span ></>
          return <>Places between <br />{data?.placement}{ordinalSuffix(data?.placement)} & {data?.placement_range}{ordinalSuffix(data?.placement_range)}.</>
        }
        break;

      case 3:
        //1v1 SPREAD
        return <><span style={{ color: theme.palette.success.main }}>Winner</span> by {data?.spreadOptions_data?.option} spots.</>
        break;

      case 4:
        //1v1 SPREAD
        return <><span style={{ color: theme.palette.success.main }}>Wins</span> the holeshot.</>
        break;

      case 5:
        //Podium <>Places <span style={{ color: theme.palette.success.main }}>Podium</span></>
        return <>Places Podium</>
        break;

      case 6:
        return <span style={{ color: theme.palette.success.main, fontWeight: 700 }}>Winner</span>
        break;

      default:
        break;
    }
  }

  const itemSizes = {
    fontSize: '10px',
    color: theme.palette.dark.light,
    fontWeight: 700
  }

  const LoserSwitcher = (wagerTypeId) => {
    switch (wagerTypeId) {
      case 1:
        //1v1
        return <><span style={{ color: theme.palette.error.main }}>Loser</span>.</>
        break;

      case 2:
        //1v1
        return "Loser."
        break;

      case 3:
        //1v1 SPREAD
        return `Loser.`
        break;

      case 6:
        return <><span style={{ color: theme.palette.error.main, fontWeight: 700 }}>Loser</span></>
        break;

      default:
        break;
    }
  }


  const toOrdinalSuffix = (num) => {
    const int = parseInt(num)
    const digits = [int % 10, int % 100]
    const ordinals = ['st', 'nd', 'rd', 'th']
    const oPattern = [1, 2, 3, 4]
    const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
      ? int + ordinals[digits[0] - 1]
      : int + ordinals[3]
  }

  const user = localStorage.getItem('user')

  const handleComment = async (e) => {
    try {
      if (comment?.trim()?.length) {
        const singleComment = {
          user: JSON.parse(user),
          wagerId: id,
          text: comment,
          date: Timestamp.now(),
          id: Math.random() * 1000
        }
        if (replyTo?.user) {
          singleComment.replyTo = replyTo.user
          setDoc(doc(db, 'wagers', id, 'comments', replyTo?.commentId ? replyTo?.commentId : replyTo.id, 'replies', `${Math.floor(Math.random() * 1000000)}`), { ...singleComment })
          dispatch(commentOnWagerNotifcation({
            wagerId: id,
            type: 'reply'
          }))
        } else {
          setDoc(doc(db, 'wagers', id, 'comments', `${Math.floor(Math.random() * 1000000)}`), { ...singleComment })
          dispatch(commentOnWagerNotifcation({
            wagerId: id,
            type: 'comment'
          }))
        }
        setComment('')
        setReplyTo({})
        dispatch(incrementCommentCount(id))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getComments = async () => {
    try {
      const commentQuery = query(collection(db, 'wagers', id, 'comments'), orderBy('date', 'asc'))
      onSnapshot(commentQuery, (commentSnaps) => {
        const list = []
        commentSnaps.forEach((doc) => {
          list.push({ ...doc.data(), id: doc.id })
        })

        // fetching replies
        list.map(doc => {
          const replies = []
          const repliesQuery = query(collection(db, 'wagers', id, 'comments', doc.id, 'replies'), orderBy('date', 'asc'))

          onSnapshot(repliesQuery, (repliesSnap) => {
            repliesSnap.forEach(val => {
              if (replies.filter(value => value.id === val.id).length === 0) {
                replies.push({ ...val.data(), id: val.id, commentId: doc.id })
              }
            })

            const repliesObj = { [doc.id]: replies, ...repliesList }
            setRepliesList((prevState) => ({
              ...prevState,
              ...repliesObj
            }))

            // eslint-disable-next-line array-callback-return
            replies.map(doc => {
              onSnapshot(collection(db, 'wagers', id, 'comments', doc.commentId, 'replies', doc.id, 'vote'), (repCommentVoteSnap) => {
                const repliesVote = []
                repCommentVoteSnap.forEach(val => {
                  repliesVote.push({ ...val.data(), id: val.id })
                })
                const repliesVoteObj = { [doc.id]: repliesVote, ...commentRepliesVotes }
                setCommentRepliesVotes((prevState) => ({
                  ...prevState,
                  ...repliesVoteObj
                }))
              })
            })
            return true
          })
          // eslint-disable-next-line array-callback-return
          return true
        })

        // fetching votes of comments
        list.map(doc => {
          onSnapshot(collection(db, 'wagers', id, 'comments', doc.id, 'vote'), (votesSnap) => {
            const votes = []
            votesSnap.forEach(val => {
              votes.push({ ...val.data(), id: val.id })
            })
            const votesObj = { [doc.id]: votes, ...commentVotes }
            setCommentVotes((prevState) => ({
              ...prevState,
              ...votesObj
            }))
          })
          return true
        })
        setCommentList(list.sort(function (a, b) {
          return new Date(a?.date) - new Date(b?.date)
        }))
      }, error => {
        console.log(error)
      })
    } catch (error) {
      console.log(error)
    }
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleVote = async (comment, reply) => {
    const userInfo = JSON.parse(localStorage.getItem('user'))
    try {
      if (reply) {
        const validateUser = commentRepliesVotes[reply.id].filter(val => val.id.toString() === userInfo.id.toString())

        if (validateUser.length) {
          deleteDoc(doc(db, 'wagers', id, 'comments', comment.id, 'replies', reply.id, 'vote', `${userInfo.id}`))
        } else {
          setDoc(doc(db, 'wagers', id, 'comments', comment.id, 'replies', reply.id, 'vote', `${userInfo.id}`), { ...userInfo })
        }
      } else {
        const validateUser = commentVotes[comment.id].filter(val => val.id.toString() === userInfo.id.toString())
        if (validateUser.length) {
          deleteDoc(doc(db, 'wagers', id, 'comments', comment.id, 'vote', `${userInfo.id}`))
        } else {
          setDoc(doc(db, 'wagers', id, 'comments', comment.id, 'vote', `${userInfo.id}`), { ...userInfo })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleEnter = async (e) => {
    if (e.key === 'Enter') {
      if (comment !== null && comment.trim().length) {
        handleComment()
      } else {
        // setComment(null)
        return false
      }
    }
  }

  const wagerStatus = isWagerClose(selectedWager?.event_data?.startTime)

  useEffect(() => {
    // eslint-disable-next-line no-empty
    if (!Object.keys(deactivateWagerResponse).includes('Status') || deactivateWagerResponse?.Status === 'Fail') {

    } else {
      setShow(false)
    }
  }, [deactivateWagerResponse])

  useEffect(() => {
    if (id) {
      dispatch(wagerdetails({ id }))
      getComments()
    }
  }, [id, show])

  useEffect(() => {
    if (wagerdetailsinfo?.wager?.result) {
      setDoc(doc(db, 'wagers', id), { ...wagerdetailsinfo?.wager?.result })
      setWagerDetails(wagerdetailsinfo?.wager?.result)
    }
  }, [wagerdetailsinfo])

  useEffect(() => {
    if (media) {
      const dataUrl = handleBucket(media, 'wagers', id)
      const user = JSON.parse(localStorage.getItem('user'))
      dataUrl.then((data) => {
        const singleComment = {
          user,
          wagerId: id,
          text: data,
          date: Timestamp.now(),
          id: Math.random() * 1000
        }
        try {
          if (replyTo?.user) {
            setRepliesList((prevState) => ({
              ...prevState,
              [replyTo.id]: singleComment
            }))
            setDoc(doc(db, 'wagers', id, 'comments', replyTo.id, 'replies', `${Math.floor(Math.random() * 1000000)}`), { ...singleComment })
          } else {
            setDoc(doc(db, 'wagers', id, 'comments', `${Math.floor(Math.random() * 1000000)}`), { ...singleComment })
          }
          setComment('')
          setReplyTo({})
        } catch (error) {
          console.log(error)
        }
      })
      setMedia(null)
    }
  }, [media])

  useEffect(() => {
    if (commentList) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [commentList])
  
  return (
    <>
      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        pb: 10,
        pt: 2,
        height: "inherit",
        bgcolor: theme.palette.dark.dark,
        overflowY: 'scroll',
        maxWidth: '450px'
      }}>
        <Box sx={{
          display: 'flex',
          gap: '8px',
          gridTemplateColumns: '1fr 2fr',
        }}>

          <Box sx={{
            display: 'flex'
          }}>
            <Avatar
              src={wagerdetailsinfo?.wager?.result?.createdBy_data?.userLogo || null}
            />
          </Box>

          <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px'
          }}>

            <Stack>
              <Typography variant='body1'>@{wagerdetailsinfo?.wager?.result?.createdBy_data?.username}</Typography>
              <Stack direction='row' spacing={1}>
                <Chip
                  label={wagerdetailsinfo?.wager?.result?.event_data?.sports_data?.name}
                  size='small'
                  sx={{
                    padding: '1px 0',
                    height: 'fit-content',
                    width: 'fit-content',
                    fontSize: '10px'
                  }} />
                <Chip
                  label={wagerdetailsinfo?.wager?.result?.wagerType_data?.name}
                  size='small'
                  sx={{
                    padding: '1px 0',
                    height: 'fit-content',
                    width: 'fit-content',
                    fontSize: '10px'
                  }} />
                <Chip
                  label={wagerdetailsinfo?.wager?.result?.mode}
                  sx={{
                    padding: '1px 0',
                    height: 'fit-content',
                    width: 'fit-content',
                    fontSize: '10px'
                  }} />
              </Stack>
            </Stack>

            {
              wagerdetailsinfo?.wager?.result?.caption &&
              <Box>
                <Typography>{wagerdetailsinfo?.wager?.result?.caption}</Typography>
              </Box>
            }

            <Box sx={{
              width: '100%',
              bgcolor: theme.palette.dark.otherdark,
              borderRadius: '12px',
              p: 1.5,
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'space-between'
            }}>

              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: selectedWager?.wagerType_data?.id === 4 || selectedWager?.wagerType_data?.id === 5 || selectedWager?.wagerType_data?.id === 2 ? 'center' : 'center',
                gap: '8px'
              }}>


                <WagerTeamItem data={{
                  racer: selectedWager?.event_data?.sports_data?.type === "match" ? selectedWager.teamsFor_data : selectedWager.racerFor_data,
                  prediction: WinnerSwitcher(selectedWager?.wagerType_data?.id)
                }} />
                {

                  selectedWager?.wagerType_data?.id === 4 || selectedWager?.wagerType_data?.id === 5 || selectedWager?.wagerType_data?.id === 2 ?
                    null :
                    <WagerTeamItem data={{
                      racer: selectedWager?.event_data?.sports_data?.type === "match" ? selectedWager.teamsAgainst_data : selectedWager.racerAgainst_data,
                      prediction: LoserSwitcher(selectedWager?.wagerType_data?.id)
                    }} />

                }
              </Box>

              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                hegiht: '100%',
                //bgcolor: 'red'
                gap: '24px'
              }}>
                <ProfilePill max={selectedWager?.maxPeople} wagerId={selectedWager?.id} items={selectedWager?.wagerusers} size={24} />
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-end'
                }}>
                  <Typography variant='caption' sx={itemSizes}>{selectedWager?.division_data?.name}</Typography>
                  <Typography variant='caption' sx={itemSizes}>{selectedWager?.event_data?.name}</Typography>
                  <Typography variant='caption' sx={itemSizes}>{selectedWager?.multiplier_data?.name} Multiplier</Typography>
                  <Typography variant='caption' sx={{ ...itemSizes, color: 'white' }}>{selectedWager?.chipsBet} chips to join</Typography>
                </Box>
              </Box>
            </Box>

            {
              false &&
              <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  color: 'white'
                }}>
                  <IconButton sx={{
                    m: 0, p: 0,
                    color: theme.palette.dark.otherlight
                  }}>
                    <FavoriteIcon fontSize='small' sx={{ color: 'white' }} />
                  </IconButton>
                  <Typography
                    sx={{
                      color: 'white'
                    }}>
                    1
                  </Typography>
                </Box>
                <Button variant='contained' size='small'>Accept wager</Button>
              </Box>
            }

          </Box>

        </Box>

        <Divider />
        {
          commentList.length === 0 ?
            <Stack
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                //bgcolor:'red'
              }}>
              <CelebrationIcon fontSize='large' sx={{ color: "white" }} />
              <Typography>Be the first to talk smack!</Typography>
            </Stack>
            :
            selectedWager?.id && commentList?.map((val, index) => {
              return (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      gap: '12px',
                      pt: .5,
                      pb: .5
                    }}>
                    <Avatar
                      src={val?.user?.userLogo || null}
                    />
                    <Box sx={{
                      width: '100%'
                    }}>
                      <Box sx={{
                        mt: -1
                      }}>
                        <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>@{val?.user?.username}</Typography>
                        <Typography variant='body1'
                          sx={{
                            mt: -.5,
                            fontWeight: 700,
                            overflowWrap: 'break-word',
                            wordBreak: "break-word", /* To break the word */
                            hyphens: "auto", /* Automatic hyphenation when the word breaks */
                            overflowWrap: "anywhere"
                          }}>
                          {isValidUrl(val.text) ? urlTypeIdentifier(val.text) === 'i' ? <img src={val.text} alt='data' height='200px' width='215px' style={{ display: 'block' }} /> : <a href={val.text}>{val.text} </a> : <ReadMore>{val.text}</ReadMore>}</Typography>
                      </Box>

                      <Stack direction='row' spacing={1} sx={{ mt: .5 }} alignItems={"center"}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                          color: 'white'
                        }}>
                          {
                            <IconButton
                              onClick={() => handleVote(val, null)}
                              sx={{
                                m: 0, p: 0,
                                color: theme.palette.dark.otherlight
                              }}>
                              {
                                commentVotes[val?.id]?.filter(val => val.id.toString() === JSON.parse(localStorage.getItem('user')).id.toString()).length > 0 ?
                                  <FavoriteIcon fontSize='small' sx={{ color: 'white' }} />
                                  :
                                  <FavoriteBorderIcon fontSize='small' />
                              }
                            </IconButton>
                          }
                          {
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'white'
                              }}>
                              {commentVotes[val?.id]?.length} {commentVotes[val?.id]?.length > 1 ? 'Likes' : commentVotes[val?.id]?.length === 0 ? 'Likes' : 'Like'}
                            </Typography>
                          }
                        </Box>
                        {
                          <Button
                            onClick={() => {
                             // inputRef.current.focus()
                              setReplyTo(val)
                            }}
                            variant='text'
                            size="small"
                            sx={{
                              pt: 0,
                              pb: 0,
                              pl: .5,
                              pr: .5
                            }}>
                            Reply
                          </Button>
                        }
                      </Stack>
                    </Box>
                  </Box>

                  {
                    repliesList[val.id]?.length > 0 && repliesList[val.id].map(value => {
                      // eslint-disable-next-line no-lone-blocks
                      return (
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          gap: '12px',
                          pt: .5,
                          pb: .5,
                          pl: 6
                        }}>

                          <Avatar
                            onClick={() => navigate(`/user-profile/${value.user.id}`)}
                            src={value?.user?.userLogo || null}
                          />

                          <Box sx={{
                            width: '100%'
                          }}>
                            <Box sx={{
                              mt: -1
                            }}>
                              <Typography variant='body2'
                                onClick={() => navigate(`/user-profile/${value.user.id}`)}
                                sx={{ color: theme.palette.dark.otherlight }}>
                                @{value?.user?.username}
                              </Typography>
                              <Typography variant='body1'
                                sx={{
                                  mt: -.5,
                                  fontWeight: 700,
                                  overflowWrap: 'break-word',
                                  wordBreak: "break-word", /* To break the word */
                                  hyphens: "auto", /* Automatic hyphenation when the word breaks */
                                  overflowWrap: "anywhere"
                                }}>
                                {isValidUrl(value.text) ? urlTypeIdentifier(value.text) === 'i' ? <img src={value.text} alt='data' height='200px' width='215px' style={{ display: 'block' }} /> : <a href={value.text}>{value.text} </a> : <ReadMore>{value.text}</ReadMore>}</Typography>
                            </Box>
                            {
                              /*
<Stack direction='row' spacing={1} sx={{ mt: .5 }}>
                              <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                color: 'white'
                              }}>
                                {
                                  <IconButton
                                    onClick={() => handleVote(val,)}
                                    sx={{
                                      m: 0, p: 0,
                                      color: theme.palette.dark.otherlight
                                    }}>
                                    {
                                      commentRepliesVotes[value?.id]?.filter(val => val.id.toString() === JSON.parse(localStorage.getItem('user')).id.toString()).length > 0 ?
                                        <FavoriteIcon fontSize='small' sx={{ color: 'white' }} />
                                        :
                                        <FavoriteBorderIcon fontSize='small' />
                                    }
                                  </IconButton>
                                }
                                {
                                  <Typography
                                    sx={{
                                      color: 'white'
                                    }}>
                                    {commentRepliesVotes[value.id]?.length} {commentRepliesVotes[value.id]?.length > 1 ? 'Likes' : 'Like'}
                                  </Typography>
                                }
                              </Box>
                            </Stack>
                              */
                            }
                          </Box>
                        </Box>
                      )
                    })
                  }
                </>
              )
            })
        }
      </Container >

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%'
      }}>
        <Container
          sx={{
            maxWidth: '450px',
            width: '100%',
            pt: 1,
            pb: 3,
          }}
        >
          <Box
            component="form"
            sx={{
              bgcolor: 'black',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '100px',
              pl: 1,
              pr: 1,
              boxSizing: 'border-box',
              border: `1px solid ${theme.palette.dark.otherlight}`
            }}
          >
            <InputBase
              multiline
              value={comment}
              onChange={(e) => { if (e.target.value !== null && e.target.value.trim().length) { setComment(e.target.value) } else { setComment('') } }}
              onKeyDown={handleEnter}
              sx={{
                ml: 1,
                flex: 1,
                fontSize: '14px',
                width: `${comment ? '70%' : '100%'}`
              }}
              placeholder={replyTo && replyTo.user ? `Reply to @${replyTo?.user?.username}` : `add a comment`}
              inputProps={{ 'aria-label': 'search google maps' }}
            />

            { //comment
              true &&
              <IconButton onClick={handleComment}>
                <SendIcon fontSize='small' />
              </IconButton>
            }
          </Box>
        </Container>
      </Box>

    </>
  )
}

export default MyWragerDetails


/*





      <Box
        sx={{
          bgcolor: 'black',
          pt: 1,
          pb: 3,
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          height: 'fit-content'
        }}>
        <Container sx={{
          width: '100%',
          maxWidth: '450px',
          height: 'fit-content'
        }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              pr: .5,
              pl: 2,
              boxSizing: 'border-box',
              width: '100%',
              bgcolor: 'blue',
              border: `2px solid ${theme.palette.dark.otherlight}`,
              borderRadius: '100px',
              height: 'fit-content'
            }}
          >


            <InputBase
              ref={inputRef}
              multiline
              onChange={(e) => { if (e.target.value !== null && e.target.value.trim().length) { setComment(e.target.value) } else { setComment('') } }}
              onKeyDown={handleEnter}
              sx={{
                ml: 1,
                flex: 1,
                width: `${comment ? '70%' : '100%'}`
              }}
              placeholder={replyTo && replyTo.user ? `Reply for @${replyTo?.user?.username}` : `add a comment for @${selectedWager?.createdBy_data?.username}`}
              inputProps={{ 'aria-label': 'search google maps' }}
            />
            { //comment
              true &&
              <IconButton onClick={handleComment}>
                <SendIcon fontSize='small' />
              </IconButton>
            }
          </Box>
        </Container>
      </Box>
*/