import { SET_SPORT_REQUEST, SET_SPORT, SET_SPORT_ERROR } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
// import eventService from '../../../services/eventId'

export const sportAction = createAsyncThunk(
  '/setSport',
  async ({ id, name, className }, thunkAPI) => {
    thunkAPI.dispatch({
      type: SET_SPORT_REQUEST
    })
    try {
    //   const data = await eventService.getEventId(id)
      thunkAPI.dispatch({
        type: SET_SPORT,
        payload: { id, name, className }
      })
    //   return { code: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: SET_SPORT_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)


export const setSports = (sports) => {
  // return {
  //     type: 'SET_LEAGUES',
  //     payload: sports
  // }
}

export const setSelectedSport = (sport_name) => {
  return {
      type: 'SET_SELECTED_SPORT',
      payload: sport_name
  }
}

export const setSportId = (id) => {
  return {
      type: 'SET_SPORT_ID',
      payload: id
  }
}