
const initialState = {
  isLoading: false
}
export default function postDeletion (state = initialState, action) {
  switch (action.type) {

    case 'REQUEST_DELETE_POST': {
      return {
        ...state,
        isLoading: true
      }
    }

    case 'DELETE_POST_ERROR': {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }

    case 'RESET_POST_DELETION_ERROR': {
      return {
        ...initialState
      }
    }
    
    default:
      return state
  }
}
