import axios from './axios'
import authHeader from './auth-header'

const getFaq = () => {
  return axios.post('faqs/list',
    {}
    , {
      headers: authHeader()
    })
}

const faqService = {
  getFaq
}

export default faqService
