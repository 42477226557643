import { Avatar, Box, Button, Chip, Container, ListItemButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import FeedSponsor from '../sponsors/feed-sponsor';
import { useTheme } from '@emotion/react';
import ReactSwipe from 'react-swipe';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import theme from '../../styles/theme';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import WagerCard from '../wager/feed-item';
import { isWagerClose } from '../../helper';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCreationWagerModeToFantasy } from '../../redux/actions/wagers';
import { openModal } from '../../redux/actions/modal';
import { setCreateWagerCurrentLeague } from '../../redux/actions/leagues';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import StadiumIcon from '@mui/icons-material/Stadium';


const EventAd = ({ data, icon, wagers }) => {

    const dispatch = useDispatch()

    const handleToggleCreateWager = () => {
        dispatch(setCreationWagerModeToFantasy())
        dispatch(openModal())
        dispatch(setCreateWagerCurrentLeague(data?.id))
    }

    return (
        <Stack
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
                //bgcolor: 'red',
                height: '100%'
            }}>
            <Stack direction='column' alignItems={'center'} spacing={1}>
                <Avatar
                    sx={{
                        bgcolor: theme.palette.primary.main
                    }}>
                    {icon}
                </Avatar>
                <Stack
                    sx={{ color: 'white' }}
                    spacing={-.25}
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <Typography variant='body1'>{`Wagering for ${data?.name} is LIVE`}</Typography>
                    {
                        wagers?.length > 0
                        &&
                        <>
                            <Typography variant='caption'>{`Swipe to see some ${data?.name} wagers`}</Typography>
                            <ArrowRightAltIcon />
                        </>
                    }

                </Stack>
                <Button
                    onClick={() => handleToggleCreateWager()}
                    size='small'
                    variant='contained'>
                    {
                        wagers?.length > 0
                            ?
                            'Place a wager'
                            :
                            'Be the first to create a wager!'
                    }
                </Button>
            </Stack>
        </Stack>
    )
}

const AdLayout = ({ type, children }) => {
    const padding = '24px 16px 24px 16px'
    const theme = useTheme()
    switch (type) {
        case 'single':
            return (
                <Box sx={{
                    padding: padding,
                    borderBottom: `1px solid ${theme.palette.dark.otherdark}`,
                }}>
                    {children}
                </Box>
            )
        case 'active-events':
            return (
                <Box sx={{
                    paddingTop: '24px',
                    paddingBottom: '24px',
                    borderBottom: `1px solid ${theme.palette.dark.otherdark}`,
                }}>
                    {children}
                </Box>
            )
        case 'carousel':
            return (
                <Container sx={{
                    paddingTop: '24px',
                    paddingBottom: '24px',
                    borderBottom: `1px solid ${theme.palette.dark.otherdark}`,
                }}>
                    {children}
                </Container>
            )
        default:
            break;
    }
}

const AdSwitcher = ({ data, wagerData, grouped }) => {

    let reactSwipeEl = useRef(null);

    const theme = useTheme()

    const iconStyle = {
        color: 'white',
        width: '20px'
    }

    const nav = [
        {
            title: 'MOTOX',
            icon: <SportsMotorsportsIcon sx={iconStyle} />
        },
        {
            title: 'FORMULA1',
            icon: <SportsScoreIcon sx={iconStyle} />
        },
        {
            title: 'MOTOGP',
            icon: <SportsMotorsportsIcon sx={iconStyle} />
        },
        {
            title: 'NASCAR',
            icon: <SportsScoreIcon sx={iconStyle} />
        },
        {
            title: 'MLB',
            icon: <SportsBaseballIcon sx={iconStyle} />
        }
    ]

    switch (data?.engagementType) {
        case 'single':
            return (
                <AdLayout type="single">
                    {
                        //
                    }
                </AdLayout>
            )
        case 'event':
            const wagerStatusOverall = isWagerClose(data?.startTime)
            return (
                <AdLayout type="active-events">
                    {
                        (wagerStatusOverall?.h >= 0 || wagerStatusOverall?.m >= 0) &&
                        <ReactSwipe
                            className="carousel"
                            swipeOptions={{
                                continuous: false,
                                //callback: function (index, elem) {  handleSlideIndex(index)},
                            }}
                            ref={reactSwipeEl}
                        >

                            <Box
                                sx={{
                                    width: '100%',
                                    height: '324px',
                                    pl: '16px',
                                    pr: '16px',
                                    boxSizing: 'border-box'
                                }}
                            >
                                <EventAd data={data?.sports_data} wagers={data?.wagers} icon={nav[data?.sports_data?.id - 1]?.icon} />
                            </Box>

                            {
                                data?.wagers?.map((item, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                width: '100%',
                                                height: '324px',
                                                pl: '16px',
                                                pr: '16px',
                                                boxSizing: 'border-box'
                                            }}
                                        >
                                            <WagerCard
                                                adComponent
                                                data={{
                                                    ...item,
                                                    wagerStatus: wagerStatusOverall,
                                                    user: wagerData?.user
                                                }} />
                                        </Box>
                                    )
                                })
                            }
                        </ReactSwipe>
                    }
                </AdLayout>
            )
        case 'ad':
            if (data?.ad_types_data?.name === 'both-stand-alone') {
                return (
                    <AdLayout type="single">
                        {
                            //
                            <FeedSponsor data={data} />
                        }
                    </AdLayout>
                )
            }
            else {
                return (
                    <AdLayout type="carousel">
                        <Stack spacing={2}>
                            <Box
                                sx={{
                                    overflow: 'hidden',
                                    borderRadius: '10px',
                                    bgcolor: theme.palette.dark.otherdark,
                                    width: '100%', // This makes the Box's width responsive to its parent container
                                    position: 'relative', // Position relative to allow absolute positioning of children
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        paddingTop: 'calc(100% / (16 / 9))', // Padding top for 16:9 aspect ratio
                                    },
                                    '& > *': {
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                    },
                                }}
                            >
                                <Box sx={{
                                    zIndex: 9
                                }}>
                                    <Box sx={{
                                        position: 'absolute',
                                        top: 10,
                                        left: 10,
                                    }}>
                                        <Chip
                                            color="primary"
                                            label='Sponsored'
                                            size='small'
                                            sx={{
                                                fontWeight: 700,
                                                color: 'white',
                                                width: 'fit-content',
                                            }} />
                                    </Box>
                                </Box>

                                <img style={{ zIndex: 0 }} src={data?.image} />

                            </Box>

                            <Stack spacing={-.5}>
                                <Typography variant='body1'>{data?.name}</Typography>
                                {
                                    !!data?.description &&
                                    <Typography variant='caption'>{data?.description}</Typography>
                                }
                            </Stack>

                            <Button
                                onClick={() => window.open(data?.url, '_blank')}
                                size='small'
                                variant='contained'>
                                Learn more
                            </Button>
                        </Stack>
                    </AdLayout>
                )
            }
        default:
            break;
    }
}

export default AdSwitcher
