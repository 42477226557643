import axios from './axios'
import authHeader from './auth-header'

const listSport = (target) => {
  return axios.get('sports/getAll', {
    headers: authHeader()
  })
}

const SportServices = {
  listSport
}
export default SportServices
