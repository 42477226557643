import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { updateUserPwd } from '../../redux/actions/profile'

import { useDispatch, useSelector } from 'react-redux'

import { isValidPassword } from '../../helper'
import GoBackHeader from '../../components/header/go-back-header'
import { Alert, Box, Button, Container, IconButton, TextField, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@emotion/react'
import { LoadingButton } from '@mui/lab'

function EditPassword() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const responseData = useSelector((state) => state.updateUserPwd)
  const isPasswordChanged = responseData?.userPWD?.status
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState('')
  const [payload, setPayload] = useState({
    password: '',
    newPassword: '',
    confirmPassword: ''
  })


  const handlePassword = () => {
    dispatch({
      type: 'RESET_PASSWORD'
    })
    dispatch(updateUserPwd(payload))
  }

  const hitAPI = () => {
    dispatch(updateUserPwd(payload))
  }

  useEffect(() => {
    if (isPasswordChanged) {
      navigate('/edit-success')
    }
  }, [isPasswordChanged])

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter' && !disabled) {
        handlePassword()
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [payload, disabled])

  useEffect(() => {
    if (responseData?.Status === 'Fail') {
      setError(responseData?.error)
    }
  }, [responseData])


  const passwordRules = [
    "Minimum 8 characters",
    "Contain at least 1 uppercase",
    "Contain at least 1 lowercase & 1 special character",
    "Contain at least 1 special character",
    "No spaces allowed"
  ]

  const handleInput = (e) => {
    if (error != '') {
      dispatch({
        type: 'RESET_PASSWORD'
      })
      setError('')
    }
    setPayload((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  console.log(responseData)
  console.log(error)
  return (
    <>

      <Container sx={{
        //position: 'fixed',
        //top: 0,
        display: 'flex',
        width: '100%',
        zIndex: '99999',
        bgcolor: 'black',
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: 4,
        mt: 10,
        pb: 2
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <IconButton onClick={() => navigate('/user-profile')}>
            <ArrowBackIcon fontSize='medium' />
          </IconButton>
          <Typography variant='h6'>Update Password</Typography>
        </Box>
      </Container>
      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        pt: 6,
        gap: 6
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px'
        }}>
          <Box sx={{
            width: '100%',
            bgcolor: theme.palette.dark.otherdark,
            p: 2
          }}>
            <Typography>Password Rules</Typography>
            <ul style={{ listStyleType: 'disc', marginLeft: '20px', color: theme.palette.dark.otherlight }}>
              {
                passwordRules.map((item, index) => (
                  <li key={index}>
                    <Typography variant="body2" sx={{
                      color: theme.palette.dark.otherlight
                    }}>
                      {item}
                    </Typography>
                  </li>
                ))
              }
            </ul>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px'
        }}>
          <TextField
            error={error != ''}
            label='Current Password'
            type="text"
            name="password"
            sx={{ width: '100%' }}
            onChange={(e) => handleInput(e)}
          />
          <TextField
            error={error != ''}
            label='New Password'
            type="text"
            name="newPassword"
            sx={{ width: '100%' }}
            onChange={(e) => handleInput(e)}
          />
          <TextField
            error={error != ''}
            label='Confirm Password'
            type="text"
            name="confirmPassword"
            sx={{ width: '100%' }}
            onChange={(e) => handleInput(e)}
          />
          {
            error != '' &&
            <Alert severity='error'>{error}</Alert>
          }
          <LoadingButton
            disabled={(
              payload.password === ''
              ||
              payload.newPassword === ''
              ||
              payload.confirmPassword === ''
              || 
              error != ''
            )}
            loading={responseData?.isLoading}
            onClick={() => hitAPI()}
            variant='contained'>
            Update Password
          </LoadingButton>
        </Box>
      </Container>
    </>
  )
}

export default EditPassword