
const initialState = {}
// Use the initialState as a default value
export default function Masters (state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case 'GET_DIVISIONS': {
      return {
        ...state,
        Status: 'Success',
        DIVISIONS: action.payload
      }
    }

    case 'GET_PRIVATEERS': {
      return {
        ...state,
        Status: 'Success',
        PRIVATEERS: action.payload
      }
    }

    case 'GET_MULTIPLIERS': {
      return {
        ...state,
        Status: 'Success',
        MULTIPLIERS: action.payload
      }
    }

    case 'LIST_PRIVATEERS_BY_EVENT': {
      return {
        ...state,
        Status: 'Success',
        error: '',
        PRIVATEERS_LIST: action.payload
      }
    }

    case 'ERROR_OCCURED': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload,
        DIVISIONS: [],
        PRIVATEERS_LIST: [],
        PRIVATEERS: [],
        MULTIPLIERS: []
      }
    }

    case 'RESET_MASTERS':
      return initialState

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}
