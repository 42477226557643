// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getMessaging, onMessage } from 'firebase/messaging'
import { getDatabase } from "firebase/database";

// import { getMessaging } from 'firebase/messaging'
import { getStorage } from 'firebase/storage'
// const firebaseConfig = {
//   apiKey: "AIzaSyCM5Zy543C40ZtYGqK1ikC4IW_NQLOHysU",
//   authDomain: "production-motobookie-betzon.firebaseapp.com",
//   projectId: "production-motobookie-betzon",
//   storageBucket: "production-motobookie-betzon.appspot.com",
//   messagingSenderId: "624415897985",
//   appId: "1:624415897985:web:da4f5bc9fb4d5c70a15080",
//   measurementId: "G-KYENDGB5GD",
//   databaseURL: "https://production-motobookie-betzon-default-rtdb.firebaseio.com",
// }

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_APIKEY,
  authDomain: process.env.REACT_APP_FCM_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECTID,
  storageBucket: process.env.REACT_APP_FCM_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FCM_APPID,
  measurementId: process.env.REACT_APP_FCM_MESSUREMENTID,
  databaseURL: process.env.REACT_APP_FCM_DATABASEURL
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const storage = getStorage(app)
// const analytics = getAnalytics(app)
const db = getFirestore(app)
//const messaging = getMessaging(app);
export const realtimeDb = getDatabase(app);


// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });
// export const messaging = getMessaging(app)

export default db
