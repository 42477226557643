import React, { useState, useEffect, createRef, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Container, Button, Stack, Box, Typography, IconButton, Avatar, Chip, Divider } from '@mui/material'
import CelebrationIcon from '@mui/icons-material/Celebration';
import { useSelector, useDispatch } from 'react-redux'
import { listPostDetails } from '../../redux/actions/Posts'
// import DateConverter from '../../components/DateConverter'
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { isWagerClose, urlTypeIdentifier, isValidUrl } from '../../helper'
import db from '../../firebase'
import handleBucket from '../../components/hooks/useBucket'
import {
  doc,
  setDoc,
  onSnapshot,
  collection,
  deleteDoc,
  Timestamp,
  query,
  orderBy
} from 'firebase/firestore'
import ReadMore from '../../components/textShow'
import postServices from '../../services/posts'
import { useTheme } from '@emotion/react'
import SendIcon from '@mui/icons-material/Send';

import InputBase from '@mui/material/InputBase';


// import commentIcon from '../../images/commentNew.png'

// function timeAgo (dateParam) {
//   if (!dateParam) {
//     return null
//   }
//   dateParam = new Date(dateParam * 1000)
//   const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam)
//   const commentMonth = date.getMonth()
//   const today = new Date()
//   const seconds = Math.round((today - date) / 1000)
//   const minutes = Math.round(seconds / 60)
//   const hour = Math.round(minutes / 60)
//   const day = Math.round(hour / 24)
//   const month = commentMonth - new Date().getMonth()
//   if (seconds < 5) {
//     return 'now'
//   } else if (seconds < 60) {
//     return `${seconds}second`
//   } else if (minutes < 60 && minutes >= 1) {
//     return `${minutes}min`
//   } else if (hour < 24 && hour >= 1) {
//     return `${hour}hr`
//   } else if (day >= 1 && month === 0) {
//     return `${day}d`
//   } else if (month >= 1) {
//     return `${month}month`
//   }
// }

function PostDetails(props) {
  console.log('POST DETAILS -', props)
  const { id: propId } = props;
  const { id: paramId } = useParams();
  const id = propId !== undefined ? propId : paramId;
  const theme = useTheme()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [comment, setComment] = useState(null)
  const [commentList, setCommentList] = useState([])
  const [replyTo, setReplyTo] = useState({})
  const [repliesList, setRepliesList] = useState({})
  const [commentVotes, setCommentVotes] = useState({})
  const [selectedWager, setWagerDetails] = useState({})
  const [deleteShow, setDeleteShow] = useState(false)

  const [commentRepliesVotes, setCommentRepliesVotes] = useState({})
  // const deactivateStatus = useSelector((state) => state?.deactivateWager)
  const inputRef = useRef(null)
  const bottomRef = useRef(null)
  const dispatch = useDispatch()
  const [media, setMedia] = useState(null)
  // const handleShow = () => {
  //   if (selectedWager?.peopleJoined === 0 && selectedWager?.status !== 'closed') {
  //     dispatch(resetDeactivateWager())
  //     setShow(true)
  //   }
  // }

  const deactivateWagerResponse = useSelector((state) => state.deactivateWager)

  const postDetailsInfo = useSelector((state) => state?.postDetails)

  const user = localStorage.getItem('user')

  const handleComment = async (e) => {
    try {
      if (comment?.trim()?.length) {
        const singleComment = {
          user: JSON.parse(user),
          postId: id,
          text: comment,
          date: Timestamp.now(),
          id: Math.random() * 1000
        }
        if (replyTo?.user) {
          singleComment.replyTo = replyTo.user
          setDoc(doc(db, 'posts', id, 'comments', replyTo?.commentId ? replyTo?.commentId : replyTo.id, 'replies', `${Math.floor(Math.random() * 1000000)}`), { ...singleComment })
        } else {
          setDoc(doc(db, 'posts', id, 'comments', `${Math.floor(Math.random() * 1000000)}`), { ...singleComment })
        }
        setComment('')
        setReplyTo({})
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getComments = async () => {
    try {
      const commentQuery = query(collection(db, 'posts', id, 'comments'), orderBy('date', 'asc'))
      onSnapshot(commentQuery, (commentSnaps) => {
        const list = []
        commentSnaps.forEach((doc) => {
          list.push({ ...doc.data(), id: doc.id })
        })

        // fetching replies
        list.map(doc => {
          const replies = []
          const repliesQuery = query(collection(db, 'posts', id, 'comments', doc.id, 'replies'), orderBy('date', 'asc'))

          onSnapshot(repliesQuery, (repliesSnap) => {
            repliesSnap.forEach(val => {
              replies.push({ ...val.data(), id: val.id, commentId: doc.id })
              // if (replies.filter(value => value.id === val.id).length === 0) {
              // }
            })

            const repliesObj = { [doc.id]: replies, ...repliesList }
            setRepliesList((prevState) => ({
              ...prevState,
              ...repliesObj
            }))

            // eslint-disable-next-line array-callback-return
            replies.map(doc => {
              onSnapshot(collection(db, 'posts', id, 'comments', doc.commentId, 'replies', doc.id, 'vote'), (repCommentVoteSnap) => {
                const repliesVote = []
                repCommentVoteSnap.forEach(val => {
                  repliesVote.push({ ...val.data(), id: val.id })
                })
                const repliesVoteObj = { [doc.id]: repliesVote, ...commentRepliesVotes }
                setCommentRepliesVotes((prevState) => ({
                  ...prevState,
                  ...repliesVoteObj
                }))
              })
            })
            return true
          })
          // eslint-disable-next-line array-callback-return
          return true
        })

        // fetching votes of comments
        list.map(doc => {
          onSnapshot(collection(db, 'posts', id, 'comments', doc.id, 'vote'), (votesSnap) => {
            const votes = []
            votesSnap.forEach(val => {
              votes.push({ ...val.data(), id: val.id })
            })
            const votesObj = { [doc.id]: votes, ...commentVotes }
            setCommentVotes((prevState) => ({
              ...prevState,
              ...votesObj
            }))
          })
          return true
        })
        setCommentList(list.sort(function (a, b) {
          return new Date(a?.date) - new Date(b?.date)
        }))
      }, error => {
        console.log(error)
      })
    } catch (error) {
      console.log(error)
    }
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleDeletePost = (id) => {
    postServices.deletePost(id).then((result) => {
      navigate(-1)
    }).catch((err) => {
      console.log(err, 'err')
    })
  }

  const handleVote = async (comment, reply) => {
    const userInfo = JSON.parse(localStorage.getItem('user'))
    try {
      if (reply) {
        const validateUser = commentRepliesVotes[reply.id].filter(val => val.id.toString() === userInfo.id.toString())

        if (validateUser.length) {
          deleteDoc(doc(db, 'posts', id, 'comments', comment.id, 'replies', reply.id, 'vote', `${userInfo.id}`))
        } else {
          setDoc(doc(db, 'posts', id, 'comments', comment.id, 'replies', reply.id, 'vote', `${userInfo.id}`), { ...userInfo })
        }
      } else {
        const validateUser = commentVotes[comment.id].filter(val => val.id.toString() === userInfo.id.toString())
        if (validateUser.length) {
          deleteDoc(doc(db, 'posts', id, 'comments', comment.id, 'vote', `${userInfo.id}`))
        } else {
          setDoc(doc(db, 'posts', id, 'comments', comment.id, 'vote', `${userInfo.id}`), { ...userInfo })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const listUSerIcon = (record, no, wagerUser) => {
  //   const rows = []
  //   const listUsers = wagerUser !== undefined ? [...wagerUser?.filter(val => val.status === 'accept'), ...wagerUser?.filter(val => val.status !== 'accept')] : []
  //   for (let i = 0; i < record; i++) {
  //     if (listUsers[i]?.status === 'accept') {
  //       rows.push(
  //         <li key={i} className='active' data-toggle="tooltip" data-placement="right" title={listUsers[i]?.username} style={{ zIndex: 999, background: ' #223F5D', border: ' 1px solid #223F5D' }} onClick={() => navigate(`/user-profile/${listUsers[i]?.userId}`)}>
  //           <FaUser />
  //         </li>
  //       )
  //     } else {
  //       rows.push(
  //         <li key={i} style={{ color: 'black', border: ' 1px solid #223F5D' }}>
  //           <FaUser/>
  //         </li>
  //       )
  //     }
  //   }
  //   return rows
  // }

  const handleEnter = async (e) => {
    if (e.key === 'Enter') {
      handleComment()
    }
  }
  const wagerStatus = isWagerClose(selectedWager?.event_data?.startTime)

  useEffect(() => {
    // eslint-disable-next-line no-empty
    if (!Object.keys(deactivateWagerResponse).includes('Status') || deactivateWagerResponse?.Status === 'Fail') {

    } else {
      setShow(false)
    }
  }, [deactivateWagerResponse])

  useEffect(() => {
    if (id) {
      console.log('REACHING OUT FOR LISDT POST DETAILS -', id)
      dispatch(listPostDetails({ id }))
      getComments()
    }
  }, [id, show])

  useEffect(() => {
    if (postDetailsInfo?.postDetails?.result) {
      setDoc(doc(db, 'posts', id), { ...postDetailsInfo?.postDetails?.result })
      setWagerDetails(postDetailsInfo?.postDetails?.result)
    }
  }, [postDetailsInfo])

  // const handleCompressedUpload = (e) => {
  //   const image = e.target.files[0]
  //   if (image.type.includes('image')) {
  //   // eslint-disable-next-line no-new
  //     new Compressor(image, {
  //       quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
  //       success: (compressedResult) => {
  //         setMedia(compressedResult)
  //       }
  //     })
  //   } else {
  //     alert('video file is not allowed')
  //   }
  // }
  useEffect(() => {
    if (media) {
      const dataUrl = handleBucket(media, 'posts', id)
      const user = JSON.parse(localStorage.getItem('user'))
      dataUrl.then((data) => {
        const singleComment = {
          user,
          postId: id,
          text: data,
          date: Timestamp.now(),
          id: Math.random() * 1000
        }
        try {
          if (replyTo?.user) {
            setRepliesList((prevState) => ({
              ...prevState,
              [replyTo.id]: singleComment
            }))
            setDoc(doc(db, 'posts', id, 'comments', replyTo.id, 'replies', `${Math.floor(Math.random() * 1000000)}`), { ...singleComment })
          } else {
            setDoc(doc(db, 'posts', id, 'comments', `${Math.floor(Math.random() * 1000000)}`), { ...singleComment })
          }
          setComment('')
          setReplyTo({})
        } catch (error) {
          console.log(error)
        }
      })
      setMedia(null)
    }
  }, [media])

  useEffect(() => {
    if (commentList) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [commentList])

  return (
    <>
      {id &&
        <>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              pb: 10,
              pt: 2,
              height: "inherit",
              bgcolor: theme.palette.dark.dark,
              overflowY: 'scroll',
              maxWidth: '450px',
              height: '100%'
            }}>

            <Stack
              spacing={1}
              sx={{
                bgcolor: theme.palette.dark.otherdark,
                p: 2,
                borderRadius: '8px'
              }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                gridTemplateColumns: '1fr 2fr',
              }}>
                <Box sx={{
                  display: 'flex'
                }}>
                  <Avatar
                    src={postDetailsInfo?.postDetails?.result?.createdBy_data?.userLogo || null}
                  />
                </Box>
                <Box sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px'
                }}>
                  <Typography>@{postDetailsInfo?.postDetails?.result?.createdBy_data?.username}</Typography>
                </Box>
              </Box>

              <Typography>
                {postDetailsInfo?.postDetails?.result?.description}
              </Typography>
            </Stack>

            {
              commentList.length === 0 ?
                <Stack
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{
                    mt: -1,
                    flexGrow: 1
                  }}>
                  <CelebrationIcon fontSize='large' sx={{ color: "white" }} />
                  <Typography>Be the first to talk smack!</Typography>
                </Stack>
                :
                selectedWager?.id && commentList?.map((val, index) => {
                  return (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          gap: '12px',
                          pt: .5,
                          pb: .5
                        }}>
                        <Avatar
                          src={val?.user?.userLogo || null}
                        />
                        <Box sx={{
                          width: '100%'
                        }}>
                          <Box sx={{
                            mt: -1
                          }}>
                            <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight }}>@{val?.user?.username}</Typography>
                            <Typography variant='body1'
                              sx={{
                                mt: -.5,
                                fontWeight: 700,
                                overflowWrap: 'break-word',
                                wordBreak: "break-word", /* To break the word */
                                hyphens: "auto", /* Automatic hyphenation when the word breaks */
                                overflowWrap: "anywhere"
                              }}>
                              {isValidUrl(val.text) ? urlTypeIdentifier(val.text) === 'i' ? <img src={val.text} alt='data' height='200px' width='215px' style={{ display: 'block' }} /> : <a href={val.text}>{val.text} </a> : <ReadMore>{val.text}</ReadMore>}</Typography>
                          </Box>

                          <Stack direction='row' spacing={1} sx={{ mt: .5 }} alignItems={"center"}>
                            <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px',
                              color: 'white'
                            }}>
                              {
                                <IconButton
                                  onClick={() => handleVote(val, null)}
                                  sx={{
                                    m: 0, p: 0,
                                    color: theme.palette.dark.otherlight
                                  }}>
                                  {
                                    commentVotes[val?.id]?.filter(val => val.id.toString() === JSON.parse(localStorage.getItem('user')).id.toString()).length > 0 ?
                                      <FavoriteIcon fontSize='small' sx={{ color: 'white' }} />
                                      :
                                      <FavoriteBorderIcon fontSize='small' />
                                  }
                                </IconButton>
                              }
                              {
                                <Typography
                                  variant='body2'
                                  sx={{
                                    color: 'white'
                                  }}>
                                  {commentVotes[val?.id]?.length} {commentVotes[val?.id]?.length > 1 ? 'Likes' : commentVotes[val?.id]?.length === 0 ? 'Likes' : 'Like'}
                                </Typography>
                              }
                            </Box>
                            {

                              <Button
                                onClick={() => {
                                  inputRef.current.focus()
                                  setReplyTo(val)
                                }}
                                variant='text'
                                size="small"
                                sx={{
                                  pt: 0,
                                  pb: 0,
                                  pl: .5,
                                  pr: .5
                                }}>
                                Reply
                              </Button>
                            }
                          </Stack>
                        </Box>
                      </Box>

                      {
                        repliesList[val.id]?.length > 0 && repliesList[val.id].map(value => {
                          // eslint-disable-next-line no-lone-blocks
                          return (
                            <Box sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                              gap: '12px',
                              pt: .5,
                              pb: .5,
                              pl: 6
                            }}>

                              <Avatar
                                onClick={() => navigate(`/user-profile/${value.user.id}`)}
                                src={value?.user?.userLogo || null}
                              />

                              <Box sx={{
                                width: '100%'
                              }}>
                                <Box sx={{
                                  mt: -1
                                }}>
                                  <Typography variant='body2'
                                    onClick={() => navigate(`/user-profile/${value.user.id}`)}
                                    sx={{ color: theme.palette.dark.otherlight }}>
                                    @{value?.user?.username}
                                  </Typography>
                                  <Typography variant='body1'
                                    sx={{
                                      mt: -.5,
                                      fontWeight: 700,
                                      overflowWrap: 'break-word',
                                      wordBreak: "break-word", /* To break the word */
                                      hyphens: "auto", /* Automatic hyphenation when the word breaks */
                                      overflowWrap: "anywhere"
                                    }}>
                                    {isValidUrl(value.text) ? urlTypeIdentifier(value.text) === 'i' ? <img src={value.text} alt='data' height='200px' width='215px' style={{ display: 'block' }} /> : <a href={value.text}>{value.text} </a> : <ReadMore>{value.text}</ReadMore>}</Typography>
                                </Box>
                                {
                                  /*
<Stack direction='row' spacing={1} sx={{ mt: .5 }}>
                                  <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                    color: 'white'
                                  }}>
                                    {
                                      <IconButton
                                        onClick={() => handleVote(val,)}
                                        sx={{
                                          m: 0, p: 0,
                                          color: theme.palette.dark.otherlight
                                        }}>
                                        {
                                          commentRepliesVotes[value?.id]?.filter(val => val.id.toString() === JSON.parse(localStorage.getItem('user')).id.toString()).length > 0 ?
                                            <FavoriteIcon fontSize='small' sx={{ color: 'white' }} />
                                            :
                                            <FavoriteBorderIcon fontSize='small' />
                                        }
                                      </IconButton>
                                    }
                                    {
                                      <Typography
                                        sx={{
                                          color: 'white'
                                        }}>
                                        {commentRepliesVotes[value.id]?.length} {commentRepliesVotes[value.id]?.length > 1 ? 'Likes' : 'Like'}
                                      </Typography>
                                    }
                                  </Box>
                                </Stack>
                                  */
                                }
                              </Box>
                            </Box>
                          )
                        })
                      }
                    </>
                  )
                })
            }


          </Container>



          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%'
          }}>
            <Container
              sx={{
                maxWidth: '450px',
                width: '100%',
                pt: 1,
                pb: 3,
              }}
            >
              <Box
                component="form"
                sx={{
                  bgcolor: 'black',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '100px',
                  pl: 1,
                  pr: 1,
                  boxSizing: 'border-box',
                  border: `1px solid ${theme.palette.dark.otherlight}`
                }}
              >
                <InputBase
                  inputRef={inputRef} // This line attaches the ref to your input element
                  multiline
                  value={comment}
                  onChange={(e) => { if (e.target.value !== null && e.target.value.trim().length) { setComment(e.target.value) } else { setComment('') } }}
                  onKeyDown={handleEnter}
                  sx={{
                    ml: 1,
                    flex: 1,
                    fontSize: '14px',
                    width: `${comment ? '70%' : '100%'}`
                  }}
                  placeholder={replyTo && replyTo.user ? `Reply to @${replyTo?.user?.username}` : `add a comment`}
                  inputProps={{ 'aria-label': 'search google maps' }}
                />

                { //comment
                  true &&
                  <IconButton onClick={handleComment}>
                    <SendIcon fontSize='small' />
                  </IconButton>
                }

              </Box>
            </Container>
          </Box>


        </>
      }

    </>
  )
}

export default PostDetails