import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import MobileBottomNavigation from '../navigation/bottom-mobile-navigation'
import { useLocation, useParams } from 'react-router-dom'

const AppLayout = ({ children, hideNavigation }) => {
    const location = useLocation();
    return (
        <Box sx={{
            height: '100vh',
            overflow: 'hidden',
            boxSizing:'border-box'

        }}>
            {children}
            {
                !!!hideNavigation
                    ?
                    <MobileBottomNavigation pathname={location.pathname} />
                    :
                    !hideNavigation
                    &&
                    <MobileBottomNavigation pathname={location.pathname} />
            }
        </Box>
    )
}

export default AppLayout
