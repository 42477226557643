import axios from './axios'
import authHeader from './auth-header'

const getEventId = () => {
  return axios.get('events/get-active-event', {
      headers: authHeader()
    })
}

const getEventById = (eventId) => {
  return axios.get(`events/get/${eventId}`, {
      headers: authHeader()
    })
}

const getTrendingEvents = () => {
  return axios.get("events/trending", {
    headers: authHeader()
  })
}

const eventService = {
  getEventId,
  getEventById,
  getTrendingEvents
}

export default eventService
