import { ERROR_OCCURED, GET_EVENT_ID, GET_EVENT_ID_REQUEST } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import eventService from '../../../services/eventId'


export const getEventId = (index) => {
  return async (dispatch) => {
    // Start the fetch operation
    try {
      // After successful fetch, dispatch the action
      const data = await eventService.getEventId()
      console.log(data)
      dispatch({
        type: "GET_EVENT_ID",
        payload: data && data?.data
      })

    } catch (error) {
      console.log(error)
      if (error?.response?.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error?.response &&
          error?.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      dispatch({
        type: "ERROR_OCCURED",
        payload: message
      })
    }
  };
};

export const getEventById = (eventId) => {
  return async (dispatch) => {
    // Start the fetch operation
    try {
      // After successful fetch, dispatch the action
      const data = await eventService.getEventById(eventId)
      console.log(data)
      dispatch({
        type: "GET_EVENT_ID",
        payload: data && data?.data
      })

    } catch (error) {
      console.log(error)
      if (error?.response?.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error?.response &&
          error?.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      dispatch({
        type: "ERROR_OCCURED",
        payload: message
      })
    }
  };
};

export const getTrendingEvents = () => {
  return async (dispatch) => {
    // Start the fetch operation
    try {
      // After successful fetch, dispatch the action

      dispatch({
        type: "GET_TRENDING_EVENTS_REQUEST"
      })
      const response = await eventService.getTrendingEvents()
      
      dispatch({
        type: "SET_TRENDING_EVENTS",
        payload: response && response?.data
      })

    } catch (error) {
      console.log(error)
      if (error?.response?.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error?.response &&
          error?.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      dispatch({
        type: "SET_TRENDING_EVENTS_ERROR",
        payload: message
      })
    }
  };
};




