import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'
import React from 'react'

const CardLayout = ({ children, bubble, bgActive, nopadding }) => {
    const theme = useTheme()
    return (
        <>
            {
                !!bubble ?
                    <Box sx={{
                        bgcolor: bgActive ? theme.palette.dark.otherdark : 'transparent',
                        border: `1px solid ${theme.palette.secondary.light}`,
                        borderRadius: 3,
                        p: nopadding ? 0 : 1
                    }}>
                        {children}
                    </Box>
                    :
                    <Box sx={{
                        //borderBottom: `1px solid ${theme.palette.dark.otherdark}`,
                        padding: '24px 16px 24px 16px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '24px',
                        justifyContent: 'space-between'
                    }}>
                        {children}
                    </Box>
            }
        </>
    )
}

export default CardLayout

/*\

<Box sx={{
            borderTop: `1px solid ${theme.palette.secondary.light}`,
            padding: '16px 16px 8px 16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '24px',
            justifyContent: 'space-between'
        }}>
            {children}
        </Box>
*/
