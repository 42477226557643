import * as React from 'react';
import Button from '@mui/material/Button';
import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material';
import logo from "../../images/betzonlogo.svg"
import ClearIcon from '@mui/icons-material/Clear';
import AccordionDetails from '@mui/material/AccordionDetails';

import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Accordion from '@mui/material/Accordion';
import theme from '../../styles/theme';
import { useNavigate } from 'react-router-dom';
// Define your component correctly
const avatarWidthTwo = "36px"
const avatarWidth = "46px"
const height = "68px"
const ReportCompleteSnackbar = React.forwardRef((props, ref) => {
    const navigate = useNavigate()
    switch (props?.type) {
        case "event-created":
            return (
                <Box
                    sx={{
                        bgcolor: theme.palette.dark.dark,
                        pl: 1,
                        pr: 1,
                        borderRadius: '6px',
                        width: '100%',
                        maxWidth: '450px',
                        height: height,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: `3px solid ${theme.palette.primary.main}`,
                        color: 'white',
                        boxSizing: 'border-box'
                    }}
                    ref={ref}>
                    <Stack
                        direction="row"
                        alignItems={'center'}
                        spacing={1}>
                        <Avatar
                            sx={{
                                bgcolor: 'black',
                            }}>
                            <img src={logo} style={{ height: '22px', marginLeft: '1px', marginBottom: '1px' }} />
                        </Avatar>

                        <Stack spacing={-.25}>
                            <Typography variant='body2'>
                                {props.data.message}
                            </Typography>
                            <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                                Be the first to create a wager!
                            </Typography>
                        </Stack>
                    </Stack>
                    {
                        /*
                        <Button
                        onClick={() => navigate('/user-profile')}
                        variant='outlined'
                        size='small'>
                        Go
                    </Button>
                    */
                    }
                </Box >
            )
        case "comment-wager":
            return (
                <Box
                    sx={{
                        bgcolor: theme.palette.dark.dark,
                        pl: 1,
                        pr: 1,
                        borderRadius: '6px',
                        width: '100%',
                        maxWidth: '450px',
                        height: height,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: `3px solid ${theme.palette.primary.main}`,
                        color: 'white',
                        boxSizing: 'border-box'
                    }}
                    ref={ref}>
                    <Stack
                        direction="row"
                        alignItems={'center'}
                        spacing={1}>
                        <Avatar
                            src={JSON.parse(props.data.metadata).likedBy_userLogo}
                            sx={{
                                bgcolor: 'black',
                                height: avatarWidthTwo,
                                width: avatarWidthTwo
                            }} />
                        {
                            //<img src={logo} style={{ height: '22px', marginLeft: '1px', marginBottom: '1px' }} />
                        }
                        {
                            // JSON.parse(props.data.metadata).commentType
                            // &&
                            <Stack spacing={-.25}>
                                <Typography variant='body2'>
                                    {props.data.message}
                                </Typography>
                                <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                                    {(JSON.parse(props.data.metadata).wagername)}
                                </Typography>
                            </Stack>
                        }
                    </Stack>
                    {
                        /*
                        <Button
                        onClick={() => navigate('/user-profile')}
                        variant='outlined'
                        size='small'>
                        Go
                    </Button>
                    */
                    }
                </Box >
            )
        case "join-wager":
            return (
                <Box
                    sx={{
                        bgcolor: theme.palette.dark.dark,
                        pl: 1,
                        pr: 1,
                        borderRadius: '6px',
                        width: '100%',
                        maxWidth: '450px',
                        height: height,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: `3px solid ${theme.palette.primary.main}`,
                        color: 'white',
                        boxSizing: 'border-box'
                    }}
                    ref={ref}>
                    <Stack
                        direction="row"
                        alignItems={'center'}
                        spacing={1}>
                        <Avatar
                            src={JSON.parse(props.data.metadata).likedBy_userLogo}
                            sx={{
                                bgcolor: 'black',
                                height: avatarWidthTwo,
                                width: avatarWidthTwo
                            }} />
                        {
                            //<img src={logo} style={{ height: '22px', marginLeft: '1px', marginBottom: '1px' }} />
                        }
                        <Stack spacing={-.25}>
                            <Typography variant='body2'>
                                {props.data.message}
                            </Typography>
                            <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                                {(JSON.parse(props.data.metadata).wagername)}
                            </Typography>
                        </Stack>
                    </Stack>
                    {
                        /*
                        <Button
                        onClick={() => navigate('/user-profile')}
                        variant='outlined'
                        size='small'>
                        Go
                    </Button>
                    */
                    }
                </Box >
            )
        case "other-wager":
            return (
                <Accordion
                    {...props}
                    ref={ref}>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{}}
                    >
                        <Stack
                            direction="row"
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            sx={{
                                //bgcolor: 'red'
                            }}
                        >
                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                <Avatar sx={{
                                    bgcolor: 'black',
                                    height: avatarWidth,
                                    width: avatarWidth
                                }}>
                                    <img src={logo} style={{ height: '22px', marginLeft: '1px', marginBottom: '1px' }} />
                                </Avatar>
                                <Stack spacing={-.5}>
                                    <Typography variant=''>
                                        {props.data.message}
                                    </Typography>
                                    <Typography variant='caption'>
                                        Check your winnings!
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </AccordionSummary>

                    <AccordionDetails
                        sx={{ p: 0, m: 0 }}>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget.
                        </Typography>
                    </AccordionDetails>

                </Accordion >
            )
            break;
        case "like-wager":
            return (
                <Box
                    sx={{
                        bgcolor: theme.palette.dark.dark,
                        pl: 1,
                        pr: 1,
                        borderRadius: '6px',
                        width: '100%',
                        maxWidth: '450px',
                        height: height,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: `3px solid ${theme.palette.primary.main}`,
                        color: 'white',
                        boxSizing: 'border-box'
                    }}
                    ref={ref}>
                    <Stack
                        direction="row"
                        alignItems={'center'}
                        spacing={1}>
                        <Avatar
                            src={JSON.parse(props.data.metadata).likedBy_userLogo}
                            sx={{
                                bgcolor: 'black',
                                height: avatarWidthTwo,
                                width: avatarWidthTwo
                            }} />
                        {
                            //<img src={logo} style={{ height: '22px', marginLeft: '1px', marginBottom: '1px' }} />
                        }
                        <Stack spacing={-.25}>
                            <Typography variant='body2'>
                                {props.data.message}
                            </Typography>
                            <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                                {(JSON.parse(props.data.metadata).wagername)}
                            </Typography>
                        </Stack>
                    </Stack>
                    {
                        /*
                        <Button
                        onClick={() => navigate('/user-profile')}
                        variant='outlined'
                        size='small'>
                        Go
                    </Button>
                    */
                    }
                </Box >
            )
        default:
            break;
    }
});

// Ensure the component is correctly exported or used
export default ReportCompleteSnackbar;

/*


                <Stack direction={"row"}>
                    <IconButton>
                        <ArrowDropDownIcon />
                    </IconButton>
                    <IconButton>
                        <ClearIcon />
                    </IconButton>
                </Stack>
*/
