import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from "../../images/betzonlogo.svg"
import { Button, Container, Typography } from '@mui/material'

function PasswordResetSuccess() {

  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem('useremail')
  })

  return (
    <Container
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px'
      }}>
      <img src={logo} style={{
        width: '108px'
      }} />
      <Typography variant='h6'>Password reset was successful.</Typography>
      <Button
        variant='contained'
        onClick={() => navigate('/')}
      >
        Back to login
      </Button>
    </Container>
  )
}

export default PasswordResetSuccess

/*

          <div className="fullScreen fullScreen-login">
             <div className="loginWrap">
                <div className="logo mb-3"><img alt="img" src={logo} style= {{ width: '80%' }} /></div>
                 <div className="infoTxt text-center">
                     <h1>Password reset was successful.</h1>
                 </div>
                 <div className="loginInp d-grid">
                     <Link className="d-grid" to="/">
                        <button className="button btn-block">Back to Login</button>
                     </Link>
                 </div>
             </div>
          </div>
*/
