import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserProfile, updateAddress } from '../../redux/actions/profile'

function EditAddress () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const responseData = useSelector((state) => state.updateAddress)
  const profileResponse = useSelector((state) => state.userProfile)
  const profileData = profileResponse?.userInfo?.result
  const isAddressUpdated = responseData?.userAdress?.status
  const [loaded, setLoaded] = useState(false)
  const [addressPayload, setAddressPayload] = useState({
    streetAddress: null,
    address: null
  })
  const { id } = useParams()

  useEffect(() => {
    setAddressPayload({
      ...addressPayload,
      streetAddress: profileData?.streetAddress,
      address: profileData?.address
    })
  }, [profileData])

  useEffect(() => {
    // need to remove skip limit
    dispatch(
      getUserProfile({
        skip: '0',
        limit: '10',
        userId: id
      })
    )
  }, [])

  const handleAddress = () => {
    dispatch(updateAddress(addressPayload))
    setLoaded(true)
  }
  useEffect(() => {
    if (isAddressUpdated) {
      setLoaded(false)
      dispatch({
        type: 'RESET_ADDRESS'
      })
      navigate('/edit-success')
    }
  }, [isAddressUpdated])

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter' && (addressPayload.streetAddress && addressPayload.address)) {
        handleAddress()
      }
    }
    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [addressPayload])
  return (
    <>
      <div className="fullScreen fullScreen-login login-solid">
        <div className="pageTop forDrop" style={{ background: '#000812', padding: '50px 12px 12px 12px' }}>
          <div className="backLink">
            <Link to="/edit-profile">
              <div className="backLink-icon noBg">
              </div>
            </Link>
            <span className="mt-0" style={{ position: 'absolute', right: '10px' }}>Address</span>
          </div>
        </div>
        {profileResponse?.isLoading
          ? (
                  <div className="text-center pt-3">
                    <div className="lds-roller">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
            )
          : (
              <div className="loginWrap scrollable-notification">
                  <div className="editPro">
                    <div className="smLogo text-center">
                    </div>
                    <div className="editInpWrap">
                      <input
                        className="editInp"
                        type="text"
                        autoComplete="off"
                        placeholder="New Street Address"
                        value={addressPayload?.streetAddress}

                        onChange={(e) =>
                          setAddressPayload({
                            ...addressPayload,
                            streetAddress: e.target.value
                          })
                        }
                      />
                    </div>
                    <div className="editInpWrap">
                      <input
                        className="editInp"
                        type="text"
                        autoComplete="off"
                        placeholder="City, State, Postal Code"
                        value={addressPayload?.address }
                        onChange={(e) =>
                          setAddressPayload({
                            ...addressPayload,
                            address: e.target.value
                          })
                        }
                      />
                    </div>
                    <div className="editInpWrap text-center">
                      <button
                        className={addressPayload.streetAddress && addressPayload.address ? 'button button-white mt-3' : 'button button-secondary mt-3'}
                        onClick={handleAddress}
                        disabled={ !(addressPayload.streetAddress && addressPayload.address)}
                      >
                        { loaded ? 'Please wait...' : 'Update' }
                      </button>
                    </div>
                  </div>
              </div>
            )}
      </div>
    </>
  )
}

export default EditAddress
