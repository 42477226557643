import { NOTIFICATIONS_ERROR, NOTIFICATIONS, NOTIFICATIONS_REQUEST, NOTIFICATIONS_LIST_ERROR, NOTIFICATIONS_LIST, NOTIFICATIONS_LIST_REQUEST, NOTIFICATIONS_UPDATE_ERROR, NOTIFICATIONS_UPDATE_REQUEST, NOTIFICATIONS_UPDATE_STATUS } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import NotificationScreenServices from '../../../services/notificationService'

export const Notifications = createAsyncThunk(
  '/notification/add-token',
  async (token, thunkAPI) => {
    thunkAPI.dispatch({
      type: NOTIFICATIONS_REQUEST
    })
    try {
      console.log('TOKEN BEING SAVED ----', token)
      const data = await NotificationScreenServices.addToken(token)
      thunkAPI.dispatch({
        type: NOTIFICATIONS,
        payload: data && data?.data
      })
      return { privateers: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: NOTIFICATIONS_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }

)
export const ListNotifications = createAsyncThunk(
  '/notification/list',
  async ({ token, limit, skip }, thunkAPI) => {
    thunkAPI.dispatch({
      type: NOTIFICATIONS_LIST_REQUEST
    })
    try {

      const data = await NotificationScreenServices.listNotifications(limit, skip)
      thunkAPI.dispatch({
        type: NOTIFICATIONS_LIST,
        payload: data && data?.data
      })
      return { privateers: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: NOTIFICATIONS_LIST_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const UpdateStatus = createAsyncThunk(
  '/notification/update-status',
  async ({ token }, thunkAPI) => {
    thunkAPI.dispatch({
      type: NOTIFICATIONS_UPDATE_REQUEST
    })
    try {
      const data = await NotificationScreenServices.updateStatus()
      thunkAPI.dispatch({
        type: NOTIFICATIONS_UPDATE_STATUS,
        payload: data && data?.data
      })
      return { privateers: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: NOTIFICATIONS_UPDATE_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
export const updateIsCliked = createAsyncThunk(
  '/notification/notification-status/:id',
  async (id, thunkAPI) => {
    try {
      await NotificationScreenServices.updateIsCliked(id)
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      return thunkAPI.rejectWithValue()
    }
  }
)

export const clearNotification = createAsyncThunk(
  '/notification/clearnotification',
  async (id, thunkAPI) => {
    try {
      await NotificationScreenServices.clearNotification(id)
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: NOTIFICATIONS_UPDATE_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
