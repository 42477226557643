import React, { useState } from 'react'
import AvatarGroup from '@mui/material/AvatarGroup';
import { Avatar, Box, Chip, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const countAcceptedUsers = (wagerUsers) => {
    // Use filter to get all users with status 'accept'
    const acceptedUsers = wagerUsers?.filter(item => item.status === 'accept') || [];

    // Return the count of accepted users
    return acceptedUsers.length;
}

const ProfilePill = (props) => {

    const theme = useTheme()
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (index) => {
        setAnchorEl(null);
    };

    return (

        <div>
            <Box
                onClick={handleClick}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                    border: 'none'
                }}
            >
                {
                    !!!props.privateWager
                        ?
                        <>
                            <AvatarGroup >
                                {
                                    new Array(props?.max).fill(null).map((item, index) => (
                                        <Avatar
                                            key={index} // Make sure to use a unique key for list items
                                            src={props.items?.length > 0 ? props?.items[index]?.user_data?.userLogo : null}
                                            alt={props.alt}
                                            sx={{
                                                width: props.size,
                                                height: props.size
                                            }} />
                                    ))
                                }

                            </AvatarGroup>
                        </>
                        :
                        <>
                            <AvatarGroup >

                                {props.items?.slice(0, props?.max).map((item, index) => {
                                    const showBadge = props.wagerStatus && !!props.privateWager && item?.status === 'accept';
                                    const avatar = (
                                        <Avatar
                                            key={index} // Make sure to use a unique key for list items
                                            src={item?.user_data?.userLogo || null}
                                            alt={props.alt}
                                            sx={{
                                                width: props.size,
                                                height: props.size,
                                            }}
                                        />
                                    );
                                    return showBadge ? (
                                        <StyledBadge
                                            key={index}
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            variant="dot"
                                        >
                                            {avatar}
                                        </StyledBadge>
                                    ) : avatar;
                                })}

                            </AvatarGroup>
                        </>
                }

                <ArrowDropDownIcon color='white' sx={{
                    color: 'white',
                    mr: -1
                }} />
            </Box>
            <Menu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    {
                        !!props.privateWager
                            ?
                            <Typography variant='caption'>Users Accepted: {props.wagerStatus ? countAcceptedUsers(props.items) : 'None'}</Typography>
                            :
                            <Typography variant='caption'>Spots Available: {props.wagerStatus ? props?.max - props.items?.length : 'None'}</Typography>


                    }
                </Box>
                {
                    new Array(props?.max).fill(null).map((item, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                navigate(`/user-profile/${props?.items[index]?.user_data?.id}`)
                                handleClose(index)
                                window.scrollTo(0, 0); // This will scroll the page to the top
                            }}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',  // Align to the left
                                alignItems: 'center',
                                gap: '8px'
                            }}>

                            <Avatar
                                src={props.items?.length > 0 ? props.items[index]?.user_data?.userLogo : null}
                                alt={props.alt}
                                sx={{
                                    width: props.size,
                                    height: props.size
                                }} />
                            {
                                <Stack spacing={-.25}>
                                    <Typography
                                        variant='caption'>
                                        {props.items?.length > 0 && !!props.items[index]?.user_data?.username
                                            ?
                                            `@${props.items[index]?.user_data?.username}`
                                            :
                                            props.wagerStatus ?
                                                'Available'
                                                :
                                                "Spot closed"
                                        }
                                    </Typography>
                                    {
                                        props.wagerStatus && !!props.privateWager && props.items[index]?.status === 'accept' &&
                                        <Typography fontSize={'11px'} color={theme.palette.success.main} >ACCEPTED</Typography>
                                    }
                                    {
                                        props.wagerStatus &&
                                        !!props.privateWager && props.items[index]?.status === 'pending' &&
                                        <Typography fontSize={'11px'} >PENDING</Typography>
                                    }
                                </Stack>
                            }
                        </MenuItem>
                    ))
                }
            </Menu>

        </div >
    )


}

export default ProfilePill