import { GET_DIVISIONS, GET_MULTIPLIERS, GET_PRIVATEERS, ERROR_OCCURED, LIST_PRIVATEERS_BY_EVENT } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import masterServices from '../../../services/get-masters'

export const getiDivisionsData = createAsyncThunk(
  '/divisions/list',
  async ({ skip, limit }, thunkAPI) => {
    try {
      const data = await masterServices.getMasters(skip, limit, 'divisions/list')
      thunkAPI.dispatch({
        type: GET_DIVISIONS,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const getiMultiplierData = createAsyncThunk(
  '/multipliers/list',
  async ({ skip, limit, sportsId }, thunkAPI) => {
    try {
      const data = await masterServices.getMasters(skip, limit, 'multipliers/list', sportsId)
      thunkAPI.dispatch({
        type: GET_MULTIPLIERS,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const getiPrivateersData = createAsyncThunk(
  '/privateers/list',
  async ({ skip, limit }, thunkAPI) => {
    try {
      const data = await masterServices.getMasters(skip, limit, 'privateers/list')
      thunkAPI.dispatch({
        type: GET_PRIVATEERS,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const getiPrivateersDataByEvent = createAsyncThunk(
  '/privateers/list/event',
  async ({ skip, limit, eventId, divisionId, sportsId }, thunkAPI) => {
    try {
      if (!!divisionId) {
        const data = await masterServices.getMastersByEvent(`event-privateers/list-racers/${eventId}/${divisionId}`)
        thunkAPI.dispatch({
          type: LIST_PRIVATEERS_BY_EVENT,
          payload: data && data?.data
        })
        return { user: data && data?.data }
      }
      else {
        const data = await masterServices.getMastersByEvent(`event-privateers/list-racers/${eventId}/sport/${sportsId}`)
        thunkAPI.dispatch({
          type: LIST_PRIVATEERS_BY_EVENT,
          payload: data && data?.data
        })
        return { user: data && data?.data }
      }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const getiPrivateersDataByEvent2 = createAsyncThunk(
  '/privateers/list/event',
  async ({ skip, limit, eventId }, thunkAPI) => {
    try {
      const data = await masterServices.getMastersByEvent(`event-privateers/list-racers/${eventId}`)
      thunkAPI.dispatch({
        type: LIST_PRIVATEERS_BY_EVENT,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
export const getTeamsDataByEvent2 = createAsyncThunk(
  '/privateers/list/event',
  async ({ skip, limit, eventId }, thunkAPI) => {
    try {
      const data = await masterServices.getMastersByEvent(`event-privateers/list-teams/${eventId}`)
      thunkAPI.dispatch({
        type: LIST_PRIVATEERS_BY_EVENT,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
