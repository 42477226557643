
const initialState = {
  isLoading: false
}
export default function deactivateWager (state = initialState, action) {
  switch (action.type) {
    case 'REQUEST_DEACTIVATE_WAGER': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'DEACTIVATE_WAGER': {
      return {
        ...state,
        isLoading: false,
        deactivateWager: action.payload
      }
    }
    case 'ERROR_OCCURED': {
      return {
        ...state,
        Status: 'Fail',
        isLoading: false,
        error: action.payload
      }
    }
    case 'RESET_DEACTIVATE_WAGER': {
      return {
        initialState
      }
    }
    default:
      return state
  }
}
