import axios from './axios'
import authHeader from './auth-header'

const addParticipants = (chatId, participants) => {
  return axios.post(`chat/addparticipant/${chatId}`, {
    participants
  }, {
    headers: authHeader()
  })
}

const ParticipantServices = {
  addParticipants
}
export default ParticipantServices
