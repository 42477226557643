import { UPDATE_PROFILE_ERROR, UPLOAD_PROFILE, UPLOAD_PROFILE_FAIL, GET_PROFILE, GET_PROFILE_REQUEST, UPDATE_USERNAME, UPDATE_USERNAME_REQUEST, UPDATE_PASSWORD, UPDATE_PASSWORD_REQUEST, UPDATE_ADDRESS, UPDATE_ADDRESS_REQUEST, UPDATE_PROFILE, UPDATE_PROFILE_REQUEST, GET_PROFILE_ERROR, UPDATE_USERNAME_ERROR, UPDATE_PASSWORD_ERROR, UPDATE_ADDRESS_ERROR } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import profileService from '../../../services/profile'

export const getUserProfile = createAsyncThunk(
  '/profile/get',
  async ({ skip, limit, userId }, thunkAPI) => {
    thunkAPI.dispatch({
      type: GET_PROFILE_REQUEST
    })
    try {
      const data = await profileService.getUserProfile(userId)
      thunkAPI.dispatch({
        type: GET_PROFILE,
        payload: data && data?.data
      })
      return { profile: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: GET_PROFILE_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const updateUserName = createAsyncThunk(
  '/profile/change-username',
  async ({ username }, thunkAPI) => {
    thunkAPI.dispatch({
      type: UPDATE_USERNAME_REQUEST
    })
    try {
      const data = await profileService.updateUserName(username)
      thunkAPI.dispatch({
        type: UPDATE_USERNAME,
        payload: data && data?.data
      })
      return { profile: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: UPDATE_USERNAME_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const updateUserPwd = createAsyncThunk(
  '/profile/change-password',
  async ({ password, newPassword, confirmPassword }, thunkAPI) => {
    thunkAPI.dispatch({
      type: UPDATE_PASSWORD_REQUEST
    })
    try {
      const data = await profileService.updateUserPwd(password, newPassword, confirmPassword)
      thunkAPI.dispatch({
        type: UPDATE_PASSWORD,
        payload: data && data?.data
      })
      return { password: data && data?.data }
    } catch (error) {

      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      thunkAPI.dispatch({
        type: UPDATE_PASSWORD_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()

    }
  }
)

export const updateAddress = createAsyncThunk(
  '/profile/change-address',
  async ({ streetAddress, address }, thunkAPI) => {
    thunkAPI.dispatch({
      type: UPDATE_ADDRESS_REQUEST
    })
    try {
      const data = await profileService.updateAddress(streetAddress, address)
      thunkAPI.dispatch({
        type: UPDATE_ADDRESS,
        payload: data && data?.data
      })
      return { address: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: UPDATE_ADDRESS_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const updateProfilePicture = createAsyncThunk(
  '/profile/change-profile-picture',
  async ({ userLogo }, thunkAPI) => {
    thunkAPI.dispatch({
      type: UPDATE_PROFILE_REQUEST
    })
    try {
      const data = await profileService.updateProfilePicture(userLogo)
      thunkAPI.dispatch({
        type: UPDATE_PROFILE,
        payload: data && data?.data
      })
      return { profile: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: UPDATE_PROFILE_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

"EiNMaW5kbGV5IEF2ZW51ZSwgTm9ydGhyaWRnZSwgQ0EsIFVTQSIuKiwKFAoSCR-UATgymsKAEbbxKzwgbCq6EhQKEgmJS1kiV5rCgBFSYV1bD6uyMw"
//submit user full name, date of birth, and address
export const uploadProfile = createAsyncThunk(
  '/profile/create-profile',
  async ({ full_name, dateOfBirth, address }, thunkAPI) => {
    try {
      const data = await profileService.uploadPersonalProfile(full_name, dateOfBirth, address)
      thunkAPI.dispatch({
        type: UPLOAD_PROFILE,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: UPLOAD_PROFILE_FAIL,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)