
const initialState = {
  isLoading: false
}
export default function myWager(state = initialState, action) {
  switch (action.type) {

    case 'LIST_MY_WAGERS_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }

    case 'LIST_MY_WAGERS': {
      return {
        ...state,
        isLoading: false,
        myWager: action.payload
      }
    }

    case 'LIST_MY_WAGERS_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        isLoading: false,
        error: action.payload
      }
    }

    case "WAGER_DELETED_UPDATE":
      return {
        ...state,
        myWager: {
          ...state.myWager,
          result: state.myWager.result.map((wager, index) =>
            wager.id === action.payload.wagerDeleted.id
              ?
              {
                ...action.payload.wagerDeleted
              }
              : wager
          ),
        }
      };

    case 'GET_WAGER_HISTORY': {
      return {
        ...state,
        isLoading: false,
        history: action.payload
      }
    }
    
    case 'GET_WAGER_HISTORY_ERROR': {
      return {
        ...state,
        isLoading: false,
        historyError: action.payload
      }
    }
    default:
      return state
  }
}
