import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { setCalendarDate, setEventCalendarDates } from '../../redux/actions/leagues';

const DateItem = ({ data }) => {
    const theme = useTheme();
    return (
        <Stack
            sx={{
                opacity: data.match.isMatch ? 1 : 0.3,
                pt: 1
            }}
            spacing={-1}
        >
            <Typography variant="caption" sx={{ fontWeight: 700, fontSize: theme.typography.pxToRem(11) }}>
                {data.day}
            </Typography>
            <Stack direction="row" spacing={0.3}>
                <Typography variant="overline" sx={{ fontSize: theme.typography.pxToRem(11) }}>{data.month}</Typography>
                <Typography variant="overline" sx={{ fontSize: theme.typography.pxToRem(11) }}>{data.date}</Typography>
            </Stack>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '-4px !important',
                }}
            >
                <Box
                    sx={{
                        backgroundColor: data.match.isMatch
                            ? theme.palette.primary.main
                            : 'black',
                        width: '6px',
                        height: '6px',
                        borderRadius: '100px',
                    }}
                ></Box>
            </Box>
        </Stack>
    );
};

const DateBarHeader = ({ createWager }) => {
    const theme = useTheme()
    const overallLeague = useSelector((state) => state.Leagues);
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();

    const tabsRef = useRef(null);

    const handleChange = (event, newValue) => {
        dispatch(
            setCalendarDate({
                date: overallLeague.league[createWager ? overallLeague?.creatorWagerIndexSelected : overallLeague?.indexSelected].dates[newValue], // Use the string date
                index: newValue,
            })
        );
    };

    useEffect(() => {
        const adjustScroll = () => {
            if (tabsRef.current) {
                const scrollableContainer = tabsRef.current.querySelector(
                    '.MuiTabs-scroller'
                );
                const selectedTabNode = scrollableContainer.querySelector(
                    '.Mui-selected'
                );
                if (selectedTabNode) {
                    scrollableContainer.scrollLeft =
                        selectedTabNode.offsetLeft +
                        selectedTabNode.clientWidth / 2 -
                        scrollableContainer.clientWidth / 2;
                }
            }
        };
        adjustScroll();
    }, [value]);

    useEffect(() => {
        dispatch(
            setCalendarDate({
                date: overallLeague.league[createWager ? overallLeague?.creatorWagerIndexSelected : overallLeague?.indexSelected].dates[0], // Use the string date
                index: 0,
            })
        )
    }, []);

    return (
        <>
            <Box sx={{
                width: '100%',
                mt: -1
            }}>
                <Tabs
                    ref={tabsRef}
                    value={overallLeague?.dateSelected?.index}
                    onChange={handleChange}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{
                        '.MuiTabs-scrollButtons': {
                            width: '18px',
                            color: 'white'
                        },
                        '.MuiTabs-scroller': {
                            scrollBehavior: 'smooth'
                        }
                    }}
                >
                    {
                        overallLeague.league[createWager ? overallLeague?.creatorWagerIndexSelected : overallLeague?.indexSelected]?.dates.map((item, key) => {
                            //console.log(item)
                            return (
                                <Tab
                                    sx={{
                                        minWidth: '24px',
                                        width: '20%',
                                        pb: 1
                                    }}
                                    key={key}
                                    //onClick={() => dateClick(key)}
                                    label={
                                        <DateItem
                                            data={item} />
                                    } />
                            )
                        }
                        )
                    }
                </Tabs>
            </Box>
        </>
    );
};

export default DateBarHeader;



/*
 
const loadMoreDates = (dateNum = 10, startOffset = 0) => {

    const now = new Date();

    //        const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();

    const calendarDates = [...Array(dateNum)].map((_, i) => {

        if (now.getDate() === new Date(now.getFullYear(), now.getMonth(), now.getDate() - startOffset + i).getDate()) {

            dispatch(setCalendarDate({
                date: new Date(now.getFullYear(), now.getMonth(), now.getDate() - startOffset + i).toString(),
                index: i
            }))

        }
        //"2024-01-20T08:00:00.000Z"
        //data.some(item => new Date(item.startTime).getTime() === new Date(now.getFullYear(), now.getMonth(), now.getDate() - startOffset + i))
        // console.log('item')
        // console.log(new Date("2024-01-20T08:00:00.000Z").getTime())
        // console.log(new Date(now.getFullYear(), now.getMonth(), now.getDate() - startOffset + i))
        // console.log(new Date("2024-01-20T08:00:00.000Z").getTime() === new Date(now.getFullYear(), now.getMonth(), now.getDate() - startOffset + i).toString())


        function isSameDay(date1, date2) {
            return date1.getFullYear() === date2.getFullYear() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getDate() === date2.getDate();
        }

        const timestamp = "2024-01-20T08:00:00.000Z";
        const dateString = new Date(now.getFullYear(), now.getMonth(), now.getDate() - startOffset + i).toString();

        const dateFromTimestamp = new Date(timestamp);
        const dateFromString = new Date(dateString);

        const sameDay = isSameDay(dateFromTimestamp, dateFromString);

        console.log(sameDay);

        return {
            match: false,
            date: new Date(now.getFullYear(), now.getMonth(), now.getDate() - startOffset + i)
        }

    });

    setDates(prevDates => [...calendarDates, ...prevDates]);

};


in case Stakeholders want to add previous days tot he calendar component
 
    useEffect(() => {
        const adjustScroll = () => {
            if (tabsRef.current) {
                const scrollableContainer = tabsRef.current.querySelector('.MuiTabs-scroller');
                const selectedTabNode = scrollableContainer.querySelector('.Mui-selected');
                if (selectedTabNode) {
                    scrollableContainer.scrollLeft =
                        selectedTabNode.offsetLeft
                        + (selectedTabNode.clientWidth / 2)
                        - (scrollableContainer.clientWidth / 2);
                }
            }
        };
 
        // Delay the adjustment slightly to ensure the DOM is ready
        const timer = setTimeout(() => adjustScroll(), 500);
 
        return () => clearTimeout(timer); // Cleanup on unmount
    }, [value]);
 
*/
