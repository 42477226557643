import { useTheme } from '@emotion/react'
import { Alert, Avatar, Box, Button, Chip, Container, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../../redux/actions/modal';
import { useNavigate } from 'react-router-dom';

const NewLeaderboard = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const theme = useTheme()
    const gold = '#EAAB00'
    const silver = '#999999'
    const bronze = '#BA732B'
    const leaderboardResponse = useSelector((state) => state.LeaderBoard)

    return (

        <Box
            sx={{
                pt: 4,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '36px',
                pb: 3
            }}>

            <Container sx={{
                //bgcolor: 'black',
                display: 'flex',
                flexDirection: 'column',
                gap: '48px',
                pb: 2
            }}>

                <Stack sx={{
                    background: theme.palette.dark.otherdark,
                    pr: 2,
                    pl: 2,
                    pt: 4,
                    pb: 4,
                    borderRadius: '8px',
                    position: 'relative',
                    overflow: 'hidden'
                }}>
                    <Box sx={{
                        zIndex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        width: 'fit-content',
                        alignItems: 'center',
                        gap: '8px'
                    }}>
                        <Typography variant='h6' fontWeight={700}>Have a chance to up to Win!</Typography>
                    </Box>
                    <Button
                        variant='outlined'
                        size='small'
                        sx={{
                            width: 'fit-content',
                            mt: 1
                        }}
                        onClick={() => window.open('https://www.betzon.com/help-center/betzon-challenge', '_blank')}
                    >
                        Learn more
                    </Button>

                    {
                        /*
                        <ul style={{ listStyleType: 'disc', paddingLeft: '14px', color: theme.palette.dark.otherlight }}>
                        <li>
                            <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight, zIndex: 1 }}>Quick Description of the <br /> prizes will go here</Typography>
                        </li>
                        <li>
                            <Typography variant='body2' sx={{ color: theme.palette.dark.otherlight, zIndex: 1 }}>Challenge ends in 48 hours</Typography>
                        </li>
                    </ul>
                        */
                    }
                    {
                        <EmojiEventsIcon
                            sx={{
                                color: theme.palette.primary.dark,
                                opacity: .2, position: 'absolute',
                                zIndex: 0,
                                right: '-48px',
                                bottom: '-48px',
                                fontSize: '200px'
                            }} />
                    }
                </Stack>

                {
                    leaderboardResponse?.leaderData?.result?.users?.length > 0 ?
                        <>
                            <Box sx={{
                                textAlign: 'center'
                            }}>
                                <Typography variant='h6' sx={{ fontWeight: 700 }}>Congrats to our winners for:</Typography>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '-12px',
                                    mt: .5
                                }}>
                                    {
                                        leaderboardResponse?.leaderData?.result?.events?.map((item, index) => (
                                            <Typography key={index} variant='body2' sx={{ mt: -.5, color: theme.palette.dark.otherlight, fontWeight: 700 }}>{item.name.toUpperCase()}</Typography>
                                        ))
                                    }
                                </Box>
                            </Box>

                            <Box sx={{
                                //bgcolor: 'red',
                                pt: '36px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-end'
                            }}>
                                <Box sx={{
                                    borderRadius: '18px 18px 0px 18px ',
                                    bgcolor: theme.palette.dark.otherdark,
                                    width: '33.33%',
                                    height: '144px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative',
                                }}>

                                    <Box sx={{
                                        position: 'absolute',
                                        top: '-36px',
                                        border: `3px solid ${silver}`,
                                        borderRadius: '100px'
                                    }}>
                                        <Avatar
                                            onClick={() => navigate(`/user-profile/${leaderboardResponse?.leaderData?.result?.users[1]?.user_data?.id}`)}
                                            src={leaderboardResponse?.leaderData?.result?.users[1]?.user_data?.userLogo}
                                            sx={{
                                                width: '48px',
                                                height: '48px',
                                            }} />
                                        <Box sx={{
                                            background: silver,
                                            borderRadius: '100px',
                                            position: 'absolute',
                                            bottom: '-12px',
                                            width: 'fit-content',
                                            width: '24px',
                                            height: '24px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            alignItems: 'center'
                                        }}>
                                            <Typography variant='caption'>2</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ height: '12px' }} />
                                    <Typography variant='caption'>@{leaderboardResponse?.leaderData?.result?.users[1]?.user_data?.username}</Typography>
                                    <Typography variant='caption' sx={{ mt: -.25, color: silver }}>{leaderboardResponse?.leaderData?.result?.users[1]?.user_data?.totalWins} wins</Typography>
                                </Box>


                                <Box sx={{
                                    borderRadius: '18px 18px 0px 0px ',
                                    bgcolor: theme.palette.dark.otherdark,
                                    width: '33.33%',
                                    height: '190px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative',
                                }}>

                                    <Box sx={{
                                        position: 'absolute',
                                        top: '-50px',
                                        border: `4px solid ${gold}`,
                                        borderRadius: '100px'
                                    }}>
                                        <Avatar
                                            onClick={() => navigate(`/user-profile/${leaderboardResponse?.leaderData?.result?.users[0]?.user_data?.id}`)}
                                            src={leaderboardResponse?.leaderData?.result?.users[0]?.user_data?.userLogo}
                                            sx={{
                                                width: '72px',
                                                height: '72px',
                                            }} />
                                        <Box sx={{
                                            background: gold,
                                            borderRadius: '100px',
                                            position: 'absolute',
                                            bottom: '-12px',
                                            width: 'fit-content',
                                            width: '24px',
                                            height: '24px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            alignItems: 'center'
                                        }}>
                                            <Typography variant='caption'>1</Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{ height: '12px' }} />
                                    <Typography variant='caption'>@{leaderboardResponse?.leaderData?.result?.users[0]?.user_data?.username}</Typography>
                                    <Typography variant='caption' sx={{ mt: -.25, color: gold }}>{leaderboardResponse?.leaderData?.result?.users[0]?.user_data?.totalWins} wins</Typography>
                                </Box>


                                <Box sx={{
                                    borderRadius: '18px 18px 18px 0px ',
                                    bgcolor: theme.palette.dark.otherdark,
                                    width: '33.33%',
                                    height: '110px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    gap: '-100px'
                                }}>

                                    <Box sx={{
                                        position: 'absolute',
                                        top: '-36px',
                                        border: `3px solid ${bronze}`,
                                        borderRadius: '100px'
                                    }}>
                                        <Avatar
                                            onClick={() => navigate(`/user-profile/${leaderboardResponse?.leaderData?.result?.users[2]?.user_data?.id}`)}

                                            src={leaderboardResponse?.leaderData?.result?.users[2]?.user_data?.userLogo}
                                            sx={{
                                                width: '48px',
                                                height: '48px',
                                            }} />
                                        <Box sx={{
                                            background: bronze,
                                            borderRadius: '100px',
                                            position: 'absolute',
                                            bottom: '-12px',
                                            width: 'fit-content',
                                            width: '24px',
                                            height: '24px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            alignItems: 'center'
                                        }}>
                                            <Typography variant='caption'>3</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ height: '12px' }} />
                                    <Typography variant='caption'>@{leaderboardResponse?.leaderData?.result?.users[2]?.user_data?.username}</Typography>
                                    <Typography variant='caption' sx={{ mt: -.25, color: bronze }}>{leaderboardResponse?.leaderData?.result?.users[2]?.user_data?.totalWins} wins</Typography>
                                </Box>
                            </Box>
                        </>
                        :
                        <Alert severity='warning'>A Leaderboard for this sport hasn't been computed yet!</Alert>
                }

            </Container>


            {
                leaderboardResponse?.leaderData?.result?.users?.slice(3).length > 0
                &&
                <Container sx={{
                    bgcolor: theme.palette.dark.otherdark,
                    height: 'auto',
                    borderRadius: '30px 30px 0 0 '
                }}>
                    {
                        leaderboardResponse?.leaderData?.result?.users?.slice(3).map((item, index) => (
                            <>
                                <Box
                                    onClick={() => navigate(`/user-profile/${item?.userId}`)}
                                    sx={{
                                        display: 'flex',
                                        pt: 2,
                                        pb: 2,
                                        justifyContent: 'space-between'
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px'
                                        }}>

                                        <Typography variant='caption'>{index + 4}</Typography>

                                        <Avatar
                                            src={item.user_data.userLogo}
                                            sx={{
                                                width: '48px',
                                                height: '48px',
                                            }} />
                                        <Stack>
                                            <Typography variant='caption'>@{item.user_data.username}</Typography>
                                            <Stack direction='row' spacing={1}>
                                                <Typography variant='caption' sx={{ mt: -.20 }}>{item.user_data.totalWins} wins</Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <IconButton
                                        disabled
                                        onClick={() => dispatch(openModal())}
                                        sx={{
                                            padding: 0,
                                            opacity: 0
                                        }}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </Box>

                                {index !== leaderboardResponse?.leaderData?.result?.users?.slice(3).length - 1 && <Divider sx={{ width: '100%' }} />}
                            </>
                        ))
                    }
                </Container>
            }
        </Box>
    )
}

export default NewLeaderboard
