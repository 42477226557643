import axios from './axios'
import authHeader from './auth-header'

const createWagersOneOnONe = (formData) => {
  const {
    eventId,
    wagerTypeId,
    divisionId,
    racerFor,
    racerAgainst,
    teamsFor,
    teamsAgainst,
    chipsBet,
    maxPeople,
    multiplierId,
    joiningUser,
    joiningUserList,
    type,
    chat,
    chatId,
    caption,
    mode
  } = formData
  const payload = {
    eventId,
    wagerTypeId,
    divisionId,
    racerFor,
    racerAgainst,
    teamsFor,
    teamsAgainst,
    chipsBet,
    maxPeople,
    multiplierId,
    joiningUser,
    joiningUserList,
    type,
    chat,
    chatId,
    caption,
    mode
  }
  if (!joiningUser) {
    delete payload.joiningUser
  }
  return axios.post(
    'wagers/add',
    {
      ...payload
    },
    {
      headers: authHeader()
    }
  )
}

const getWagers = (skip, limit, filter, sortBy, sort) => {
  return axios.post(
    'wagers/list',
    {
      skip,
      limit,
      filter,
      sort: sortBy,
      sortBy: sort
    },
    {
      headers: authHeader()
    }
  )
}

const getMyWagers = (skip, limit, filter) => {
  return axios.post(
    'wagers/my-wagers',
    {
      skip,
      limit,
      filter
    },
    {
      headers: authHeader()
    }
  )
}

const getUserWagers = (skip, limit, filter, id) => {
  return axios.post(
    `wagers/user-wagers/${id}`,
    {
      skip,
      limit,
      filter
    },
    {
      headers: authHeader()
    }
  )
}

const createWagersPlacement = (formData) => {
  const {
    eventId,
    wagerTypeId,
    divisionId,
    racerFor,
    placement,
    placementRange,
    chipsBet,
    maxPeople,
    multiplierId,
    joiningUser,
    joiningUserList,
    teamsFor,
    type,
    chat,
    chatId,
    caption,
    mode
  } = formData
  return axios.post(
    'wagers/add',
    {
      eventId,
      wagerTypeId,
      divisionId,
      racerFor,
      teamsFor,
      placement,
      placementRange,
      chipsBet,
      maxPeople,
      multiplierId,
      joiningUser,
      joiningUserList,
      type,
      chat,
      chatId,
      caption,
      mode
    },
    {
      headers: authHeader()
    }
  )
}

const createWagersPodium = (formData) => {
  const {
    eventId,
    wagerTypeId,
    divisionId,
    racerFor,
    teamsFor,
    chipsBet,
    maxPeople,
    multiplierId,
    joiningUser,
    joiningUserList,
    type,
    chat,
    chatId,
    caption,
    mode
    // racerAgainst
  } = formData
  return axios.post(
    'wagers/add',
    {
      eventId,
      wagerTypeId,
      divisionId,
      racerFor,
      teamsFor,
      chipsBet,
      maxPeople,
      multiplierId,
      joiningUser,
      joiningUserList,
      type,
      chat,
      chatId,
      caption,
      mode
      // racerAgainst
    },
    {
      headers: authHeader()
    }
  )
}

const createWagersSpread = (formData) => {
  const {
    eventId,
    wagerTypeId,
    divisionId,
    racerFor,
    racerAgainst,
    teamsFor,
    teamsAgainst,
    // placement,
    // placementRange,
    chipsBet,
    maxPeople,
    multiplierId,
    joiningUser,
    joiningUserList,
    type,
    chat,
    chatId,
    spread,
    holeShot,
    spredOptionId,
    caption,
    mode
  } = formData
  return axios.post(
    'wagers/add',
    {
      eventId,
      wagerTypeId,
      divisionId,
      racerFor,
      racerAgainst,
      teamsFor,
      teamsAgainst,
      // placement,
      // placementRange,
      chipsBet,
      maxPeople,
      multiplierId,
      joiningUser,
      joiningUserList,
      type,
      chat,
      chatId,
      spread,
      holeShot,
      spredOptionId,
      caption,
      mode
    },
    {
      headers: authHeader()
    }
  )
}

const createWagersHoleShot = (formData) => {
  const {
    eventId,
    wagerTypeId,
    divisionId,
    racerFor,
    // racerAgainst,
    // placement,
    // placementRange,
    chipsBet,
    maxPeople,
    multiplierId,
    joiningUser,
    joiningUserList,
    type,
    chat,
    chatId,
    spread,
    holeShot,
    caption,
    mode
  } = formData
  return axios.post(
    'wagers/add',
    {
      eventId,
      wagerTypeId,
      divisionId,
      racerFor,
      // racerAgainst,
      // placement,
      // placementRange,
      chipsBet,
      maxPeople,
      multiplierId,
      joiningUser,
      joiningUserList,
      type,
      chat,
      chatId,
      spread,
      holeShot,
      caption,
      mode
    },
    {
      headers: authHeader()
    }
  )
}

const createWagersTeamVsTeam = (formData) => {
  const {
    eventId,
    wagerTypeId,
    //divisionId,
    // racerFor,
    // racerAgainst,
    // placement,
    // placementRange,
    chipsBet,
    maxPeople,
    multiplierId,
    joiningUser,
    joiningUserList,
    type,
    chat,
    chatId,
    spread,
    holeShot,
    caption,
    mode,
    teamsFor,
    teamsAgainst
  } = formData
  return axios.post(
    'wagers/add',
    {
      eventId,
      wagerTypeId,
      //divisionId,
      // racerFor,
      // racerAgainst,
      // placement,
      // placementRange,
      chipsBet,
      maxPeople,
      multiplierId,
      joiningUser,
      joiningUserList,
      type,
      chat,
      chatId,
      spread,
      holeShot,
      caption,
      mode,
      teamsFor,
      teamsAgainst
    },
    {
      headers: authHeader()
    }
  )
}

const getWagerConfig = (skip, limit) => {
  return axios.post(
    'option/get/wager-config',
    {
      skip,
      limit
    },
    {
      headers: authHeader()
    }
  )
}

const deactivateWager = (id) => {
  return axios.post(
    `wagers/delete/${id}`,
    {},
    {
      headers: authHeader()
    }
  )
}

const getWagerHistory = (skip, limit, filter, data) => {
  return axios.post(
    'wagers/wager-history',
    {
      skip,
      limit,
      filter,
      ...data
    },
    {
      headers: authHeader()
    }
  )
}

const getSpreadOptions = () => {
  return axios.get('wagers/spreadOption/all', {
    headers: authHeader()
  })
}

const createWagerCounter = (body) => {
  return axios.post('wager-counter/create', body, {
    headers: authHeader()
  })
}

const counterWagerAction = (body) => {
  return axios.post('wager-counter/action', body, {
    headers: authHeader()
  })
}

const commentWagerNotification = (body) => {
  return axios.post('wagers/comment', body, {
    headers: authHeader()
  })
}

const WagersServices = {
  createWagersOneOnONe,
  createWagersPlacement,
  getWagers,
  getWagerConfig,
  getMyWagers,
  deactivateWager,
  getWagerHistory,
  getSpreadOptions,
  createWagersSpread,
  createWagersHoleShot,
  getUserWagers,
  createWagersPodium,
  createWagerCounter,
  counterWagerAction,
  createWagersTeamVsTeam,
  commentWagerNotification
}
export default WagersServices
