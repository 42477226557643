import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getFaq } from '../../redux/actions/faq'

import { useSelector, useDispatch } from 'react-redux'

function FaqScreen() {
  const dispatch = useDispatch()
  const responseData = useSelector((state) => state.Faq)
  const faqData = responseData?.faq?.result
  useEffect(() => {
    dispatch(
      getFaq({
        id: '4'
      })
    )
  }, [])
  return (
    <>
      <div className="fullScreen fullScreen-login login-solid justify-content-start screenWithoutFooter">
        <div className="pageTop forDrop bgDark">
          <div className="backLink">
            <Link to="/account-settings">
              <div className="backLink-icon noBg">
              </div>
            </Link>
            <span className="mt-0">{"FAQ's"}</span>
          </div>
        </div>

        <div className="loginWrap scrollable-notification">
          <div className="theme-accordion">
            {
              /*
            <Accordion defaultActiveKey="0">
              {faqData &&
                faqData.map((val, index) => {
                  return (
                    <Accordion.Item eventKey={index} key={val?.id}>
                      <Accordion.Header>
                        <span>{val?.question}</span>
                      </Accordion.Header>
                      <Accordion.Body style={{ color: 'white' }}>
                        <p>{val?.answer}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                })}
            </Accordion>
              */
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default FaqScreen
