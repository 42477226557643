
const initialState = {
    toggle: false,
    userModal: true
};

function modalReducer(state = initialState, action) {
    switch (action.type) {
        case 'OPEN_MODAL':
            return {
                ...state,
                toggle: true
            };

        case 'CLOSE_MODAL':
            return {
                ...state,
                toggle: false
            };

        case 'OPEN_USER_MODAL':
            return {
                ...state,
                userModal: true
            };

        case 'CLOSE_USER_MODAL':
            return {
                ...state,
                userModal: false
            };

        default:
            return state;
    }
}

export default modalReducer;