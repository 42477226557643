export const openModal = () => {
    return {
        type: 'OPEN_MODAL'
    }
}

export const closeModal = () => {
    return {
        type: 'CLOSE_MODAL'
    }
}

export const openUserModal = () => {
    return {
        type: 'OPEN_USER_MODAL'
    }
}

export const closeUserModal = () => {
    return {
        type: 'CLOSE_USER_MODAL'
    }
}