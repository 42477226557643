const initialState = {
  isLoading: false
}
export default function addChips (state = initialState, action) {
  switch (action.type) {
    case 'ADD_CHIPS_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'ADD_CHIPS': {
      return {
        ...state,
        isLoading: false,
        chipsInfo: action.payload
      }
    }
    case 'ADD_CHIPS_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }
    case 'RESET_CHIPS': {
      return {
        isLoading: false
      }
    }
    default:
      return state
  }
}
