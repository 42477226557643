import ChatsServices from '../../../services/chat';
import { FILTER_CHAT_LIST } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const filterChatList = createAsyncThunk(
  '/notify', async ({ filterBy }, thunkAPI) => {
    thunkAPI.dispatch({
      type: FILTER_CHAT_LIST,
      payload: filterBy
    })
  })


export const getMoreMessages = (chatId, limit, filterBy) => {
  return async (dispatch) => {
    // Start the fetch operation
    try {
      // After successful fetch, dispatch the action
      dispatch({
        type: "SET_MORE_MESSAGES_REQUEST"
      })
      console.log('IN THE GET MORE MESSAGES - ', chatId, limit, filterBy)

      const data = await ChatsServices.getMoreMessages(chatId, limit, filterBy)
      console.log('IN THE DATA DATA DATA - ', data)
      dispatch({
        type: "SET_MORE_MESSAGES",
        payload: data && data?.data
      })

    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      dispatch({
        type: "SET_MESSAGES_ERROR",
        payload: message
      })
    }
  };
};


export const resetMessages = (chatId, limit) => {
  return async (dispatch) => {
    dispatch({
      type: "RESET_MESSAGES"
    })
  };
};