import axios from './axios'
import authHeader from './auth-header'
const getMyPosts = (skip, limit) => {
  return axios.post(
    'posts/my',
    {
      skip,
      limit
    },
    {
      headers: authHeader()
    }
  )
}
const getAllPosts = (skip, limit) => {
  return axios.post(
    'posts/getAll',
    {
      skip,
      limit
    },
    {
      headers: authHeader()
    }
  )
}
const getUserPosts = (skip, limit, id) => {
  return axios.post(
    `posts/user/${id}`,
    {
      skip,
      limit
    },
    {
      headers: authHeader()
    }
  )
}

const deletePost = (id) => {
  return axios.post(
    `posts/delete/${id}`, {},
    {
      headers: authHeader()
    }
  )
}
const getPostById = (id) => {
  return axios.get(
    `posts/get/${id}`, {},
    {
      headers: authHeader()
    }
  )
}

const createPost = (description) => {
  return axios.post(
    'posts/create', { description },
    {
      headers: authHeader()
    }
  )
}
const postServices = {
  getMyPosts,
  getUserPosts,
  deletePost,
  getAllPosts,
  getPostById,
  createPost
}
export default postServices
