
const initialState = {
  isLoading: false
}
export default function postDetails (state = initialState, action) {
  switch (action.type) {

    case 'GET_POST_DETAILS_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'GET_POST_DETAILS_POST': {
      return {
        ...state,
        isLoading: false,
        postDetails: action.payload,
        error: ''

      }
    }
    case 'GET_POST_DETAILS_ERROR': {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    
    default:
      return state
  }
}
