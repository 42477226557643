import React, { useEffect, useState } from 'react'
import WagerCreateHeader from './components/header'
import LeagueHeaders from '../../header/leagues-header'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Container, Box, Button, Divider, Typography, TextField, MenuItem, InputAdornment, ListItemButton, Stack, Avatar, List } from '@mui/material'
import { sportAction } from '../../../redux/actions/sport'
import FeedLayout from '../../layouts/feed'
import { MatchItem } from '../../matches/list-items'
import Loader from '../../loader'
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@emotion/react'
import WagerCreationFooter from './components/footer'
import { closeModal, openModal } from '../../../redux/actions/modal'
import { useLocation, useNavigate } from 'react-router-dom'
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import WagerTypeForms from './components/wagerTypeForms'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import WagersServices from '../../../services/wagers'
import { getWalletBalance } from '../../../redux/actions/wallet'
import { getiDivisionsData, getiMultiplierData, getiPrivateersDataByEvent } from '../../../redux/actions/masters'
import { createWager, getWagerConfig, resetWager } from '../../../redux/actions/wagers'
import { isOnlyNumber } from '../../../helper'
import { getLeagues, setCurrentLeague } from '../../../redux/actions/leagues'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { LoadingButton } from '@mui/lab'
import { VerticalAlignBottom } from '@mui/icons-material'
import { listMyWaggers } from '../../../redux/actions/myWager'
import { useSocket } from '../../hooks/useSocket'
import { useParams } from 'react-router-dom';

const CreateWager = ({ privateWager, chatId, roomDetails }) => {

    let { matchId } = useParams();
    const dispatch = useDispatch()
    const socket = useSocket()
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme()
    const isOnMyWager = location?.pathname === "/my-wagers"
    const [isLoading, setIsLoader] = useState(false)
    const newlyCreatedWager = useSelector((state) => state.Wagers);
    const sport_data = useSelector((state) => state.Sport);
    const leagues = useSelector((state) => state.Leagues.league);
    const eventIdResponse = useSelector((state) => state.eventId)
    const walletResponse = useSelector((state) => state.Wallet)
    const wager_data = useSelector((state) => state.Wagers)
    const wagerConfigResponse = useSelector((state) => state.wagerConfig)
    const MasterResponse = useSelector((state) => state.Masters)
    const maxPeople = wagerConfigResponse?.wagerConfigInfo?.result?.maxPeople
    const toggleMain = useSelector((state) => state.Modals.toggle);
    const overallLeagues = useSelector((state) => state.Leagues);

    const [wagerInput, setWagerInput] = useState(
        !!privateWager ?
            {
                mode: wager_data?.mode, // need to add to db
                eventId: null,
                wagerTypeId: 1,
                divisionId: '',
                racerFor: '',
                racerAgainst: '',
                chipsBet: '10',
                maxPeople: roomDetails?.length, // it should be by default 2
                multiplierId: 1,
                checkbox: false,
                type: 'private',
                chat: true,
                chatId: Number(chatId),
                holeShot: null,
                spread: null,
                spredOptionId: 1,
                caption: '',
                placement: 0,
                teamsFor: null,
                teamsAgainst: null
            }
            :
            {
                mode: wager_data?.mode, // need to add to db
                eventId: null,
                wagerTypeId: 1,
                divisionId: '',
                racerFor: '',
                racerAgainst: '',
                chipsBet: '10',
                maxPeople: '1', // it should be by default 2
                multiplierId: 1,
                checkbox: false,
                type: 'public',
                holeShot: null,
                spread: null,
                spredOptionId: 1,
                caption: '',
                placement: 0,
                teamsFor: null,
                teamsAgainst: null
            }
    )

    const [selectedEventToWager, setSelectedEventToWager] = useState(null)
    const [filteredSportEvents, setFilteredSportEvents] = useState([])
    const [loading, setLoader] = useState(true)
    const [stage, setStage] = useState(0)
    const [toggle, setToggle] = useState(true)
    const [spreadOptions, setSpreadOptions] = useState()
    const [privateerPosition, setPrivateerPosition] = useState([])
    const [peopleList, setPeopleList] = useState([])
    const [apiError, setAPiError] = useState('')
    const [apiSuccess, setApiSuccess] = useState('')
    const [walletInUse, setWalletInUse] = useState('')
    const SportData = useSelector((state) => state?.Sport?.sport)

    useEffect(() => {
        if (wager_data?.mode === null) {
            dispatch(closeModal())
        }
        else {
            let temp = wagerInput
            temp.mode = wager_data?.mode
            setWagerInput(temp)
        }
    }, [wager_data?.mode]);

    useEffect(() => {
        if (wager_data?.mode == null || !!!wager_data.mode || wager_data.mode == 'undefined' || wager_data.mode == null) {
            dispatch(closeModal())
        }
        else {
            let temp = wagerInput
            temp.mode = wager_data?.mode
            setWagerInput(temp)
        }
    }, []);

    useEffect(() => {
        if (!toggleMain) {
            dispatch(resetWager())
            resetWagerInputState()
            setTimeout(() => setStage(0), 1000)
        }
        else {
            dispatch(getLeagues())
        }
    }, [toggleMain]);

    useEffect(() => {
        if (wager_data?.Status === 'Success') {
            setAPiError('')
            setApiSuccess(wager_data?.wager?.message)
            if (wagerInput.type === 'private' && !!newlyCreatedWager) {
                const user = JSON.parse(localStorage.getItem('user'))
                const payload = {
                    chatId,
                    wagerId: newlyCreatedWager.wager.wager.id,
                    message: 'WAGER',
                    userId: user?.id
                }
                socket.emit('chat_msg', payload)
            }
            if (wagerInput.type === 'private') {
                dispatch(closeModal())
            }
            if (wagerInput.type != 'private') {
                setIsLoader(false)
                setStage(4)
            }
        } else {
            if (wager_data?.Status === 'Fail') {
                if (wager_data.createWagerError) {
                    if (wager_data?.createWagerError === '400! Bad Request') {
                        setAPiError(wager_data?.createWagerError)
                    }
                    else {
                        setAPiError(wager_data?.createWagerError)
                    }
                } else if (wager_data?.error) {
                    setAPiError(wager_data?.error)
                }
            }
        }
    }, [wager_data])

    // console.log(leagues)
    // console.log(eventIdResponse?.eventInfo?.result)
    useEffect(() => {

        setLoader(true)
        // console.log("CHECKING CREATE WAGER")
        // console.log(eventIdResponse)
        const temp = eventIdResponse?.eventInfo?.result?.filter(item => item?.sportsId === sport_data?.sport?.id);
        setFilteredSportEvents(temp)
        setLoader(false)

    }, [eventIdResponse, sport_data]);

    useEffect(() => {

        dispatch(
            getWalletBalance({
                skip: 5,
                limit: 6
            })
        )
        dispatch(
            getWagerConfig({
                skip: 0,
                limit: 10
            })
        )
    }, [])

    useEffect(() => {
        dispatch(getiDivisionsData({ skip: 0, limit: 100 }))
        //dispatch(getiMultiplierData({ skip: 0, limit: 100, sportsId: selectedEventToWager?.sports_data?.id  }))
        dispatch(getiMultiplierData({ skip: 0, limit: 100 }))

        // if (selectedEventToWager?.sports_data?.class === 'Dirt') {
        //     dispatch(getiMultiplierData({ skip: 0, limit: 100 }))
        // } else {
        //     dispatch(getiMultiplierData({ skip: 0, limit: 100, sportsId: selectedEventToWager?.sports_data?.id }))
        // }
    }, [selectedEventToWager])

    useEffect(() => {
        if (MasterResponse?.PRIVATEERS_LIST?.result) {
            fillValue()
        }
    }, [MasterResponse])


    useEffect(() => {
        if (wager_data.mode === 'fantasy') {
            if (location.pathname === `/match/${matchId}` && !!overallLeagues?.indexSelected && !!overallLeagues?.league && !!overallLeagues?.league[overallLeagues.indexSelected]) {
                let tempEvent = overallLeagues?.league[overallLeagues.indexSelected]
                const item = tempEvent.events.find(event => event.id === Number(matchId));
                if (item != undefined || checkIfTimestampIsInFuture(item.startTime)) {
                    wagerSelectEvent(item)
                }
            }
        }
    }, [wager_data.mode]);



    const fillValue = () => {
        const position = []
        const privateerLength = Object.keys(
            MasterResponse?.PRIVATEERS_LIST?.result
        ).length
        let step = 2
        for (let i = 1; i <= privateerLength; i++) {
            if (i < 4) {
                position.push((i))
            } else {
                position.push((i) + ' through ' + (i + step))
                i = i + step
                step += 1
            }
        }
        setPrivateerPosition(JSON.parse(JSON.stringify(position)))
    }

    const handlePeople = () => {
        const peopleLength = []
        for (let i = 1; i <= maxPeople; i++) {
            peopleLength.push(i)
        }
        setPeopleList(JSON.parse(JSON.stringify(peopleLength)))
    }

    useEffect(() => {
        if (maxPeople) {
            handlePeople()
        }
    }, [maxPeople])


    const toOrdinalSuffix = (num) => {
        let a
        let b
        const int = parseInt(num)
        const digits = [int % 10, int % 100]
        const ordinals = ['st', 'nd', 'rd', 'th']
        const oPattern = [1, 2, 3, 4]
        const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
        if (typeof (num) === 'string') {
            if (num.includes('through')) {
                a = num.split(' ')[0]
                b = num.split(' ')[2]
                const inta = parseInt(a)
                const intb = parseInt(b)
                const digitsa = [inta % 10, inta % 100]
                return oPattern.includes(digitsa[0]) && !tPattern.includes(digitsa[1])
                    ? inta + ordinals[digits[0] - 1] + ' through ' + intb + ordinals[digits[0] - 1]
                    : inta + ordinals[3] + ' through ' + intb + ordinals[3]
            }
        } else {
            return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
                ? int + ordinals[digits[0] - 1]
                : int + ordinals[3]
        }
    }
    //SKIPPED SOMETHING HERE
    useEffect(() => {
        setAPiError('')
        //dispatch(resetWager())
    }, [])
    //SKIPPED SOMETHING HERE


    useEffect(() => {
        if (wagerInput?.wagerTypeId === '5') {
            setWagerInput((prev) => ({
                ...prev,
                racerAgainst: '1st to 3rd',
                wagerType: 'Podium'
            }))
        }
    }, [])

    //SKLIPPED SOMETHING HERE

    const handleteams = (e) => {
        setAPiError(' ')
        setWagerInput((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
        //SOMETHING WAS DELETED HERE
        //    if (e.target.value === 'Teams') {
        //     e.target.value !== '' && eventData?.id && dispatch(getTeamsDataByEvent2({ skip: 0, limit: 100, eventId: eventData?.id }))
        //   } else {
        //     e.target.value !== '' && eventData?.id && dispatch(getiPrivateersDataByEvent2({ skip: 0, limit: 100, eventId: eventData?.id }))
        //   }
    }




    const switchSport = async (name, id) => {
        setLoader(true)
        // console.log("SWITCHING SPORT", name, id)
        //await dispatch(sportAction({ id: id, name: name, className: id === 5 ? 'match' : 'event' }))
        setLoader(false)
    }






    const selectWagerEventHandler = () => {
        if (stage === 3) {

            if (selectedEventToWager.sportsId != 1 && selectedEventToWager?.sports_data?.type === 'event') {
                delete wagerInput['divisionId']
                delete wagerInput['teamsFor']
                delete wagerInput['teamsAgainst']
                setIsLoader(true)
                dispatch(createWager({ formData: wagerInput }))
            }
            else if (selectedEventToWager?.sports_data?.type === 'match') {
                delete wagerInput['divisionId']
                delete wagerInput['racerFor']
                delete wagerInput['racerAgainst']
                setIsLoader(true)
                dispatch(createWager({ formData: wagerInput, matchId: matchId }))
            }
            else {
                setIsLoader(true)
                delete wagerInput['teamsFor']
                delete wagerInput['teamsAgainst']
                dispatch(createWager({ formData: wagerInput }))
            }
        }

        else if (selectedEventToWager?.sports_data.type === 'event') {
            if (stage === 2 && wagerInput.type === 'private') {

                if (selectedEventToWager.sportsId != 1) {
                    delete wagerInput['divisionId']
                    setIsLoader(true)
                    dispatch(createWager({ formData: wagerInput }))
                }
                else {
                    setIsLoader(true)
                    delete wagerInput['teamsFor']
                    delete wagerInput['teamsAgainst']
                    dispatch(createWager({ formData: wagerInput }))
                }
            }
            else {

                setStage(stage + 1);
            }
        }
        else if (selectedEventToWager?.sports_data.type === 'match') {
            if (stage === 1 && wagerInput.type === 'private') {
                delete wagerInput['divisionId']
                delete wagerInput['racerFor']
                delete wagerInput['racerAgainst']
                setIsLoader(true)
                dispatch(createWager({ formData: wagerInput }))
            }
            else {
                //use to be stage + 1 but dont change it! creation process will get messed up
                setStage(stage + 2);
            }
        }
        else {
            if (stage === 1) {
                setStage(stage + 2);
            }
            else {
                setStage(stage + 1);
            }
        }
    };

    const wagerSelectEvent = (event) => {
        //const temp = eventIdResponse?.eventInfo?.result?.find(item => item?.id === event.id);

        setSelectedEventToWager(event)

        if (!!privateWager) {
            setWagerInput((prev) => ({
                ...prev,
                eventId: event.id,
                joiningUser: roomDetails.map(detail => detail.userId),
                maxPeople: roomDetails?.length,
            }))
        }
        else {
            setWagerInput(prevWagerInput => ({
                ...prevWagerInput,
                eventId: event.id,
            }));
        }

        if (wager_data?.mode === 'fantasy') {
            switch (event.sportsId) {
                case 1:
                    setWalletInUse(walletResponse?.walletInfo?.result?.motocrossChips)
                    break;
                case 2:
                    setWalletInUse(walletResponse?.walletInfo?.result?.formulaOneChips)
                    break;
                case 3:
                    setWalletInUse(walletResponse?.walletInfo?.result?.motogpChips)
                    break;
                case 4:
                    setWalletInUse(walletResponse?.walletInfo?.result?.nascarChips)
                    break;
                case 5:
                    setWalletInUse(walletResponse?.walletInfo?.result?.mlbChips)
                    break;
                default:
                    break;
            }
        }
        else {
            setWalletInUse(walletResponse?.walletInfo?.result?.walletBalance)
        }

        if (event?.sportsId != 1) {
            dispatch(getiPrivateersDataByEvent({ skip: 0, limit: 100, eventId: event?.id, divisionId: null, sportsId: event?.sportsId }))
        }


        if (event?.sports_data.type === 'event') {
            setStage(stage + 1);
        }
        else {
            setWagerInput(prevWagerInput => ({
                ...prevWagerInput,
                teamsFor: event.homeTeamId,
                teamsAgainst: event.awayTeamId,
                wagerTypeId: "6"
            }));
            setStage(stage + 1);
        }
    }

    const goBackWagerEventHandler = () => {

        setAPiError('')
        if (selectedEventToWager?.sports_data.type === 'event') {
            setStage(stage - 1);
        }
        else {
            if (stage == 3) {
                setStage(stage - 2);
            }
            else {

                setStage(stage - 1);
            }
        }
    };

    useEffect(() => {
        if (stage === 1 && selectedEventToWager?.sports_data.type === 'event') {
            resetWagerInputState()
        }
    }, [stage]);

    const toggleHandler = () => {
        if (!toggle) {
            setWagerInput(prevWagerInput => ({
                ...prevWagerInput,
                teamsFor: selectedEventToWager.homeTeamId,
                teamsAgainst: selectedEventToWager.awayTeamId
            }));
        }
        else {
            setWagerInput(prevWagerInput => ({
                ...prevWagerInput,
                teamsFor: selectedEventToWager.awayTeamId,
                teamsAgainst: selectedEventToWager.homeTeamId
            }));
        }

        setToggle(!toggle)
    }

    const wagerInputHandle = (event) => {
        const { name, value } = event.target;
        setWagerInput(prevWagerInput => ({
            ...prevWagerInput,
            [name]: value
        }));
    };

    const selectWagerType = (typeId) => {
        // console.log('')
        // console.log('SETTING WAGER TYPE -', typeId)
        let wagerType = ''

        switch (typeId) {
            case 1:
                wagerType = 'Rider v. Rider'
                break;
            case 2:
                wagerType = 'Finish line wager'
                break;
            case 3:
                wagerType = 'Spread wager'
                break;
            case 4:
                wagerType = 'Holeshot wager'
                break;
            case 5:
                wagerType = 'Podium wager'
                break;
            default:
                break;
        }



        if (!!privateWager) {
            setWagerInput((prev) => ({
                ...prev,
                joiningUser: roomDetails.map(detail => detail.userId),
                maxPeople: roomDetails?.length,
                wagerTypeId: typeId,
                holeShot: typeId === 4,
                spread: typeId === 3,
            }))
        }
        else {
            setWagerInput(prevWagerInput => ({
                ...prevWagerInput,
                wagerTypeId: typeId,
                holeShot: typeId === 4,
                spread: typeId === 3,
            }));
        }

        if (typeId === 3) {
            WagersServices.getSpreadOptions().then((data) => {
                setSpreadOptions(data?.data?.spreadOption)
            }).catch((error) => {
                console.log(error, 'errrr')
            })
        }

    }

    const resetWagerInputState = () => {
        setAPiError('')
        if (!!privateWager) {
            setWagerInput((prev) => (
                {
                    ...prev,
                    wagerTypeId: 1,
                    divisionId: '',
                    racerFor: '',
                    racerAgainst: '',
                    chipsBet: '10',
                    maxPeople: roomDetails?.length, // it should be by default 2
                    multiplierId: 1,
                    checkbox: false,
                    type: 'private',
                    chat: true,
                    chatId: Number(chatId),
                    holeShot: null,
                    spread: null,
                    spredOptionId: 1,
                    caption: '',
                    placement: 0
                }
            ))
        }
        else {
            setWagerInput((prev) => (
                {
                    ...prev,
                    wagerTypeId: 1,
                    divisionId: '',
                    racerFor: '',
                    racerAgainst: '',
                    chipsBet: '10',
                    maxPeople: '1', // it should be by default 2
                    multiplierId: 1,
                    checkbox: false,
                    type: 'public',
                    holeShot: null,
                    spread: null,
                    spredOptionId: 1,
                    caption: '',
                    placement: 0
                }
            ))
        }

    }

    const currencies = [
        {
            value: 1,
            label: '1 user',
        },
        {
            value: 2,
            label: '2 users',
        },
        {
            value: 3,
            label: '3 users',
        },
        {
            value: 4,
            label: '4 users',
        },
        {
            value: 5,
            label: '5 users',
        },
    ];

    const wagerTypeIds = [
        {
            title: 'Rider v. Rider',
            icon: <SportsScoreIcon />,
            selected: false,
            id: 1
        },
        {
            title: 'Placement',
            icon: <FormatListNumberedIcon sx={{ mt: -.25 }} />,
            selected: false,
            id: 2
        },
        {
            title: 'Spread',
            icon: <SocialDistanceIcon />,
            selected: false,
            id: 3
        },
        {
            title: 'Holeshot',
            icon: <TurnRightIcon sx={{ mt: -.5 }} />,
            selected: false,
            id: 4
        },
        {
            title: 'Podium',
            icon: <EmojiEventsIcon />,
            selected: false,
            id: 5
        },
    ]

    const checkIfTimestampIsInFuture = (timestamp) => {
        // Convert the given timestamp string to a Date object
        const givenDate = new Date(timestamp);
        // Get the current date and time
        const currentDate = new Date();

        // Add ten minutes to the current date and time
        currentDate.setMinutes(currentDate.getMinutes() + 5);

        // Compare the given timestamp with the current date and time (plus ten minutes)
        // If the given timestamp is greater (i.e., in the future), return true
        // Otherwise, return false
        return givenDate > currentDate;
    }

    // console.log('')
    // console.log('CREATE WAGER')
    // console.log(filteredSportEvents)
    // console.log(!!privateWager)
    // console.log('wagerInput -', wagerInput)
    // console.log(MasterResponse?.MULTIPLIERS?.result)
    // console.log(stage)
    // console.log('')
    //let filteredObjects = eventIdResponse?.eventInfo?.result.filter(item => item.sportsId != 5);
    // console.log(selectedEventToWager?.sportsId === 1 && wagerTypeIds[wagerInput?.wagerTypeId - 1]?.title ? "Racer V. Racer" : wagerTypeIds[wagerInput?.wagerTypeId - 1]?.title)
    // console.log(wagerTypeIds[wagerInput.wagerTypeId - 1]?.title)
    // console.log(wagerInput.wagerTypeId - 1)
    // // console.log(wagerTypeIds)
    switch (stage) {
        case 0:
            return (

                leagues.some(league => league.events && league.events.length > 0)
                    ?
                    <>
                        <Container sx={{
                            mb: 1
                        }} >

                            <WagerCreateHeader
                                data={{
                                    title: 'Select an event to wager on!',
                                    subtitle: wager_data?.mode === 'real' ? "Real Money" : "Fantasy"
                                }} />
                        </Container >
                        <LeagueHeaders
                            createWager
                            handleSportFilter={switchSport}
                            nohome />
                        <Container sx={{ bgcolor: 'black', mt: 3, mb: 3 }}>
                            {
                                (loading || !!!leagues[overallLeagues?.creatorWagerIndexSelected]?.events) ?
                                    <Loader isloading={true} />
                                    :
                                    !!leagues[overallLeagues?.creatorWagerIndexSelected]?.events && leagues[overallLeagues?.creatorWagerIndexSelected]?.events?.length > 0 ?
                                        leagues[overallLeagues?.creatorWagerIndexSelected].type === 'event'
                                            ?
                                            leagues[overallLeagues?.creatorWagerIndexSelected]?.events?.map((item, index) => (
                                                <MatchItem key={index} data={item} functionToClick={() => wagerSelectEvent(item)} />
                                            ))
                                            :
                                            leagues[overallLeagues?.creatorWagerIndexSelected]?.dates[overallLeagues?.dateSelected?.index]?.match?.events?.length > 0
                                                ?
                                                <List sx={{ mt: -4 }}>
                                                    {
                                                        leagues[overallLeagues?.creatorWagerIndexSelected]?.dates[overallLeagues?.dateSelected?.index]?.match?.events?.map((item, index) => (
                                                            <>
                                                                {
                                                                    < MatchItem key={index} data={item} functionToClick={() => {
                                                                        wagerSelectEvent(item)
                                                                    }} />
                                                                }
                                                            </>
                                                        ))
                                                    }
                                                </List>
                                                :
                                                <Alert>No Event!</Alert>
                                        :
                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <Alert sx={{
                                                width: 'fit-content'
                                            }} severity="warning">There are no events for this sport yet!</Alert>
                                        </Box>
                            }
                        </Container>
                        <WagerCreationFooter stage={stage} privateWager={!!privateWager} />
                    </>
                    :

                    <>
                        <Container>
                            <Alert severity='warning'>There are currently no active events to wager on</Alert>
                        </Container>
                    </>
            )
        case 1:
            return (
                <>
                    <Container sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px'
                    }}>

                        <WagerCreateHeader data={{
                            title: 'Create a wager!',
                            subtitle: wager_data?.mode === 'real' ? "Real Money" : "Fantasy"
                        }} />
                        <MatchItem createWager data={selectedEventToWager} functionToClick={goBackWagerEventHandler} />

                        <Button variant='outlined'
                            disabled={true}
                            sx={{
                                color: 'white',
                                width: '100%',
                                pl: 1.5,
                                pt: 1,
                                pb: 1,
                                pr: 1.5,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                border: `1px solid ${theme.palette.dark.otherlight}`
                            }}>
                            Create a Parlay Wager
                            <AddIcon sx={{
                                mr: -.5,
                                color: theme.palette.dark.otherlight
                            }} fontSize='small' />
                        </Button>
                    </Container>

                    <Divider sx={{ mt: '16px', mb: '16px' }} />

                    <Container sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        pb: 2
                    }}>
                        {
                            selectedEventToWager?.sports_data.type === 'event'
                                ?
                                <>
                                    <Stack spacing={1} sx={{ textAlign: 'center' }}>
                                        <Typography>Select your wager type!</Typography>
                                        <Box sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr 1fr',
                                            gap: '12px'
                                        }}>
                                            {
                                                wagerTypeIds.map((item, index) =>
                                                    <>
                                                        {
                                                            (selectedEventToWager.sportsId === 1 || item.id !== 4)
                                                            &&
                                                            <Button
                                                                key={index}
                                                                onClick={() => selectWagerType(item.id)}
                                                                variant='outlined'
                                                                sx={{
                                                                    borderRadius: '4px',
                                                                    position: 'relative',
                                                                    paddingBottom: '100%', // 100% padding-bottom makes the height equal to the width
                                                                    width: '100%', // Ensure the width is properly set
                                                                }}>
                                                                <Box sx={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    gap: '2px',
                                                                    p: .5,
                                                                    opacity: wagerInput.wagerTypeId - 1 === index ? 1 : .2,
                                                                    boxSizing: 'border-box'
                                                                }}>
                                                                    <Box sx={{
                                                                        p: 1,
                                                                        height:'40px',
                                                                        width:'40px',
                                                                        borderRadius: '100px',
                                                                        border: '2px solid white',
                                                                        color: 'white',
                                                                        boxSizing: 'border-box',
                                                                        display:'flex',
                                                                        justifyContent:'center',
                                                                        alignItems:'center'
                                                                    }}>
                                                                        {item?.icon}
                                                                    </Box>
                                                                    <Typography variant='caption'>{
                                                                        (selectedEventToWager.sportsId != 1 && item.id === 1)
                                                                            ? "Racer V. Racer" : item.title}</Typography>
                                                                </Box>
                                                            </Button>
                                                        }
                                                    </>
                                                )
                                            }
                                        </Box>
                                    </Stack>

                                </>
                                :
                                <>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: '6px'
                                    }}>
                                        <Typography variant='caption'>For your to win this wager...</Typography>

                                        <button id='toggle-switch-wrapper' style={{ border: `1px solid ${theme.palette.dark.otherlight}` }} onClick={toggleHandler}>
                                            <div id='toggle-switch-button' style={{
                                                backgroundColor: theme.palette.primary.main
                                            }} className={toggle ? 'toggle-left' : 'toggle-right'}>
                                                {
                                                    toggle ?
                                                        <>
                                                            <Typography color='white' variant='caption' sx={{ fontWeight: 700 }}>
                                                                {selectedEventToWager.home_teams_data.name}
                                                            </Typography>
                                                            <Typography color='white' variant='caption' sx={{ fontWeight: 700, mt: -.5 }}>
                                                                WIN
                                                            </Typography>
                                                        </>
                                                        :
                                                        <>
                                                            <Typography color='white' variant='caption' sx={{ fontWeight: 700 }}>
                                                                {selectedEventToWager.away_teams_data.name}
                                                            </Typography>
                                                            <Typography color='white' variant='caption' sx={{ fontWeight: 700, mt: -.5 }}>
                                                                WIN
                                                            </Typography>
                                                        </>
                                                }
                                            </div>

                                            <div className='toggle-switch-item'>
                                                <Typography color='white' variant='caption' sx={{ fontWeight: 700 }}>
                                                    {selectedEventToWager.home_teams_data.name}
                                                </Typography>
                                                <Typography color='white' variant='caption' sx={{ fontWeight: 700, mt: -.5 }}>
                                                    LOSE
                                                </Typography>
                                            </div>

                                            <div className='toggle-switch-item'>
                                                <Typography color='white' variant='caption' sx={{ fontWeight: 700, }}>
                                                    {selectedEventToWager.away_teams_data.name}
                                                </Typography>
                                                <Typography color='white' variant='caption' sx={{ fontWeight: 700, mt: -.5 }}>
                                                    LOSE
                                                </Typography>
                                            </div>
                                        </button>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '20px'
                                    }}>
                                        {
                                            !!!privateWager
                                                ?
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                    gap: '4px'
                                                }}>
                                                    <Typography variant='caption'># of users that can join</Typography>
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        name="maxPeople"
                                                        onChange={(e) => {
                                                            setWagerInput((prev) => ({
                                                                ...prev,
                                                                [e.target.name]: e.target.value
                                                            }))
                                                        }}
                                                        value={wagerInput?.maxPeople === '' ? 1 : wagerInput?.maxPeople}
                                                        sx={{
                                                            //color:`${theme.palette.dark.otherlight}`,
                                                            width: '100%',
                                                            '.MuiSelect-select': {
                                                                padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                                fontSize: '0.75rem', // Font size for the input text
                                                            },
                                                            '& .MuiInputLabel-root': { // If you're using a label
                                                                fontSize: '0.75rem', // Font size for the label
                                                            }
                                                        }}
                                                    >
                                                        {currencies.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Box>
                                                :

                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                    gap: '4px'
                                                }}>
                                                    <Typography variant='caption'>{roomDetails?.length} {roomDetails?.length === 1 ? 'user' : 'users'} can accept</Typography>
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        defaultValue={roomDetails[0].userId}
                                                        sx={{
                                                            //color:`${theme.palette.dark.otherlight}`,
                                                            width: '100%',
                                                            '.MuiSelect-select': {
                                                                padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                                fontSize: '0.75rem', // Font size for the input text
                                                            },
                                                            '& .MuiInputLabel-root': { // If you're using a label
                                                                fontSize: '0.75rem', // Font size for the label
                                                            }
                                                        }}
                                                    >
                                                        {roomDetails.map((user) => (
                                                            <MenuItem key={user.userId} value={user.userId} disabled>
                                                                @{user.user_data.username}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Box>
                                        }

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            gap: '4px'
                                        }}>
                                            <Typography variant='body2'>Multiplier</Typography>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                name="multiplierId"
                                                value={wagerInput?.multiplierId === '' || wagerInput?.multiplierId === null ? MasterResponse?.MULTIPLIERS?.result[0].multiplierId : wagerInput?.multiplierId}
                                                //defaultValue={multipierList[0].multiplier}
                                                onChange={(e) => {
                                                    setWagerInput((prev) => ({
                                                        ...prev,
                                                        [e.target.name]: e.target.value
                                                    }))
                                                }}
                                                sx={{
                                                    //color:`${theme.palette.dark.otherlight}`,
                                                    width: '100%',
                                                    '.MuiSelect-select': {
                                                        padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                        fontSize: '0.75rem', // Font size for the input text
                                                    },
                                                    '& .MuiInputLabel-root': { // If you're using a label
                                                        fontSize: '0.75rem', // Font size for the label
                                                    }
                                                }}
                                            >
                                                {MasterResponse?.MULTIPLIERS?.result?.map((option, index) => (
                                                    <MenuItem key={index} value={option?.id}>
                                                        {option?.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Box>
                                    </Box>

                                    {
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            gap: '4px'
                                        }}>
                                            <Typography variant='caption'>Select your wager amount (Chips Available: {walletInUse})</Typography>
                                            <TextField
                                                name="chipsBet"
                                                onChange={(e) => {
                                                    if (isOnlyNumber(e.target.value) || e.target.value === '') {
                                                        setWagerInput((prev) => ({
                                                            ...prev,
                                                            [e.target.name]: e.target.value
                                                        }))
                                                    }
                                                }}
                                                value={wagerInput?.chipsBet}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <span style={{ fontSize: '0.75rem', color: theme.palette.dark.otherlight }}>chips</span>
                                                        </InputAdornment>
                                                    ),
                                                    sx: {
                                                        input: {
                                                            height: '22.5px',
                                                            fontSize: '0.75rem', // Font size for the input text
                                                            padding: '5px 5px 5px 10px', // Adjust this value as needed
                                                        }
                                                    }
                                                }}
                                            />
                                        </Box>
                                    }

                                </>
                        }

                        {
                            ((wagerInput?.chipsBet * MasterResponse?.MULTIPLIERS?.result[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople) > walletInUse) &&
                            <Alert severity='error'>This wager will cost you: {wagerInput?.chipsBet * MasterResponse?.MULTIPLIERS?.result[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople} chips. <br />You don't have enough!</Alert>
                        }
                        {
                            ((wagerInput?.chipsBet * MasterResponse?.MULTIPLIERS?.result[wagerInput.multiplierId - 1].multiplier * wagerInput?.maxPeople) > 1000) && walletInUse > 1000 &&
                            <Alert severity='error'>Wagers more than 1,000 chips are not allowed!</Alert>
                        }
                        {
                            (wagerInput?.chipsBet < 10 || wagerInput?.chipsBet >= 10000)
                            &&
                            <Alert severity='error'>Wagers cannot be less than 10 chips or more than 1000 chips!</Alert>
                        }
                        {
                            apiError != '' &&
                            (
                                apiError === "You don't have enough chips" || apiError === "Chips bet should be less or equal to 1000"
                                    ?
                                    <Alert severity='error'>
                                        {apiError}. Go back and change your parameters!
                                    </Alert>
                                    :
                                    <Alert severity='error'>
                                        Please go back and check you wager parameters! Seems to be an error!
                                    </Alert>
                            )
                        }

                        {
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <Button variant='text' onClick={() => goBackWagerEventHandler()}>Cancel</Button>
                                {
                                    (!!privateWager && selectedEventToWager?.sports_data.type === 'match') ?
                                        <LoadingButton
                                            disabled={
                                                wagerInput.chipsBet < 10
                                                ||
                                                (
                                                    (wagerInput?.chipsBet * MasterResponse?.MULTIPLIERS?.result[wagerInput?.multiplierId - 1]?.multiplier * wagerInput?.maxPeople) > walletInUse
                                                )
                                                ||
                                                (
                                                    (wagerInput?.chipsBet * MasterResponse?.MULTIPLIERS?.result[wagerInput?.multiplierId - 1]?.multiplier * wagerInput?.maxPeople) > 1000 && walletInUse > 1000
                                                )
                                            }
                                            loading={!!privateWager ? isLoading : false}
                                            variant='contained'
                                            onClick={() => selectWagerEventHandler()}>
                                            {
                                                !!privateWager ?
                                                    "Send Wager"
                                                    :
                                                    "Continue"
                                            }

                                        </LoadingButton>
                                        :
                                        <Button variant='contained' onClick={() => selectWagerEventHandler()}>
                                            Continue
                                        </Button>
                                }
                            </Box>
                        }
                    </Container >
                    <WagerCreationFooter stage={stage} eventType={selectedEventToWager?.sports_data.type} privateWager={!!privateWager} />
                </>
            )

        case 2:
            return (
                <>
                    <Container sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px'
                    }}>

                        <WagerCreateHeader data={{
                            title: 'Wager Details',
                            subtitle: wager_data?.mode === 'real' ? "Real Money" : "Fantasy"
                        }} />

                        <MatchItem createWager data={selectedEventToWager} functionToClick={goBackWagerEventHandler} />
                        <Box sx={{
                            bgcolor: theme.palette.dark.otherdark,
                            display: 'flex',
                            alignItems: 'center',
                            //border: `1px solid ${theme.palette.dark.otherlight}`,
                            display: 'flex',
                            gap: '8px',
                            borderRadius: '6px',
                            p: 1.5
                        }}>
                            <Avatar sx={{
                                bgcolor: theme.palette.primary.main,
                                borderRadius: '100px',
                                color: 'white'
                            }}
                                variant="square">
                                {wagerTypeIds[wagerInput?.wagerTypeId - 1]?.icon}
                            </Avatar>
                            <Stack spacing={-.4}>
                                <Typography variant='body2'>{
                                    (
                                        (selectedEventToWager?.sportsId != 1 && wagerTypeIds[wagerInput?.wagerTypeId - 1]?.title === "Rider v. Rider")
                                        ||
                                        (selectedEventToWager?.sportsId != 3 && wagerTypeIds[wagerInput?.wagerTypeId - 1]?.title === "Rider v. Rider")
                                    )
                                        ? "Driver v. Driver" : wagerTypeIds[wagerInput.wagerTypeId - 1]?.title} Wager Selected</Typography>
                            </Stack>
                        </Box>

                        {
                            /*
                            <Button variant='outlined' sx={{
                            color: 'white',
                            width: '100%',
                            pl: 1.5,
                            pt: 1,
                            pb: 1,
                            pr: 1.5,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: `1px solid ${theme.palette.dark.otherlight}`
                        }}>
                            Create a Parlay Wager
                            <AddIcon sx={{
                                mr: -.5,
                                color: theme.palette.dark.otherlight
                            }} fontSize='small' />
                        </Button>
                            */
                        }
                    </Container>
                    <Divider sx={{ mt: '16px', mb: '16px' }} />

                    <Container sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        pb: 2
                    }}>

                        <WagerTypeForms
                            roomDetails={roomDetails}
                            walletBalance={walletInUse}
                            privateWager={!!privateWager}
                            selectedEventToWager={selectedEventToWager}
                            divisionFunction={(e) => {
                                setAPiError('')
                                e.target.value !== '' && selectedEventToWager?.id && dispatch(getiPrivateersDataByEvent({ skip: 0, limit: 100, eventId: selectedEventToWager?.id, divisionId: e.target.value }))
                                setWagerInput((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                    racerFor: 0,
                                    racerAgainst: 0
                                }))
                            }}
                            divisionOptions={MasterResponse?.DIVISIONS?.result}
                            divisionValue={wagerInput.divisionId}
                            wagerTypeId={wagerInput.wagerTypeId}
                            wagerInput={wagerInput}
                            privateerOptions={MasterResponse?.PRIVATEERS_LIST?.result}
                            riderOneFunction={(e) => {
                                setWagerInput((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value
                                }))
                            }}
                            riderOneValue={wagerInput?.racerFor}
                            peopleList={peopleList}
                            peopleListFunction={
                                (e) => {
                                    setWagerInput((prev) => ({
                                        ...prev,
                                        [e.target.name]: e.target.value
                                    }))
                                }
                            }
                            multiplierFunction={
                                (e) => {
                                    setWagerInput((prev) => ({
                                        ...prev,
                                        [e.target.name]: e.target.value
                                    }))
                                }
                            }
                            multipierList={MasterResponse?.MULTIPLIERS?.result}
                            chipsFunction={
                                (e) => {
                                    if (isOnlyNumber(e.target.value) || e.target.value === '') {
                                        setWagerInput((prev) => ({
                                            ...prev,
                                            [e.target.name]: e.target.value
                                        }))
                                    }
                                }
                            }
                            privateerPosition={privateerPosition}
                            privateerPositionFunction={(e) => {
                                if (e.target.value.length > 3) {
                                    const placement = e.target.value.split(' ')[0]
                                    const placementRange = e.target.value.split(' ')[2]
                                    setWagerInput((prev) => ({
                                        ...prev,
                                        [e.target.name]: Number(placement),
                                        placementRange: Number(placementRange)
                                    }))
                                }
                                else {
                                    setWagerInput((prev) => ({
                                        ...prev,
                                        [e.target.name]: Number(e.target.value),
                                        placementRange: Number(e.target.value)
                                    }))
                                }
                            }}
                            spreadOptions={spreadOptions}
                            spreadFunction={(e) => {
                                setWagerInput((prev) => ({
                                    ...prev,
                                    [e.target.name]: Number(e.target.value)
                                }))
                            }}
                        />
                        {
                            // <Typography variant='caption'>{walletResponse?.walletInfo?.result?.walletBalance * wagerInput.}</Typography>
                        }
                        {
                            (wagerInput?.chipsBet < 10 || wagerInput?.chipsBet >= 10000)
                            &&
                            <Alert severity='error'>Wagers cannot be less than 10 chips or more than 1000 chips!</Alert>
                        }
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Button variant='text' onClick={() => goBackWagerEventHandler()}>Go Back</Button>
                            <LoadingButton
                                disabled={
                                    (

                                        selectedEventToWager.sportsId === 1 &&
                                        (
                                            wagerInput.divisionId === ''
                                        )
                                    )
                                    ||
                                    wagerInput.racerFor === '' ||
                                    wagerInput.racerFor === 0 ||
                                    (
                                        wagerInput.wagerTypeId === 1 &&
                                        (
                                            wagerInput.racerAgainst === '' ||
                                            wagerInput.racerAgainst === 0
                                        )
                                        ||
                                        wagerInput.wagerTypeId === 2 &&
                                        (
                                            wagerInput.placement === 0
                                        )
                                        ||
                                        wagerInput.wagerTypeId === 3 &&
                                        (
                                            wagerInput.racerAgainst === '' ||
                                            wagerInput.racerAgainst === 0 ||
                                            wagerInput.spredOptionId === 0
                                        )
                                    )
                                    ||
                                    wagerInput.chipsBet < 10

                                    ||
                                    (
                                        (wagerInput?.chipsBet * MasterResponse?.MULTIPLIERS?.result[wagerInput?.multiplierId - 1]?.multiplier * wagerInput?.maxPeople) > walletInUse
                                    )
                                    ||
                                    (
                                        (wagerInput?.chipsBet * MasterResponse?.MULTIPLIERS?.result[wagerInput?.multiplierId - 1]?.multiplier * wagerInput?.maxPeople) > 1000 && walletInUse > 1000
                                    )
                                }
                                loading={!!privateWager ? isLoading : false}
                                variant='contained'
                                onClick={() => selectWagerEventHandler()}>
                                {
                                    !!privateWager ?
                                        "Send Wager"
                                        :
                                        "Continue"
                                }

                            </LoadingButton>
                        </Box>
                    </Container >
                    <WagerCreationFooter stage={stage} eventType={selectedEventToWager?.sports_data.type} privateWager={!!privateWager} />
                </>
            )
        case 3:
            return (

                <>
                    <Container sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        pb: 2
                    }}>

                        <WagerCreateHeader data={{
                            title: 'Create a caption!',
                            subtitle: wager_data?.mode === 'real' ? "Real Money" : "Fantasy"
                        }} />

                        <Box>
                            <textarea
                                value={wagerInput.wagercaption}
                                placeholder="Type your caption"
                                name='caption'
                                id='wager-caption'
                                onChange={(event) => wagerInputHandle(event)}
                                rows={4} // You can set the initial number of rows
                                style={{ resize: 'none', width: '100%', fontSize: '16px' }} // Prevent resizing and set width
                            />
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>{wagerInput.caption.length}/160 Characters</Typography>
                            </Box>
                        </Box>

                        {
                            apiError != '' &&
                            (
                                apiError === "You don't have enough chips" || apiError === "Chips bet should be less or equal to 1000"
                                    ?
                                    <Alert severity='error'>
                                        {apiError}. Go back and change your parameters!
                                    </Alert>
                                    :
                                    <Alert severity='error'>
                                        Please go back and check you wager parameters! Seems to be an error!
                                    </Alert>
                            )
                        }

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Button
                                variant='text'
                                disabled={isLoading}
                                onClick={() => goBackWagerEventHandler()}>
                                Go Back
                            </Button>

                            <LoadingButton
                                loading={isLoading}
                                variant='contained'
                                disabled={apiError != ''}
                                onClick={() => selectWagerEventHandler()}>
                                Create
                            </LoadingButton>
                        </Box>
                    </Container>
                </>
            )

        case 4:
            return (
                <>
                    <Container sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        pb: 2
                    }}>
                        <Stack spacing={2}>
                            {
                                !isOnMyWager &&
                                <Button
                                    variant='outlined'
                                    fullWidth
                                    onClick={() => {
                                        if (wagerInput.type != 'private') {
                                            const tempMode = wager_data?.mode || "fantasy"
                                            dispatch(resetWager())
                                            dispatch(closeModal())
                                            dispatch(
                                                listMyWaggers({
                                                    skip: 0,
                                                    limit: 10,
                                                    filter: {
                                                        mywagers: 'all',
                                                        mode: 'fantasy'
                                                    }
                                                })
                                            )
                                            setTimeout(() => navigate(`/my-wagers?key=${tempMode}`), 1000)
                                        }
                                    }}
                                >
                                    Go to My Wagers
                                </Button>
                            }

                            <Button
                                variant='contained'
                                fullWidth
                                onClick={() => {
                                    dispatch(closeModal())
                                    setTimeout(() => setStage(0), 1000)
                                    if (isOnMyWager) {
                                        dispatch(resetWager())
                                        dispatch(closeModal())
                                        dispatch(
                                            listMyWaggers({
                                                skip: 0,
                                                limit: 10,
                                                filter: {
                                                    mywagers: 'all',
                                                    mode: 'fantasy'
                                                }
                                            })
                                        )
                                    }
                                }}
                            >
                                Continue Scrolling
                            </Button>
                        </Stack>
                    </Container>
                </>
            )

        default:
            break;
    }
}

export default CreateWager
/*
 <Typography color='white' variant='caption' sx={{ fontWeight: 700 }}>
                                    {toggle ? <>Los Angeles Lakers<br /><strong>WIN</strong></> : <>Denver Nuggets<br /><strong style={{ marginTop: '-20px' }}>WIN</strong></>}
                                </Typography>
*/