import axios from './axios'
import authHeader from './auth-header'

const getLeaderBoard = (sportsId) => {
  return axios.post('events/getLeaderBoard',
    { sportsId },
    {
      headers: authHeader()
    })
}

const LeaderBoardService = {
  getLeaderBoard
}
export default LeaderBoardService
