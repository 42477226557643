import axios from './axios'
import authHeader from './auth-header'

const getAd = (id) => {
  return axios.get(`ads/getall/${id}`, {
    headers: authHeader()
  })
}

const AdServices = {
  getAd
}
export default AdServices
