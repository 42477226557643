const initialState = {
  isLoading: false
}
export default function updateUserPwd (state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_PASSWORD_REQUEST': {
      return {
        ...state,
        isLoading: true,
        error: null
      }
    }
    case 'UPDATE_PASSWORD': {
      return {
        ...state,
        isLoading: false,
        userPWD: action.payload,
        error: null
      }
    }
    case 'UPDATE_PASSWORD_ERROR': {
      return {
        isLoading: false,
        Status: 'Fail',
        error: action.payload
      }
    }
    case 'RESET_PASSWORD': {
      return {
        isLoading: false
      }
    }
    default:
      return state
  }
}
