import axios from './axios'
import authHeader from './auth-header'
const wagerLike = (wagerId) => {
  return axios.post(
    'wagers/like',
    { wagerId },
    {
      headers: authHeader()
    }
  )
}
const postLike = (postId) => {
  return axios.post(
    'posts/like',
    { postId },
    {
      headers: authHeader()
    }
  )
}

const LikeServices = {
  wagerLike,
  postLike
}
export default LikeServices
