import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import { register, resetAuth, checkUserName, checkUserEmail } from '../../redux/actions/auth'
import withRouter, {
  isValidUsernameLength,
  hasWhiteSpace,
  ValidateEmail,
  hasSpecialCharacter,
  isValidPassword,
  checkOnlyNumber
} from '../../helper'
import LogoContainer from '../../components/header/logo-container'
import AuthLayout from '../../components/layouts/auth'
import { Box, Button, Container, Divider, Stack, TextField, Typography } from '@mui/material'

function SignUpScreen() {

  const [inputs, setInputs] = useState({
    email: '',
    username: '',
    password: ''
  })

  const [error, setError] = useState({
    status: false,
    message: ''
  })

  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const SignUp = useSelector((state) => state.Auth)



  useEffect(() => {
    if (SignUp && SignUp.Status === 'Success') {
      dispatch(resetAuth())
      navigate('/verifyOtp')
    }
    if (SignUp && SignUp?.Status === 'Fail') {
      setError({
        status: true,
        message: SignUp?.response
      })
    }

    if (SignUp && SignUp?.Status === 'INVITE_ONLY') {
      setError({
        status: true,
        message: SignUp?.response
      })
    }
  }, [SignUp])

  const hitSignUPAPI = (e) => {
    e.preventDefault()
    dispatch(
      register({
        username: inputs.username,
        email: inputs.email,
        password: inputs.password,
        referralCode: id || null
      })
    )
  }
  
  const handleCredentials = (event) => {
    setError({
      status: false,
      message: ''
    })
    let temp = { ...inputs }
    temp[event.target.name] = event.target.value
    setInputs(temp)
  }
  // https://www.app.betzon.com/invite/CkPmhZLdX1H2UAPf
  useEffect(() => {
    if (!id) {
      console.log('NO ID')
      navigate('/beta')
    }
  }, []);

  console.log(SignUp)

  return (
    <AuthLayout>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          //bgcolor: 'magenta',
          gap: '24px',
        }}>

        <LogoContainer />

        <Typography variant="h4">Let's get started!</Typography>

        <TextField
          sx={{
            width: '100%'
          }}
          error={error.status}
          name="email"
          onChange={() => handleCredentials(event)}
          variant='filled'
          label="Enter your email"
        />

        <TextField
          sx={{
            width: '100%'
          }}
          error={error.status}
          name="username"
          onChange={() => handleCredentials(event)}
          variant='filled'
          label="Create a username"
        />

        <Stack spacing={1} sx={{ width: '100%' }}>
          <TextField
            sx={{
              width: '100%'
            }}
            error={error.status}
            type='password'
            name="password"
            onChange={() => handleCredentials(event)}
            variant='filled'
            label="Password"
          />
          {
            error.status ?
              <Typography variant='body2' color="error" sx={{ fontWeight: 400 }}>{error.message}</Typography> : ''
          }
        </Stack>

      </Box>

      <Stack
        spacing={2}
        sx={{
          width: '100%'
        }}>

        <LoadingButton
          onClick={() => hitSignUPAPI(event)}
          disabled={inputs.username === '' || inputs.password === '' || error.status}
          sx={{
            width: '100%'
          }}
          size="large"
          variant='contained'>
          Create an account
        </LoadingButton>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px'
        }}>
          <Typography variant='body2'>Already have an account?</Typography>
          <Typography variant='body2' sx={{ textDecoration: 'underline' }} onClick={() => navigate('/')}>Login</Typography>
        </Box>

      </Stack>
    </AuthLayout>
  )
}

export default withRouter(SignUpScreen)