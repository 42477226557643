const initialState = {
  isLoading: false
}
// Use the initialState as a default value
export default function Sport(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case 'SET_SPORT_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'SET_SPORT': {
      return {
        ...state,
        isLoading: false,
        status: 'Success',
        sport: action.payload
      }
    }

    case 'SET_SPORT_ERROR': {
      return {
        ...state,
        isLoading: false,
        status: 'Fail',
        error: action.payload
      }
    }

    case 'SET_SPORTS_LIST': {
      return {
        ...state,
        isLoading: false,
        sportsList: action.payload
      }
    }

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}
