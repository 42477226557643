import { Box, Stack, Typography, Button, List, Divider } from '@mui/material'
import React from 'react'
import { MatchItem } from '../matches/list-items'
import { useNavigate } from 'react-router-dom'
import { setCurrentLeague } from '../../redux/actions/leagues'
import { listWaggers } from '../../redux/actions/wagers'
import { useDispatch } from 'react-redux'

const EventAd = ({ data }) => {

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const goToHandler = (event) => {
        switch (data?.type) {
            case 'event':
                dispatch(setCurrentLeague(data.id))
                dispatch(
                    listWaggers({
                        skip: 0,
                        limit: 10,
                        filter: {
                            mode: 'fantasy',
                            sportsId: data.id,
                            hasAds: true
                        }
                    })
                )
                break;

            case 'match':
                dispatch(setCurrentLeague(data.id))
                navigate(`/match/${event?.id}`)
                break;
            default:
                break;
        }
    }


    const goToSeeMore = (event) => {
        dispatch(setCurrentLeague(data.id))
    }
    return (
        <Stack sx={{
            p: 2,
            width: '100%',
            boxSizing: 'border-box'
        }}>
            <Stack
                sx={{ width: '100%' }}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'small'}>
                <Typography>{data?.name}</Typography>
                {
                    data.type === 'match' &&
                    <Button
                        onClick={() => goToSeeMore()}
                        variant="text"
                        size="small">
                        See More
                    </Button>
                }
            </Stack>
            <Stack
                sx={{ width: '100%', mt: -1 }}>
                {

                    data?.events.map((item, index) => (
                        <MatchItem
                            goTo
                            key={index}
                            data={item}
                            functionToClick={() =>
                                goToHandler(item)
                            }
                        />
                    ))

                }
            </Stack>

        </Stack>
    )
}

export default EventAd
