import { NEWCHAT_COUNT } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getNewChatCount = createAsyncThunk(
  '/notify', async ({ count }, thunkAPI) => {
    thunkAPI.dispatch({
      type: NEWCHAT_COUNT,
      payload: count
    })
  })
