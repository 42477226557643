import React, { useState, useEffect, useRef } from 'react'
import { listWaggers } from '../../redux/actions/wagers'
import { logout } from '../../redux/actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import { getEventId } from '../../redux/actions/eventId'
import Loader from '../../components/loader'
import '../../css/WraggerBoard.css'
import { isWagerClose } from '../../helper'
import LikeServices from '../../services/like'
import UsersServices from '../../services/users'
import { useSocket } from '../../components/hooks/useSocket'
import DashboardHeader from '../../components/header/dashboard-header'
import { Container, Stack, Tab, Tabs, Typography, Button, Fab, SwipeableDrawer } from '@mui/material'
import LeagueHeaders from '../../components/header/leagues-header'
import WagerCard from '../../components/wager/feed-item'
import AppLayout from '../../components/layouts/app'
import MatchListItem, { MatchItem } from '../../components/matches/list-items'
import { Box, Alert, List } from '@mui/material'
import DateConverter from '../../components/DateConverter'
import theme from '../../styles/theme'
import PropTypes from 'prop-types';
import { setCurrentLeague, getLeagues } from '../../redux/actions/leagues'
import NewLeaderboard from '../../components/leaderboard/newleaderboard'
import { LoadingButton } from '@mui/lab'
import LockerRoom from '../../components/lockerroom'
import AdSwitcher from '../../components/Ads/switcher'
import { useNavigate } from 'react-router-dom'
import TuneIcon from '@mui/icons-material/Tune';
import { styled } from '@mui/system';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';
import Home from './home'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <>
      {value === index && (
        <Box sx={props.sx}
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}>
          {children}
        </Box>
      )}
    </>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const MySwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '&.MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, .6)'
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  overflow: 'hidden',
  padding: 0,
  display: 'flex',
  flexWrap: 'wrap',
  paddingLeft: 1,
  gap: '12px',
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    '&:not(:first-of-type), &:first-of-type, &:last-of-type': {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '100px',
      height: 'fit-content',
      padding: '4px 12px',
      boxSizing: 'border-box',

    },
    // ... other styles
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  // Default state styles go here
  color: theme.palette.text.primary,
  borderRadius: '100px',
  width: 'fit-content',
  fontSize: '12px',
  // Override selected state styles with increased specificity
  '&.Mui-selected, &.MuiToggleButton-root.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    zIndex: 9
  },
  '& .MuiTypography-caption': {
    lineHeight: '1', // Adjust line-height to ensure it fits within the button
  },
}));
const filterTypes = [
  {
    title: 'Wager Availablilty',
    filters: [
      {
        value: 'all',
        title: 'All Wagers'
      },
      {
        value: 'open',
        title: 'Open Wagers'
      },
      {
        value: 'closed',
        title: 'Closed Wagers'
      }
    ]
  },
  {
    title: 'Wager Types',
    filters: [
      {
        value: 'racer-v-racer',
        title: 'Racer v. Racer'
      },
      {
        value: 'podium',
        title: 'Podium'
      },
      {
        value: 'spread',
        title: 'Spread'
      },
      {
        value: 'holeshot',
        title: 'Holeshot'
      }
    ]
  }
]

function WraggerBoard() {
  const navigate = useNavigate()
  const socket = useSocket()
  const user = JSON.parse(localStorage.getItem('user'))
  const userInfo = useSelector((state) => state?.userProfile?.userInfo?.result)
  const dispatch = useDispatch()
  const loginData = useSelector((state) => state?.Auth)
  const wagerResponse = useSelector((state) => state?.Wagers)
  const wagerResponseData = wagerResponse?.wager?.result
  const [filterBy, setFilterBy] = useState({
    mode: 'fantasy',
    hasAds: true,
  })
  const leagues = useSelector((state) => state.Leagues.league);
  const overallLeagues = useSelector((state) => state.Leagues);
  const [wagerLimit, setWagerLimit] = useState(10)
  const eventIdResponse = useSelector((state) => state.eventId)
  const [counter, SetCounter] = useState(0)
  const postResponseData = useSelector((state) => state.allPost)
  const [likeId, setLikeId] = useState([])
  const SportData = useSelector((state) => state?.Sport?.sport)
  const [counterModalShow, setCounterModalShow] = useState(false)
  const [counterItem, setCounterItem] = useState(null)
  const [newChip, setNewChip] = useState(null)
  const [rowIndex, setRowIndex] = useState(null)
  // const [displayCounter, setDisplayCounter] = useState(null)
  const [loader, setLoader] = useState(false)
  const [counterLoader, setCounterLoader] = useState(false)
  const ads = useSelector((state) => state?.Ads)
  const [value, setValue] = React.useState(1);
  const [toggleFilter, setToggleFilter] = React.useState(false);
  const [filters, setFilters] = React.useState(() => []);
  const [wagerAvailabilityFilter, setWagerAvailabilityFilter] = React.useState(null);
  const [disableApply, setDisableApply] = React.useState(true);

  const handleWagerAvailability = (event, availablilty) => {
    setDisableApply(false)
    setWagerAvailabilityFilter(availablilty);
  };

  const handleFormat = (event, newFormats) => {
    setDisableApply(false)
    setFilters(newFormats);
  };

  const resetFilters = () => {
    setWagerAvailabilityFilter(null)
    setFilters([])
  }

  //EVERYTIME THE LEAGUE CHANGES --> RESET THE FILTER!!!

  const applyFilters = () => {
    let tempFilter = { ...filterBy }
    if (wagerAvailabilityFilter != null) tempFilter.wager = wagerAvailabilityFilter
    else delete tempFilter.wager
    if (filters.length > 0) tempFilter.wagerTypes = [...filters]
    else delete tempFilter.wagerTypes
    setFilterBy({ ...tempFilter })
    dispatch(
      listWaggers({
        skip: 0,
        limit: wagerLimit,
        filter: {
          ...tempFilter,
          sportsId: overallLeagues?.indexSelected
        }
      })
    )
    setToggleFilter(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getLeagues())
    if (overallLeagues?.indexSelected != 0) {
      dispatch(
        listWaggers({
          skip: 0,
          limit: wagerLimit,
          filter: {
            mode: 'fantasy',
            hasAds: true,
            sportsId: overallLeagues?.indexSelected
          }
        })
      )
      // dispatch(getAdDetails({
      //   id: overallLeagues?.indexSelected
      // }))
    }
  }, [])

  useEffect(() => {

    setWagerAvailabilityFilter(null)
    setFilters([])

  }, [overallLeagues.indexSelected]);


  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (!user && loginData.Login != 'Success') {
      navigate('/')
    }
    dispatch(
      listWaggers({
        skip: 0,
        limit: wagerLimit,
        filter: {
          ...filterBy,
          sportsId: overallLeagues?.indexSelected
        }
      })
    )
  }, [wagerLimit])

  const handleWagerLike = async (id, idx, isWagerLikeByMe) => {
    if (likeId.includes(id)) {
      const array = likeId.filter(item => item !== id)
      setLikeId(array)
      // }
    } else {
      setLikeId([...likeId, id])
    }
    await LikeServices.wagerLike(id).then((result) => {
    }).catch((err) => {
      console.log(err, 'error')
    })
  }

  useEffect(() => {
    if (leagues[overallLeagues?.indexSelected]?.feed) {
      const array = []
      for (let i = 0; i < leagues[overallLeagues?.indexSelected]?.feed?.length; i++) {
        if (leagues[overallLeagues?.indexSelected]?.feed[i].isWagerLikeByMe) {
          array.push(leagues[overallLeagues?.indexSelected]?.feed[i].id)
        }
      }
      setLikeId(array)
    }
  }, [leagues])

  const fetchMoreData = () => {
    setLoader(true)
    SetCounter(counter + 1)
    setTimeout(() => {
      setWagerLimit(wagerLimit + 5)
      setLoader(false)
    }, 1000)
  }

  useEffect(() => {
    SetCounter(0)
    window.scrollTo(0, 0)
  }, [overallLeagues.indexSelected]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false); // New state for the button visibility
  const [showHeader, setShowHeader] = useState(true);
  const lastScrollY = useRef(0);
  const scrollingContainerRef = useRef(null); // Ref for the scrolling div
  const scrollTimeout = useRef(null); // Ref to hold the timeout ID

  const controlHeaderVisibility = () => {
    if (scrollingContainerRef.current) {
      let currentScrollY = scrollingContainerRef.current.scrollTop;

      // Show or hide the header based on scroll direction
      if (currentScrollY < lastScrollY.current) {
        setShowHeader(true);
      } else if (currentScrollY > lastScrollY.current) {
        setShowHeader(false);
      }

      // Determine whether to show the "Scroll to Top" button
      setShowScrollToTopButton(currentScrollY > 100); // Example threshold

      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      scrollTimeout.current = setTimeout(() => {
        setShowHeader(true);
      }, 500);

      lastScrollY.current = currentScrollY;
    }
  };

  useEffect(() => {
    const scrollingContainer = scrollingContainerRef.current;
    if (scrollingContainer) {
      scrollingContainer.addEventListener('scroll', controlHeaderVisibility);

      return () => {
        scrollingContainer.removeEventListener('scroll', controlHeaderVisibility);
        if (scrollTimeout.current) {
          clearTimeout(scrollTimeout.current);
        }
      };
    }
  }, []);


  // Function to scroll to the top
  const scrollToTop = () => {
    if (scrollingContainerRef.current) {
      scrollingContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const marginTopContainers = '90px' //'0px'


  return (
    <AppLayout hideNavigation={
      (overallLeagues?.indexSelected === 0 ? false : leagues[overallLeagues?.indexSelected]?.events[0]?.startTime) ? value === 2 : false
    }>
      <Box
        ref={scrollingContainerRef}
        sx={{
          height: 'inherit',
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          //pb: value === 2 ? '0' : '110px',
        }}>

        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 99,
          bgcolor: 'black',
          transform: (showHeader) ? 'translateY(0)' : 'translateY(-100%)',
          transition: 'transform 0.3s ease-in-out'
        }}>
          <DashboardHeader title='MOTOBOOKIE' />
          {
            <LeagueHeaders
              leagues={leagues}
              eventInfo={eventIdResponse}
            />
          }
          {showScrollToTopButton && (
            <Container sx={{ pt: 1, pb: 1 }}>
              <Button
                //endIcon={<ArrowCircleUpIcon />}
                size='small'
                onClick={scrollToTop}
                fullWidth
                variant='outlined'>
                Scroll to top
              </Button>
            </Container>
          )}
        </Box>

        {
          leagues[0].status ?
            <Container sx={{
              marginTop: 16
            }}>
              <Home />
            </Container>
            :
            <>
              <MySwipeableDrawer
                anchor={'bottom'}
                open={toggleFilter}
                onClose={() => setToggleFilter(false)}
                onOpen={() => setToggleFilter(true)}
                PaperProps={{
                  style: {
                    minHeight: '60vh',
                    height: 'fit-content',
                    width: '100%',
                    backgroundColor: 'black',
                    boxShadow: 'none',
                    borderTop: 0,
                    borderRadius: '8px 8px 0 0',
                    borderBottom: '0 !important',
                    zIndex: '999999999999',
                    borderTop: `3px solid ${theme.palette.dark.otherdark}`
                  },
                }}
              >
                <Stack
                  spacing={3}
                  sx={{
                    bgcolor: 'black',
                    width: '100%',
                    height: '100%',
                    p: 2,
                    boxSizing: 'border-box'
                  }}>
                  <Stack
                    direction='row'
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{ width: '100%' }}
                  >
                    <Typography>Filter</Typography>
                    <Button onClick={() => resetFilters()} variant='text' size='small'>
                      Reset Filters
                    </Button>
                  </Stack>


                  <Stack spacing={1}>
                    <Typography>Wager Availablilty</Typography>
                    <StyledToggleButtonGroup
                      size="small"
                      exclusive
                      value={wagerAvailabilityFilter}
                      onChange={handleWagerAvailability}
                      aria-label="text formatting"
                    >
                      {
                        [
                          {
                            value: 'open',
                            title: 'Open Wagers'
                          },
                          {
                            value: 'closed',
                            title: 'Closed Wagers'
                          }
                        ].map((innerFilter, index) => (
                          <StyledToggleButton
                            key={index}
                            value={innerFilter.value}
                            aria-label={innerFilter.value}>
                            {innerFilter.title}
                          </StyledToggleButton>
                        ))
                      }
                    </StyledToggleButtonGroup>
                  </Stack>

                  {
                    overallLeagues.indexSelected != 5 &&
                    <Stack spacing={1}>
                      <Typography>Wager Types</Typography>
                      <StyledToggleButtonGroup
                        size="small"
                        value={filters}
                        onChange={handleFormat}
                        aria-label="text formatting"
                      >
                        {
                          [
                            {
                              value: 1,
                              title: overallLeagues.indexSelected != 1 ? 'Racer v. Racer' : 'Rider v. Rider'
                            },
                            {
                              value: 5,
                              title: 'Podium'
                            },
                            {
                              value: 3,
                              title: 'Spread'
                            },
                            {
                              value: 4,
                              title: 'Holeshot'
                            },
                            {
                              value: 2,
                              title: 'Placement'
                            }
                          ].map((innerFilter, index) => {
                            if ((innerFilter.value != 'holeshot' && overallLeagues.indexSelected != 1) || overallLeagues.indexSelected === 1) {
                              return (
                                <StyledToggleButton
                                  key={index}
                                  value={innerFilter.value}
                                  aria-label={innerFilter.value}>
                                  {innerFilter.title}
                                </StyledToggleButton>
                              )
                            }
                          })
                        }
                      </StyledToggleButtonGroup>
                    </Stack>
                  }


                  <Button
                    disabled={disableApply}
                    onClick={() => applyFilters()}
                    variant='contained'
                    size='small'>
                    Apply Filters
                  </Button>
                </Stack>

              </MySwipeableDrawer>

              {
                (
                  overallLeagues.indexSelected != 5
                  &&
                  value === 1
                ) &&
                <Fab
                  onClick={() => setToggleFilter(true)}
                  color='primary'
                  size="small"
                  sx={{
                    color: "white",
                    position: 'absolute',
                    bottom: '78px',
                    right: 6,
                    zIndex: 99
                  }}>
                  <TuneIcon />
                </Fab>
              }

              {
                leagues[overallLeagues?.indexSelected]?.isLoading && counter === 0
                  // adding counter turns it off
                  ? <Loader isloading={true} />
                  :
                  leagues[overallLeagues?.indexSelected]?.type === 'match'
                    ?
                    <>
                      <Container sx={{ mt: '136px', pb: '108px' }}>
                        <List>
                          {
                            leagues[overallLeagues?.indexSelected]?.dates[overallLeagues?.dateSelected?.index]?.match?.events?.length > 0
                              ?
                              leagues[overallLeagues?.indexSelected]?.dates[overallLeagues?.dateSelected?.index]?.match?.events?.map((item, index) => (
                                <>
                                  <MatchItem key={index} data={item} functionToClick={() => {
                                    navigate(`/match/${item.id}`)
                                  }} />
                                </>
                              ))
                              :
                              <Alert sx={{ mt: 2 }}>No Event available to wager on!</Alert>
                          }
                        </List>
                      </Container>
                    </>
                    :
                    leagues[overallLeagues?.indexSelected]?.feed?.length > 0 //&& leagues[overallLeagues?.indexSelected]?.events?.length > 0
                      ? (
                        <>
                          <Container
                            sx={{
                              mt: marginTopContainers,
                              //bgcolor: theme.palette.dark.dark,
                              //borderRadius: '0px 0px 8px 8px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: "center",
                              alignItems: 'center',
                              pb: 1
                            }}>
                            <Typography variant='h6'>{leagues[overallLeagues?.indexSelected]?.events[0]?.name}</Typography>
                            {
                              leagues[overallLeagues?.indexSelected].events[0]?.startTime
                                ?
                                <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}><DateConverter currTime={leagues[overallLeagues?.indexSelected].events[0]?.startTime} /></Typography>
                                :
                                <Stack
                                  direction="row"
                                  sx={{
                                    width: '100%'
                                  }}
                                >
                                  {
                                    <Alert sx={{ flexGrow: 1 }} severity="warning">{!!wagerResponseData?.message ? wagerResponseData?.message : "There is currently no active event!"}</Alert>
                                  }
                                </Stack>

                            }
                          </Container>

                          <Box sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            zIndex: 9
                          }}>
                            <Tabs
                              variant="scrollable"
                              value={value}
                              onChange={handleChange}
                              allowScrollButtonsMobile
                              scrollButtons="auto"
                              sx={theme.tabs}
                              aria-label="basic tabs example">
                              <Tab label="Real Money" sx={theme.tab} />
                              <Tab label="Fantasy" sx={theme.tab} />
                              {
                                leagues[overallLeagues?.indexSelected].events[0]?.startTime &&
                                <Tab label="Lockerroom" sx={theme.tab} />
                              }
                              <Tab label="Leaderboard" sx={theme.tab} />
                            </Tabs>
                          </Box>

                          <CustomTabPanel value={value} index={0}>
                            <List className="loginWrap scrollable center" id='scrollableDiv'>
                              <Container sx={{ mt: 2 }}>
                                <Alert severity="warning">Real Money Wagering coming soon!</Alert>
                              </Container>
                            </List>
                          </CustomTabPanel>

                          <CustomTabPanel value={value} index={1} sx={{ pb: 14 }}>
                            <List  >
                              {
                                leagues[overallLeagues?.indexSelected]?.feed?.map((item, index) => {
                                  const wagerStatus = isWagerClose(item?.event_data?.startTime)
                                  return (
                                    <div key={index}>
                                      {
                                        !!item?.engagementType
                                          ?
                                          <AdSwitcher
                                            wagerData={{
                                              user: user
                                            }}
                                            data={item} />
                                          :
                                          <WagerCard
                                            data={{
                                              ...item,
                                              wagerStatus: wagerStatus,
                                              user: user,
                                              index: index,
                                              likeId: likeId
                                            }}
                                            likeFunction={() => handleWagerLike(item?.id, index, item?.isWagerLikeByMe)} />

                                      }
                                    </div>
                                  )
                                })}
                              {
                                wagerLimit < leagues[overallLeagues?.indexSelected]?.count
                                &&
                                <Container>
                                  <LoadingButton
                                    loading={leagues[overallLeagues.indexSelected]?.isLoading || loader}
                                    onClick={() => fetchMoreData()}
                                    fullWidth
                                    variant='outlined'>
                                    Load more
                                  </LoadingButton>
                                </Container>
                              }

                            </List>
                          </CustomTabPanel>


                          {
                            leagues[overallLeagues?.indexSelected].events[0]?.startTime &&
                            <CustomTabPanel
                              value={value}
                              index={2}
                              sx={{
                                flexGrow: 1,
                                overflow: 'hidden'
                              }}>
                              <LockerRoom eventId={leagues[overallLeagues?.indexSelected]?.events[0]?.id} />
                            </CustomTabPanel>
                          }

                          <CustomTabPanel value={value} index={leagues[overallLeagues?.indexSelected].events[0]?.startTime ? 3 : 2} sx={{}}>
                            <Box sx={{
                              height: 'auto',
                              //maxHeight: 'calc(100vh - 160px)',
                              overflowY: 'auto'
                            }}>
                              <NewLeaderboard />
                            </Box>
                          </CustomTabPanel>
                        </>
                      )
                      : (
                        leagues[overallLeagues?.indexSelected].events?.length > 0
                          ?
                          <>
                            <Container
                              sx={{
                                mt: marginTopContainers,
                                borderBottom: `3px solid ${theme.palette.dark.otherdark}`,
                                bgcolor: theme.palette.dark.dark,
                                //borderRadius: '0px 0px 8px 8px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: "center",
                                alignItems: 'center',
                                pb: 1.5,
                                pt: 1
                              }}>
                              <Typography variant='h6'>{leagues[overallLeagues?.indexSelected].events[0]?.name}</Typography>
                              {
                                leagues[overallLeagues?.indexSelected].events[0]?.startTime
                                  ? <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}><DateConverter currTime={leagues[overallLeagues?.indexSelected].events[0]?.startTime} /></Typography>
                                  :
                                  <Box sx={{
                                    mt: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}>
                                    <Alert severity="warning">{!!wagerResponseData?.message ? wagerResponseData?.message : "There is currently no active event!"}</Alert>
                                  </Box>
                              }
                            </Container>
                            <Box sx={{
                              mt: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                              <Alert severity="success">Be the first to wager!</Alert>
                            </Box>
                          </>
                          :
                          <Container sx={{
                            mt: '100px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                            <Alert severity="info" sx={{ width: '90%' }}>{!!overallLeagues?.message ? overallLeagues?.message : "Coming soon!"}</Alert>
                          </Container>
                      )
              }

            </>
        }
      </Box>

    </AppLayout >
  )
}

export default WraggerBoard

