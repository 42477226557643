
const initialState = {
  isLoading: false
}
export default function addCard (state = initialState, action) {
  switch (action.type) {
    case 'ADD_CARD_REQUEST': {
      return {
        ...state,
        isLoading: true,
        cardInfo: action.payload
      }
    }
    case 'ADD_CARD': {
      return {
        ...state,
        isLoading: false,
        cardInfo: action.payload
      }
    }
    case 'ERROR_OCCURED': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }
    case 'RESET_CARD' : {
      return {
        initialState
      }
    }
    default:
      return state
  }
}
