import React from 'react'
import { Box, Container, useMediaQuery, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import authbgimg from "../../images/authHeader.jpg"

const AuthLayout = ({ children }) => {
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    
    return (
        <Box sx={{
            height: '100vh',
            padding: false ? '36px' : 0,
            display: false ? "grid" : 'flex',
            gridTemplateColumns: '1fr 1fr',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '36px',
            pt:2,
            pb:2,
            boxSizing:'border-box'

        }}>

            {
                false ?
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        background: theme.palette.primary.dark,
                        backgroundImage: `url(${authbgimg})`,           // Add this line for the background image
                        backgroundPosition: 'center',                    // Center the image
                        backgroundSize: 'cover',
                        borderRadius: '24px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            background: 'rgb(0,0,0,.8)',
                            width: '100%',
                            height: '100%',
                            borderRadius: '24px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center'
                            //backdropFilter: 'blur(25px)',  // Adjust the pixel value as needed for the desired blur amount

                        }}>
                            <Typography variant='h2'>Sports Betting Meets <br />Social Media</Typography>
                        </Box>
                    </Box > : null
            }

            <Container sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Box
                    sx={{
                        maxWidth: '400px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: isSmall ? 'center' : 'space-between',
                        gap: '24px',
                        alignItems: 'center',
                        height: '100%',
                        maxHeight: 'fit-content',
                        width: '100%'
                    }}>
                    <div></div>
                    {children}
                </Box>
            </Container>
        </Box >
    )
}

export default AuthLayout
/*

            <Container
                sx={{
                    width: '100%',
                    maxWidth: '400px',
                    bgcolor: 'pink',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: 'inherit',
                    width: 'inherit',
                    pt: 1,
                    pb: 2
                }}>
                <div></div>
                {children}
            </Container>
*/