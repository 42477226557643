import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// eslint-disable-next-line react/prop-types
const Loader = ({ isloading }) => {
  return <>
    {isloading &&
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          pt: 4
        }}>
        <CircularProgress />
      </Box>
    }
  </>
}

Loader.prototype = {
  isloading: PropTypes.bool
}

export default Loader
