import React from 'react'
import { Link } from 'react-router-dom'


function EditEmail () {
  return (
        <>
          <div className="fullScreen fullScreen-login login-solid">
          <div className="pageTop forDrop bgDark">
                <div className="backLink">
                   <Link to="/edit-profile">
                       <div className="backLink-icon noBg"></div>
                   </Link>
                  <span className="mt-0">Email</span>
                </div>
            </div>

             <div className="loginWrap scrollable-notification">
                <div className="editPro">
                    <div className="smLogo text-center">
                        <p className="smTxt pt-2">Your username cannot contain a space.</p>
                    </div>
                    <div className="editInpWrap">
                       <input className="editInp" type="email" autoComplete="off" placeholder="New Email" />
                    </div>
                    <div className="editInpWrap">
                       <input className="editInp" type="email" autoComplete="off" placeholder="Confirm New Email" />
                    </div>
                    <div className="editInpWrap">
                       <input className="editInp" type="password" autoComplete="off" placeholder="Current Password" />
                    </div>
                    <div className="editInpWrap text-center">
                    <Link to="/edit-success"><button className="button button-white mt-3">Change</button></Link>
                    </div>
                </div>
             </div>

          </div>
        </>
  )
}

export default EditEmail
