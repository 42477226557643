import { ERROR_OCCURED, GET_ADS_REQUEST, GET_ADS } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import AdServices from '../../../services/ads'

export const getAdDetails = createAsyncThunk(
  '/ads/getad/id',
  async ({ id }, thunkAPI) => {
    thunkAPI.dispatch({
      type: GET_ADS_REQUEST
    })
    try {
      const data = await AdServices.getAd(1)
      thunkAPI.dispatch({
        type: GET_ADS,
        payload: data && data?.data
      })
      return { subscription: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
