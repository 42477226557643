import React from 'react'
import { IconButton, Box, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@emotion/react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
const GoBackHeader = ({ title, borderbottom }) => {

    const theme = useTheme()

    const navigate = useNavigate()

    return (

        <Box
            sx={{
                padding: '12px',
                borderBottom: !!borderbottom ? `solid .5px ${theme.palette.secondary.light}` : ''
            }}>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIosNewIcon fontSize='small' />
                </IconButton>
                <Typography
                    variant='h6'
                    sx={{
                        textAlign: 'center'
                    }}>
                    {title}
                </Typography>

            </Box>

        </Box>
    )
}

export default GoBackHeader