import React from 'react'
import CardLayout from '../layouts/card'
import { Box, Typography, Divider, Stack, List, ListItem, ListItemButton } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom';

const SponsorItem = ({ data }) => {
    const theme = useTheme()
    return (
        <ListItemButton
            onClick={() => {
                window.open(data?.url, '_blank', 'noopener,noreferrer');
            }}
            sx={{
                display: 'flex',
                p: .5,
                gap: '12px'
            }}>

            <Box>
                <Box sx={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '6px',
                    bgcolor: theme.palette.secondary.light,
                    border: `solid 1px ${theme.palette.secondary.light}`,
                    display: 'flex',              // Add this to use flexbox
                    justifyContent: 'center',     // Center horizontally
                    alignItems: 'center',         // Center vertically
                    overflow: 'hidden'            // Hide overflow
                }}>
                    <img
                        src={data?.image}
                        style={{
                            width: '100%',        // Set width to fill container
                            height: '100%',       // Set height to fill container
                            objectFit: 'cover'    // Cover the full area, cropping if necessary
                        }}
                        alt="Descriptive text"
                    />
                </Box>

            </Box>
            <Box sx={{
                flexGrow: 1,
                maxWidth: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <Stack spacing={-.5} sx={{}}>
                    <Typography variant='body2' sx={{ fontWeight: 700 }}>{data?.name}</Typography>
                    <Typography variant='caption' sx={{ fontWeight: 700, color: theme.palette.dark.otherlight }} >Sponsored</Typography>
                </Stack>
                <Typography variant='caption' style={{ wordWrap: 'break-word', whiteSpace: 'normal', lineHeight: "16px" }}>{data?.description}</Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <KeyboardArrowRightIcon sx={{ color: 'white' }} />
            </Box>
        </ListItemButton >
    )
}
// on the trendiest hats, exclyusively at LIDS!

const FeedSponsor = ({ data, grouped }) => {
    const theme = useTheme()
    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            borderRadius: '8px',
        }}>
            {
                !!!grouped &&
                <Typography sx={{ fontWeight: 700 }} variant='body1'>Sponsored</Typography>
            }
            
            <SponsorItem data={data} />
        </Box>
    )
}

export default FeedSponsor

/*
{
                data?.ads?.map((item, index) => (
                    <div key={index}>
                        <SponsorItem data={item} />
                        {index != data?.ads.length - 1 && <Divider sx={{ width: '100%' }} />}
                    </div>
                ))
            }
*/

/*
<Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '25px'
            }}>
                <Typography sx={{ fontWeight: 700 }}>Sponsored</Typography>
                <Divider sx={{ width: '100%' }} />
                <SponsorItem />
                <Divider sx={{ width: '100%' }} />
                <SponsorItem />
            </Box>
*/