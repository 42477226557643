
const initialState = {
  isLoading: false
}

export default function updateNotification (state = initialState, action) {
  switch (action.type) {
    case 'SET_NOTIFICATION_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'SET_NOTIFICATION': {
      return {
        ...state,
        isLoading: false,
        notification: action.payload
      }
    }
    case 'ERROR_OCCURED': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }
    default:
      return state
  }
}
