import { ERROR_OCCURED, SET_NOTIFICATION, SET_NOTIFICATION_REQUEST } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import NotificationServices from '../../../services/notification'

export const updateNotification = createAsyncThunk(
  '/update-notification',
  async ({ notificationSetting }, thunkAPI) => {
    thunkAPI.dispatch({
      type: SET_NOTIFICATION_REQUEST
    })
    try {
      console.trace('RUNNING NOTIFICATIONS!')
      const data = await NotificationServices.setNotification(notificationSetting)
      thunkAPI.dispatch({
        type: SET_NOTIFICATION,
        payload: data && data?.data
      })
      return { privateers: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }

)
