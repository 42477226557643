import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { updateUserName } from '../../redux/actions/profile'
import { useDispatch, useSelector } from 'react-redux'

function EditUserName () {
  const [name, setName] = useState(null)
  const navigate = useNavigate()
  const responseData = useSelector((state) => state.updateUsername)
  const isUserNameChanged = responseData?.userName?.status
  const dispatch = useDispatch()
  const handleUserProfile = () => {
    dispatch(
      updateUserName({
        username: name
      })
    )
  }

  useEffect(() => {
    if (isUserNameChanged) {
      navigate('/edit-success')
    }
  }, [isUserNameChanged])

  return (
    <>
      <div className="fullScreen fullScreen-login login-solid">
        <div className="pageTop forDrop bgDark">
          <div className="backLink">
            <Link to="/edit-profile">
              <div className="backLink-icon noBg">
              </div>
            </Link>
            <span className="mt-0">User Nameee</span>
          </div>
        </div>

        <div className="loginWrap scrollable-notification">
          <div className="editPro">
            <div className="smLogo text-center">
              <p className="smTxt pt-2">
                Your username cannot contain a space.
              </p>
            </div>
            <div className="editInpWrap">
              <input
                className="editInp"
                type="text"
                placeholder="New Username"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="editInpWrap">
              <input
                className="editInp"
                type="password"
                placeholder="Current Password"
              />
            </div>
            <div className="editInpWrap text-center">
              <button
                className="button button-white mt-3"
                onClick={handleUserProfile}
              >
                Change
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditUserName
