import React from 'react'
import { Box, Typography, Chip } from "@mui/material"

const WagerCreateHeader = ({ data }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='h6'>{data.title}</Typography>
            <Chip label={data.subtitle.toUpperCase()} size='small' sx={{ fontWeight: 700 }} />
        </Box>
    )
}

export default WagerCreateHeader
