const initialState = {}
export default function updatePrivateer (state = initialState, action) {
  switch (action.type) {
    case 'SET_PRIVATEERS': {
      return {
        ...state,
        PrivateerStatus: 'Success',
        privateers: action.payload
      }
    }
    case 'ERROR_OCCURED': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }
    default:
      return state
  }
}
