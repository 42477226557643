import { useTheme } from '@emotion/react';
import { Avatar, Box, Container, Stack, Typography } from '@mui/material';
import React from 'react'
import HandleDate from '../DateCalculator';
import logo from '../../images/betzonlogo.svg'

const NotificationItem = ({ type, data }) => {
    const theme = useTheme()
    const padding = 2
    switch (type) {
        case 'like-wager':
            const metaDataLikeWager = JSON.parse(data?.metadata)
            return (
                <Container>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent={'space-between'}
                        alignItems={"center"}
                        sx={{
                            bgcolor: 'black',
                            height: '',
                            width: '100%',
                            pt: padding,
                            pb: padding
                        }}>
                        <Stack
                            direction='row'
                            spacing={1}
                            alignItems={"center"}>
                            <Avatar src={metaDataLikeWager?.likedBy_userLogo} />
                            <Stack spacing={-.25}>
                                <Typography variant='body2'>{data?.message}</Typography>
                                <Typography variant='caption' color={theme.palette.dark.otherlight}>Wager:  {metaDataLikeWager?.wagername}</Typography>
                            </Stack>
                        </Stack>
                        <Typography variant='caption' sx={{ textAlign: 'right' }}>
                            <HandleDate date={data?.createdAt} />
                        </Typography>
                    </Stack>
                </Container>
            )
            break;

        case 'join-wager':
            const metaDataJoinWager = JSON.parse(data?.metadata)
            return (
                <Container>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent={'space-between'}
                        alignItems={"center"}
                        sx={{
                            bgcolor: 'black',
                            height: '',
                            width: '100%', pt: padding,
                            pb: padding
                        }}>
                        <Stack
                            direction='row'
                            spacing={1}
                            alignItems={"center"}>
                            <Avatar src={metaDataJoinWager?.likedBy_userLogo} />
                            <Stack spacing={-.25}>
                                <Typography variant='body2'>{data?.message}</Typography>
                                <Typography variant='caption' color={theme.palette.dark.otherlight}>Wager: {metaDataJoinWager?.wagername}</Typography>
                            </Stack>
                        </Stack>
                        <Typography variant='caption' sx={{ textAlign: 'right' }}>
                            <HandleDate date={data?.createdAt} />
                        </Typography>
                    </Stack>
                </Container>
            )
            break;


        case 'comment-wager':
            const metaDataCommentWager = JSON.parse(data?.metadata)
            return (
                <Container>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent={'space-between'}
                        alignItems={"center"}
                        sx={{
                            bgcolor: 'black',
                            height: '',
                            width: '100%', pt: padding,
                            pb: padding
                        }}>
                        <Stack
                            direction='row'
                            spacing={1}
                            alignItems={"center"}>
                            <Avatar src={metaDataCommentWager?.likedBy_userLogo} />
                            <Stack spacing={-.25}>
                                <Typography variant='body2'>{data?.message}</Typography>
                                <Typography variant='caption' color={theme.palette.dark.otherlight}>Wager {metaDataCommentWager?.wagername}</Typography>
                            </Stack>
                        </Stack>
                        <Typography variant='caption' sx={{ textAlign: 'right' }}>
                            <HandleDate date={data?.createdAt} />
                        </Typography>
                    </Stack>
                </Container>
            )
            break;

        case 'event-created':
            const metaDataEventCreate = data?.metadata
            return (
                <Container>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent={'space-between'}
                        alignItems={"center"}
                        sx={{
                            bgcolor: 'black',
                            height: '',
                            width: '100%', pt: padding,
                            pb: padding
                        }}>
                        <Stack
                            direction='row'
                            spacing={1}
                            alignItems={"center"}>
                            <Avatar sx={{ bgcolor: 'black', border: `2px solid ${theme.palette.primary.main}` }}>
                                <img src={logo} style={{ width: '22px', height: '22px' }} />
                            </Avatar>
                            <Stack spacing={-.25}>
                                <Typography variant='body2'>{data?.message}</Typography>
                            </Stack>
                        </Stack>
                        <Typography variant='caption' sx={{ textAlign: 'right' }}>
                            <HandleDate date={data?.createdAt} />
                        </Typography>
                    </Stack>
                </Container>
            )
            break;

        default:
            break;
    }
}

export default NotificationItem
