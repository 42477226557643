import axios from './axios'
import authHeader from './auth-header'

const getMasters = (skip, limit, route, sportsId) => {
  return axios.post(route, {
    skip,
    limit,
    sportsId
  }, {
    headers: authHeader()
  })
}

const getMastersByEvent = (route) => {
  return axios.get(route, {
    headers: authHeader()
  })
}

const masterServices = {
  getMasters,
  getMastersByEvent
}
export default masterServices
