import { SET_CALENDAR_DATE, SET_CURRENT_LEAGUE } from "../../types";

const initialState = {
    ads: [],
    isLoading: false
};

function adsReducer(state = initialState, action) {
    switch (action.type) {
        case "GET_ADS":
            return {
                ...state,
                ads: action.payload,
                isLoading: false
            }
        case "GET_ADS_REQUEST":
            return {
                ...state,
                isLoading: true
            }
        default:
            return state;
    }
}

export default adsReducer;

