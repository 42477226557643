const initialState = {
  isLoading: false,
  league: [
    {
        title: 'Home',
        status: true

    },
    {
        title: 'NBA',
        status: false

    },
    {
        title: 'NFL',
        status: false
    },
    {
        title: 'MLB',
        status: false
    },
    {
        title: 'NHL',
        status: false
    }
],
}
export default function LeaderBoard (state = initialState, action) {
  switch (action.type) {
    case 'LEADERBOARD_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'LEADERBOARD': {
      return {
        ...state,
        isLoading: false,
        Status: 'Pass',
        leaderData: action.payload,
        error: null
      }
    }
    case 'LEADERBOARD_ERROR': {
      return {
        Status: 'Fail',
        error: action.payload
      }
    }
    default:
      return state
  }
}
