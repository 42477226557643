import React, { useRef, useEffect, useState } from 'react';
import { Avatar, Box, Button, Divider, Stack, Typography } from '@mui/material'
import { useSelector, useDispatch } from "react-redux"
import FestivalIcon from '@mui/icons-material/Festival';
import DateBarHeader from './date-bar';
import { setCreateWagerCurrentLeague, setCurrentLeague } from '../../redux/actions/leagues';
import { useTheme } from '@emotion/react';
import { listWaggers } from '../../redux/actions/wagers';
import { ListLeaderBoard } from '../../redux/actions/leaderBoard';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import StadiumIcon from '@mui/icons-material/Stadium';
import gsap from 'gsap';
import { alpha, styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import { getAdDetails } from '../../redux/actions/ads';

const LeagueHeaders = ({ handleSportFilter, nohome, fantasyChips, createWager }) => {

    const dispatch = useDispatch();

    const theme = useTheme()
    const [wagerLimit, setWagerLimit] = useState(10)
    const [filterBy, setFilterBy] = useState({
        mode: 'fantasy'
    })
    const leagues = useSelector((state) => state.Leagues.league);
    const overallLeagues = useSelector((state) => state.Leagues);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabsRef = useRef(null);

    const buttonRefs = useRef([]);

    const addOrRemoveTeamHandler = (event, index) => {
        const newValue = !!fantasyChips ? index : index
        //console.log('CHANIGN HEREOAIDHFOIAHFGOIAHOIHEAO"IHGO":EAG', index, name, id, leaderboardResponse)
        if (!!createWager) {
            dispatch(setCreateWagerCurrentLeague(index))
        }
        else {
            if (newValue === 0) {
                dispatch(setCurrentLeague(index))
            }
            else if (!!fantasyChips) {
                dispatch(setCurrentLeague(index))
            }
            else {
                dispatch(setCurrentLeague(Number(newValue)))
                if (leagues[newValue].type === 'event') {
                    dispatch(ListLeaderBoard({ sportsId: newValue }))
                    dispatch(
                        listWaggers({
                            skip: 0,
                            limit: wagerLimit,
                            filter: {
                                ...filterBy,
                                sportsId: newValue,
                                hasAds: true
                            }
                        })
                    )
                    // dispatch(getAdDetails({
                    //     id: newValue
                    // }))
                }
                else if (leagues[newValue].type === 'match') {
                    //console.log('SETTING A MATCH')
                }
            }
        }
    }

    useEffect(() => {
        const adjustScroll = () => {
            if (tabsRef.current) {
                const scrollableContainer = tabsRef.current.querySelector(
                    '.MuiTabs-scroller'
                );
                const selectedTabNode = scrollableContainer.querySelector(
                    '.Mui-selected'
                );
                if (selectedTabNode) {
                    scrollableContainer.scrollLeft =
                        selectedTabNode.offsetLeft +
                        selectedTabNode.clientWidth / 2 -
                        scrollableContainer.clientWidth / 2;
                }
            }
        };
        adjustScroll();
    }, [value]);

    const iconStyle = {
        // color: 'white'
        pt: .5,
        height: '16px',
        width: '16px'
    }
    const nav = [
        {
            title: 'MOTOX',
            icon: <SportsMotorsportsIcon sx={iconStyle} />
        },
        {
            title: 'FORMULA1',
            icon: <SportsScoreIcon sx={iconStyle} />
        },
        {
            title: 'MOTOGP',
            icon: <SportsMotorsportsIcon sx={iconStyle} />
        },
        {
            title: 'NASCAR',
            icon: <SportsScoreIcon sx={iconStyle} />
        },
        {
            title: 'MLB',
            icon: <SportsBaseballIcon sx={iconStyle} />
        }
    ]
    useEffect(() => {
        // Target the dot by its class name and create the pulsating effect
        gsap.to(".dot", {
            scale: 2, // Scale up to 150% of its original size
            duration: 0.75, // Duration of one pulse
            ease: "power1.inOut", // Easing function for a smooth animation
            repeat: -1, // Repeat the animation indefinitely
            yoyo: true // Go back to the original state before repeating the animation
        });
    }, []);

    const styleTab = {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 2,
        paddingRight: 1,
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: '10px', // Set your desired height here
        alignItems: 'center', // This ensures that the content of the tab is centered vertically
        justifyContent: 'center',
        gap: '6px',
        textTransform: 'none',
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(11),
        marginRight: theme.spacing(1),
        color: 'rgba(255, 255, 255, 0.6)',
        width: 'fit-content',
        borderRadius: '6px',
        border: `2px solid ${theme.palette.primary.main}`,
        '&.Mui-selected': {
            color: '#fff',
            background: alpha(theme.palette.primary.main, .3),
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }

    return (
        <>
            <Tabs
                value={!!createWager ? overallLeagues?.creatorWagerIndexSelected : overallLeagues?.indexSelected}
                ref={tabsRef}
                onChange={addOrRemoveTeamHandler}
                variant="scrollable"
                scrollButtons={false}
                aria-label="styled tabs example"
                sx={{
                    '& .MuiTabs-indicator': {
                        backgroundColor: 'transparent',
                    },
                    '& .MuiTabs-indicatorSpan': {
                        maxWidth: 0,
                        width: '100%',
                        backgroundColor: '#635ee7',
                    },
                    '.MuiTabs-scroller': {
                        scrollBehavior: 'smooth'
                    },
                    position: 'relative',
                    minHeight:'fit-content',
                }}
            >
                {
                    !!!nohome &&
                    <Tab
                        value={0}
                        //onClick={() => addOrRemoveTeamHandler(0)}
                        icon={
                            <StadiumIcon sx={iconStyle} />
                        }
                        label={

                            <>
                                TRENDING
                                {
                                    !!!nohome &&
                                    <Box
                                        className="dot"
                                        sx={{
                                            borderRadius: '200px',
                                            bgcolor: theme.palette.primary.main,
                                            height: '4px',
                                            width: '4px',
                                            opacity: 0
                                        }} />
                                }

                            </>
                        }
                        sx={{ ...styleTab, ml: 2 }} />
                }
                {
                    leagues.slice(1).map((item, index) => (
                        <Tab
                            key={index}
                            value={index + 1}
                            //onClick={() => addOrRemoveTeamHandler(index + 1, item?.name, item?.id)}
                            icon={nav[index].icon} // Adjust bottom padding to reduce space
                            label={
                                <>
                                    {nav[index].title}
                                    {
                                        <Box
                                            className="dot"
                                            sx={{
                                                mt: .1,
                                                ml: .4,
                                                mr: item?.events?.length > 0 ? 1.25 : 0,
                                                borderRadius: '200px',
                                                bgcolor: theme.palette.primary.main,
                                                height: '4px',
                                                width: '4px',
                                                opacity: item?.events?.length > 0 ? 1 : 0
                                            }} />
                                    }
                                </>
                            }
                            sx={{
                                ...styleTab,
                                ml: (!!nohome && index === 0) ? 2 : 0
                            }} />
                    ))
                }
            </Tabs>

            {
                //creatorWagerIndexSelected
                (
                    leagues[!!createWager ? overallLeagues?.creatorWagerIndexSelected : overallLeagues?.indexSelected]?.type === 'match'
                    &&
                    !!!fantasyChips
                )
                &&
                <DateBarHeader
                    data={leagues[!!createWager ? overallLeagues?.creatorWagerIndexSelected : overallLeagues?.indexSelected]}
                    createWager={!!createWager} />
            }

        </>

    )
}

export default LeagueHeaders