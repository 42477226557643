import axios from './axios'
import authHeader from './auth-header'

const BillingDetails = (id, isWithdraw) => {
  return axios.post(`profile/retrieve-card/${id}`, {
    id, isWithdraw
  }, {
    headers: authHeader()
  })
}

const BillingDetailsService = {
  BillingDetails
}

export default BillingDetailsService
