


import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { SnackbarProvider, useSnackbar } from 'notistack';
import ReportCompleteSnackbar from './notification-content';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useSocket } from '../hooks/useSocket';
import { useLocation } from 'react-router-dom';
import logo from '../../images/betzonlogo.svg'

function MyApp() {
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar();
    const user = JSON.parse(localStorage.getItem('user'))

    const response = useSelector((state) => state?.NotificationList)
    const messages = useSelector((state) => state?.allChats?.chatList)
    //const [messages, setMessages] = useState([])
    const unReadChatCount = useSelector((state) => state.newChatCount)

    const [readCount, setReadCount] = React.useState(response?.notification?.readCount)
    const [counter, setCounter] = React.useState(0)
    const [counterMessages, setCounterMessages] = React.useState(0)
    const [messageReadCount, setMessageReadCount] = React.useState(unReadChatCount.chatCount)
    const isFirstRun = useRef(true); // This ref will keep track of whether it's the first execution
    const isFirstNotificationRun = useRef(true); // This ref will keep track of whether it's the first execution
    const location = useLocation()
    const socket = useSocket()

    //WORKING!
    useEffect(() => {
        if (isFirstNotificationRun.current) {
            setCounter(counter + 1)
            isFirstNotificationRun.current = false; // Set it to false so next time this runs, it won't skip
            return; // Exit the effect early on the first run
        }
        else if (
            !isFirstNotificationRun.current
            &&
            readCount < response?.notification?.readCount
        ) {
            handleClick(response?.notification?.result[0])
            setReadCount(response?.notification?.readCount)
        }
    }, [response]);


    useEffect(() => {
        if (isFirstRun.current) {
            setCounterMessages(counterMessages + 1)
            isFirstRun.current = false; // Set it to false so next time this runs, it won't skip
            return; // Exit the effect early on the first run
        }
        else if (
            !isFirstRun?.current
            &&
            messageReadCount < unReadChatCount.chatCount
            &&
            // location.pathname != '/message'
            // &&
            // location.pathname != '/chat-screen'
            // &&
            user?.id != messages[0]?.chatmessages[0]?.userId
        ) {
            //setCounterMessages(counterMessages + 1)
            handleClick({
                userId: null,
                // message: `@${messages[0]?.chatmessages[0]?.user_data?.username} sent you a message!`,
                message: `Someone sent you a message!`,
                type: 'like-wager',
                createdAt: new Date(),
                metadata: JSON.stringify({
                    type: 'like-wager',
                    wagername: `See what they said!`,
                    likedBy_userLogo: logo,
                    //likedBy_userLogo: messages[0]?.chatparticipants?.find(participant => participant?.userId !== user?.id)?.user_data?.userLogo,
                    isClickable: false,
                    isClicked: false,
                }),
            })
            setMessageReadCount(unReadChatCount?.chatCount)
        }
    }, [unReadChatCount]);

    const action = key => (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
                // Use the key to close the snackbar
                enqueueSnackbar.closeSnackbar(key);
            }}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    );
    const handleClick = (data) => {
        enqueueSnackbar(
            ``,
            {
                variant: 'reportComplete',
                autoHideDuration: 2500,
                //persist: true, // Hide after 3000 milliseconds (3 seconds)
                action, // Pass the action function correctly
                content: (key) => <ReportCompleteSnackbar
                    key={key}
                    type={data?.type}
                    data={data} />,
            })
    };


    return (
        <React.Fragment>
        </React.Fragment>
    );
}

export default function NotificationAppAlert() {
    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: 'top', // Position vertically at the top
                horizontal: 'right', // Position horizontally at the right
            }}
        >
            <MyApp />
        </SnackbarProvider>
    );
}

/*
useEffect(() => {
        if (isFirstRun.current) {
            setCounterMessages(counterMessages + 1)
            isFirstRun.current = false; // Set it to false so next time this runs, it won't skip
            return; // Exit the effect early on the first run
        }
        else if (!isFirstRun.current && messageReadCount < unReadChatCount.chatCount && !!messages[0]?.chatparticipants?.find(participant => participant.userId !== user?.id)?.user_data?.username) {
            //setCounterMessages(counterMessages + 1)
            handleClick({
                userId: null,
                message: `@${messages[0]?.chatmessages[0]?.user_data?.username} sent you a message!`,
                type: 'like-wager',
                createdAt: new Date(),
                metadata: JSON.stringify({
                    type: 'like-wager',
                    wagername: `See what they said!`,
                    likedBy_userLogo: messages[0]?.chatparticipants?.find(participant => participant?.userId !== user?.id)?.user_data?.userLogo,
                    isClickable: false,
                    isClicked: false,
                }),
            })
            setMessageReadCount(unReadChatCount?.chatCount)
        }
    }, [unReadChatCount]);
*/



/*


    useEffect(() => {
        socket.on('something', data => {
            console.log('SOCKET -', data)
            console.log(data[0]?.chatmessages[0]?.userId)
            console.log(user?.id)
            console.log((
                location.pathname != '/message'
                ||
                location.pathname != '/chat-screen'
            )
                &&
                user?.id != data[0]?.chatmessages[0]?.userId)
            if (isFirstRun.current) {
                setCounterMessages(counterMessages + 1)
                isFirstRun.current = false; // Set it to false so next time this runs, it won't skip
                return; // Exit the effect early on the first run
            }
            else if (
                (
                    location.pathname != '/message'
                    ||
                    location.pathname != '/chat-screen'
                )
                &&
                user?.id != data[0]?.chatmessages[0]?.userId
            ) {
                console.log('SOCKET -', data)
                handleClick({
                    userId: null,
                    message: `@${data[0]?.chatmessages[0]?.user_data?.username} sent you a message!`,
                    type: 'like-wager',
                    createdAt: new Date(),
                    metadata: JSON.stringify({
                        type: 'like-wager',
                        wagername: `See what they said!`,
                        likedBy_userLogo: data[0]?.chatmessages[0]?.user_data?.userLogo,
                        isClickable: false,
                        isClicked: false,
                    }),
                })
                setMessageReadCount(unReadChatCount?.chatCount)
            }
        })
    }, [socket]);

*/