import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { logout, logout2 } from '../../redux/actions/auth'
import { getUserProfile } from '../../redux/actions/profile'
import { updateNotification } from '../../redux/actions/notification'
import Loader from '../../components/loader'
import '../../css/TransferTranscript.css'
// import { messaging } from '../../firebase'
import { getToken, getMessaging, isSupported } from 'firebase/messaging'
import { Notifications } from '../../redux/actions/notificationScreen'
import GoBackHeader from '../../components/header/go-back-header'
import AppLayout from '../../components/layouts/app'
import { Box, Container, List, ListItemButton, Typography, Button, ListItem, Switch, Avatar } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useTheme } from '@emotion/react'

function AccountSettings() {
  const navigate = useNavigate()
  const profileResponse = useSelector((state) => state.userProfile)
  const profileData = profileResponse?.userInfo?.result
  const LogoutAuth = useSelector((state) => state?.Auth?.Logout)
  const [isNotification, setNotification] = useState()
  const [show, setShow] = useState(false)
  const [currentToken, setCurrentToken] = useState(null)
  const dispatch = useDispatch()
  let messaging = null

  const hitLogout = () => {
    // console.log('l1')
    if (currentToken) {
      dispatch(logout2({
        id: profileData?.id,
        token: currentToken
      }))
    } else {
      dispatch(logout())
      // window.location = '/'
      navigate('/')
    }
  }
  useEffect(() => {
    if (LogoutAuth) {
      if (LogoutAuth === 'Fail') {
        setShow(true)
      } else {
        // window.location = '/'
        navigate('/')
      }
    }
  }, [LogoutAuth])

  useEffect(() => {
    if (profileData?.notificationSetting) {
      setNotification(profileData?.notificationSetting)
    }
  }, [profileData])

  useEffect(() => {
    dispatch(
      getUserProfile({
        skip: '0',
        limit: '10',
        userId: null
      })
    )
  }, [])
  const handleShow = () => setShow(true)
  const handleClose = () => {
    setShow(false)
    if (LogoutAuth === 'Fail') {
      dispatch({
        type: 'RESET_AUTH'
      })
    }
  }
  const handleNotification = async () => {
    if (isNotification) {
      setNotification(false)
    } else {
      setNotification(true)
      const isSupportedBrowser = await isSupported()
      isSupportedBrowser && firebaseGenerateToken()
    }
    handleShow()
    dispatch(updateNotification(
      {
        notificationSetting: !isNotification
      }
    ))
  }

  const firebaseGenerateToken = () => {
    messaging = getMessaging()
    getToken(messaging, { vapidKey: process.env.REACT_APP_WEB_PUSH_KEY }).then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        localStorage.setItem('deviceToken', currentToken)
        setCurrentToken(currentToken)
        dispatch(Notifications({
          token: currentToken
        }))
        return currentToken
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.')
        // ...

        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.')
          }
        })
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // ...
    })
  }

  // const handleBugRedirection = () => {
  //   window.open('https://motobookie.canny.io/', '_blank')
  // }
  const settings = [
    {
      icon: <NotificationsIcon fontSize='medium' />,
      title: 'Notifications',
      toggle: true,
      path: '/'
    },
    {
      icon: <SupportAgentIcon fontSize='medium' />,
      title: 'Contact Support',
      toggle: false,
      path: '/'
    }
  ]

  const theme = useTheme()

  const openMail = () => {
    window.location.href = 'mailto:support@motobookie.com?subject=Need Help';

  }
// console.log(profileData)
  return (
    <AppLayout>
      <GoBackHeader title='Settings' borderbottom />
      <Container sx={{
        height: '100%',
        pt: 2
      }}>

        <List>
          <Typography variant='h6'>Account</Typography>
          <ListItemButton
            onClick={() => navigate('/edit-profile')}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '8px',
              color: 'white'
            }}>
              <Avatar
                src={profileData?.userLogo || null}
                sx={{
                  height: '48px',
                  width: '48px'
                }} />
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start'
              }}>
                <Typography variant='h6' sx={{ mb: '-4px' }}>{profileData?.username}</Typography>
                <Typography variant='caption' sx={{
                  color: theme.palette.dark.otherlight
                }}>Profile Settings</Typography>
              </Box>
            </Box>
            <ArrowRightIcon fontSize='small' sx={{
              color: 'white'
            }} />
          </ListItemButton>
        </List>

        <List>
          <Typography variant='h6'>Settings</Typography>
          {
            settings.map((item, index) =>
              item.toggle ?
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '8px',
                    color: 'white'
                  }}>
                    {item.icon}
                    <Typography>{item.title}</Typography>
                  </Box>
                  <Switch
                    checked={isNotification}
                    onChange={handleNotification} />
                </ListItem>
                :
                <ListItemButton
                  key={index}
                  onClick={() => openMail()}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '8px',
                    color: 'white'
                  }}>
                    {item.icon}
                    <Typography>{item.title}</Typography>
                  </Box>
                  <ArrowRightIcon fontSize='small' sx={{
                    color: 'white'
                  }} />
                </ListItemButton>

            )
          }
        </List>


        <Button
          onClick={() => hitLogout()}
          variant='contained'
          sx={{
            mt: 2,
            width: '100%'
          }}>
          LOG OUT
        </Button>

      </Container>
    </AppLayout>
  )
}

export default AccountSettings
/*
<>
      <div className="fullScreen fullScreen-login login-solid justify-content-start screenWithoutFooter">
        <div className="pageTop forDrop" style={{ background: '#000812', padding: '50px 12px 12px 12px' }}>
          <div className="backLink">
            <Link to="/user-profile">
              <div className="backLink-icon noBg">
                <BiLeftArrowAlt />
              </div>
            </Link>
            <span className="mt-0" style={{ position: 'absolute', right: '10px' }}>Settings</span>
          </div>
        </div>
        {!profileResponse?.isLoading
          ? (
              <div className="loginWrap scrollable-notification">
                <div className="AccountSettings-cont">
                  <div className="AccountSettings-block">
                    <h2>Account Settings</h2>
                    <Link className="acsLink" to="/edit-profile">
                      <div className="acsLink-left">
                        <div className="acsLink-img">
                        <img
                            alt="profile"
                            src={profileData?.userLogo || avatar}
                          />
                        </div>
                        <p>
                          {profileData?.username} <br /> <span>Profile Settings</span>
                        </p>
                      </div>
                      <div className="acsLink-right">
                        <ImArrowRight2 />
                      </div>
                    </Link>
                  </div>
                  <div className="AccountSettings-block">
                    <h2>Account Settings</h2>
                    <div className="acsLink">
                      <div className="acsLink-left">
                        <div className="acsLink-icon">
                          <BsBellFill />
                        </div>
                        <p>Notifications</p>
                      </div>
                      <div className="acsLink-right">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isNotification}
                            onChange = {handleNotification}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <Link className="acsLink" to="/faq">
                      <div className="acsLink-left">
                        <div className="acsLink-icon">
                          <FaQuestion />
                        </div>
                        <p>{"FAQ's"}</p>
                      </div>
                      <div className="acsLink-right">
                        <ImArrowRight2 />
                      </div>
                    </Link>
                    { <Link className="acsLink" to="/send-feedback" }
                    { <Link className="acsLink" to="/#"

                    onClick={(e) => {
                      window.location.href = 'support@motobookie.com'
                      e.preventDefault()
                    }}
                  > }
                  <a href="mailto:support@motobookie.com?subject=Feedback!" className='acsLink'>
                      <div className="acsLink-left">
                        <div className="acsLink-icon">
                          <FaPaperPlane />
                        </div>
                        <p>Send Feedback</p>
                      </div>
                      <div className="acsLink-right">
                        <ImArrowRight2 />
                      </div>
                    </a>
                    { <div className="acsLink cursor-pointer" onClick={handleBugRedirection}> }
                    <a href="mailto:support@motobookie.com?subject=Report Bug!" className='acsLink'>
                      <div className="acsLink-left">
                        <div className="acsLink-icon">
                          <AiFillBug />
                        </div>
                        <p>Report a bug</p>
                      </div>
                      <div className="acsLink-right">
                        <ImArrowRight2 />
                      </div>
                    </a>
                    <div className="d-flex w-100 mt-3">
                      <button
                        // className="py-1 px-4 rounded-3 m-auto"
                        className='button my-2 w-100'style={{ background: 'var(--color-light-blue)' }}
                        onClick={hitLogout}
                      >
                        Log out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          : (
            <div className='errorDiv'>
              <Loader isloading={true}/>
            </div>
            )
        }
        <Modal show={show} onHide={handleClose} centered className='ps-0 border-none' backdrop="static" animation={false}>
          <Modal.Body style={{ backgroundColor: '#212529' }} className="rounded">
            {LogoutAuth === 'Fail'
              ? (
              <h5 className=' text-center subscriptionPopup'>Error Logging out! </h5>

                )
              : (
                  <h5 className={isNotification ? 'subscriptionPopup text-center' : ' text-center subscriptionPopup'}>
                    {isNotification ? 'Notification enabled.' : 'Notifications disabled.'}
                  </h5>
                )
            }
        <div className='text-center pt-3 ' style={{ backgroundColor: '#212529' }}>
            <Button className=" subscriptionBtn " onClick={handleClose}>Close</Button>
        </div>
        </Modal.Body>
      </Modal>
      </div>
    </>
*/