import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Container, Stack, Alert } from '@mui/material';
import WagerCreateHeader from '../wager/create/components/header';
import { LoadingButton } from '@mui/lab'
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux'
import { createPosts, resetPostError } from '../../redux/actions/Posts';
import { resetWager } from '../../redux/actions/wagers';
import { closeModal } from '../../redux/actions/modal';
import { useNavigate, useLocation } from 'react-router-dom';

const CreatePost = () => {

    const location = useLocation()

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const theme = useTheme()

    const toggleMain = useSelector((state) => state.Modals.toggle);

    const postCreation = useSelector((state) => state.CreatePost);

    const [stage, setStage] = useState(0)

    const [postInput, setPostValue] = useState('')

    const [apiError, setApiError] = useState('')

    const handlePostCaption = (event) => {
        if (!!postCreation?.error) {
            setApiError('')
            dispatch(resetPostError())
        }
        setPostValue(event.target.value)
    }

    const cancelPost = () => {
        dispatch(resetWager())
        dispatch(closeModal())
        dispatch(resetPostError())
    }

    const submitPost = () => {
        dispatch(
            createPosts({
                description: postInput
            })
        )
    }

    useEffect(() => {
        if (!!postCreation?.error) {
            setApiError(postCreation?.error)
        }
        else if (postCreation?.myPosts?.message === 'success') {
            console.log('SUCCESS')
            //cancelPost()
            setStage(1)
        }
    }, [postCreation]);

    useEffect(() => {
        if (!toggleMain) {
            dispatch(resetPostError())
            dispatch(resetWager())
        }
    }, [toggleMain]);

    console.log(postInput)
    console.log(postCreation)
    console.log(apiError)
    switch (stage) {
        case 0:
            return (
                <Container>
                    <Stack sx={{ width: '100%' }} spacing={1}>
                        <WagerCreateHeader
                            data={{
                                title: 'Create a Post',
                                subtitle: 'POST'
                            }} />
                        <Box sx={{ width: '100%' }}>
                            <textarea
                                onChange={(e) => handlePostCaption(e)}
                                id='wager-caption'
                                value={postInput}
                                placeholder='Got something on your mind?'
                                name='post-caption'
                                style={{ resize: 'none', width: '100%', fontSize: '16px' }} // Prevent resizing and set width
                            />

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>{postInput.length}/160 Characters</Typography>
                            </Box>
                        </Box>

                        {
                            apiError != '' &&

                            <Alert severity='error'>
                                ERROR ERROR
                            </Alert>

                        }

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Button
                                onClick={() => cancelPost()}
                                variant='text'
                                disabled={postCreation.isLoading}>
                                Cancel
                            </Button>
                            <LoadingButton
                                loading={postCreation.isLoading}
                                onClick={() => submitPost()}
                                variant='contained'
                                disabled={apiError != '' || postInput === ''}>
                                Post
                            </LoadingButton>
                        </Box>

                    </Stack>
                </Container>
            )
            break;
        case 1:
            return (
                <Container sx={{ pb: 2 }}>
                    <Stack spacing={1}>
                        {
                            location.pathname != '/social'
                            &&
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    navigate('/social?posts')
                                    cancelPost()
                                }}>
                                Go to Post Wall
                            </Button>
                        }
                        <Button
                            variant="contained"
                            onClick={() => cancelPost()}>
                            Continue Scrolling
                        </Button>
                    </Stack>
                </Container>
            )
        default:
            break;
    }
}

export default CreatePost
