const initialState = {}
// Use the initialState as a default value
export default function WagerDetails(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case 'WAGER_DETAILS_REQUEST': {
      return {
        ...state,
        isLoading: true,
        Status: ''

      }
    }

    case 'WAGER_DETAILS': {
      return {
        ...state,
        isLoading: false,
        Status: 'Success',
        wager: action.payload
      }
    }

    case 'WAGER_DETAILS_ERROR': {
      return {
        ...state,
        isLoading: false,
        Status: 'Fail',
        error: action.payload
      }
    }

    case 'ACCEPT_WAGGER': {
      return {
        ...state,
        isLoading: false,
        status: 'success',
        data: action.payload
      }
    }

    case 'RESET_ACCEPT_WAGER': {
      return {
        ...state,
        isLoading: false,
        status: 'success',
        data: {},
        wager: {}
      }
    }
    
    case 'ACCEPT_WAGEER_ERROR': {
      return {
        ...state,
        isLoading: false,
        status: 'Fail',
        notAccepted: action.payload
      }
    }

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}
