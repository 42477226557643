import { CREATE_WAGER, LIST_WAGERS_REQUEST, ERROR_OCCURED, RESET_WAGER, LIST_WAGERS, GET_WAGER_CONFIG, DEACTIVATE_WAGER, REQUEST_DEACTIVATE_WAGER, GET_WAGER_CONFIG_ERROR, RESET_DEACTIVATE_WAGER, CREATE_WAGER_ERROR } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import WagersServices from '../../../services/wagers'
import { useSocket } from '../../../components/hooks/useSocket'


export const createWager = createAsyncThunk(
  '/wagers/add',
  async ({ formData, matchId }, thunkAPI) => {
    try {
      thunkAPI.dispatch({
        type: 'LIST_WAGERS_REQUEST'
      })
      if (parseInt(formData.wagerTypeId) === 1) {
        const data = await WagersServices.createWagersOneOnONe(formData)
        thunkAPI.dispatch({
          type: CREATE_WAGER,
          payload: data && data?.data
        })
        if (formData.type === 'public') {
          thunkAPI.dispatch({
            type: "UPDATE_FEED_W_CREATED_WAGER",
            payload: data && data?.data
          })
        }
        return { user: data && data?.data }
      }
      else if (parseInt(formData.wagerTypeId) === 2) {
        const data = await WagersServices.createWagersPlacement(formData)
        thunkAPI.dispatch({
          type: CREATE_WAGER,
          payload: data && data?.data
        })

        if (formData.type === 'public') {
          thunkAPI.dispatch({
            type: "UPDATE_FEED_W_CREATED_WAGER",
            payload: data && data?.data
          })
        }
        return { user: data && data?.data }
      }
      else if (parseInt(formData.wagerTypeId) === 3) {
        const data = await WagersServices.createWagersSpread(formData)
        thunkAPI.dispatch({
          type: CREATE_WAGER,
          payload: data && data?.data
        })

        if (formData.type === 'public') {
          thunkAPI.dispatch({
            type: "UPDATE_FEED_W_CREATED_WAGER",
            payload: data && data?.data
          })
        }
        return { user: data && data?.data }
      }
      else if (parseInt(formData.wagerTypeId) === 4) {
        const data = await WagersServices.createWagersHoleShot(formData)
        thunkAPI.dispatch({
          type: CREATE_WAGER,
          payload: data && data?.data
        })
        if (formData.type === 'public') {
          thunkAPI.dispatch({
            type: "UPDATE_FEED_W_CREATED_WAGER",
            payload: data && data?.data
          })
        }
        return { user: data && data?.data }
      }
      else if (parseInt(formData.wagerTypeId) === 5) {
        const data = await WagersServices.createWagersPodium(formData)
        thunkAPI.dispatch({
          type: CREATE_WAGER,
          payload: data && data?.data
        })
        if (formData.type === 'public') {
          thunkAPI.dispatch({
            type: "UPDATE_FEED_W_CREATED_WAGER",
            payload: data && data?.data
          })
        }
        return { user: data && data?.data }
      }
      else if (parseInt(formData.wagerTypeId) === 6) {
        const data = await WagersServices.createWagersTeamVsTeam(formData)
        thunkAPI.dispatch({
          type: CREATE_WAGER,
          payload: data && data?.data
        })
        if (formData.type === 'public') {
          thunkAPI.dispatch({
            type: "UPDATE_FEED_W_CREATED_WAGER",
            payload: data && ({ ...data?.data, matchId })
          })
        }
        return { user: data && data?.data }
      }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response.data.status && error.response.data.errors[0]) || (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: CREATE_WAGER_ERROR,
        payload: message
      })
      switch (formData.mode) {
        case "fantasy":
          thunkAPI.dispatch({
            type: "SET_WAGER_MODE_FANTASY"
          })
          break;
        case "real":
          thunkAPI.dispatch({
            type: "SET_WAGER_MODE_REAL"
          })
          break;
        default:
          break;
      }
      return thunkAPI.rejectWithValue()
    }
  }
)

export const listWaggers = createAsyncThunk(
  '/privateers/list',
  async ({ skip, limit, filter, sortBy, sort }, thunkAPI) => {
    try {
      thunkAPI.dispatch({
        type: 'SET_WAGER_LOADING_FEED'
      })
      const data = await WagersServices.getWagers(skip, limit, filter, sortBy, sort)
      /*
      thunkAPI.dispatch({
        type: LIST_WAGERS,
        payload: data && data?.data
      })
      */
      //IN LEAGUES
      thunkAPI.dispatch({
        type: "SET_WAGER_FEED",
        payload: data && data?.data,
        leagueId: filter.sportsId,
        eventId: filter.eventId
      })
      return { user: data && data?.data }
    } catch (error) {
      console.log(error, 'errrrrr')
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const getWagerConfig = createAsyncThunk(
  '/option/get/wager-config',
  async ({ skip, limit }, thunkAPI) => {
    try {
      const data = await WagersServices.getWagerConfig(skip, limit)
      thunkAPI.dispatch({
        type: GET_WAGER_CONFIG,
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: GET_WAGER_CONFIG_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const deactivateWager = createAsyncThunk(
  '/wagers/delete/',
  async ({ id }, thunkAPI) => {
    thunkAPI.dispatch({
      type: REQUEST_DEACTIVATE_WAGER
    })
    try {
      const data = await WagersServices.deactivateWager(id)
      thunkAPI.dispatch({
        type: DEACTIVATE_WAGER,
        payload: data && data?.data
      })
      thunkAPI.dispatch({
        type: 'WAGER_DELETED_UPDATE',
        payload: data && data?.data
      })
      return { user: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: ERROR_OCCURED,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)

export const resetWager = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_WAGER
    })
  }
}

export const resetDeactivateWager = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_DEACTIVATE_WAGER
    })
  }
}

export const incrementCommentCount = (id) => {
  return (dispatch) => {
    dispatch({
      type: "INCREMENT_COMMENT_COUNT",
      payload: { id: Number(id) }
    })
  }
}

export const setCreationWagerModeToReal = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_WAGER_MODE_REAL"
    })
  }
}

export const setCreationWagerModeToFantasy = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_WAGER_MODE_FANTASY"
    })
  }
}

export const setCreationModeToPost = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_WAGER_MODE_POST"
    })
  }
}


export const commentOnWagerNotifcation = createAsyncThunk(
  '/wagers/comment',
  async (wagerId, thunkAPI) => {
    try {
      const data = await WagersServices.commentWagerNotification(wagerId)
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response.data.status && error.response.data.errors[0]) || (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue()
    }
  }
)
