const initialState = {
  counter: []
}
export default function Counter (state = initialState, action) {
  switch (action.type) {
    case 'SETCOUNTER': {
      return {
        ...state,
        counter: action.payload
      }
    }
    case 'REMOVECOVER': {
      return {
        ...state,
        counter: action.payload
      }
    }
    default:
      return state
  }
}
