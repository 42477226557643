import eventService from "../../../services/eventId";
import { SET_CALENDAR_DATE, SET_CURRENT_LEAGUE } from "../../types";

export const setCurrentLeague = (index) => {
  return async (dispatch) => {
    // Start the fetch operation
    try {
      // After successful fetch, dispatch the action
      dispatch({
        type: SET_CURRENT_LEAGUE,
        payload: {
          index: index,
        }
      });

    } catch (error) {
      console.error(error);
    }
  };
};


export const setCreateWagerCurrentLeague = (index) => {
  return async (dispatch) => {
    // Start the fetch operation
    try {

      dispatch({
        type: 'SET_CREATE_WAGER_CURRENT_LEAGUE',
        payload: {
          index: index,
        }
      });

    } catch (error) {
      console.error(error);
    }
  };
};

export const getLeagues = () => {
  return async (dispatch) => {
    // Start the fetch operation
    try {
      // After successful fetch, dispatch the action
      const data = await eventService.getEventId()
      dispatch({
        type: "SET_LEAGUES",
        payload: data && data?.data
      })

    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      dispatch({
        type: "ERROR_OCCURED",
        payload: message
      })
    }
  };
};

export const setCalendarDate = (date, index) => {
  return {
    type: SET_CALENDAR_DATE,
    payload: {
      date: date,
      index: index
    }
  }
}

export const setEventCalendarDates = (dates) => {
  return {
    type: "SET_DATES",
    payload: dates
  }
}

export const setAdEventWagerToAccepted = (acceptedWager) => {
  return {
    type: "SET_AD_EVENT_WAGER_TO_ACCEPTED",
    payload: acceptedWager?.result
  }
}