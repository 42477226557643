import React from 'react'
import { IconButton, Box, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@emotion/react';


const TitleHeader = ({ title, borderbottom }) => {

    const theme = useTheme()
    return (

        <Box
            sx={{
                padding: '12px',
                borderBottom: !!borderbottom ? `solid .5px ${theme.palette.secondary.light}` : ''
            }}>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >

                <Typography
                    variant='h6'
                    sx={{
                        fontWeight: 700,
                        flexGrow: 1,
                        textAlign: 'center'
                    }}>
                    {title}
                </Typography>

            </Box>

        </Box>
    )
}

export default TitleHeader