
import React, { useEffect } from 'react'
import { logout } from '../../redux/actions/auth'
import { useDispatch } from 'react-redux'

function DeleteAccountConfirmation () {
  const dispatch = useDispatch()
  const hitLogout = () => {
    dispatch(logout())
    window.location = '/'
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        hitLogout()
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])
  return (
        <>
          <div className="fullScreen fullScreen-login login-solid">
             <div className="loginWrap scrollable-notification">
                <div className="editPro text-center">
                    <div className="successCircle">
                    </div>
                    <div className="successTxt pt-4">
                        <h2>Deactivation Successful!</h2>
                    </div>
                    <div className="text-center pt-4">
                          <button className="button button-success" onClick = {hitLogout}>Continue</button>
                    </div>
                </div>
             </div>

          </div>
        </>
  )
}

export default DeleteAccountConfirmation
