import axios from './axios'
import authHeader from './auth-header'

const requestDeletion = (reasonForLeaving) => {
  return axios.post('profile/send-deletion-code',
    {
      reasonForLeaving
    },
    {
      headers: authHeader()
    }
  )
}

const deleteAccount = (accountDeletionCode, password) => {
  return axios.post('profile/delete-account',
    {
      accountDeletionCode,
      password
    },
    {
      headers: authHeader()
    }
  )
}

const deleteService = {
  requestDeletion,
  deleteAccount
}

export default deleteService
