import React, { useState, useRef, useEffect } from 'react'
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listMyWaggers, myWagersHistory } from '../../redux/actions/myWager'
import { isWagerClose } from '../../helper'
import Loader from '../../components/loader'
import { useSocket } from '../../components/hooks/useSocket'
import SportServices from '../../services/sport'
import AppLayout from '../../components/layouts/app'
import { useTheme } from '@emotion/react'
import { styled } from '@mui/system';
import { List, ListItemButton, Divider, Box, Stack, Typography, Chip, Tabs, Tab, Alert, Container, SwipeableDrawer, Button, IconButton, } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TitleHeader from "../../components/header/title-header"
import WagerTeamItem from '../../components/wager/team-item'
import PropTypes from 'prop-types';
import WagerDetailsNew from '../wagerboard/wagerDetailsNew'

import LockIcon from '@mui/icons-material/Lock';
import { LoadingButton } from '@mui/lab'
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <>
      {value === index && (
        <Box
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
          sx={props.sx}>
          {children}
        </Box>
      )}
    </>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const MySwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, .6)'
  },
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 40,
  height: 4,
  backgroundColor: theme.palette.dark.otherlight,
  borderRadius: 3,
  top: 8,
  left: 'calc(50% - 15px)',
  zIndex: '999px'
}));


const WagerRefundedTypography = ({ item, lastChipTransaction }) => {
  const theme = useTheme();

  // Ensure lastChipTransaction is treated as an array
  const transactionsArray = Array.isArray(lastChipTransaction) ? lastChipTransaction : [lastChipTransaction];

  // Calculate refunded amount
  let amountLosingsWinnings = 0;
  let amountReversals = 0;

  transactionsArray.forEach(transaction => {
    if (transaction?.transactionType === "wager-losings" || transaction?.transactionType === "wager-winnings") {
      amountLosingsWinnings += transaction.amount;
    }
    // else if (transaction?.transactionType === "wager-reversals") {
    //   amountReversals += transaction?.amount;
    // }

    else if (transaction?.transactionType === "wager-reversals") {
      amountReversals += transaction?.amount;
    }
  });

  const finalAmount = amountLosingsWinnings - amountReversals;

  // Render only if lastChipTransaction exists
  if (!lastChipTransaction) {
    return null;
  }

  return (
    <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
      Refunded: <b>{item.result === 'loser' ? amountReversals : amountLosingsWinnings - item.chipsBet} chips</b>
    </Typography>
  );
};


export const MyWagerItem = ({ item }) => {
  const user = localStorage.getItem('user')

  const theme = useTheme()

  const [toggle, setToggle] = useState(false)

  const openDrawer = () => {
    setToggle(true)
  }

  const closeDrawer = () => {
    setToggle(false)
  }


  const ordinalSuffix = (number) => {
    let j = number % 10,
      k = number % 100;
    if (j == 1 && k != 11) {
      return 'st';
    }
    if (j == 2 && k != 12) {
      return 'nd';
    }
    if (j == 3 && k != 13) {
      return 'rd';
    }
    return 'th';
  }

  const WinnerSwitcher = (wagerTypeId, data) => {
    switch (wagerTypeId) {
      case 1:
        //1v1
        return <><span style={{ color: theme.palette.success.main, fontWeight: 700 }}>Winner</span></>
        break;

      case 2:
        //1v1
        if (data.placement === data.placement_range) {
          return <>Places {data.placement}{ordinalSuffix(data.placement)}</>

        }
        else {
          //<>Places between <span style={{ color: theme.palette.success.main }}>{data.placement}{ordinalSuffix(data.placement)}</span> and <span style={{ color: theme.palette.success.main }}>{data.placement_range}{ordinalSuffix(data.placement_range)}</span ></>
          return <>Places between <br />{data.placement}{ordinalSuffix(data.placement)} & {data.placement_range}{ordinalSuffix(data.placement_range)}</>
        }
        break;

      case 3:
        //1v1 SPREAD
        return <><span style={{ color: theme.palette.success.main, fontWeight: 700 }}>Winner</span> by {data.spreadOptions_data.option} spots or more</>
        break;

      case 4:
        //holeshot
        return <><span style={{ color: theme.palette.success.main, fontWeight: 700 }}>Wins</span> the holeshot</>
        break;

      case 5:
        //Podium <>Places <span style={{ color: theme.palette.success.main }}>Podium</span></>
        return <>Places Podium</>

      case 6:
        return <span style={{ color: theme.palette.success.main, fontWeight: 700 }}>Winner</span>
        break;
      default:
        break;
    }
  }
  const LoserSwitcher = (wagerTypeId, data) => {
    switch (wagerTypeId) {
      case 1:
        //1v1
        return <><span style={{ color: theme.palette.error.main, fontWeight: 700 }}>Loser</span></>
        break;

      case 2:
        //1v1
        return "Loser"
        break;

      case 3:
        //1v1 SPREAD
        return `Loser`
        break;

      case 6:
        return <><span style={{ color: theme.palette.error.main, fontWeight: 700 }}>Loser</span></>
        break;
      default:
        break;
    }
  }
  return (
    <>
      <MySwipeableDrawer
        anchor={'bottom'}
        open={toggle}
        onClose={() => {
          closeDrawer()
        }}
        onOpen={() => openDrawer()}
        PaperProps={{
          style: {
            height: '80vh',
            backgroundColor: 'black',
            boxShadow: 'none',
            borderTop: 0,
            borderRadius: '8px 8px 0 0',
            borderBottom: '0 !important',
            zIndex: '999999999999',
            borderTop: `3px solid ${theme.palette.dark.otherdark}`
          },
        }}
      >
        <Puller />
        <Stack
          alignItems={"center"}
          sx={{
            //padding: '32px 24px 24px 24px',
            minHeight: '100%',
            width: '100%',
            backgroundColor: theme.palette.dark.dark,
            position: 'relative'
          }}>
          <Container sx={{
            pt: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}>
            <IconButton onClick={() => closeDrawer()}>
              <ClearIcon />
            </IconButton>
          </Container>
          <Box sx={{
            pb: 8
          }}>
            <Box sx={{
              bgcolor: 'black',
              width: '100%',
              height: 'fit-content',
              position: 'absolute',
              left: 0,
              pt: 2,
              top: 0
            }}>
              {
                toggle &&
                <WagerDetailsNew
                  closeFunction={() => closeDrawer()}
                  id={JSON.stringify(item?.id ?? "undefined")} />
              }
            </Box>
          </Box>
          <Container sx={{
            position: 'fixed',
            bgcolor: theme.palette.dark.dark,
            bottom: 0,
            pt: 1,
            pb: 4,
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Button onClick={() => closeDrawer()} fullWidth variant='outlined'>Back to wagers</Button>
          </Container>
        </Stack>

      </MySwipeableDrawer >

      <ListItemButton
        disabled={item?.result === "refund"}
        sx={{ pt: 4, pb: 4, }}
        onClick={() => {
          if (item?.result != "refund") {
            openDrawer()
            //navigate(`/wager-details-new/${item?.id}`)
          }
        }}
      >
        <Stack spacing={3} sx={{
          width: '100%'
        }}>

          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            spacing={1.5}
            sx={{
              width: '100%'
            }}>
            <Stack direction='row' spacing={1}>
              <Box sx={{
                width: 'fit-content',
                background: theme.palette.primary.main,
                padding: '.5px 8px',
                borderRadius: '2px'
              }}>
                <Typography variant='caption' sx={{}}>{item?.event_data?.sports_data?.name}</Typography>
              </Box>
              <Box sx={{
                width: 'fit-content',
                background: theme.palette.dark.otherlight,
                padding: '.5px 8px',
                borderRadius: '2px'
              }}>
                <Typography variant='caption' sx={{}}>{item?.mode === 'real' ? "Real Money" : "Fantasy"}</Typography>
              </Box>
              {
                !!item?.division_data &&
                <Box sx={{
                  width: 'fit-content',
                  background: theme.palette.dark.otherlight,
                  padding: '.5px 8px',
                  borderRadius: '2px'
                }}>
                  <Typography variant='caption' sx={{}}>{item?.division_data?.name}</Typography>
                </Box>
              }
            </Stack>

            <Box sx={{
              width: 'fit-content',
              background: item?.createdBy === JSON.parse(user)?.id ? theme.palette.primary.main : theme.palette.dark.otherdark,

              padding: '.5px 8px',
              borderRadius: '2px'
            }}>
              <Typography variant='caption' sx={{}}>{item?.createdBy === JSON.parse(user)?.id ? "Wager Created" : "Wager Joined"}</Typography>
            </Box>
          </Stack>

          <Box sx={{
            display: 'flex',
            alignItems: item?.wagerType_data?.id === 4 || item.wagerType_data?.id === 5 || item.wagerType_data?.id === 2 ? 'center' : 'center',
            justifyContent: 'space-between'
          }}>

            <Stack
              spacing={.5}>

              <WagerTeamItem data={{
                racer: item?.event_data?.sports_data?.type === "match" ? item?.teamsFor_data : item?.racerFor_data,
                prediction: WinnerSwitcher(item?.wagerType_data?.id, item)
              }} />

              {
                item?.wagerType_data?.id === 4 || item?.wagerType_data?.id === 5 || item?.wagerType_data?.id === 2 ?
                  null :
                  <WagerTeamItem data={{
                    racer: item?.event_data?.sports_data?.type === "match" ? item?.teamsAgainst_data : item?.racerAgainst_data,
                    prediction: LoserSwitcher(item?.wagerType_data?.id, item)
                  }} />
              }

              <Typography
                variant='caption'
                sx={{ color: theme.palette.dark.otherlight }}>
                {item?.event_data?.name}
              </Typography>
            </Stack>

            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px'
            }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                textAlign: 'right',
                color: theme.palette.dark.otherlight
              }}>

                <Typography
                  variant='caption'
                  sx={{ color: theme.palette.dark.otherlight }}>
                  Creator: @{item?.createdBy_data?.username}
                </Typography>

                <Typography
                  variant='caption'
                  sx={{ color: theme.palette.dark.otherlight }}>
                  Accepted by: {item?.wagerusers?.length > 0 && item?.wagerusers[0]?.status === 'accept'
                    ? item?.wagerusers?.length > 1
                      ? item?.wagerusers?.length + ' opponents'
                      : '@' + item?.wagerusers[0]?.user_data?.username
                    : 'No one'}
                </Typography>

                <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                  Status:
                  {
                    item?.result === 'winner'
                      ? JSON.parse(user).id === item?.createdBy
                        ? <strong style={{ fontWeight: 700, color: theme.palette.success.main }}> Won</strong>
                        : <strong style={{ fontWeight: 700, color: theme.palette.error.main }}> Lost</strong>
                      : item?.result === 'loser'
                        ? JSON.parse(user).id === item?.createdBy
                          ? <strong style={{ fontWeight: 700, color: theme.palette.error.main }}> Lost</strong>
                          : <strong style={{ fontWeight: 700, color: theme.palette.success.main }}> Won</strong>
                        : item?.result === 'refund'
                          ? <strong style={{ fontWeight: 700, color: theme.palette.dark.otherlight }}> Refunded</strong>
                          : item?.result === 'pending'
                            ? <strong style={{ fontWeight: 700, color: theme.palette.dark.otherlight }}> Pending </strong>
                            : <span>{item?.result}</span>
                  }
                </Typography>


                {
                  //item?.result === 'winner' && item?.result !== 'pending' &&
                  //<Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>Refunded: {item?.lastChipTransaction?.amount} chips</Typography>
                }

                {item?.result === 'loser' &&
                  <> {JSON.parse(user).id === item?.createdBy
                    ?
                    <>

                      <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                        Losses: <b>{item?.chipsBet * item?.peopleJoined * item?.multiplier_data?.multiplier} chips</b>
                      </Typography>

                      <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                        You spent:  <b>{item?.chipsBet * item?.multiplier_data.multiplier * item?.maxPeople} chips</b>
                      </Typography>
                      {
                        /*
<Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                        For others to join: <b>{item?.chipsBet} chips</b>
                      </Typography>
                        */
                      }

                      {
                        item?.maxPeople - item?.peopleJoined !== 0 &&
                        < WagerRefundedTypography
                          item={item}
                          lastChipTransaction={item?.lastChipTransaction}
                        />
                      }
                    </>
                    :
                    <>
                      <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                        Winnings: <b>{item?.chipsBet?.toLocaleString()} chips</b>
                      </Typography>

                      <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                        You spent:  <b>{item?.chipsBet?.toLocaleString()} chips</b>
                      </Typography>
                    </>
                  }
                  </>
                }

                {item?.result === 'winner' &&
                  <>
                    {JSON.parse(user).id === item?.createdBy
                      ?
                      <>
                        <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                          Winnngs: <b>{(item?.chipsBet * item?.peopleJoined)?.toLocaleString()} chips</b>
                        </Typography>
                        {
                          /*
<Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                          Others to join:  <b>{(item?.chipsBet * item?.maxPeople * item?.multiplier_data?.multiplier)?.toLocaleString()} chips</b>
                        </Typography>
                          */
                        }
                        {
                          item?.maxPeople - item?.peopleJoined !== 0 &&
                          < WagerRefundedTypography
                            item={item}
                            lastChipTransaction={item?.lastChipTransaction}
                          />
                        }
                      </>
                      :
                      <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                        Losses: <b>{item?.chipsBet?.toLocaleString()} chips</b>
                      </Typography>
                    }
                  </>
                }

                {
                  item?.result === 'refund' &&
                  <>
                    {
                      /*
                    <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                      Initial Wager:  <b>{item?.lastChipTransaction?.amount?.toLocaleString()} chips</b>
                    </Typography>
                      */
                    }
                    <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                      Refunded amount:  <b>{item?.lastChipTransaction[0]?.amount?.toLocaleString()} chips</b>
                    </Typography>
                  </>
                }

                {
                  item?.result === 'pending' &&
                  <>
                    <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                      To join:  <b>{item?.chipsBet?.toLocaleString()} chips</b>
                    </Typography>
                  </>
                }

                {
                  /*
   <Typography
                    variant='caption'
                    sx={{ color: theme.palette.dark.otherlight }}>
                    Multiplier: {item?.multiplier_data?.name}
                  </Typography>
                  */
                }

                {
                  JSON.parse(user).id === item?.createdBy &&
                  <Typography
                    variant='caption'
                    sx={{ color: theme.palette.dark.otherlight }}>
                    Max People: {item?.maxPeople}
                  </Typography>
                }

                {
                  item?.createdBy === JSON.parse(user).id && item?.result === 'pending' &&
                  <>
                    <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>
                      You spent:  <b>{item?.chipsBet * item?.multiplier_data.multiplier * item?.maxPeople} chips</b>
                    </Typography>
                  </>
                }

                {
                  // (item?.wagerStatus.h > 0 || item?.wagerStatus.m > 0) &&
                  // item?.CounterCount !== 0 && item?.createdBy === JSON.parse(user).id &&
                  // <li className='mt-1'><div style={{ background: '#223F5D', padding: '3px 10px', fontWeight: 700, fontSize: '10px', color: '#fff', display: 'flex', alignItems: 'center' }}><div style={{ width: '8px', height: '8px', position: 'relative', background: '#98FF8F', borderRadius: 672, marginRight: '5px' }} /><span>Counters: {item?.CounterCount}</span></div></li>
                }

              </Box>

              <KeyboardArrowRightIcon
                sx={{
                  color: 'white',
                  opacity: item?.result != "refund" ? 1 : .4
                }} />

            </Box>
          </Box>

          {
            // item?.status === 'closed' && item?.result === 'pending' ?
            //   < Alert severity="error" iconMapping={{
            //     error: <LockIcon fontSize="inherit" />,
            //   }}>
            //     This wager is closed and pending results.
            //   </Alert> : null
          }
        </Stack>
      </ListItemButton >
    </>
  )
}

function MyWagers() {
  const location = useLocation();

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const socket = useSocket()
  const searchParams = new URLSearchParams(location.search);
  const queryValue = searchParams.get('key');
  const ref = useRef(null)
  const ref2 = useRef(null)

  const [show, setShow] = useState(false)


  const [filterBy, setFilterBy] = useState({
    mywagers: 'all',
    mode: 'fantasy'
  })
  const [wagerLimit, setWagerLimit] = useState(10)
  const [counter, setCounter] = useState(0)
  const Sport = useSelector((state) => state.Sport.sport)
  // const [counterCount, setCounterCount] = useState(null)
  const CounterVal = useSelector((state) => state?.Counter?.counter)
  const [sport, setSport] = useState([])
  const [selectedSport, setSelectedSport] = useState('All')
  const [sportId, setSportId] = useState()
  // console.log(selectedSport, 'selectedSport')
  // console.log(sportId, 'spid')
  const [loader, setLoader] = useState(false)
  const wagerResponseData = useSelector((state) => state.myWager)

  const [value, setValue] = React.useState(1);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    socket.on('newCounter', (arg) => {
      if (arg?.wagerCreatedBy === JSON.parse(user).id) {
        if (arg?.CounterCount > 0) {
          setTimeout(() => {
            dispatch(
              listMyWaggers({
                skip: 0,
                limit: wagerLimit,
                filter: filterBy
              })
            )
          }, 300000)
          let temp = []
          // setCounterCount(arg?.CounterCount)
          if (CounterVal?.length === 0) {
            temp = [arg?.wagerId]
          } else {
            const temp2 = CounterVal?.filter((val) => val === arg.wagerId)
            if (temp2.length === 0) {
              temp = [...CounterVal, arg.wagerId]
            } else {
              temp = CounterVal
            }
            // temp = CounterVal?.filter((val) => val !== arg.wagerId)
            // temp = [...CounterVal, CounterVal?.filter((val) => val !== arg.wagerId)]
          }
          dispatch({
            type: 'SETCOUNTER',
            payload: temp
          })
          dispatch(
            listMyWaggers({
              skip: 0,
              limit: wagerLimit,
              filter: filterBy
            })
          )
        }
      }
    })
  }, [socket])

  useEffect(() => {
    SportServices.listSport().then((response) => {
      setSport(response.data.result)
      setSelectedSport('All')
      setSportId(null)
    }).catch((error) => {
      console.log(error, 'Error')
    })
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && ref2.current && !ref2.current.contains(event.target)) {
        // onClickOutside && onClickOutside();
        setShow(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])


  useEffect(() => {
    if (Object.keys(filterBy).length !== 0) {
      if (filterBy?.mywagers === 'wagerHistory') {
        dispatch(myWagersHistory({
          skip: 0,
          limit: wagerLimit
        }))
      } else {
        dispatch(
          listMyWaggers({
            skip: 0,
            limit: wagerLimit,
            filter: filterBy
          })
        )
      }
    } else {
      dispatch(
        listMyWaggers({
          skip: 0,
          limit: wagerLimit,
          filter: filterBy
        })
      )
    }

  }, [filterBy, wagerLimit])

  const fetchMoreData = () => {
    setLoader(true)
    setCounter(counter + 1)

    setTimeout(() => {
      if (wagerLimit < wagerResponseData?.myWager?.count) {
        setWagerLimit(wagerLimit + 10)
        setLoader(false)
      }
    }, 1500)

  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const list = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  const theme = useTheme()

  useEffect(() => {
    if (queryValue === 'fantasy') {
      setValue(1)
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AppLayout>
      <Box
        sx={{
          height: 'inherit',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <TitleHeader title='My Wagers' />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

          <Tabs
            sx={{ width: '100%' }} value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Real Money" {...a11yProps(0)} sx={{ width: '50%', fontWeight: 700 }} />
            <Tab label="Fantasy" {...a11yProps(1)} sx={{ width: '50%', fontWeight: 700 }} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>

          <List >
            <Container sx={{ mt: 2 }}>
              <Alert severity="warning">Real Money Wagering coming soon!</Alert>
            </Container>
          </List>

        </CustomTabPanel>

        <CustomTabPanel
          value={value}
          index={1}
          sx={{
            flexGrow: 1,
            overflow: 'scroll',
            pb: 14
          }}>
          <List sx={{
            pb: 1
          }} >
            {
              wagerResponseData?.isLoading && counter === 0 ?
                <Loader isloading={true} />
                : wagerResponseData?.myWager?.result.length > 0
                  ?
                  (
                    <>
                      {
                        wagerResponseData?.myWager?.result.map((item, index) => {
                          const wagerStatus = isWagerClose(item?.event_data?.startTime)
                          return (
                            <div key={index}>
                              <MyWagerItem item={{
                                ...item,
                                wagerStatus: wagerStatus
                              }} />
                              {
                                index !== wagerResponseData?.myWager?.result?.length - 1 ? <Divider sx={{ bgcolor: theme.palette.dark.otherdark }} key={index} /> : null
                              }
                            </div>

                          )
                        }
                        )
                      }
                      {
                        wagerLimit < wagerResponseData?.myWager?.count &&
                        <Container >
                          <LoadingButton
                            loading={wagerResponseData?.isLoading || loader}
                            onClick={() => fetchMoreData()}
                            variant='outlined'
                            fullWidth >
                            Load More
                          </LoadingButton>
                        </Container>
                      }

                    </>
                  )
                  : (
                    wagerResponseData?.wager?.result?.length === 0 && wagerResponseData?.wager?.message
                      ?
                      <Container sx={{
                        mt: 2
                      }}>
                        <Alert severity='warning'>{wagerResponseData?.wager?.message}</Alert>
                      </Container>
                      :
                      <Container sx={{
                        mt: 2
                      }}>
                        <Alert severity='success'>Create your first wager!</Alert>
                      </Container>
                  )
            }
          </List>
        </CustomTabPanel>
      </Box>
    </AppLayout >
  )
}

export default MyWagers