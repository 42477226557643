import { Button, Stack, SwipeableDrawer } from '@mui/material'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import theme from '../../styles/theme';
import { closeModal, openModal } from '../../redux/actions/modal';

const MySwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
    '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, .6)'
    },
    borderTop: `3px solid ${theme.palette.secondary.main}`,

}));

const Puller = styled(Box)(({ theme }) => ({
    width: 40,
    height: 4,
    backgroundColor: theme.palette.dark.otherlight,
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const Drawer = ({ contentKey, children }) => {

    const dispatch = useDispatch()

    const toggle = useSelector((state) => state.Modals.toggle);

    return (
        <MySwipeableDrawer
            key={contentKey}
            anchor={'bottom'}
            open={toggle}
            onClose={() => dispatch(closeModal())}
            onOpen={() => dispatch(openModal())}
            PaperProps={{
                style: {
                    maxHeight: '80vh',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    borderTop: 0,
                    borderRadius: '8px 8px 0 0',
                    borderTop: `solid 1px ${theme.palette.dark.main}`,
                    borderBottom: '0 !important',
                    zIndex: '999999999999'
                },
            }}
        >

            <Puller />

            <Stack
                alignItems={"center"}
                sx={{
                    paddingTop: '32px',
                    paddingBottom: '24px',
                    //padding: '32px 24px 24px 24px',
                    backgroundColor: theme.palette.dark.dark// or any other color you want
                }}>
                <Box sx={{ width: '100%', maxWidth: '450px' }}>
                    {children}
                </Box>
            </Stack>

        </MySwipeableDrawer>

    )
}

export default Drawer