const initialState = {
  isLoading: false
}
export default function allMessages (state = initialState, action) {
  switch (action.type) {
    case 'GET_ALL_MESSAGES_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'GET_ALL_MESSAGES': {
      return {
        ...state,
        isLoading: false,
        messages: action.payload
      }
    }
    case 'GET_ALL_MESSAGES_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }
    default:
      return state
  }
}
