import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { getAllChats, setChat } from '../../redux/actions/chats'
import { useDispatch, useSelector } from 'react-redux'
import { useSocket } from '../../components/hooks/useSocket'
import {
  isValidUrl
} from '../../helper'
import { Container, Box, Typography, IconButton, Fab, Avatar, Divider, ListItemButton, AvatarGroup, Alert } from '@mui/material'
import { useTheme } from '@emotion/react'
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Loader from '../../components/loader'
import { LoadingButton } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
  const day = date.getDate()
  // const month = MONTH_NAMES[date.getMonth()]
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours()
  let minutes = date.getMinutes()

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${minutes}`
  }

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return `${prefomattedDate} at ${hours}:${minutes}`
  }

  if (hideYear) {
    // 10. January at 10:20
    // return `${day} ${month} at ${hours}:${minutes}`
    return `${month}}/${day}/${year}. at ${hours}:${minutes}`
  }

  // 10. January 2017. at 10:20
  // return `${day} ${month} ${year}. at ${hours}:${minutes}`
  return `${month}/${day}/${year}. at ${hours}:${minutes}`
}

function timeAgo(dateParam) {
  if (!dateParam) {
    return null
  }
  dateParam = new Date(dateParam)
  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam)
  const DAY_IN_MS = 86400000 // 24 * 60 * 60 * 1000
  const today = new Date()
  const yesterday = new Date(today - DAY_IN_MS)
  const seconds = Math.round((today - date) / 1000)
  const minutes = Math.round(seconds / 60)
  const isToday = today.toDateString() === date.toDateString()
  const isYesterday = yesterday.toDateString() === date.toDateString()
  const isThisYear = today.getFullYear() === date.getFullYear()

  if (seconds < 5) {
    return 'now'
  } else if (seconds < 60) {
    return `${seconds} seconds ago`
  } else if (seconds < 90) {
    return 'about a minute ago'
  } else if (minutes < 60) {
    return `${minutes} minutes ago`
  } else if (isToday) {
    return getFormattedDate(date, 'Today') // Today at 10:20
  } else if (isYesterday) {
    return getFormattedDate(date, 'Yesterday') // Yesterday at 10:20
  } else if (isThisYear) {
    return getFormattedDate(date, false) // 10. January at 10:20
  }

  return getFormattedDate(date) // 10. January 2017. at 10:20
}

function MessageScreen() {
  const theme = useTheme()
  const [searchParams] = useSearchParams()
  const fromProfile = searchParams.get('profile')
  const navigate = useNavigate()
  const [showItemList, setShowItemList] = useState(false)
  const chatUserList = useSelector((state) => state.allChats)
  const [chatList, setChatList] = useState()
  const [sortedChatList, setSortedChatList] = useState()
  const [unreadChatRoomCat, setUnreadChatRoomCat] = useState({
  })
  const [messageLimit, setMessageLimit] = useState(15)
  const user = JSON.parse(localStorage.getItem('user'))
  const [allChats, setAllChats] = useState([])
  const dispatch = useDispatch()
  const socket = useSocket()
  const [isLoading, setIsLoading] = useState(true)
  const getUnreadChatRoom = () => {
    let count = 0
    let count2 = 0
    let count3 = 0
    let count4 = 0
    // code for categorized unread chatRoom
    const filteredData = allChats.filter((value) => value.chatparticipants.length === 2 && value.wagerId === null)
    filteredData?.map((value) => {
      if (value?.chatmessages[0]?.messageuserstatuses?.find((val) => val.userId.toString() === user.id.toString())?.unread) {
        count = count + 1
      }
      return 'avc' // unrltd return for lint rule
    })
    const filteredData2 = allChats.filter((value) => value.chatparticipants.length > 2 && value.wagerId === null)
    filteredData2?.map((value) => {
      if (value?.chatmessages[0]?.messageuserstatuses?.find((val) => val.userId.toString() === user.id.toString())?.unread) {
        count2 = count2 + 1
      }
      return 'vsd' // unrltd return for lint rule
    })
    const filteredData3 = allChats.filter((value) => value.wagerId !== null)
    filteredData3?.map((value) => {
      if (value?.chatmessages[0]?.messageuserstatuses?.find((val) => val.userId.toString() === user.id.toString())?.unread) {
        count3 = count3 + 1
      }
      return 'asdcc' // unrltd return for lint rule
    })
    allChats?.map((value) => {
      if (value?.chatmessages[0]?.messageuserstatuses?.find((val) => val.userId.toString() === user.id.toString())?.unread) {
        count4 = count4 + 1
      }
      return 'avs' // unrltd return for lint rule
    })
    setUnreadChatRoomCat((prev) => {
      return {
        ...prev,
        General: count,
        GroupChat: count2,
        wagerChat: count3,
        Messages: count4
      }
    })
  }
  const loadMoreChats = () => {
    setMessageLimit(messageLimit + 5)
  }

  useEffect(() => {
    dispatch(
      getAllChats({
        uid: user?.id,
        skip: 0,
        limit: messageLimit,
      })
    )
  }, [messageLimit]);

  useEffect(() => {
    socket.emit('messagelist', { uid: user?.id })
    socket.emit('something', { uid: user?.id })
    socket.on('something', data => {
      dispatch(setChat({ result: data.slice(0, 10), totalCount: data?.length || 0 }))
      setIsLoading(false)
    })
    // return () => { socket.disconnect() }
  }, [])


  const toOrdinalSuffix = (num) => {
    const int = parseInt(num)
    const digits = [int % 10, int % 100]
    const ordinals = ['st', 'nd', 'rd', 'th']
    const oPattern = [1, 2, 3, 4]
    const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
      ? int + ordinals[digits[0] - 1]
      : int + ordinals[3]
  }

  useEffect(() => {
    const modifiedChatList = []
    chatList?.map((value, index) => {
      // console.log(value, 'valalalal')
      // if (value?.chatmessages[0]?.messageuserstatuses?.find((val) => val.userId.toString() === user.id.toString())?.unread) {
      //   modifiedChatList.unshift(value)
      // } else {
      // if (value?.chatmessages?.length > 0) {
      modifiedChatList.push(value)
      // }
      // }
      return 0
    })
    setSortedChatList(JSON.parse(JSON.stringify(modifiedChatList)))
  }, [chatList])
  // console.log(showItemList, 'showItemList')
  //console.log(sortedChatList?.filter(chat => chat?.type === 'p'))
  // console.log('')
  // console.log('')
  // console.log('chatUserList', chatUserList)
  // console.log('allChats', allChats)
  // console.log('chatUserList?.chatList?.length', chatUserList?.chatList?.length)
  // console.log('chatUserList?.totalCount', chatUserList?.totalCount)
  // console.log('')
  // console.log('')
  return (
    <>
      {
        false ?
          <Box>
            <Loader isloading={true} />
          </Box>
          :
          (!chatUserList?.chatList?.length)
            ?
            <Container sx={{
              mt: 4
            }}>
              <Alert severity='info'>Send your first message to someone by tapping the plus sign bottom right!</Alert>
            </Container>
            :
            <>
              {
                chatUserList?.chatList?.map((value, index) => {
                  return (
                    <>
                      <ListItemButton
                        onClick={() => navigate(`/chat-screen?chatId=${value.id}`)}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          pl: 2,
                          pr: 2,
                          pt: 2,
                          pb: 2
                        }}>
                        <Box sx={{
                          display: 'flex',
                          gap: '8px',
                        }}>

                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant={value?.chatmessages[0]?.messageuserstatuses?.find((val) => val.userId.toString() === user.id.toString())?.unread ? "dot" : ''}
                          >
                            <AvatarGroup max={2}>
                              {
                                < Avatar
                                  src={value?.chatparticipants?.filter((val) => val.userId !== user.id)[0]?.user_data?.userLogo ? value?.chatparticipants?.filter((val) => val.userId !== user.id)[0]?.user_data?.userLogo : null}
                                  sx={{
                                    height: '48px',
                                    width: '48px'
                                  }} />
                              }
                            </AvatarGroup>
                          </StyledBadge>

                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}>
                            <Typography
                              variant='caption'
                              sx={{
                                fontWeight: 700
                              }}>
                              {value?.name ? value?.name : value.chatparticipants?.filter((val) => val.userId !== user?.id).map((val, index) => val.userId !== user.id && index === 0 ? `@${val?.user_data?.username}` : val.userId !== user.id && index === 1 && `, @${val?.user_data?.username} ${value?.chatparticipants?.length <= 3 ? '' : `... +${value?.chatparticipants?.length - 3}`}`)}
                            </Typography>
                            <Typography
                              variant='body2'
                              sx={{
                                mt: -.55,
                                color: value?.chatmessages[0]?.messageuserstatuses?.find((val) => val.userId.toString() === user.id.toString())?.unread ? 'white' : theme.palette.dark.otherlight
                              }}>
                              {value?.chatmessages.length ? isValidUrl(value?.chatmessages[0]?.message) ? <></> : value?.chatmessages[0]?.message : 'Start a conversation today!'}
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{
                          display: 'flex',
                          alignItems: 'flex-end'
                        }}>
                          <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>{value.chatmessages.length ? timeAgo(new Date(value.chatmessages[0].createdAt).getTime()) : ''}</Typography>
                        </Box>
                      </ListItemButton >
                      <Divider sx={{ width: '100%', bgcolor: theme.palette.dark.otherdark }} />
                    </>
                  )
                })
              }
              {
                chatUserList?.chatList?.length != chatUserList?.totalCount &&
                <Container>
                  < LoadingButton
                    loading={chatUserList?.isLoading}
                    sx={{ mt: 2, mb: 2 }}
                    onClick={() => loadMoreChats()}
                    fullWidth
                    variant='outlined'>
                    Load more
                  </LoadingButton>
                </Container>
              }
            </>
      }

      <Box sx={{
        position: 'fixed',
        bottom: '112px',
        right: '12px'
      }}>
        <Fab color="primary" aria-label="add" size="small">
          <AddIcon
            onClick={() => navigate('/search-friend')}

            sx={{
              color: 'white',
              fontSize: '24px'
            }} />
        </Fab>
      </Box>
    </>
  )
}

export default MessageScreen