const initialState = {
  isLoading: false
}
export default function createMessage (state = initialState, action) {
  switch (action.type) {
    case 'CREATE_MESSAGE_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'CREATE_MESSAGE': {
      return {
        ...state,
        isLoading: false,
        messageInfo: action.payload
      }
    }
    case 'CREATE_MESSAGE_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        isLoading: false,
        error: action.payload
      }
    }
    default:
      return state
  }
}
