import { LEADERBOARD_REQUEST, LEADERBOARD, LEADERBOARD_ERROR } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import LeaderBoardService from '../../../services/leaderbord'
export const ListLeaderBoard = createAsyncThunk(
  '/events/getLeaderBoard',
  async ({ sportsId }, thunkAPI) => {
    thunkAPI.dispatch({
      type: LEADERBOARD_REQUEST
    })
    try {
      const data = await LeaderBoardService.getLeaderBoard(sportsId)
      thunkAPI.dispatch({
        type: LEADERBOARD,
        payload: data && data?.data
      })
      return { leaderboard: data && data?.data }
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
      thunkAPI.dispatch({
        type: LEADERBOARD_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
