import React from 'react'
import { Link } from 'react-router-dom'

function ReportBug () {
  return (
        <>
          <div className="fullScreen fullScreen-login login-solid">

            <div className="pageTop forDrop bgDark">
                <div className="backLink">
                   <Link to="/account-settings">
                       <div className="backLink-icon noBg"></div>
                   </Link>
                    <span className="mt-0">Report a bug</span>
                </div>
            </div>

             <div className="loginWrap scrollable-notification">
                <div className="editPro">
                    <div className="row">
                        <div className="col-6">
                            <div className="feedIcon"></div>
                            <p className="text-center fw-bold pt-2">email</p>
                        </div>
                        <div className="col-6">
                            <div className="feedIcon"></div>
                            <p className="text-center fw-bold pt-2">call</p>
                        </div>
                    </div>
                </div>
             </div>

             <div className="pageBottom text-center blue">
                <div className="editInpWrap text-center">
                </div>
             </div>

          </div>
        </>
  )
}

export default ReportBug
