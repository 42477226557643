
const initialState = {
}
export default function Privateers (state = initialState, action) {
  switch (action.type) {
    case 'LIST_PRIVATEERS': {
      return {
        ...state,
        ListStatus: 'Success',
        privateers: action.payload
      }
    }
    case 'LIST_PRIVATEERS_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }
    default:
      return state
  }
}
