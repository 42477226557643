import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './App.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation
} from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Login from './pages/login/login'
import ResetPassword from './pages/login/resetPassword'
import WraggerBoard from './pages/wagerboard'
import MyWagers from './pages/my-wagers/myWagers'
import ConfirmWrager from './pages/wagerboard/confirmWrager'
import ResetPasswordConfirmation from './pages/login/resetPasswordConfirmation'
import CreatePassword from './pages/login/createPassword'
import PasswordResetSuccess from './pages/login/passwordResetSuccess'
import SignUp from './pages/signup/signUp'
import LoginLoading from './pages/login/loginLoading'
import SignUpOtp from './pages/otpScreen'
import UserProfile from './pages/user-profile/userProfile'
import EditProfile from './pages/user-profile/editProfile'
import EditUserName from './pages/user-profile/editUserName'
import EditEmail from './pages/user-profile/editEmail'
import EditSuccess from './pages/user-profile/editSuccess'
import EditPassword from './pages/user-profile/editPassword'
import EditAddress from './pages/user-profile/editAddress'
import InviteFriend from './pages/user-profile/inviteFriend'
import AccountSettings from './pages/user-profile/accountSettings'
import FaqScreen from './pages/user-profile/faqScreen'
import SendFeedback from './pages/user-profile/sendFeedback'
import ReportBug from './pages/user-profile/reportBug'
import MyWragerDetails from './pages/my-wagers/myWragerDetails'
import DeleteAccount from './pages/user-profile/deleteAccount'
import DeleteAccountStep2 from './pages/user-profile/deleteAccountStep2'
import DeleteAccountConfirmation from './pages/user-profile/deleteAccountConfirmation'
import PropTypes from 'prop-types'
import ChipBank from './pages/chip-bank/chipBank'
import Invite from './pages/signup/Invite'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import MessageScreen from './pages/messaging/messageScreen'
import SearchFriend from './pages/messaging/searchFriend'
import ChatScreen from './pages/messaging/chatScreen'
import NotFound from './pages/404/404'
import WagerDetailsNew from './pages/wagerboard/wagerDetailsNew'
import NotificationScreen from './pages/notifications/notificationScreen'
import { ListNotifications, Notifications } from './redux/actions/notificationScreen'
import { useSocket } from './components/hooks/useSocket'
import { getNewChatCount } from './redux/actions/chatCount'
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging'
import HowItWorks from './pages/signup/howItWorks'
import { Container } from '@mui/material'
import { useTheme } from '@emotion/react'
import AgeVerificationPage from './pages/age-verification'
import MatchPage from './pages/match'
import NotificationAppAlert from './components/notification/notification-in-app-alert'
import { onMessageListener } from './firebase'
import { getAllChats, setChat } from './redux/actions/chats'
import SocialPage from './pages/social'
import InviteOnly from './pages/login/inviteOnly'
import InviteCode from './pages/login/inviteCode'

function App() {
  const [loading, setLoading] = useState(true)
  const socket = useSocket()
  const dispatch = useDispatch()
  const theme = useTheme()
  // const stripePromise = loadStripe('pk_test_Uxl5ej2b1trcG5k9hjdOibql005wce8pwG')
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
  const [currentToken, setCurrentToken] = useState(null)
  const CounterVal = useSelector((state) => state?.Counter?.counter)

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const isSupportedBrowser = await isSupported()
  //       const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false
  //       if (isSupportedBrowser) {
  //         await Notification.requestPermission().then(async (permission) => {
  //           if (permission === 'granted') {
  //             if (!currentToken && user?.notificationSetting) {
  //               const registration = await navigator.serviceWorker.register('/service-worker.js');
  //               const subscription = await registration.pushManager.getSubscription();

  //               if (subscription) {
  //                 console.log("No subscription detected, subscribing...");
  //                 const pushSubscription = await subscribeUserToPush(registration);

  //                 //console.log("STRING to push:", JSON.stringify(pushSubscription));

  //                 const sub = JSON.parse(JSON.stringify(pushSubscription))

  //                 dispatch(Notifications({
  //                   endpoint: sub.endpoint,
  //                   expirationTime: sub.expirationTime,
  //                   keys: { ...sub.keys }
  //                 }))

  //               } else {

  //                 await subscription.unsubscribe().then(async successful => {
  //                   // You've successfully unsubscribed
  //                   console.log('You were unsubscribed successfully: ', successful);
  //                   console.log("No subscription detected, subscribing...");
  //                   const pushSubscription = await subscribeUserToPush(registration);

  //                   // console.log("STRING to push:", JSON.stringify(pushSubscription));

  //                   const sub = JSON.parse(JSON.stringify(pushSubscription))

  //                   dispatch(Notifications({
  //                     endpoint: sub.endpoint,
  //                     expirationTime: sub.expirationTime,
  //                     keys: { ...sub.keys }
  //                   }))
  //                 }).catch(error => {
  //                   // Handling errors
  //                   console.log('Unsubscription failed: ', error);
  //                 });
  //                 // console.log("Already subscribed to push:", JSON.stringify(subscription));
  //               }
  //             }
  //           }
  //         })
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })()
  // }, []);

  // Placeholder function to demonstrate push subscription
  async function subscribeUserToPush(registration) {
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array('BGPZG1tXhLzDEhexgMt8U5bDwjmUsAZLgpyJtcmL-wl-NyVX6ho3e6ClpJzQPvvYrktUDBjuOn2JJ9pJ7VHPfmQ')
    };

    return registration.pushManager.subscribe(subscribeOptions);
  }

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    socket.on('newCounter', (arg) => {
      if (arg?.wagerCreatedBy === user?.id) {
        if (arg?.CounterCount > 0) {
          setTimeout(() => {
            // const temp = []
            if (arg?.wagerId && CounterVal?.length) {
              const temp = CounterVal?.filter((val) => val !== arg?.wagerId)
              // setCounterCount(arg?.CounterCount)
              dispatch({
                type: 'SETCOUNTER',
                payload: temp
              })
            }
          }, 300000)
        }
      }
    })
  }, [socket])


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      //dispatch(ListNotifications({}))
    }
    setTimeout(() => setLoading(false), 3000)
    if (user?.id) {
      socket.emit('getmessagenotification', { uid: user?.id })
    }
    socket.on('getmessagenotification', (data) => {
      dispatch(getNewChatCount({ count: data }))
    })
    socket.emit('newCounter', { uid: user?.id })
  }, [])

  const Guard = ({ token, routeRedirect }) => {

    const location = useLocation()

    const user = JSON.parse(localStorage.getItem(token))

    useEffect(() => {

      if (user) {
        dispatch(ListNotifications({}))
        socket.emit('something', { uid: user?.id })
        socket.on('something', data => {
          dispatch(setChat({ result: data.slice(0, 10), totalCount: data?.length || 0 }))
        })
      }

      setTimeout(() => setLoading(false), 3000)

      if (user?.id) {
        socket.emit('getmessagenotification', { uid: user?.id })
        socket.emit('notificationcount', { uid: user?.id })
      }

      socket.on('getmessagenotification', (data) => {
        dispatch(getNewChatCount({ count: data }))
      })

      socket.on('notificationcount', (data) => {
        //dispatch(ListNotifications({}))
      })
    }, [location])

    if (!user) {
      return <Navigate to='/' replace state={{ from: location }} />
    }
    else {
      if (!user?.isInvited) {
        return <Navigate to='/invite-code' replace state={{ from: location }} />
      }
      else if (!user?.isVerified) {
        return <Navigate to='/verifyOtp' replace state={{ from: location }} />
      }
      else if (user?.isVerified && user?.stage < 4) {
        if (user?.stage === 1 && !window.location.href.includes('/verifyOtp')) {
          return <Navigate to='/verifyOtp' replace state={{ from: location }} />
        }
        else if (user?.stage === 2 && !window.location.href.includes('/age-verification')) {
          return <Navigate to='/age-verification' replace state={{ from: location }} />
        }
      }

      return localStorage.getItem(token)
        ? (
          <>
            <NotificationAppAlert />
            <Outlet />
          </>
        )
        : (
          <Navigate to={routeRedirect} replace state={{ from: location }} />
        )
    }
  }

  Guard.propTypes = {
    token: PropTypes.string.isRequired,
    routeRedirect: PropTypes.string.isRequired
  }
  /*
  
      <div className="appWrap">
      </div>
 
      <Container maxWidth='lg' sx={theme.containers.main}>
 
    </Container>
      */
  return (
    <div className="appWrap">
      <Elements stripe={stripePromise}>
        <Router history={createBrowserHistory}>
          {
            loading
              ? (<LoginLoading />)
              : (
                <Routes>
                  {/* normal routes */}
                  <Route path="/age-verification" element={<AgeVerificationPage />} />

                  <Route exact path="/" element={<Login />} />
                  <Route exact path="/beta" element={<InviteOnly />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route
                    path="/reset-password-confirmation"
                    element={<ResetPasswordConfirmation />}
                  />
                  <Route path="/create-password" element={<CreatePassword />} />
                  <Route
                    path="/password-reset-success"
                    element={<PasswordResetSuccess />}
                  />
                  {
                    <Route path="/verifyOtp" element={<SignUpOtp />} />
                  }
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/signup/:id" element={<SignUp />} />
                  <Route path="/invite/:id" element={<Invite />} />
                  {/* normal routes ended */}
                  {/* protected Routes started */}
                  <Route path="/invite-code" element={<InviteCode />} />
                  <Route element={<Guard token="user" routeRedirect="/" />}>
                    {
                      //<Route path="/dashboard" element={<Dashboard/>} />
                      //<Route path="/dashboard" element={<WraggerBoard />} />
                    }
                    <Route path="/dashboard" element={<WraggerBoard />} />
                    <Route path="/my-wagers/" element={<MyWagers />} />
                    {
                      /*
                    <Route path="/age-verification" element={<AgeVerificationPage />} />
                    */
                    }
                    {
                      /*
                      <Route path="/card-detail" element={<CardDetails />} />
                    <Route path="/subscription" element={<Subscription />} />
                    <Route path="/privateers" element={<Privateers />} />
                      */
                    }

                    <Route path="/how-it-works" element={<HowItWorks />} />
                    <Route path="/confirm-wager/:id" element={<ConfirmWrager />} />
                    <Route path="/user-profile" element={<UserProfile />} />
                    <Route path="/user-profile/:id" element={<UserProfile />} />
                    <Route path="/edit-profile" element={<EditProfile />} />
                    <Route path="/edit-user-name" element={<EditUserName />} />
                    <Route path="/edit-email" element={<EditEmail />} />
                    <Route path="/edit-success" element={<EditSuccess />} />
                    <Route path="/edit-password" element={<EditPassword />} />
                    <Route path="/edit-address" element={<EditAddress />} />
                    <Route path="/invite-friends" element={<InviteFriend />} />
                    <Route path="/account-settings" element={<AccountSettings />} />
                    <Route path="/faq" element={<FaqScreen />} />
                    <Route path="/message" element={<MessageScreen />} />
                    <Route path="/search-friend" element={<SearchFriend />} />
                    <Route path="/chat-screen" element={<ChatScreen />} />
                    <Route path="/send-feedback" element={<SendFeedback />} />
                    <Route path="/report-bug" element={<ReportBug />} />
                    <Route path="/notifications" element={<NotificationScreen />} />
                    <Route
                      path="/comments/:id"
                      element={<MyWragerDetails />}
                    />
                    <Route
                      path="/wager-details-new/:id"
                      element={<WagerDetailsNew />}
                    />
                    <Route path="/delete-account" element={<DeleteAccount />} />

                    <Route
                      path="/delete-account-step-2"
                      element={<DeleteAccountStep2 />}
                    />
                    <Route
                      path="/delete-account-confirmation"
                      element={<DeleteAccountConfirmation />}
                    />
                    <Route path="/social" element={<SocialPage />} />
                    <Route path="/chip-bank" element={<ChipBank />} />
                    {
                      /*
                      <Route path='/buy-chip' element={<BuyChips/>} />
                    <Route path="/chip-review/:amount" element={<ChipReview/>} />
                    <Route path="/stripe/checkout-cancel" element={<CheckoutCancel/>} />
                    <Route path="/stripe/checkout-success" element={<CheckoutSuccess/>} />
    
                    <Route path="/deposit-initiated" element={<DepositeInitiate/>} />
                    <Route path="/deposit-success" element={<DepositSuccess/>} />
                    <Route path="/chip-withdraw" element={<ChipWithdraw/>} />
                    <Route path="/withdraw-review/:amount" element={<WithdrawReview/>} />
                    <Route path="/withdraw-initiated" element={<WithdrawInitiated/>} />
                    <Route path="/transcript" element={<TransferTranscript/>} />
                    <Route path="/activate-card" element={<ActivateCard/>} />
                      */
                    }

                    <Route path="/match/:matchId" element={<MatchPage />} />

                  </Route>
                  {/* protected Routes ended */}
                  <Route path='*' element={<NotFound />} />
                </Routes>
              )}
        </Router>
      </Elements>
    </div>
  )
}

export default App
