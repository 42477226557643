import authService from "../../../services/auth.service";

export const submitInviteCode = (inviteCode) => {
  return async (dispatch) => {
    // Start the fetch operation
    try {

      const user = JSON.parse(localStorage.getItem('user'))
      // After successful fetch, dispatch the action
      dispatch({
        type: 'REQUEST_INVITE_CODE_VALIDATION'
      });

      const response = await authService.submitInviteCode(inviteCode)
      console.log(response)
      dispatch({
        type: 'SET_INVITE_CODE',
        payload: response && response.data
      });
      user.isInvited = true
      localStorage.setItem('user', JSON.stringify(user))
    } catch (error) {
      console.log(error)

      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      dispatch({
        type: 'SET_INVITE_CODE_ERROR',
        payload: message
      });

    }
  };
};


export const resetInviteCode = () => {
  return async (dispatch) => {
    dispatch({
      type: 'RESET_INVITE_CODE_ERROR'
    });
  }
};