import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import withRouter, { isOnlyNumber } from '../../helper'
import { useDispatch, useSelector } from 'react-redux'

import { login, resetAuth } from '../../redux/actions/auth'
// import { messaging } from '../../firebase'
import { getToken, getMessaging, isSupported } from 'firebase/messaging'
import { Notifications, ListNotifications } from '../../redux/actions/notificationScreen'
import { Box, Button, Container, Divider, Stack, TextField, Typography } from '@mui/material'
import theme from '../../styles/theme'
import { LoadingButton } from '@mui/lab'
import LogoContainer from '../../components/header/logo-container'
import AuthLayout from '../../components/layouts/auth'

function Login(props) {

  const [inputs, setInputs] = useState({
    username: '',
    password: ''
  })

  const [error, setError] = useState({
    status: false,
    message: ''
  })

  const [Username, setUsername] = useState('')
  const [Password, setPassword] = useState('')
  const [showPwd, setShowPwd] = useState(false)
  const [errorUsername, setUsernameError] = useState(false)
  const [errorPassword, setPasswordError] = useState(false)
  const [userNotFound, setUserNotFound] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSupportedBrowser, setIsSupportedBrowser] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loginData = useSelector((state) => state.Auth)
  const user = JSON.parse(localStorage.getItem('user'))
  let messaging = null


  const handleCredentials = (event) => {
    setError({
      status: false,
      message: ''
    })
    let temp = { ...inputs }
    temp[event.target.name] = event.target.value
    setInputs(temp)
  }

  useEffect(() => {
    (async () => {
      const isSupport = await isSupported()
      if (isSupport) messaging = getMessaging()
      setIsSupportedBrowser(isSupport)
    })()
  }, [loginData])

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('user'))
    if (token?.tokens?.accessToken) {
      navigate('/dashboard')
      dispatch(ListNotifications({}))
    }
    window.scrollTo(0, 0)
  }, [])


  // Placeholder function to demonstrate push subscription
  async function subscribeUserToPush(registration) {
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array('BGPZG1tXhLzDEhexgMt8U5bDwjmUsAZLgpyJtcmL-wl-NyVX6ho3e6ClpJzQPvvYrktUDBjuOn2JJ9pJ7VHPfmQ')
    };

    return registration.pushManager.subscribe(subscribeOptions);
  }

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  const firebaseGenerateToken = async () => {
    try {
      const isSupportedBrowser = await isSupported()
      const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false
      if (isSupportedBrowser) {
        await Notification.requestPermission().then(async (permission) => {
          if (permission === 'granted') {
            const registration = await navigator.serviceWorker.register('/service-worker.js');
            const subscription = await registration.pushManager.getSubscription();
            if (!subscription) {
              console.log("No subscription detected, subscribing...");
              const pushSubscription = await subscribeUserToPush(registration);

             // console.log("STRING to push:", JSON.stringify(pushSubscription));

              const sub = JSON.parse(JSON.stringify(pushSubscription))

              dispatch(Notifications({
                endpoint: sub.endpoint,
                expirationTime: sub.expirationTime,
                keys: { ...sub.keys }
              }))

            } else {
              await subscription.unsubscribe().then(async successful => {
                // You've successfully unsubscribed
                //console.log('You were unsubscribed successfully: ', successful);
                console.log("No subscription detected, subscribing...");
                const pushSubscription = await subscribeUserToPush(registration);

                console.log("STRING to push:", JSON.stringify(pushSubscription));

                const sub = JSON.parse(JSON.stringify(pushSubscription))

                dispatch(Notifications({
                  endpoint: sub.endpoint,
                  expirationTime: sub.expirationTime,
                  keys: { ...sub.keys }
                }))
              }).catch(error => {
                // Handling errors
                console.log('Unsubscription failed: ', error);
              });
              console.log("Already subscribed to push:", JSON.stringify(subscription));
            }

          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (user && loginData.Login === 'Success' && loginData?.response) {
      setIsLoading(false)
      if(
        false
        //!loginData?.response?.isInvited
      ){
        navigate('/invite-code')
      }
      else{
        if (loginData?.response?.isVerified) {
          if (loginData?.response?.notificationSetting && isSupportedBrowser) {
            //firebaseGenerateToken()
          }
          navigate('/dashboard')
        }
        else {
          if (user) {
            navigate('/verifyOtp')
          }
        }
      }
    }

    if (loginData.Login === 'Fail') {
      if (loginData.message === 'No Active Subscription') {
        if (user === null) {
          const user = loginData?.response
          localStorage.setItem('user', JSON.stringify(user))
          navigate('/dashboard')
        }
        else {
          navigate('/dashboard')
        }
      }
      setIsLoading(false)
      setError({
        status: true,
        message: "Invalid credentials."
        //message: loginData.message
      })
    }

    // if (loginData.Login === 'INVITE_ONLY') {
    //   navigate('/invite-code')
    //   setIsLoading(false)
    //   dispatch(resetAuth())
    // }

  }, [loginData, isSupportedBrowser])

  const hitLoginApi = (e) => {
    e.preventDefault()
    setIsLoading(true)
    dispatch(
      login({
        username: inputs.username,
        password: inputs.password
      })
    )
  }

  const showPassword = () => {
    setShowPwd(!showPwd)
  }


  return (
    <AuthLayout>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '24px',
          boxSizing: 'border-box'
        }}>

        <LogoContainer />

        {
          /*
          <Typography variant="h4" fontWeight={700}>Welcome back!</Typography>
          */
        }
        {
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography variant="h4" fontWeight={700}>Welcome Back!</Typography>
            <Typography variant="body1" fontWeight={700}>New Betzon Coming Fall 2024</Typography>
          </Stack>
        }

        <TextField
          sx={{
            width: '100%'
          }}
          error={error.status}
          name="username"
          onChange={() => handleCredentials(event)}
          variant='filled'
          label="Username"
        />

        <Stack spacing={1} sx={{ width: '100%' }}>
          <TextField
            sx={{
              width: '100%'
            }}
            error={error.status}
            type='password'
            name="password"
            onChange={() => handleCredentials(event)}
            variant='filled'
            label="Password"
          />
          {
            error.status ?
              <Typography variant='body2' color="error" sx={{ fontWeight: 400 }}>{error.message}</Typography> : ''
          }
          <Link to="/reset-password"><Typography variant='body2' sx={{ textDecoration: 'underline' }}>Forgot password?</Typography></Link>
        </Stack>

      </Box>

      <Stack
        spacing={1}
        sx={{
          width: '100%'
        }}>

        <LoadingButton
          loading={isLoading}
          onClick={() => hitLoginApi(event)}
          disabled={inputs.username === '' || inputs.password === '' || error.status}
          sx={{
            width: '100%'
          }}
          size="large"
          variant='contained'>
          Login
        </LoadingButton>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px'
        }}>
          <Typography variant='body2'>Already have an account?</Typography>
          <Link to='/signup'><Typography variant='body2' sx={{ textDecoration: 'underline' }}>Sign up</Typography> </Link>
        </Box>

      </Stack>

    </AuthLayout>
  )
}

export default withRouter(Login)