
import AWS from 'aws-sdk'
AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_ID
})
const S3_BUCKET = process.env.REACT_APP_BUCKETNAME
const REGION = process.env.REACT_APP_REGION
const myBucket = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: S3_BUCKET },
  region: REGION
})

const handleBucket = async (file, path, id) => {
  let url
  if (file) {
    try {
      const fileUrl = await myBucket
        .upload({
          ACL: 'public-read',
          Bucket: process.env.REACT_APP_BUCKETNAME,
          Key: `${path}/${id}/` + file.name,
          Body: file,
          ContentEncoding: 'base64'
        })
        .promise()
      url = fileUrl.Location
    } catch (error) {
      console.log(error)
    }
  }
  return url
}
export default handleBucket
