import React, { useEffect } from 'react'
import { Box, Button, Container, Divider, Stack, TextField, Typography } from '@mui/material'
import AuthLayout from '../../components/layouts/auth'
import logo from '../../images/betzonlogo.svg'
import { useTheme } from '@emotion/react'
import { useSelector } from 'react-redux'
const InviteOnly = () => {
    const theme = useTheme()
    
    const inviteResponse = useSelector((state) => state.inviteReducer)

    const loginData = useSelector((state) => state.Auth)

    

    const goToWaitlist = () => {
        window.open('https://www.betzon.com', '_blank');
    }

    return (
        <AuthLayout>
            <Stack
                sx={{
                    width: '100%',
                    height: '100%'
                }}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <img src={logo} style={{
                    width: '60px',
                    marginTop: '-24px'
                }} />
                <Stack
                    spacing={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ textAlign: 'center' }}
                >

                    <Stack
                        spacing={-1}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ textAlign: 'center' }}
                    >
                        <Typography>Relaunching Fall 2024</Typography>
                        <Typography variant='h4'>LIVE <span style={{ color: theme.palette.primary.main }}>GAMING</span></Typography>
                        <Typography variant='h4'>LIVE <span style={{ color: theme.palette.primary.main }}>WAGERING</span> </Typography>

                    </Stack>

                    <Box sx={{
                        p: 2,
                        borderRadius: '12px',
                        bgcolor: theme.palette.dark.otherdark,
                        //border: `1px solid ${theme.palette.primary.main}`
                    }}>
                        <Typography sx={{ fontWeight: 700 }}>
                            If you would like to be part of our Beta program to help improve our platform,
                            please send us an email at <a href="mailto:info@betzon.com?subject=BETA%20PROGRAM" style={{ color: theme.palette.primary.main }}>info@betzon.com</a>
                        </Typography>
                    </Box>
                    <Button onClick={()=> goToWaitlist()} variant='outlined' size='small' sx={{ mt: 2 }}>Join our waitlist to recieve updates!</Button>
                </Stack>
                <Typography></Typography>
            </Stack>
        </AuthLayout>
    )
}

export default InviteOnly
