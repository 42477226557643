import axios from './axios'
import authHeader from './auth-header'

const getWagerDetails = (data) => {
  return axios.post(
    `wagers/get/${data?.id}`,
    {
    },
    {
      headers: authHeader()
    }
  )
}

const acceptWagger = ({ id, status }) => {
  return axios.post(
    `wagers/join/${id}`,
    {
      status
    },
    {
      headers: authHeader()
    }
  )
}

export default {
  getWagerDetails,
  acceptWagger
}
