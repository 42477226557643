
const initialState = {}
export default function Manufactuerers (state = initialState, action) {
  switch (action.type) {
    case 'LIST_MANUFACTUERERS': {
      return {
        ...state,
        ListStatus: 'Success',
        manufactuerers: action.payload
      }
    }
    case 'LIST_MANUFACTUERERS_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }
    default:
      return state
  }
}
