import React from 'react'
import { Link } from 'react-router-dom'

function ResetPasswordConfirmation () {
  return (
        <>
          <div className="fullScreen fullScreen-login">
            <div className="pageTop">
                <div className="backLink">
                   <Link to="/reset-password">
                       <div className="backLink-icon"><BiLeftArrowAlt /></div>
                       <span>Go Back</span>
                   </Link>
                </div>
            </div>
             <div className="loginWrap">
                 <div className="infoTxt text-center">
                     <p><FaEnvelope className="envLarge" /></p>
                     <h1>Reset Password</h1>
                     <p>{"Enter the email associated with your account and we'll send an email with instructions to reset your password. Make sure to check your spam folder!"}</p>
                 </div>
                 <div className="loginInp d-grid">
                     <Link className="d-grid" to="/create-password">
                        <button className="button btn-block">Send again</button>
                     </Link>
                    <div className="inpMsg success text-center">A new email was sent. Please disregard the old one.</div>
                 </div>
             </div>
             <div className="pageBottom text-center keyOpen">
                 <p>Brought to you by <span>COVERED Inc</span>.</p>
             </div>
          </div>
        </>
  )
}

export default ResetPasswordConfirmation
