const initialState = {
  isLoading: false
}
// Use the initialState as a default value
export default function Chips (state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case 'CHIP_BALANCE_REQUEST': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'CHIP_BALANCE': {
      return {
        ...state,
        isLoading: false,
        Status: 'Success',
        chips: action.payload
      }
    }

    case 'CHIP_BALANCE_ERROR': {
      return {
        ...state,
        isLoading: false,
        Status: 'Fail',
        error: action.payload
      }
    }

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}
