import React, { useEffect } from 'react'
import logo from '../../../src/images/betzonlogo.svg'
import { Box, Typography } from '@mui/material'
// import amazonAdLogo from '../../images/amazonAdImg.svg'
// import GoogleAds from '../../components/Ads'
import { gsap } from "gsap";
import { useTheme } from '@emotion/react';

function LoginLoading() {

  const theme = useTheme()
  useEffect(() => {
    // Start with the rotation and resizing animation
    gsap.fromTo("#logo",
      { rotation: 100, attr: { width: 10, height: 10 } },
      {
        height: 240,
        width: 240,
        duration: 3,
        ease: 'elastic',
        rotation: 0,
        onComplete: startPulsing // Call startPulsing function once the initial animation completes
      }
    );

    // Define the pulsing animation as a separate function
    function startPulsing() {
      gsap.to("#logo", {
        scaleX: 1.1,
        scaleY: 1.1,
        duration: 0.5,
        ease: 'power1.inOut',
        yoyo: true, // Make the animation go back and forth
        repeat: -1 // Repeat this pulsation indefinitely
      });
    }
  }, []);



  return (
    <Box
      id='loader-body'
      sx={{
        left: 0,
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'black',
        position: 'fixed',
        zIndex: 99999,
        pb: 1,
        pt: 1
      }}>
      <Box
        id='logo-container'>
        <img id='logo' src={logo} style={{ width: '288px' }} />
      </Box>
    </Box >
  )
}

export default LoginLoading

/*

      <div className="fullScreen fullScreen-login">
        <div className="loginWrap d-flex">
          <div className="logo_loading"><img alt="img" src={logo} /></div>
          </div>
      </div>
*/

