import React from 'react'
import { useTheme } from '@emotion/react'

import { Avatar, Box, Stack, Typography, Divider, Button, IconButton } from '@mui/material'
import CardLayout from '../layouts/card'
import { Link } from 'react-router-dom'
import MatchTeamItem from './team-item'
import SettingsIcon from '@mui/icons-material/Settings';

export const MatchItem = ({ data, functionToClick, createWager, goTo }) => {
    // console.log(data)
    // console.log(data?.sports_data?.type)
    const theme = useTheme()
    const date = new Date(data?.startTime);
    const year = date.getFullYear(); // 2024
    const month = date.getMonth() + 1; // January is 0, so add 1 to get the correct month number
    const day = date.getDate();
    const timeInPST = date.toLocaleTimeString('en-US', {
        timeZone: 'America/Los_Angeles',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true // Set to false if you prefer 24-hour format
    });
    //console.log('MATCH ITEM -', data)
    return (
        <>
            {
                !!createWager ?

                    <Box sx={{
                        bgcolor: theme.palette.dark.otherdark,
                        //border: `1px solid ${theme.palette.dark.otherlight}`,
                        display: 'flex',
                        gap: '16px',
                        borderRadius: '6px',
                        p: 1.5
                    }}>
                        <Box sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px'
                        }}>
                            {
                                data?.sports_data?.type === 'event' ?
                                    <MatchTeamItem data={{
                                        name: data?.name,
                                        type: data?.sports_data?.type
                                    }} />
                                    :
                                    <>

                                        <MatchTeamItem data={{
                                            name: data?.home_teams_data?.name,
                                            type: data?.type,
                                            url_logo: data?.home_teams_data?.url_logo,
                                            location: 'home'
                                        }} />
                                        <MatchTeamItem data={{
                                            name: data?.away_teams_data?.name,
                                            type: data?.type,
                                            url_logo: data?.away_teams_data?.url_logo,
                                            location: 'away'
                                        }} />
                                    </>

                            }
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            textAlign: 'right',
                            justifyContent: 'center',
                        }}>
                            <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight, mb: .25, lineHeight: "16px" }}>Wagering ends</Typography>
                            <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight, mb: -.5 }}>{month + '.' + day + '.' + year}</Typography>
                            <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>{timeInPST}</Typography>

                            {
                                /*
     <Divider sx={{ width: '100%', height: '3px', mt: .5, mb: .5, bgcolor: theme.palette.dark.otherlight }} />
                                <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight, mb: -.5 }}>50 wagers</Typography>
                                <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight, mb: -.5 }}>25 messages</Typography>
                                */
                            }
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',

                            alignItems: 'center'
                        }}>
                            <IconButton onClick={() => functionToClick()}>
                                <SettingsIcon sx={{
                                    height: '16px',
                                    padding: 0,
                                    margin: 0
                                }} />
                            </IconButton>
                        </Box>

                    </Box >
                    :
                    <Box sx={{
                        display: 'flex',
                        gap: '16px',
                        pt: 2,
                        pb: 2
                    }}>
                        <Box sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                            {
                                data?.sports_data?.type === 'event' ?
                                    <MatchTeamItem data={{
                                        name: data?.name,
                                        type: data?.sports_data?.type
                                    }} />
                                    :
                                    <>
                                        <MatchTeamItem data={{
                                            name: data?.home_teams_data?.name,
                                            type: data?.type,
                                            url_logo: data?.home_teams_data?.url_logo,
                                            location: 'Home Team'
                                        }} />
                                        <MatchTeamItem data={{
                                            name: data?.away_teams_data?.name,
                                            type: data?.type,
                                            url_logo: data?.away_teams_data?.url_logo,
                                            location: 'Away Team'
                                        }} />
                                    </>
                            }
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            textAlign: 'right',
                        }}>

                            <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight, mb: -.5 }}>Wagering ends</Typography>
                            <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight, mb: -.5 }}>{month + '.' + day + '.' + year}</Typography>
                            <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>{timeInPST}</Typography>
                            {

                                //     <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight, mb: -.5 }}>04.05.24</Typography>
                                // <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight }}>5:30 PM</Typography>
                                // <Divider sx={{ width: '100%', height: '3px', mt: .5, mb: .5 }} />
                                // <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight, mb: -.5 }}>50 wagers</Typography>
                                // <Typography variant='caption' sx={{ color: theme.palette.dark.otherlight, mb: -.5 }}>25 messages</Typography>
                            }
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',

                            alignItems: 'center'
                        }}>
                            {
                                data?.sports_data?.type === 'event' ?
                                    <Button
                                        onClick={() => functionToClick()}
                                        variant='outlined'
                                        color='neutral'
                                        size='small'
                                        sx={{
                                            fontSize: '10px',
                                            borderRadius: '4px',
                                            height: 'fit-content'
                                        }}>
                                        {
                                            !!goTo ?
                                                'Go' : 'Wager'
                                        }
                                    </Button>
                                    :

                                    <Button
                                        onClick={() => functionToClick()}
                                        variant='outlined'
                                        color='neutral'
                                        size='small'
                                        sx={{
                                            fontSize: '10px',
                                            borderRadius: '4px',
                                            height: 'fit-content'
                                        }}>
                                        Go
                                    </Button>
                            }
                        </Box>

                    </Box>
            }
        </>
    )
}

export const MatchListItem = () => {
    const theme = useTheme()
    return (
        <CardLayout bubble>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: '100%'
            }}>

                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Typography variant='h6' sx={{ fontWeight: 700 }}>NBA</Typography>
                    <Link>
                        <Typography>See More</Typography>
                    </Link>
                </Box>

                {
                    ['', ''].map((item, index) => (
                        <MatchItem />
                    ))
                }

            </Box>
        </CardLayout>
    )
}

