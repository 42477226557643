const initialState = {}
export default function Subscription (state = initialState, action) {
  switch (action.type) {
    case 'LIST_SUBSCRIPTION': {
      return {
        ...state,
        ListStatus: 'Success',
        subscription: action.payload
      }
    }

    case 'LIST_SUBSCRIPTION_ERROR': {
      return {
        ...state,
        Status: 'Fail',
        error: action.payload
      }
    }

    default:
      return state
  }
}
