const initialState = {
    isLoading: false
};

function inviteReducer(state = initialState, action) {
    switch (action.type) {

        case 'REQUEST_INVITE_CODE_VALIDATION':
            return {
                isLoading: true
            };

        case 'SET_INVITE_CODE':
            return {
                isLoading: false,
                status: 'success',
                response: action.payload.message
            };

        case 'SET_INVITE_CODE_ERROR':
            return {
                isLoading: false,
                status: 'fail',
                error: action.payload
            };

        case 'RESET_INVITE_CODE_ERROR':
            return {
                ...initialState
            };

        default:
            return state;
    }
}

export default inviteReducer;