import { GET_WALLET_BALANCE_ERROR, GET_WALLET_BALANCE } from '../../types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import WalletServices from '../../../services/wallet'

export const getWalletBalance = createAsyncThunk(
  '/profile/wallet-balance',
  async ({ skip, limit }, thunkAPI) => {
    try {
      const data = await WalletServices.getWallet()
      thunkAPI.dispatch({
        type: GET_WALLET_BALANCE,
        payload: data && data?.data
      })
      return { privateers: data && data?.data }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear()
        window.location.href = '/'
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch({
        type: GET_WALLET_BALANCE_ERROR,
        payload: message
      })
      return thunkAPI.rejectWithValue()
    }
  }
)
